import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { MyProjectsList } from './components/MyProjectsList';

export const MyProjects = (): JSX.Element | null => {
  const { t } = useTranslation();

  const saveToPngElementId = useId();

  return (
    <>
      <ViewHeader>
        <Heading1>{t('ImplementedProjects.MyProjects.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isTagFilterShown={true}
            isPhaseFilterShown={true}
            isStatusFilterShown={true}
            isResetFilterShown={true}
            isPeriodFilterShown={false}
            isPeriodRangeFilterShown={true}
            isIncludeMineOnlyFilterShown={false}
            isProjectEffectTakeHomeFilterShown={false}
            isCustomDimensionFiltersShown={false}
            isProjectEffectRealisationPhasesFilterShown={true}
          />
        </CardBody>
      </Card>

      <Card
        title={t('ImplementedProjects.MyProjects.Projects.Title')}
        canBeSavedAsPng={true}
        canBeSavedAsCsv={true}
        saveToPngElementId={saveToPngElementId}
      >
        <CardBody>
          <MyProjectsList saveToPngElementId={saveToPngElementId} />
        </CardBody>
      </Card>
    </>
  );
};

MyProjects.displayName = 'MyProjects';
