import { FeatureTestFlag1 } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureTestFlag1';
import { FeatureTestFlag2 } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureTestFlag2';
import { FeatureTestFlag3 } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureTestFlag3';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureGoalAiEvaluation } from './components/FeatureGoalAiEvaluation';
import { FeatureProjectIdeasReviewers } from './components/FeatureProjectIdeasReviewers';

import { FeatureProjectPeriodMonth } from './components/FeatureProjectPeriodMonth';
import { FeaturePublishFunctionality } from './components/FeaturePublishFunctionality';
import { FeatureTags } from './components/FeatureTags';

export const AdministrateFeatureFlag = (): JSX.Element | null => {
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const isShowDevDebugInfoFlagOn =
    useFlags()[flags.permanent.shared.showDevDebugInfo];

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('Administrate.FeatureFlag.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>{t('Administrate.FeatureFlag.Text')}</CardBody>
      </Card>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === 0}
          handleSelect={() => setSelectedTabIndex(0)}
        >
          {t('Administrate.FeatureFlag.Features.Tags.Title')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === 1}
          handleSelect={() => setSelectedTabIndex(1)}
        >
          {t('Administrate.FeatureFlag.Features.GoalAiEvaluation.Title')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === 2}
          handleSelect={() => setSelectedTabIndex(2)}
        >
          {t('Administrate.FeatureFlag.Features.ProjectIdeasReviewers.Title')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === 3}
          handleSelect={() => setSelectedTabIndex(3)}
        >
          {t('Administrate.FeatureFlag.Features.ProjectPeriodMonth.Title')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === 4}
          handleSelect={() => setSelectedTabIndex(4)}
        >
          {t('Administrate.FeatureFlag.Features.PublishFunctionality.Title')}
        </Tab>

        {isShowDevDebugInfoFlagOn && (
          <>
            <Tab
              isSelected={selectedTabIndex === 100}
              handleSelect={() => setSelectedTabIndex(100)}
            >
              {t('Administrate.FeatureFlag.Features.TestFlag1.Title')}
            </Tab>

            <Tab
              isSelected={selectedTabIndex === 101}
              handleSelect={() => setSelectedTabIndex(101)}
            >
              {t('Administrate.FeatureFlag.Features.TestFlag2.Title')}
            </Tab>

            <Tab
              isSelected={selectedTabIndex === 102}
              handleSelect={() => setSelectedTabIndex(102)}
            >
              {t('Administrate.FeatureFlag.Features.TestFlag3.Title')}
            </Tab>
          </>
        )}
      </Tabs>

      {selectedTabIndex === 0 && <FeatureTags />}
      {selectedTabIndex === 1 && <FeatureGoalAiEvaluation />}
      {selectedTabIndex === 2 && <FeatureProjectIdeasReviewers />}
      {selectedTabIndex === 3 && <FeatureProjectPeriodMonth />}
      {selectedTabIndex === 4 && <FeaturePublishFunctionality />}

      {isShowDevDebugInfoFlagOn && (
        <>
          {selectedTabIndex === 100 && <FeatureTestFlag1 />}
          {selectedTabIndex === 101 && <FeatureTestFlag2 />}
          {selectedTabIndex === 102 && <FeatureTestFlag3 />}
        </>
      )}
    </div>
  );
};

AdministrateFeatureFlag.displayName = 'AdministrateFeatureFlag';
