import { axiosBaseQueryWithReAuthentication } from '@dimatech/features-core/lib/middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '@dimatech/features-core/lib/middleware/axiosRequestInterceptor';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

// Initialize an empty Pios api service that we'll inject endpoints into

// TODO: Categorise these to make it easier to find the right one
export enum CacheTags {
  Tag = 'Tag',
  Portfolio = 'Portfolio',
  PortfolioOverview = 'PortfolioOverview',
  PortfolioOverviewByPeriod = 'PortfolioOverviewByPeriod',
  Project = 'Project',
  ProjectGoal = 'ProjectGoal',
  ProjectNote = 'ProjectNote',
  ProjectDocument = 'ProjectDocument',
  ProjectOverview = 'ProjectOverview',
  ProjectTimeline = 'ProjectTimeline',
  ProjectIdeaOverview = 'ProjectIdeaOverview',
  ProjectOverviewByPeriod = 'ProjectOverviewByPeriod',
  ProjectOverviewByTag = 'ProjectOverviewByTag',
  MyProjectOverview = 'MyProjectOverview',
  MyProjectOverviewByPeriod = 'MyProjectOverviewByPeriod',
  ProjectPeriod = 'ProjectPeriod',
  ProjectDimension = 'ProjectDimension',
  PortfolioHistory = 'PortfolioHistory',
  ProjectHistory = 'ProjectHistory',
  ProjectManager = 'ProjectManager',
  EffectRealisationResponsible = 'EffectRealisationResponsible',
  CustomerAccountAdmin = 'CustomerAccountAdmin',
  PortfolioOwner = 'User',
  Dimension = 'Dimension',
  Calculation = 'Calculation',
  Reviewer = 'Reviewer',
  ProjectEffectRealisationOverview = 'ProjectEffectRealisationOverview',
  OrderingParty = 'OrderingParty',
  ProjectIdeaOverviewArchive = 'ProjectIdeaOverviewArchive',
  NationalTags = 'NationalTags',
}

initializeAxiosConfig({ ...config.axiosDefaults });
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();

// Api
export const piosApi = createApi({
  reducerPath: 'piosApi',
  keepUnusedDataFor: 3600,
  tagTypes: [Object.values(CacheTags).join(',')],
  baseQuery: axiosBaseQueryWithReAuthentication({
    baseUrl: config.apiUrl.product.current,
  }),
  endpoints: () => ({}),
});
