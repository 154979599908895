import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalculationMutation } from 'models/calculation';
import { RootState } from 'store';

const initialState: {
  selectedCalculation?: CalculationMutation;
} = {};

export const { reducer: calculationReducer, actions: calculationActions } =
  createSlice({
    name: 'calculation',
    initialState,
    reducers: {
      selectedCalculation: (
        state,
        action: PayloadAction<CalculationMutation | undefined>
      ) => {
        state.selectedCalculation = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedCalculation = initialState.selectedCalculation;
      });
    },
  });

export const selectSelectedCalculation = (
  state: RootState
): CalculationMutation | undefined => state.calculation.selectedCalculation;
