import { CacheTags, piosApi } from 'api/piosApi';
import { ProjectNote } from 'models';

const projectNoteApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get notes for project
     */
    getNotes: build.query<
      ProjectNote[],
      {
        projectId: string;
        _customerId: string | undefined;
      }
    >({
      query: ({ projectId, _customerId }) => `projects/${projectId}/notes`,
      providesTags: [CacheTags.ProjectNote],
      transformResponse: (result: ProjectNote[]) => {
        return result.sort((a, b) =>
          (a.created ?? '') > (b.created ?? '') ? -1 : 1
        );
      },
    }),

    /**
     * Create new note
     */
    addNote: build.mutation({
      query: (note: ProjectNote) => ({
        url: `projects/${note.projectId}/notes`,
        method: 'POST',
        body: { content: note.content },
      }),
      invalidatesTags: [CacheTags.ProjectNote],
      transformResponse: (result: ProjectNote, _meta, arg) => {
        return {
          ...arg,
          id: result.id,
        };
      },
    }),

    /**
     * Update note
     */
    updateNote: build.mutation({
      query: (note: ProjectNote) => ({
        url: `projects/${note.projectId}/notes/${note.id}`,
        method: 'PUT',
        body: {
          ...note,
        },
      }),
      invalidatesTags: [CacheTags.ProjectNote],
    }),

    /**
     * Delete note
     */
    deleteNote: build.mutation({
      query: (note: ProjectNote) => ({
        url: `projects/${note.projectId}/notes/${note.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTags.ProjectNote],
    }),
  }),
});

export const {
  useGetNotesQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = projectNoteApi;
