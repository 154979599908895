import { HistoryLog } from '@dimatech/shared/lib/models';
import { CacheTags, piosApi } from 'api/piosApi';

const portfolioHistoryApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get project history
     */
    getProjectHistory: build.query<
      HistoryLog[],
      {
        portfolioId: string;
        projectId: string;
        _customerId: string | undefined;
      }
    >({
      query: ({ portfolioId, projectId, _customerId }) =>
        `portfolios/history?portfolioId=${portfolioId}&projectId=${projectId}`,
      providesTags: [CacheTags.ProjectHistory],
    }),

    /**
     * Get portfolio history
     */
    getPortfolioHistory: build.query<
      HistoryLog[],
      {
        portfolioId: string;
        _customerId: string | undefined;
      }
    >({
      query: ({ portfolioId, _customerId }) =>
        `portfolios/history?portfolioId=${portfolioId}`,
      providesTags: [CacheTags.PortfolioHistory],
    }),
  }),
});

export const { useGetProjectHistoryQuery, useGetPortfolioHistoryQuery } =
  portfolioHistoryApi;
