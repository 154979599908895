import { ProjectIdeas as ProjectIdeasFeature } from 'features/project-idea/Ideas';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectIdeas = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('FutureProjects.ProjectIdea.Title')}>
      <ProjectIdeasFeature />
    </View>
  );
};

ProjectIdeas.displayName = 'ProjectIdeas';
