import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  Input,
  Label,
  TextArea,
} from '@dimatech/shared/lib/components/form';
import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import {
  useAddCalculationMutation,
  useUpdateCalculationMutation,
} from 'api/calculation/calculationApi';
import {
  calculationActions,
  selectSelectedCalculation,
} from 'api/calculation/calculationSlice';
import {
  dimensionActions,
  selectSelectedClassification,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CalculationType, Dimension, DimensionDisplayType } from 'models';
import { CalculationMutation } from 'models/calculation';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil } from 'react-icons/bs';
import { isAdminReadOnly } from 'utils';
import { CalculationPropertiesEditButtons } from './CalculationPropertiesEditButtons';
import { WeightProperties } from './WeightProperties';

export const CalculationProperties = ({
  classifications,
  standardCalculationId,
}: {
  classifications: Dimension[];
  standardCalculationId: string | undefined;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addCalculation, { isLoading: addPosting, error: addErrors }] =
    useAddCalculationMutation();
  const [updateCalculation, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateCalculationMutation();

  const selectedCalculation = useAppSelector(selectSelectedCalculation);
  const selectedClassification = useAppSelector(selectSelectedClassification);
  const [calculation, setCalculation] = useState<
    CalculationMutation | undefined
  >();
  const isAdding = selectedCalculation && !selectedCalculation.id;
  const isPosting = updatePosting || addPosting;

  const [isValid, setIsValid] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  useEffect(() => {
    setCalculation(
      selectedCalculation ? { ...selectedCalculation } : undefined
    );
  }, [selectedCalculation]);

  const handleSave = (e: React.SyntheticEvent) => {
    if (isReadOnly) {
      return;
    }

    setIsValid(true);

    if (!calculation?.name) {
      setIsValid(false);
      return;
    }
    if (calculation?.id) {
      updateCalculation({
        ...calculation,
      })
        .unwrap()
        .then(() => {
          dispatch(
            calculationActions.selectedCalculation({
              ...calculation,
            })
          );
          setIsChangesSaved(true);
          setHasChanges(false);
        });
    } else {
      addCalculation({
        ...calculation,
      })
        .unwrap()
        .then((result) => {
          dispatch(
            calculationActions.selectedCalculation({
              ...calculation,
              ...result,
            })
          );
          setIsChangesSaved(true);
          setHasChanges(false);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    dispatch(calculationActions.selectedCalculation());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === 'Enter') {
      handleSave(e);
    }

    if (e?.key === 'Escape') {
      handleCancel(e);
    }
  };

  const handleSelect = (classification: Dimension) => {
    dispatch(
      dimensionActions.selectClassification(
        selectedClassification?.id === classification.id
          ? undefined
          : classification
      )
    );
  };

  return (
    <>
      {isValid && (
        <AlertErrors
          error={addErrors ?? updateErrors}
          translationPath="Calculation.ValidationError"
        />
      )}

      {calculation?.type === CalculationType.System && (
        <AlertInfo>{t(`Administrate.Calculation.StandardRule.Text`)}</AlertInfo>
      )}

      <Label htmlFor="name"> {t('Administrate.Calculation.Name')}</Label>
      <Input
        maxLength={100}
        autoFocus
        type="text"
        id="name"
        name="name"
        placeholder={t('Administrate.Calculation.NamePlaceholder')}
        value={calculation?.name ?? ''}
        invalid={!isValid && !calculation?.name}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setHasChanges(true);
          setCalculation({
            ...calculation,
            name: e.currentTarget.value,
          });
        }}
      />

      {calculation?.id && classifications.length > 0 && (
        <div style={{ margin: '20px 0 30px' }}>
          <TooltipContent
            id="tt-weight-edit"
            text={t('Administrate.Calculation.EditWeight')}
          />

          {t('Administrate.CustomDimension.ClassificationWeights')}

          <Table
            style={{
              width: 'fit-content',
              minWidth: 300,
              marginTop: 10,
            }}
          >
            <tbody>
              {[...classifications]
                .filter(
                  (classification) =>
                    classification.displayType === DimensionDisplayType.Rating
                )
                .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                .map((classification) => {
                  const weight = classification.calculationWeights?.find(
                    (w) => w.calculationId === calculation?.id
                  );
                  return (
                    <Fragment key={classification.id}>
                      <Tr
                        key={classification.id}
                        isSelected={
                          selectedClassification?.id === classification.id
                        }
                        onSelect={() => handleSelect(classification)}
                      >
                        <Td>{classification.name}</Td>
                        <TdRight>{weight?.weight ?? '-'}</TdRight>
                        <TdRight
                          style={{ display: 'flex', justifyContent: 'end' }}
                        >
                          <BadgeMini
                            style={{ marginLeft: '10px' }}
                            data-tip
                            data-for="tt-weight-edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelect(classification);
                            }}
                          >
                            <BsPencil />
                          </BadgeMini>
                        </TdRight>
                      </Tr>

                      {selectedClassification?.id === classification.id && (
                        <Tr>
                          <Td colSpan={3} style={{ padding: 0 }}>
                            <WeightProperties
                              classification={classification}
                              calculation={calculation}
                            />
                          </Td>
                        </Tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          </Table>
        </div>
      )}

      <Label htmlFor="description">
        {t('Administrate.Calculation.Description')}
      </Label>
      <div className="size-s i">
        {t('Administrate.Calculation.DescriptionInfo')}
      </div>
      <TextArea
        id="description"
        name="description"
        value={calculation?.description ?? ''}
        onChange={(e) => {
          setHasChanges(true);
          setCalculation({
            ...calculation,
            description: e.currentTarget.value,
          });
        }}
      />

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && calculation && (
          <Buttons style={{ justifyContent: 'flex-start' }}>
            <CalculationPropertiesEditButtons
              item={calculation}
              setSelectedItem={setCalculation}
              isReadOnly={isReadOnly}
            />
          </Buttons>
        )}

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={isReadOnly || isPosting || !hasChanges}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

CalculationProperties.displayName = 'CalculationProperties';
