import { Color } from '@dimatech/shared/lib/themes';
import { ProjectDimensionEdit } from 'features/project/components/ProjectDimensionEdit';
import {
  Dimension,
  DimensionDisplayType,
  DimensionType,
  Permission,
  ProjectIdea,
} from 'models';
import { CSSProperties } from 'styled-components';
import { hasPermission } from 'utils';
import { IdeaProgress } from './IdeaProgress';

export const IdeaClassification = ({
  idea,
  setIdea,
  classification,
  isEditing = false,
  showEmpty = false,
  width,
  style,
}: {
  idea: ProjectIdea;
  setIdea?: (idea: ProjectIdea) => void;
  classification: Dimension;
  isEditing?: boolean;
  showEmpty?: boolean;
  width?: string | number;
  style?: CSSProperties;
}): JSX.Element => {
  const value =
    idea.customDimensions?.find(
      (cd) => cd.projectDimensionId === classification.id
    )?.value ?? '';

  const handleChangeDimension = (
    idea: ProjectIdea,
    dimension: Dimension,
    value?: string
  ) => {
    if (!isEditing || !setIdea) {
      return;
    }

    const newDimensions = [...(idea.customDimensions ?? [])];
    const index = newDimensions.findIndex(
      (d) => d.projectDimensionId === dimension.id
    );

    if (index > -1) {
      newDimensions[index] = {
        projectDimensionId: dimension.id,
        value,
      };
    } else {
      newDimensions.push({
        projectDimensionId: dimension.id,
        value,
      });
    }

    const newIdea = {
      ...idea,
      customDimensions: newDimensions,
    } as ProjectIdea;

    setIdea(newIdea);
  };

  return (
    <>
      {isEditing && hasPermission(Permission.EditIdea, idea) && (
        <ProjectDimensionEdit
          isLabelShown={false}
          dimension={{
            ...classification,
            value,
            projectDimensionId: classification.id,
          }}
          style={{
            minWidth:
              classification.displayType === DimensionDisplayType.Text
                ? 150
                : 80,
            width:
              classification.displayType === DimensionDisplayType.Text
                ? '100%'
                : 80,
            ...style,
          }}
          handleChange={(value) => {
            handleChangeDimension(idea, classification, value || undefined);
          }}
        />
      )}

      {!isEditing && value && (
        <>
          {classification.displayType === DimensionDisplayType.Rating ? (
            <IdeaProgress
              width={width}
              showEmpty={showEmpty}
              value={+value}
              singleValue={true}
              style={style}
              color={
                classification.type === DimensionType.ClassificationStake
                  ? Color.KojiOrange
                  : undefined
              }
            />
          ) : (
            value
          )}
        </>
      )}

      {!isEditing && !value && showEmpty && <>-</>}
    </>
  );
};

IdeaClassification.displayName = 'IdeaClassification';
