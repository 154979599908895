import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { SelectPortfolio } from 'components/SelectPortfolio';
import { useTranslation } from 'react-i18next';

export const IdeaConvertConfirmation = ({
  isConverting,
  handleSave,
  portfolioId,
  setPortfolioId,
}: {
  isConverting: boolean;
  handleSave: (isConfirmed: boolean) => void;
  portfolioId?: string;
  setPortfolioId: (portfolioId?: string) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (!isConverting) {
    return null;
  }

  return (
    <Modal
      title={t('FutureProjects.ProjectIdea.Convert.Title')}
      handleKeyEnter={() => handleSave(true)}
      handleKeyEsc={() => handleSave(false)}
    >
      <div>{t('FutureProjects.ProjectIdea.Convert.ConfirmText')}</div>

      <SelectPortfolio
        setPortfolio={(portfolio) => setPortfolioId(portfolio?.id)}
        portfolioId={portfolioId}
        style={{ marginTop: 20 }}
      />

      <Buttons>
        <ButtonSecondary type="button" onClick={() => handleSave(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button
          type="button"
          onClick={() => portfolioId && handleSave(true)}
          disabled={!portfolioId}
        >
          {t('Common.UI.Yes')}
        </Button>
      </Buttons>
    </Modal>
  );
};

IdeaConvertConfirmation.displayName = 'IdeaConvertConfirmation';
