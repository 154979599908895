var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsTag, featuresCoreApi } from '../featuresCoreApi';
var tagsApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get tags
         */
        getTags: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_customerId) { return 'tags'; },
            transformResponse: function (result) {
                return result.sort(function (a, b) {
                    return a.displayName > b.displayName ? 1 : -1;
                });
            },
            providesTags: [CacheTagsTag.Tag],
        }),
        /**
         * Create new tag
         */
        addTag: build.mutation({
            query: function (tag) { return ({
                url: "tags",
                method: 'POST',
                body: tag,
            }); },
            invalidatesTags: [CacheTagsTag.Tag],
            transformResponse: function (result, _meta, arg) {
                return __assign(__assign({}, arg), result);
            },
        }),
        /**
         * Update tag
         */
        updateTag: build.mutation({
            query: function (tag) { return ({
                url: "tags/".concat(tag.id),
                method: 'PUT',
                body: {
                    displayName: tag.displayName,
                    description: tag.description,
                },
            }); },
            invalidatesTags: [CacheTagsTag.Tag],
        }),
        /**
         * Delete tag
         */
        deleteTag: build.mutation({
            query: function (tag) { return ({
                url: "tags/".concat(tag.id),
                method: 'DELETE',
            }); },
            invalidatesTags: [CacheTagsTag.Tag],
        }),
        /**
         * Move tag
         */
        moveTag: build.mutation({
            query: function (_a) {
                var tagId = _a.tagId, parentId = _a.parentId;
                return ({
                    url: "tags/".concat(tagId, "/move/").concat(parentId),
                    method: 'PUT',
                });
            },
            invalidatesTags: [CacheTagsTag.Tag],
            transformResponse: function (_result, _meta, arg) {
                return {
                    id: arg.tagId,
                    parentId: !arg.parentId ? null : arg.parentId,
                };
            },
        }),
    }); },
});
export var useGetTagsQuery = tagsApi.useGetTagsQuery, useAddTagMutation = tagsApi.useAddTagMutation, useUpdateTagMutation = tagsApi.useUpdateTagMutation, useDeleteTagMutation = tagsApi.useDeleteTagMutation, useMoveTagMutation = tagsApi.useMoveTagMutation;
