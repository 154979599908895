import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeLarge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { HistoryChangeLog } from '@dimatech/shared/lib/components/history';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  CardRow,
  CardTile,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectSelectedProjectIdea } from 'api/piosSlice';
import { useGetProjectHistoryQuery } from 'api/portfolio/portfolioHistoryApi';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppSelector } from 'hooks';
import { HistoryLog, ProjectIdeaAction } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { parseHistoryChange } from 'utils';

export const IdeaHistory = (): JSX.Element | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const selectAndNavigate = useSelectAndNavigate();

  const { accessToken } = useContext(AuthenticationContext);
  const { locale } = useContext(LocalizationContext);

  const idea = useAppSelector(selectSelectedProjectIdea);

  const [projectHistory, setProjectHistory] = useState<HistoryLog[]>();

  const {
    data: history,
    isLoading,
    isFetching,
  } = useGetProjectHistoryQuery(
    accessToken.customerId && accessToken.user?.id && idea
      ? {
          portfolioId: idea.portfolioId as string,
          projectId: idea.id as string,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handleCancel = () => {
    selectAndNavigate(ProjectIdeaAction.Identify, idea?.id ? idea : undefined);
  };

  useEffect(() => {
    if (history) {
      const parsed = history.map((historyUpdate) => ({
        ...historyUpdate,
        changes: historyUpdate.changes.map((change) => ({
          ...change,
          metaData: parseHistoryChange(change, accessToken.locale, t),
        })),
      }));

      setProjectHistory(parsed);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locale]);

  if (isLoading || isFetching) {
    return <Loader>{t('Common.UI.Loading')}</Loader>;
  }

  return (
    <Style>
      <ViewHeader style={{ marginLeft: 0, marginTop: 0 }}>
        <div>
          <TooltipContent id="tt-navigate-back" text={t('Common.UI.Back')} />
          <BadgeSmall
            data-tip
            data-for="tt-navigate-back"
            onClick={handleCancel}
            $inverted={true}
            style={{ marginRight: 15 }}
          >
            <BsArrowLeft />
          </BadgeSmall>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Heading1>{t('History.HistoryFor', { name: idea?.title })}</Heading1>
          {idea && (
            <ButtonLink
              onClick={() => navigate(`/portfolio/${idea.portfolioId}`)}
            >
              {idea.portfolioName}
            </ButtonLink>
          )}
        </div>

        <ViewHeaderActions style={{ marginBottom: 0, marginRight: 10 }}>
          <div
            style={{
              display: 'flex',
              gap: 10,
            }}
          >
            <TooltipContent id="tt-cancel" text={t('Common.Form.Cancel')} />

            <BadgeLarge
              data-tip
              data-for="tt-cancel"
              onClick={handleCancel}
              $inverted={true}
            >
              <BsX />
            </BadgeLarge>
          </div>
        </ViewHeaderActions>
      </ViewHeader>

      <CardRow>
        <CardTile>
          {projectHistory && (
            <HistoryChangeLog
              history={projectHistory}
              currentUserEmail={accessToken.user?.email || ''}
            />
          )}
        </CardTile>
      </CardRow>
    </Style>
  );
};

IdeaHistory.displayName = 'IdeaHistory';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 30px 10px;
  margin: -10px;

  column-gap: 15px;
  row-gap: 15px;

  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

  label {
    font-weight: bold;
  }
`;
