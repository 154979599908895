import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import {
  dimensionActions,
  selectSelectedClassification,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dimension, DimensionDisplayType, DimensionType } from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { isAdminReadOnly, projectIdeaPhases } from 'utils';
import { mergeDimensionCalculations } from 'utils/dimension';
import { TabContentStyle } from './TabContentStyle';
import { ClassificationProperties } from './components/ClassificationProperties';
import { ClassificationsList } from './components/ClassificationsList';

export const Classifications = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [classifications, setClassifications] = useState<Dimension[]>([]);
  const selectedClassification = useAppSelector(selectSelectedClassification);

  const { data, isFetching, isLoading } = useGetDimensionsQuery(
    accessToken.customerId ?? skipToken
  );

  const { data: calculations } = useGetCalculationsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const handleCreate = () => {
    if (isReadOnly) {
      return;
    }

    dispatch(
      dimensionActions.selectClassification({
        projectPhases: projectIdeaPhases,
        type: DimensionType.ClassificationBenefit,
        displayType: DimensionDisplayType.Rating,
        calculationWeights: calculations?.map((calculation) => ({
          calculationId: calculation.id,
          calculationName: calculation.name,
          calculationDescription: calculation.description,
        })),
      })
    );
  };

  useEffect(() => {
    setClassifications([
      ...(data
        ?.filter(
          (dimension) =>
            dimension.type === DimensionType.ClassificationBenefit ||
            dimension.type === DimensionType.ClassificationStake
        )
        .map((dimension) => ({
          ...dimension,
          calculationWeights: mergeDimensionCalculations(
            dimension,
            calculations ?? []
          ),
          values: dimension.values?.map((value) => ({
            ...value,
            uid: value.id,
          })),
        })) ?? []),
    ]);
  }, [data, calculations]);

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <Trans i18nKey="Administrate.CustomDimension.TextClassification" />
      </div>

      <LinkWithTooltip
        isPrimary={true}
        isDisabled={isReadOnly}
        handleClick={handleCreate}
        title={t('Administrate.CustomDimension.AddClassification.TooltipTitle')}
        tooltipTitle={t(
          'Administrate.CustomDimension.AddClassification.TooltipTitle'
        )}
        tooltip={t('Administrate.CustomDimension.AddClassification.Tooltip')}
        icon={<BsPlus />}
        style={{ marginBottom: 20 }}
      />

      {(isFetching || isLoading) && <LoaderOverlay />}

      {(!classifications || classifications.length === 0) &&
      !selectedClassification ? (
        <>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.CustomDimension.NoClassifications')}
          </div>
          <ButtonLink type="button" onClick={handleCreate}>
            {t('Administrate.CustomDimension.NoClassificationsAddFirst')}
          </ButtonLink>
        </>
      ) : (
        <TabContentStyle>
          <ClassificationsList classifications={classifications} />

          {selectedClassification && (
            <div style={{ flexGrow: 1 }}>
              <ClassificationProperties calculations={calculations} />
            </div>
          )}
        </TabContentStyle>
      )}
    </>
  );
};

Classifications.displayName = 'Classifications';
