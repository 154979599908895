var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { flags } from '../../feature-flags';
import { Color } from '../../themes';
import { Tooltip } from '../tooltip';
import { GanttCategory } from './GanttCategory';
export var Gantt = function (_a) {
    var categories = _a.categories, config = _a.config, handleSelectItem = _a.handleSelectItem, handleSelectCategory = _a.handleSelectCategory, saveToPngElementId = _a.saveToPngElementId;
    var isStartAndEndMonthEnabledFlagOn = useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];
    var _b = useState(), highlightedYear = _b[0], setHighlightedYear = _b[1];
    var mapped = categories
        .map(function (category) {
        var items = category.items
            .map(function (item) {
            return __assign(__assign({}, item), { startYear: Math.min.apply(Math, item.sections.map(function (s) { return s.startYear; })), endYear: Math.max.apply(Math, item.sections.map(function (s) { return s.endYear; })), startMonth: Math.min.apply(Math, item.sections.map(function (s) { return s.startMonth || 1; })), endMonth: Math.max.apply(Math, item.sections.map(function (s) { return s.endMonth || 12; })) });
        })
            // Sort by "startYear", "endYear" and last "title"
            .sort(function (a, b) {
            return a.startYear === b.startYear
                ? a.endYear === b.endYear
                    ? a.title > b.title
                        ? 1
                        : -1
                    : a.endYear - b.endYear
                : a.startYear - b.startYear;
        });
        var startYear = Math.min.apply(Math, items.map(function (item) { return item.startYear; }));
        var endYear = Math.max.apply(Math, items.map(function (item) { return item.endYear; }));
        var startMonth = items
            .filter(function (el) { return el.startYear === startYear; })
            .map(function (el) { return el.startMonth; })
            .reduce(function (minMonth, currentMonth) { return Math.min(minMonth, currentMonth); }, Infinity);
        var endMonth = items
            .filter(function (el) { return el.endYear === endYear; })
            .map(function (el) { return el.endMonth; })
            .reduce(function (maxMonth, currentMonth) { return Math.max(maxMonth, currentMonth); }, -Infinity);
        var years = Array.from(new Array(endYear - startYear + 1), function (_, i) { return i + startYear; });
        return __assign(__assign({}, category), { startYear: startYear, endYear: endYear, startMonth: startMonth, endMonth: endMonth, years: years, items: items });
    })
        // Sort by "startYear", "endYear" and last "title"
        .sort(function (a, b) {
        return a.startYear === b.startYear
            ? a.endYear === b.endYear
                ? a.title > b.title
                    ? 1
                    : -1
                : a.endYear - b.endYear
            : a.startYear - b.startYear;
    });
    var startYear = Math.min.apply(Math, __spreadArray(__spreadArray([], mapped.map(function (category) { return category.startYear; }), false), [new Date().getFullYear()], false));
    var endYear = Math.max.apply(Math, __spreadArray(__spreadArray([], mapped.map(function (category) { return category.endYear; }), false), [new Date().getFullYear()], false));
    var years = Array.from(new Array(endYear - startYear + 1), function (_, i) { return i + startYear; });
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, {}), config && config.labels.length > 0 && (_jsx(LegendStyle, { children: config.labels.map(function (label, index) { return (_jsxs("div", { children: [_jsx("div", __assign({ style: { backgroundColor: label.color } }, { children: "\u00A0" })), _jsx("div", { children: label.text })] }, index)); }) })), _jsx(ResponsiveContainer, { children: _jsx(TableStyle, __assign({ id: saveToPngElementId }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx(TdEmpty, {}), years.map(function (year) { return (_jsx(HeaderCellStyle, __assign({ isHighlighted: highlightedYear === year }, { children: _jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx("div", { children: year }), isStartAndEndMonthEnabledFlagOn && (_jsx(StyleMonth, __assign({ isHighlighted: highlightedYear === year, style: {
                                                        borderTop: 'none',
                                                        padding: '0 2px',
                                                    } }, { children: Array.from({ length: 12 }, function (_, i) { return (_jsx(StyleMonth, __assign({ isHighlighted: highlightedYear === year, style: { padding: '3px 2px' } }, { children: i + 1 }), i)); }) })))] })) }), year)); })] }), mapped.map(function (category, i) {
                                return (_jsx(GanttCategory, { years: years, category: category, setHighlightedYear: setHighlightedYear, highlightedYear: highlightedYear, handleSelectItem: handleSelectItem, handleSelectCategory: handleSelectCategory }, i));
                            })] }) })) })] }));
};
Gantt.displayName = 'Gantt';
var LegendStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n\n  gap: 15px;\n  margin-bottom: 30px;\n\n  > div {\n    display: flex;\n    gap: 5px;\n\n    > div:first-child {\n      width: 20px;\n      height: 20px;\n      border-radius: 4px;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n\n  gap: 15px;\n  margin-bottom: 30px;\n\n  > div {\n    display: flex;\n    gap: 5px;\n\n    > div:first-child {\n      width: 20px;\n      height: 20px;\n      border-radius: 4px;\n    }\n  }\n"])));
var ResponsiveContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: auto;\n  width: 80vw;\n"], ["\n  overflow: auto;\n  width: 80vw;\n"])));
var TableStyle = styled.table(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  border-top: 1px solid ", ";\n\n  td:first-child {\n    border-right: none;\n    position: sticky;\n    left: 0;\n    &::before {\n      content: '';\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      border-right: 1px solid\n        ", ";\n      pointer-events: none;\n      box-sizing: border-box;\n    }\n  }\n"], ["\n  border-bottom: 1px solid ", ";\n  border-top: 1px solid ", ";\n\n  td:first-child {\n    border-right: none;\n    position: sticky;\n    left: 0;\n    &::before {\n      content: '';\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      border-right: 1px solid\n        ", ";\n      pointer-events: none;\n      box-sizing: border-box;\n    }\n  }\n"])), Color.Grey20, Color.Grey20, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
});
var HeaderCellStyle = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n\n  padding: 6px 3px;\n\n  text-align: center;\n\n  ", "\n\n  ", "\n"], ["\n  color: ", ";\n  background-color: ", ";\n\n  padding: 6px 3px;\n\n  text-align: center;\n\n  ", "\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (props) {
    return !props.isHighlighted && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      &:nth-child(odd) {\n        color: ", ";\n        background-color: ", ";\n      }\n    "], ["\n      &:nth-child(odd) {\n        color: ", ";\n        background-color: ", ";\n      }\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.onSurface;
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors.surface;
    });
}, function (props) {
    return props.isHighlighted && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n    "], ["\n      color: ", ";\n      background-color: ", ";\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.onPrimary;
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors.primary;
    });
});
var StyleMonth = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border-top: 1px solid ", ";\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  border-top: 1px solid ", ";\n\n  ", "\n"])), Color.Grey20, function (props) {
    return props.isHighlighted && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.onPrimary;
    });
});
var TdEmpty = styled.td(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
