var _a;
import { createSlice } from '@reduxjs/toolkit';
import { appActions } from '../appSlice';
var initialState = {};
export var tagReducer = (_a = createSlice({
    name: 'tag',
    initialState: initialState,
    reducers: {
        selectedTag: function (state, action) {
            state.selectedTag = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function (state) {
            state.selectedTag = initialState.selectedTag;
        });
    },
}), _a.reducer), tagActions = _a.actions;
export var selectSelectedTag = function (state) { return state.tag.selectedTag; };
