import { jsx as _jsx } from "react/jsx-runtime";
import { BsFile, BsFileSlides, BsFileSpreadsheet, BsFileText, BsFilm, } from 'react-icons/bs';
export var LinkIcon = function (_a) {
    var linkType = _a.linkType, style = _a.style;
    var getIcon = function () {
        if (['Spreadsheet'].includes(linkType)) {
            return _jsx(BsFileSpreadsheet, { style: style });
        }
        if (['Presentation'].includes(linkType)) {
            return _jsx(BsFileSlides, { style: style });
        }
        if (['Document'].includes(linkType)) {
            return _jsx(BsFileText, { style: style });
        }
        if (['Video'].includes(linkType)) {
            return _jsx(BsFilm, { style: style });
        }
        return _jsx(BsFile, { style: style });
    };
    return getIcon();
};
LinkIcon.displayName = 'LinkIcon';
