var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HistoryLogChangeType } from 'models';
import { BsArrowRight } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { Color } from '../../themes';
export var HistoryChangeLogChange = withTheme(function (_a) {
    var change = _a.change, _b = _a.isInverted, isInverted = _b === void 0 ? false : _b, theme = _a.theme;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ style: {
                display: 'flex',
                flexWrap: 'wrap',
                gap: 5,
            } }, { children: [(change.metaData.type === HistoryLogChangeType.AddToSet ||
                    change.metaData.type === HistoryLogChangeType.Create ||
                    change.metaData.type === HistoryLogChangeType.Copied) &&
                    change.metaData.newValue && (_jsx(_Fragment, { children: _jsx(ValueStyle, __assign({ style: {
                            backgroundColor: theme.colors.success,
                            color: theme.colors.onSuccess,
                        } }, { children: change.metaData.newValue })) })), (change.metaData.type === HistoryLogChangeType.RemoveFromSet ||
                    change.metaData.type === HistoryLogChangeType.Delete) && (_jsx(_Fragment, { children: _jsx(ValueStyle, __assign({ style: {
                            backgroundColor: Color.Pippin,
                            color: theme.colors.error,
                        } }, { children: change.metaData.oldValue })) })), change.metaData.type === HistoryLogChangeType.Update && (_jsxs(_Fragment, { children: [_jsx(ValueStyle, __assign({ style: {
                                backgroundColor: isInverted
                                    ? theme.colors.surfaceVariant
                                    : theme.colors.background,
                                color: isInverted
                                    ? theme.colors.onSurfaceVariant
                                    : theme.colors.onBackground,
                            } }, { children: change.metaData.oldValue })), _jsx(ValueStyle, __assign({ style: { minWidth: 15, padding: '5px 0' } }, { children: _jsx(BsArrowRight, {}) })), _jsx(ValueStyle, __assign({ style: {
                                backgroundColor: theme.colors.success,
                                color: theme.colors.onSuccess,
                            } }, { children: change.metaData.newValue }))] }))] })) }));
});
HistoryChangeLogChange.displayName = 'HistoryChangeLogChange';
var ValueStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 5px 8px;\n  min-height: 25px;\n  min-width: 25px;\n"], ["\n  padding: 5px 8px;\n  min-height: 25px;\n  min-width: 25px;\n"])));
var templateObject_1;
