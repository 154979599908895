import { base, Color, Theme, ThemeType } from '@dimatech/shared/lib/themes';

// Light theme, only overrides necessary properties
export const light: Theme = {
  ...base,
  name: 'Pios Light',
  type: ThemeType.Light,

  size: {
    viewMaxWidth: '2800px',
  },

  colors: {
    ...base.colors,

    primary: Color.RadicalRed,
    primaryVariant: Color.DimiosBlue,
    onPrimary: Color.White,

    secondary: Color.DarkTurquoise,
    secondaryVariant: Color.SeaGreen,
    onSecondary: Color.White,

    chart: {
      ...base.colors.chart,

      primary: Color.SeaGreen,
      primaryHighlight: Color.SeaGreenDark,

      secondary: Color.DimiosBlue,
      secondaryHighlight: Color.DimiosBlueDark,
    },
  },
};
