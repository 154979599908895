import { FeatureFlag } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureFlag';
import {
  ImageCarousel,
  ImageCarouselBody,
  ImageCarouselFooter,
  ImageCarouselItem,
} from '@dimatech/shared/lib/components/carousel';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import screenshot1 from './feature-flag-tags-1.png';
import screenshot2 from './feature-flag-tags-2.png';
import screenshot3 from './feature-flag-tags-3.png';
import screenshot4 from './feature-flag-tags-4.png';

export const FeatureTags = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <Trans i18nKey="Administrate.FeatureFlag.Features.Tags.Description" />

          <ImageCarousel
            style={{
              maxWidth: 600,
            }}
          >
            <ImageCarouselItem>
              <ImageCarouselBody>
                <Image
                  style={{ maxWidth: 400 }}
                  src={screenshot1}
                  alt={t('Administrate.FeatureFlag.Features.Tags.Title')}
                />
              </ImageCarouselBody>

              <ImageCarouselFooter>
                {t('Administrate.FeatureFlag.Features.Tags.Description2')}
              </ImageCarouselFooter>
            </ImageCarouselItem>

            <ImageCarouselItem>
              <ImageCarouselBody>
                <Image
                  style={{ maxWidth: 250 }}
                  src={screenshot2}
                  alt={t('Administrate.FeatureFlag.Features.Tags.Title')}
                />
              </ImageCarouselBody>

              <ImageCarouselFooter>
                {t('Administrate.FeatureFlag.Features.Tags.Description3')}
              </ImageCarouselFooter>
            </ImageCarouselItem>

            <ImageCarouselItem>
              <ImageCarouselBody>
                <Image
                  style={{ maxWidth: 250 }}
                  src={screenshot3}
                  alt={t('Administrate.FeatureFlag.Features.Tags.Title')}
                />
              </ImageCarouselBody>

              <ImageCarouselFooter>
                {t('Administrate.FeatureFlag.Features.Tags.Description4')}
              </ImageCarouselFooter>
            </ImageCarouselItem>

            <ImageCarouselItem>
              <ImageCarouselBody>
                <Image
                  style={{ maxWidth: 250 }}
                  src={screenshot4}
                  alt={t('Administrate.FeatureFlag.Features.Tags.Title')}
                />
              </ImageCarouselBody>

              <ImageCarouselFooter>
                {t('Administrate.FeatureFlag.Features.Tags.Description4')}
              </ImageCarouselFooter>
            </ImageCarouselItem>
          </ImageCarousel>
        </div>

        <FeatureFlag featureFlagKey={flags.permanent.app.pios.isTagsEnabled} />
      </CardBody>
    </Card>
  );
};

FeatureTags.displayName = 'FeatureTags';

const Image = styled.img`
  display: inline-block;
  max-width: 450px;
  margin: 10px;
  box-shadow: ${(props) => props.theme.boxShadows.default};
`;
