import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { OverviewByPeriod } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency, formatAsPercentRange } from 'utils';

export const BudgetAndBalanceOverTimeTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    payload: OverviewByPeriod;
  }[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  if (active && payload && payload.length > 0) {
    const item = payload[0].payload;

    return (
      <TooltipStyle>
        <div className="tooltip-title">{item.period}</div>

        <div>
          {t('Statistics.AverageBudget', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          : {formatAsCurrency(item.averageBudget)}
        </div>
        <div>
          {t('Portfolio.InternalExternal')}:{' '}
          {formatAsPercentRange(item.internalExternal)}
        </div>
        <div>
          {t('Portfolio.EfficiencyInnovation')}:{' '}
          {formatAsPercentRange(item.efficiencyInnovation)}
        </div>
      </TooltipStyle>
    );
  }

  return null;
};

BudgetAndBalanceOverTimeTooltip.displayName = 'BudgetAndBalanceOverTimeTooltip';
