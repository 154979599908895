var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, Buttons, ButtonSecondary, Label, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonPlus } from 'react-icons/bs';
import { SearchUser } from '../../../components/SearchUser';
export var UserAdd = function (_a) {
    var feature = _a.feature, setFeature = _a.setFeature;
    var t = useTranslation().t;
    var _b = useState(false), isAdding = _b[0], setIsAdding = _b[1];
    var _c = useState(null), user = _c[0], setUser = _c[1];
    var _d = useState(''), validationError = _d[0], setValidationError = _d[1];
    var handleAdd = function (e) {
        e.stopPropagation();
        setValidationError('');
        if (!(user === null || user === void 0 ? void 0 : user.email)) {
            return;
        }
        if (feature.users && feature.users.some(function (us) { return us === user.email; })) {
            setValidationError('ErrorSameEmail');
            setIsAdding(false);
            return;
        }
        var newUsers = feature.users
            ? __spreadArray(__spreadArray([], feature.users, true), [user.email], false) : [user.email];
        setFeature(__assign(__assign({}, feature), { users: newUsers }));
        setIsAdding(false);
    };
    useEffect(function () {
        setValidationError('');
    }, [feature]);
    return (_jsxs("div", { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: _jsx(LinkWithTooltip, { handleClick: function () { return setIsAdding(true); }, title: t('Administrate.FeatureFlag.Exception.Add.Title'), tooltipTitle: t('Administrate.FeatureFlag.Exception.Add.TooltipTitle'), tooltip: t('Administrate.FeatureFlag.Exception.Add.Tooltip'), isPrimary: true, icon: _jsx(BsPersonPlus, {}), isDisabled: !feature.isEnabled }) })), validationError && (_jsx(AlertWarning, __assign({ style: { marginTop: 15, marginBottom: 15 } }, { children: t("Administrate.FeatureFlag.Exception.".concat(validationError)) }))), isAdding && (_jsxs(Modal, __assign({ title: t('Administrate.FeatureFlag.Exception.Add.Title'), handleKeyEnter: handleAdd, handleKeyEsc: function () { return setIsAdding(false); } }, { children: [_jsx("div", { children: t('Administrate.FeatureFlag.Exception.Add.ConfirmText') }), _jsx(Label, { children: t('Administrate.FeatureFlag.Exception.Add.Email') }), _jsx(SearchUser, { user: user, setUser: setUser, admin: true }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsAdding(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleAdd }, { children: t('Administrate.FeatureFlag.Exception.Add.Title') }))] })] })))] }));
};
UserAdd.displayName = 'UserAdd';
