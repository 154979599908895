import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparatorSlim,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles, View } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArchive, BsClipboardCheck, BsLightbulb } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { isProjectIdeaArchiveAvailable } from 'utils';

export const NavigationFuture = ({
  isReader,
  defaultView,
}: {
  isReader: boolean;
  defaultView: View;
}): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isReviewer = accessToken.isInRole(CommonRoles.PiosReviewer);
  const isProjectOrderingParty = accessToken.isInRole(
    CommonRoles.PiosOrderingParty
  );

  const isReaderOrOrderingParty = isReader || isProjectOrderingParty;

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
  const isStructuredNavigationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStructuredNavigationEnabled];

  if (!isReaderOrOrderingParty) {
    return null;
  }

  return (
    <>
      {!isStructuredNavigationEnabledFlagOn && (
        <>
          <NavigationSeparatorSlim />

          <NavigationHeader>{t('Navigation.FutureProjects')}</NavigationHeader>
        </>
      )}

      {isStructuredNavigationEnabledFlagOn && (
        <NavigationHeader style={{ paddingTop: 0, marginTop: 0 }}>
          {t('Navigation.FutureProjects')}
        </NavigationHeader>
      )}

      <NavigationItem
        $selected={
          location.pathname.startsWith('/project-idea') &&
          !location.pathname.startsWith('/project-idea/archive') &&
          !location.pathname.startsWith('/project-idea/review')
        }
        $disabled={accessToken.isBaseLicense}
      >
        <BsLightbulb />
        <Link to="/project-idea/identify">{t('Navigation.ProjectIdeas')}</Link>
      </NavigationItem>

      {isProjectIdeasReviewersEnabledFlagOn &&
        (isReviewer || isCustomerAccountAdmin) && (
          <NavigationItem
            $selected={
              location.pathname.startsWith('/project-idea/review') ||
              (location.pathname === '/' &&
                defaultView === View.ProjectIdeasReview)
            }
            $disabled={accessToken.isBaseLicense}
          >
            <BsClipboardCheck />
            <Link to="/project-idea/review">
              {t('Navigation.ProjectIdeasReview')}
            </Link>
          </NavigationItem>
        )}

      {isProjectIdeaArchiveAvailable(accessToken) && (
        <NavigationItem
          $selected={location.pathname.startsWith('/project-idea/archive')}
          $disabled={accessToken.isBaseLicense}
        >
          <BsArchive />
          <Link to="/project-idea/archive">
            {t('Navigation.ProjectIdeasArchive')}
          </Link>
        </NavigationItem>
      )}
    </>
  );
};

NavigationFuture.displayName = 'NavigationFuture';
