import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGoalsQuery } from 'api/project/projectGoalApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, PiosColors } from 'models';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFlag } from 'react-icons/bs';
import styled from 'styled-components';
import {
  formatAsCurrency,
  innovationEfficiencyToPercent,
  internalExternalToPercent,
} from 'utils';
import { GoalBenefits } from './GoalBenefits';

export const GoalListEffectRealisation = ({
  projectId,
  projectPhase,
}: {
  projectId?: string;
  projectPhase?: Phase | null;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const [selectedGoalId, setSelectedGoalId] = useState<string>();

  const { data: goals, isFetching } = useGetGoalsQuery(
    accessToken.customerId &&
      projectId &&
      isProjectEffectRealisationEnabledFlagOn
      ? {
          projectId,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handleSelectGoal = (toGoalId: string) => {
    if (toGoalId === selectedGoalId) {
      setSelectedGoalId(undefined);
    } else {
      setSelectedGoalId(toGoalId);
    }
  };

  if (isFetching) {
    return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
  }

  if (!isProjectEffectRealisationEnabledFlagOn) {
    return null;
  }

  return (
    <>
      <Heading4 style={{ marginBottom: 10 }}>
        {t('Project.Goals.GoalsAndBenefits')}
      </Heading4>

      <div>{t('Portfolio.Goal.TextForGoalsAndBenefits')}</div>

      {goals && goals.length === 0 && <div>{t('Portfolio.Goal.NoGoals')}</div>}

      <TableResponsiveContainer>
        <TableStyle>
          {goals && goals.length > 0 && (
            <thead>
              <tr>
                <Th style={{ width: 25 }} />

                <Th style={{ width: '100%' }}>{t('Portfolio.Goal.Name')}</Th>

                <ThRight>
                  {t('ImplementedProjects.ExpectedBenefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                <ThRight>
                  {t('ImplementedProjects.ActualBenefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                {(projectPhase === Phase.Finished ||
                  projectPhase === Phase.FinishedEffectRealizationStarted ||
                  projectPhase ===
                    Phase.FinishedEffectRealizationConcluded) && (
                  <ThRight>
                    {t('ImplementedProjects.Deviation', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                )}
                <ThRight>{t('ImplementedProjects.TakeHomePeriodYear')}</ThRight>

                <ThCentered style={{ minWidth: 120 }}>
                  {t('Project.EfficiencyInnovation')}
                </ThCentered>
                <ThCentered style={{ minWidth: 120 }}>
                  {t('Project.InternalExternal')}
                </ThCentered>
              </tr>
            </thead>
          )}
          <tbody>
            {goals?.map((goal) => (
              <Fragment key={goal.id}>
                <Tr
                  isExpanded={selectedGoalId === goal.id}
                  isExpandable={true}
                  onSelect={() => {
                    handleSelectGoal(goal.id as string);
                  }}
                >
                  <TdCentered className="narrow">
                    <BsFlag />
                  </TdCentered>

                  <Td>{goal.name}</Td>

                  <TdRight>
                    {formatAsCurrency(goal.totalExpectedBenefit)}
                  </TdRight>
                  <TdRight>{formatAsCurrency(goal.totalActualBenefit)}</TdRight>

                  {(projectPhase === Phase.Finished ||
                    projectPhase === Phase.FinishedEffectRealizationStarted ||
                    projectPhase ===
                      Phase.FinishedEffectRealizationConcluded) && (
                    <TdRight>{formatAsCurrency(goal.deviation)}</TdRight>
                  )}
                  <TdRight>{goal.takeHomePeriod}</TdRight>

                  <TdCentered>
                    <Progress
                      width={120}
                      value={innovationEfficiencyToPercent(
                        goal.innovationEfficiencyClassification
                      )}
                      color={PiosColors.efficiency}
                      backgroundColor={PiosColors.innovation}
                    />
                  </TdCentered>

                  <TdCentered>
                    <Progress
                      width={120}
                      value={internalExternalToPercent(
                        goal.internalExternalClassification
                      )}
                      color={PiosColors.internal}
                      backgroundColor={PiosColors.external}
                    />
                  </TdCentered>
                </Tr>
                {selectedGoalId === goal.id && (
                  <Tr>
                    <BenefitTableStyleCell colSpan={8}>
                      <div>
                        <GoalBenefits
                          goal={goal}
                          canEditExpected={false}
                          canEditActual={false}
                        />
                      </div>
                    </BenefitTableStyleCell>
                  </Tr>
                )}
              </Fragment>
            ))}

            {goals && goals.length > 0 && (
              <Tr className="b">
                <Td />
                <TdRight>{t('Common.UI.Total')}</TdRight>
                <TdRight>
                  {formatAsCurrency(
                    goals.reduce(
                      (total, goal) => total + (goal.totalExpectedBenefit ?? 0),
                      0
                    )
                  )}
                </TdRight>
                <TdRight>
                  {formatAsCurrency(
                    goals.reduce(
                      (total, goal) => total + (goal.totalActualBenefit ?? 0),
                      0
                    )
                  )}
                </TdRight>
                {(projectPhase === Phase.Finished ||
                  projectPhase === Phase.FinishedEffectRealizationStarted ||
                  projectPhase ===
                    Phase.FinishedEffectRealizationConcluded) && (
                  <TdRight>
                    {formatAsCurrency(
                      goals.reduce(
                        (total, goal) => total + (goal.deviation ?? 0),
                        0
                      )
                    )}
                  </TdRight>
                )}
                <Td colSpan={4} />
              </Tr>
            )}
          </tbody>
        </TableStyle>
      </TableResponsiveContainer>
    </>
  );
};

GoalListEffectRealisation.displayName = 'GoalListEffectRealisation';

const TableStyle = styled(Table)`
  > tbody {
    > tr {
      height: 50px;

      > td {
        vertical-align: middle;
      }
    }
  }
`;

const BenefitTableStyleCell = styled(Td)`
  padding: 0 0 30px 0;

  > div {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.surfaceVariant};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

    padding: 10px;
  }
`;
