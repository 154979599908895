import { GaugeMulti } from '@dimatech/features-core/lib/components/GaugeMulti';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import i18n from 'localization';
import {
  ProjectEffectRealisation,
  ProjectEffectRealisationAdditionalData,
  ProjectStatus,
} from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrow90DegRight, BsMagic, BsPatchCheck } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const ProjectsStatistics = withTheme(
  ({
    projects,
    additionalData,
    theme,
  }: {
    projects: ProjectEffectRealisation[];
    additionalData?: ProjectEffectRealisationAdditionalData;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const formatter = (value: number | undefined | null) => {
      const numeric = parseInt((value || 0).toString());
      return isNaN(numeric) || numeric === 0
        ? '-'
        : numeric.toLocaleString(i18n.language);
    };

    const totalExpectedBenefit = additionalData?.totalExpectedBenefit ?? 0;
    const totalActualBenefit = additionalData?.totalActualBenefit ?? 0;
    const deviation = totalActualBenefit - totalExpectedBenefit;

    const endYear = Math.max(
      ...projects.map((p) => (p.endYear ?? 0) + (p.maxTakeHomePeriod ?? 0))
    );

    const gaugeData = [
      {
        value:
          (projects.filter(
            (project) => project.projectStatus === ProjectStatus.Zero
          ).length /
            projects.length) *
          100,
        color: Color.Grey70,
        name: t(`Project.Status.Zero`),
      },
      {
        value:
          (projects.filter(
            (project) => project.projectStatus === ProjectStatus.One
          ).length /
            projects.length) *
          100,
        color: Color.RadicalRedLight,
        name: t(`Project.Status.One`),
      },
      {
        value:
          (projects.filter(
            (project) => project.projectStatus === ProjectStatus.Two
          ).length /
            projects.length) *
          100,
        color: Color.KojiOrangeLight,
        name: t(`Project.Status.Two`),
      },
      {
        value:
          (projects.filter(
            (project) => project.projectStatus === ProjectStatus.Three
          ).length /
            projects.length) *
          100,
        color: Color.CaribbeanGreenLight,
        name: t(`Project.Status.Three`),
      },
    ];

    return (
      <Style>
        <ViewRow>
          <Number
            icon={<BsMagic />}
            color={theme.colors.primary}
            text={t(
              'ImplementedProjects.ProjectsStatistics.TotalExpectedBenefit',
              {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
                year: endYear,
              }
            )}
            help={t(
              'ImplementedProjects.ProjectsStatistics.TotalExpectedBenefitHelp'
            )}
            formattedValue={`${formatter(totalExpectedBenefit)}`}
          />

          <Number
            icon={<BsPatchCheck />}
            color={theme.colors.primary}
            text={t('ImplementedProjects.ActualBenefit', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={`${formatter(totalActualBenefit)}`}
          />

          <div style={{ display: 'flex' }}>
            <Number
              icon={<BsArrow90DegRight />}
              color={theme.colors.primary}
              text={t('ImplementedProjects.Deviation', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
              formattedValue={`${formatter(deviation)}`}
            />
          </div>

          <GaugeMulti
            values={gaugeData}
            name={t(
              'ImplementedProjects.ProjectsStatistics.ProjectStatus.Title'
            )}
            helpTitle={t(
              'ImplementedProjects.ProjectsStatistics.ProjectStatus.Title'
            )}
            help={t(
              'ImplementedProjects.ProjectsStatistics.ProjectStatus.Help'
            )}
          />
        </ViewRow>
      </Style>
    );
  }
);

ProjectsStatistics.displayName = 'ProjectsStatistics';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 0 10px;

  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  > div {
    margin: 15px 0;
    flex-grow: 1;
    justify-content: space-around;

    > div {
      margin-right: 15px;
    }
  }
`;
