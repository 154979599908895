import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency } from '@dimatech/shared/lib/utils';
import { ProjectEffectRealisationPerYear } from 'models';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { withTheme } from 'styled-components';
import { ProjectsBenefitsTooltip } from './ProjectsBenefitsTooltip';

export const ProjectsExpectedBenefitOverTime = withTheme(
  ({
    projectsExpectedPerYear,
    maxValue,
    theme,
  }: {
    projectsExpectedPerYear: ProjectEffectRealisationPerYear[];
    maxValue: number;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const filtered = projectsExpectedPerYear.filter(
      (project) => project.year >= new Date().getFullYear()
    );

    return filtered.length === 0 ? (
      <div>{t('ImplementedProjects.NoProjects')}</div>
    ) : (
      <div
        style={{
          marginTop: 30,
          height: 300,
          fontSize: theme.fonts.size.xxs,
        }}
      >
        <ResponsiveContainer width="100%" height="95%" debounce={1}>
          <BarChart data={filtered} barCategoryGap="20%">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="year"
              tickLine={false}
              stroke={theme.colors.chart.axisStroke}
            />
            <YAxis
              type="number"
              tickLine={false}
              stroke={theme.colors.chart.axisStroke}
              tickFormatter={(value) => formatAsCurrency(value) ?? ''}
              domain={['dataMin', maxValue]}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<ProjectsBenefitsTooltip />}
            />
            <Legend />
            <Bar
              dataKey="finance"
              name={t('ImplementedProjects.Benefits.Finance')}
              stackId="a"
              fill={theme.colors.chart.colors[0]}
            />
            <Bar
              dataKey="redistribution"
              name={t('ImplementedProjects.Benefits.Redistribution')}
              stackId="a"
              fill={theme.colors.chart.colors[2]}
            />
            <Bar
              dataKey="quality"
              name={t('ImplementedProjects.Benefits.Quality')}
              stackId="a"
              fill={theme.colors.chart.colors[6]}
            />
            <Bar
              dataKey="environment"
              name={t('ImplementedProjects.Benefits.Environment')}
              stackId="a"
              fill={theme.colors.chart.colors[8]}
            />
            <Bar
              dataKey="other"
              name={t('ImplementedProjects.Benefits.Other')}
              stackId="a"
              fill={theme.colors.chart.colors[3]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

ProjectsExpectedBenefitOverTime.displayName = 'ProjectsExpectedBenefitOverTime';
