import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { View } from 'models';
import { useContext } from 'react';

export const useGetDefaultView = (): View => {
  const { accessToken } = useContext(AuthenticationContext);

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
  const isProjectRolesEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectRolesEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isProjectRoleOrderingPartyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectRoleOrderingPartyEnabled];

  if (accessToken.isBaseLicense) {
    return View.Projects;
  }

  if (accessToken.isInRole(CommonRoles.PortfolioOwner)) {
    return View.MyPortfolios;
  }

  if (
    (accessToken.isInRole(CommonRoles.PiosProjectManager) &&
      isProjectRolesEnabledFlagOn) ||
    (accessToken.isInRole(CommonRoles.PiosOrderingParty) &&
      isProjectRoleOrderingPartyEnabledFlagOn)
  ) {
    return View.MyProjects;
  }

  if (
    accessToken.isInRole(CommonRoles.PiosEffectRealizationResponsible) &&
    isProjectRolesEnabledFlagOn &&
    isProjectEffectRealisationEnabledFlagOn
  ) {
    return View.ProjectEffectMyProjects;
  }

  if (
    accessToken.isInRole(CommonRoles.PiosReviewer) &&
    isProjectIdeasReviewersEnabledFlagOn
  ) {
    return View.ProjectIdeasReview;
  }

  return View.Portfolios;
};
