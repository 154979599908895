import { Gauge } from '@dimatech/features-core/lib/components/Gauge';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Number } from '@dimatech/shared/lib/components/Number';
import {
  ViewRow,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { formatAsNumber } from '@dimatech/shared/lib/utils';
import { AdditionalData } from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsArrow90DegRight, BsMagic, BsPatchCheck } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { StatisticsStyle } from './StatisticsStyle';

export const StatisticsForBenefits = withTheme(
  ({
    theme,
    data,
  }: {
    theme: Theme;
    data: AdditionalData;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const totalExpectedBenefit = data.totalExpectedBenefit ?? 0;
    const totalActualBenefit = data.totalActualBenefit ?? 0;
    const deviation = totalActualBenefit - totalExpectedBenefit;

    return (
      <StatisticsStyle style={{ borderBottom: 'none' }}>
        <ViewRow>
          <Gauge
            value={data.hasEffectTakeHomePlan ?? 0}
            color={Color.CaribbeanGreenLight}
            name={t('Statistics.EffectTakeHome')}
            text={<Trans i18nKey="Statistics.EffectTakeHomeHelp" />}
          />

          <ViewRowBreak className="show-xs" />

          <Number
            icon={<BsMagic />}
            color={theme.colors.primary}
            text={t(
              'ImplementedProjects.ProjectsStatistics.TotalExpectedBenefitCurrent',
              {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              }
            )}
            help={t(
              'ImplementedProjects.ProjectsStatistics.TotalExpectedBenefitHelp'
            )}
            formattedValue={`${formatAsNumber(totalExpectedBenefit)}`}
          />
        </ViewRow>

        <ViewRowBreak className="show-xs" />

        <ViewRow>
          <Number
            icon={<BsPatchCheck />}
            color={theme.colors.primary}
            text={t('ImplementedProjects.ActualBenefit', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={`${formatAsNumber(totalActualBenefit)}`}
          />

          <ViewRowBreak className="show-xs" />

          <Number
            icon={<BsArrow90DegRight />}
            color={theme.colors.primary}
            text={t('ImplementedProjects.Deviation', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={`${formatAsNumber(deviation)}`}
          />

          {/* This is only for layout reasons, to make it better align with other statistics elements */}
          <div className="hide-xs" />
        </ViewRow>
      </StatisticsStyle>
    );
  }
);

StatisticsForBenefits.displayName = 'StatisticsForBenefits';
