import { YearPicker } from '@dimatech/shared/lib/components/form';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

export const SelectPeriod = ({
  period,
  setPeriod,
  isAnyAllowed,
  inverted = false,
}: {
  period?: number;
  setPeriod: (period?: number) => void;
  isAnyAllowed?: boolean;
  inverted?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const date =
    !period || isNaN(period)
      ? isAnyAllowed
        ? undefined
        : formatDate(new Date(), 'date')
      : formatDate(new Date(period, 1, 1), 'date');

  return (
    <>
      <YearPicker
        inverted={inverted}
        date={date}
        setDate={(date: Date | null) => {
          setPeriod(date ? date.getFullYear() : undefined);
        }}
        placeholder={t('Search.AnyYear')}
      />
    </>
  );
};

SelectPeriod.displayName = 'SelectPeriod';
