import { AdministrateMessageTemplate as AdministrateMessageTemplateFeature } from 'features/administrate-message-template';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateMessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.MessageTemplate.Title')}>
      <AdministrateMessageTemplateFeature />
    </View>
  );
};

AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplate';
