import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { CardTile } from '@dimatech/shared/lib/components/workspace';
import { IdeaClassification } from 'features/project-idea/components/IdeaClassification';
import { IdeaRadarChart } from 'features/project-idea/components/IdeaRadarChart';
import { IdeaScatterChart } from 'features/project-idea/components/IdeaScatterChart';
import { Dimension, ProjectIdea } from 'models';
import styled from 'styled-components';
import { ProjectDataTable } from './ProjectDataTable';

export const ProjectIdeaClassificationsList = ({
  idea,
  classifications,
}: {
  idea: ProjectIdea;
  classifications: Dimension[];
}): JSX.Element | null => {
  return (
    <>
      <Style>
        <CardTile>
          <ProjectDataTable>
            <tbody>
              {classifications.map((classification, index) => (
                <Tr key={index}>
                  <Td>
                    <HelpIconLeft>
                      {classification.name}

                      {classification.description && (
                        <HelpIcon
                          showTiny={true}
                          style={{ marginLeft: 5 }}
                          title={'classification.name'}
                          text={classification.description}
                        />
                      )}
                    </HelpIconLeft>
                  </Td>
                  <Td>
                    <IdeaClassification
                      width={90}
                      showEmpty={true}
                      idea={idea}
                      classification={classification}
                      isEditing={false}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </ProjectDataTable>
        </CardTile>
        <CardTile>
          <IdeaScatterChart ideas={[idea]} />
          <IdeaRadarChart project={idea} classifications={classifications} />
        </CardTile>
      </Style>
    </>
  );
};

ProjectIdeaClassificationsList.displayName = 'ProjectIdeaClassificationsList';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > div {
    flex-grow: 1;
    flex-shrink: 1;

    > table {
      td {
        &:last-of-type {
          width: 1%;
        }
      }
    }

    &:last-of-type {
      flex-grow: 2;
      flex-shrink: 1;

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
`;
