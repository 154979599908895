import { MyProjects as ProjectEffectMyProjectsFeature } from 'features/project-effect/MyProjects';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectEffectMyProjects = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('ImplementedProjects.MyProjects.Title')}>
      <ProjectEffectMyProjectsFeature />
    </View>
  );
};

ProjectEffectMyProjects.displayName = 'ProjectEffectMyProjects';
