import { UpgradeLicense as ProjectUpgradeLicense } from 'features/upgrade-license';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const UpgradeLicense = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <ViewCentered
      title={t('UpgradeLicense.Title')}
      style={{ width: '80%', maxWidth: 650 }}
    >
      <ProjectUpgradeLicense />
    </ViewCentered>
  );
};

UpgradeLicense.displayName = 'UpgradeLicense';
