import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Card,
  CardBody,
  CardFocused,
  CardFocusedBody,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetProjectsOverviewByTagQuery } from 'api/project/projectOverviewApi';
import { TagTreeTable } from 'components/TagTreeTable';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const ProjectsTagTreeTable = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];

  const { data, isFetching } = useGetProjectsOverviewByTagQuery(
    isTagsEnabledFlagOn && accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  if (!isTagsEnabledFlagOn) {
    return null;
  }

  if (data && data.length === 0) {
    return null;
  }

  return (
    <Card style={{ paddingTop: 10, paddingBottom: 20 }}>
      <CardBody isLoading={isFetching}>
        {data && (
          <CardFocused
            helpTextTitle={t('Projects.TagTable.Title')}
            helpText={<Trans i18nKey={'Projects.TagTable.Help'} />}
            canBeSavedAsPng={true}
            style={{ width: 'fit-content' }}
          >
            <CardFocusedBody style={{ paddingTop: 10 }}>
              <TagTreeTable overviewsByTag={data} />
            </CardFocusedBody>
          </CardFocused>
        )}
      </CardBody>
    </Card>
  );
};

ProjectsTagTreeTable.displayName = 'ProjectsTagTreeTable';
