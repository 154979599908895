import { OrderDios as OrderDiosComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { config } from '@dimatech/shared/lib/config';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const OrderDios = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <ViewCentered
      title={t('Order.Dios.Title')}
      style={{ width: '80%', maxWidth: 650 }}
    >
      <OrderDiosComponent
        fromProductLink={
          <a href={config.products.pios.url}>
            {t('Order.VerificationLinkTextPios')}
          </a>
        }
      />
    </ViewCentered>
  );
};

OrderDios.displayName = 'OrderDios';
