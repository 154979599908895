import { CacheTags, piosApi } from 'api/piosApi';
import { ProjectDocument } from 'models';

const projectDocumentApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get documents for project
     */
    getDocuments: build.query<
      ProjectDocument[],
      {
        projectId: string;
        _customerId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ projectId, _customerId }) => `projects/${projectId}/documents`,
      providesTags: [CacheTags.ProjectDocument],
    }),

    /**
     * Create new document
     */
    addDocument: build.mutation({
      query: ({
        document,
        projectId,
      }: {
        document: ProjectDocument;
        projectId: string;
      }) => ({
        url: `projects/${projectId}/documents`,
        method: 'POST',
        body: document,
      }),
      invalidatesTags: [CacheTags.ProjectDocument, CacheTags.ProjectHistory],
      transformResponse: (result: string, _meta, arg) => {
        return {
          ...arg,
          id: result,
        };
      },
    }),

    /**
     * Delete document
     */
    deleteDocument: build.mutation({
      query: ({
        documentId,
        projectId,
      }: {
        documentId: string;
        projectId: string;
      }) => ({
        url: `projects/${projectId}/documents/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTags.ProjectDocument, CacheTags.ProjectHistory],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
} = projectDocumentApi;
