import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { BadgeLarge } from '@dimatech/shared/lib/components/Badge';
import { ButtonSecondary, Buttons } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  ViewHeader,
  ViewHeaderActions,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import {
  getPortfolioExport,
  selectPortfolioExportIsPosting,
} from 'api/portfolio/portfolioExportSlice';
import { Owners } from 'components/Owners';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PortfolioOverview } from 'models';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory, BsFileExcel } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAdminAvailable, isPiosReadOnly } from 'utils';

export const PortfolioHeader = ({
  portfolio,
}: {
  portfolio: PortfolioOverview;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const isHistoryEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isHistoryEnabled];
  const isPortfolioExportEveryonesEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPortfolioExportEveryonesEnabled];

  // Disable if not admin and not owner, and the
  // isPortfolioExportEveryonesEnabled flag is off
  const canExportPortfolios =
    isAdminAvailable(accessToken) ||
    isPortfolioExportEveryonesEnabledFlagOn ||
    (accessToken.user?.email &&
      portfolio.owners?.includes(accessToken.user.email));

  const isPosting = useAppSelector(selectPortfolioExportIsPosting);
  const [showSelectExportType, setShowSelectExportType] = useState(false);
  const handleExportDownload = (
    e: React.SyntheticEvent,
    includeProjectIdeas: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (isViewReadOnly || !portfolio || !canExportPortfolios) {
      return;
    }

    dispatch(
      getPortfolioExport({
        portfolioName: portfolio.portfolioName,
        portfolioId: portfolio.portfolioId as string,
        includeProjectIdeas,
      })
    );
  };

  const handleViewHistory = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!isHistoryEnabledFlagOn) {
      return;
    }

    navigate(`/portfolio/${portfolio.portfolioId}/history`);
  };

  const handleBack = () => {
    const defaultPath = location.pathname.startsWith('/myportfolio')
      ? '/myportfolios'
      : '/portfolios';

    navigate((location.state as { from: string })?.from ?? defaultPath);
  };

  return (
    <>
      <ViewHeader>
        <div>
          <BackButton handleBack={handleBack} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Heading1>
            {portfolio && <>{portfolio.portfolioName}</>}
            {portfolio?.portfolioExternalId && (
              <> - {portfolio.portfolioExternalId}</>
            )}
          </Heading1>

          {portfolio?.owners && (
            <div className="size-s" style={{ display: 'block' }}>
              {t('UserRoles.PortfolioOwner.Title')}:
              {portfolio.owners?.length === 0 ? (
                <> -</>
              ) : (
                <Owners owners={portfolio.owners} />
              )}
            </div>
          )}
        </div>

        <ViewRowBreak className="show-xs" style={{ margin: 0 }} />

        {canExportPortfolios && (
          <ViewHeaderActions style={{ gap: 10 }}>
            <TooltipContent
              id="tt-portfolio-history"
              title={t('Portfolio.History.TooltipTitle')}
              text={t('Portfolio.History.Tooltip')}
            />

            <TooltipContent
              id="tt-portfolio-export"
              title={t('Portfolio.Export.Download.TooltipTitle')}
              text={t('Portfolio.Export.Download.Tooltip')}
            />

            {isHistoryEnabledFlagOn && (
              <BadgeLarge
                data-tip
                data-for="tt-portfolio-history"
                onClick={(e) => handleViewHistory(e)}
                $inverted={true}
              >
                <BsClockHistory />
              </BadgeLarge>
            )}

            <BadgeLarge
              data-tip
              data-for="tt-portfolio-export"
              onClick={() => !isPosting && setShowSelectExportType(true)}
              $inverted={true}
              disabled={isPosting || isViewReadOnly}
            >
              {isPosting ? <LoaderSmall /> : <BsFileExcel />}
            </BadgeLarge>
          </ViewHeaderActions>
        )}
      </ViewHeader>

      {showSelectExportType && (
        <Modal
          title={t('Portfolio.Export.Download.TooltipTitle')}
          handleKeyEsc={() => setShowSelectExportType(false)}
        >
          <div>{t('Portfolio.Export.Download.Text')}</div>

          <LinkWithTooltip
            title={t('Portfolio.Export.Download.Download')}
            tooltipTitle={t('Portfolio.Export.Download.TooltipTitle')}
            tooltip={t('Portfolio.Export.Download.Download')}
            handleClick={(e) => !isPosting && handleExportDownload(e, false)}
            isDisabled={isPosting}
            icon={isPosting ? <LoaderSmall /> : <BsFileExcel />}
            style={{ margin: '15px 0' }}
          />

          <LinkWithTooltip
            title={t('Portfolio.Export.Download.DownloadWithProjectIdeas')}
            tooltipTitle={t('Portfolio.Export.Download.TooltipTitle')}
            tooltip={t('Portfolio.Export.Download.DownloadWithProjectIdeas')}
            handleClick={(e) => !isPosting && handleExportDownload(e, true)}
            isDisabled={isPosting}
            icon={isPosting ? <LoaderSmall /> : <BsFileExcel />}
          />

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={() => setShowSelectExportType(false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
          </Buttons>
        </Modal>
      )}
    </>
  );
};

PortfolioHeader.displayName = 'PortfolioHeader';
