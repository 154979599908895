import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { ProjectDocument } from 'models';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getProjectDocumentContent = createAsyncThunk(
  "projectDocumentContent/get'",
  async ({
    projectId,
    projectDocument,
  }: {
    projectId: string;
    projectDocument: ProjectDocument;
  }) => {
    return axios({
      url: `${config.apiUrl.product.pios}projects/${projectId}/documents/${projectDocument.id}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const fileName = projectDocument.name as string;

      const file = new File([response.data], fileName);

      saveAs(file, fileName);
    });
  }
);

export const {
  reducer: projectDocumentContentReducer,
  actions: projectDocumentContentActions,
} = createSlice({
  name: 'projectDocumentContent/get',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getProjectDocumentContent.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getProjectDocumentContent.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getProjectDocumentContent.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectProjectDocumentContentError = (
  state: RootState
): string | null | undefined => state.projectDocumentContent.error;

export const selectProjectDocumentContentIsPosting = (
  state: RootState
): boolean => state.projectDocumentContent.isPosting;
