import { Phase } from 'models';
import { useTranslation } from 'react-i18next';

export const ProjectPhase = ({
  phase,
}: {
  phase?: Phase | null;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return phase ? (
    <span>
      {phase === Phase.Finished
        ? t(`Project.Phase.FinishedEffectRealization`)
        : t(`Project.Phase.${phase}`)}
    </span>
  ) : null;
};

ProjectPhase.displayName = 'ProjectPhase';
