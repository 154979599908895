import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Projects as ProjectsFeature } from 'features/project/Projects';
import { ProjectsBaseLicense as ProjectsBaseLicenseFeature } from 'features/project/ProjectsBaseLicense';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Projects = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  return (
    <View title={t('Projects.Title')}>
      {!accessToken.isBaseLicense ? (
        <ProjectsFeature />
      ) : (
        <ProjectsBaseLicenseFeature />
      )}
    </View>
  );
};

Projects.displayName = 'Projects';
