import { CacheTags, piosApi } from 'api/piosApi';
import { Admin, SearchAdministrateUsers } from 'models';

const effectRealisationResponsibleApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get all effect realisation responsible
     */
    getEffectRealisationResponsible: build.query<
      Admin[],
      {
        filter: SearchAdministrateUsers;
        _customerId: string;
      }
    >({
      query: ({ filter, _customerId }) =>
        `portfolio/projects/realizationresponsible?1=1${
          filter.email ? `&email=${filter.email}` : ''
        }${
          filter.portfolioIds && filter.portfolioIds.length > 0
            ? filter.portfolioIds
                .map((portfolio) => `&portfolioIds=${portfolio}`)
                .join('')
            : ''
        }`,
      providesTags: [CacheTags.EffectRealisationResponsible],
    }),

    /**
     * Create effect realisation responsible
     */
    addEffectRealisationResponsible: build.mutation({
      query: ({
        portfolioId,
        projectId,
        projectUsers,
      }: {
        portfolioId: string;
        projectId: string;
        projectUsers: string[];
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/realizationresponsible`,
        method: 'PUT',
        body: { projectUsers },
      }),
      invalidatesTags: [
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Delete effect realisation responsible
     */
    deleteEffectRealisationResponsible: build.mutation({
      query: ({
        portfolioId,
        projectId,
        projectUsers,
      }: {
        portfolioId: string;
        projectId: string;
        projectUsers: string[];
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/realizationresponsible`,
        method: 'DELETE',
        body: { projectUsers },
      }),
      invalidatesTags: [
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Remove effect realisation responsible from all projects
     */
    removeEffectRealisationResponsible: build.mutation({
      query: ({
        portfolioId,
        projectUser,
      }: {
        portfolioId?: string;
        projectUser: string;
      }) => ({
        url: `portfolio/projects/realizationresponsible`,
        method: 'DELETE',
        body: {
          portfolioId,
          projectUser,
        },
      }),
      invalidatesTags: [
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),
  }),
});

export const {
  useGetEffectRealisationResponsibleQuery,
  useLazyGetEffectRealisationResponsibleQuery,
  useAddEffectRealisationResponsibleMutation,
  useDeleteEffectRealisationResponsibleMutation,
  useRemoveEffectRealisationResponsibleMutation,
} = effectRealisationResponsibleApi;
