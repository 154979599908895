import { CacheTags, piosApi } from 'api/piosApi';
import { Tag } from 'models';

const projectTagApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Add project tag
     */
    addProjectTag: build.mutation({
      query: ({
        portfolioId,
        projectId,
        tag,
      }: {
        portfolioId: string;
        projectId: string;
        tag: Tag;
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/tags`,
        method: 'PUT',
        body: {
          tags: [tag.id],
        },
      }),
      invalidatesTags: [
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectTimeline,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg.tag,
        };
      },
    }),

    /**
     * Delete project tag
     */
    deleteProjectTag: build.mutation({
      query: ({
        portfolioId,
        projectId,
        tag,
      }: {
        portfolioId: string;
        projectId: string;
        tag: Tag;
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/tags`,
        method: 'DELETE',
        body: {
          tags: [tag.id],
        },
      }),
      invalidatesTags: [
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectTimeline,
      ],
    }),
  }),
});

export const { useAddProjectTagMutation, useDeleteProjectTagMutation } =
  projectTagApi;
