var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var HeaderStyle = styled.td(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n\n  width: 250px;\n  min-width: 250px;\n  padding: 6px 0;\n"], ["\n  color: ", ";\n  background-color: ", ";\n\n  width: 250px;\n  min-width: 250px;\n  padding: 6px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
});
export var CellStyle = styled.td(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-spacing: 0;\n\n  width: 50px;\n  min-width: 50px;\n  padding: 6px 0;\n\n  > div.first {\n    border-top-left-radius: 5px;\n    border-bottom-left-radius: 5px;\n    margin-left: 3px;\n  }\n\n  > div.last {\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n    margin-right: 3px;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  border-spacing: 0;\n\n  width: 50px;\n  min-width: 50px;\n  padding: 6px 0;\n\n  > div.first {\n    border-top-left-radius: 5px;\n    border-bottom-left-radius: 5px;\n    margin-left: 3px;\n  }\n\n  > div.last {\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n    margin-right: 3px;\n  }\n\n  ", "\n\n  ", "\n"])), function (props) {
    return !props.isHighlighted && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      &:nth-child(even) {\n        background-color: ", ";\n      }\n    "], ["\n      &:nth-child(even) {\n        background-color: ", ";\n      }\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.background;
    });
}, function (props) {
    return props.isHighlighted && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.primary;
    });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
