import { CacheTags, piosApi } from 'api/piosApi';
import { Calculation, CalculationMutation } from 'models/calculation';

const calculationsApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get calculations
     */
    getCalculations: build.query<Calculation[], string>({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: (_customerId) => 'calculations',
      providesTags: [CacheTags.Calculation],
    }),

    /**
     * Create new calculation
     */
    addCalculation: build.mutation({
      query: (tag: CalculationMutation) => ({
        url: `calculations`,
        method: 'POST',
        body: tag,
      }),
      invalidatesTags: [CacheTags.Calculation],
      transformResponse: (result: Calculation, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Update calculation
     */
    updateCalculation: build.mutation({
      query: (calculation: CalculationMutation) => ({
        url: `calculations/${calculation.id}`,
        method: 'PUT',
        body: {
          name: calculation.name,
          description: calculation.description,
        },
      }),
      invalidatesTags: [
        CacheTags.Calculation,
        CacheTags.ProjectDimension,
        CacheTags.Dimension,
        CacheTags.ProjectIdeaOverview,
      ],
    }),

    /**
     * Delete calculation
     */
    deleteCalculation: build.mutation({
      query: (calculation: CalculationMutation) => ({
        url: `calculations/${calculation.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.Calculation,
        CacheTags.ProjectDimension,
        CacheTags.Dimension,
        CacheTags.ProjectIdeaOverview,
      ],
    }),
  }),
});

export const {
  useGetCalculationsQuery,
  useAddCalculationMutation,
  useUpdateCalculationMutation,
  useDeleteCalculationMutation,
} = calculationsApi;
