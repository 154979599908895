import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { ProjectIdea } from 'models';
import { useTranslation } from 'react-i18next';
import { formatAsNumber } from 'utils';

export const IdeaScatterChartTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    payload: ProjectIdea;
  }[];
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const item = payload[0].payload;

    return (
      <TooltipStyle>
        <div className="tooltip-title">
          {payload[0].payload.title}
          {payload[0].payload.entityName && (
            <div className="tooltip-info">{payload[0].payload.entityName}</div>
          )}
        </div>

        <div>
          {t(
            'Administrate.CustomDimension.DimensionClassificationType.Benefit'
          )}{' '}
          : {formatAsNumber(item.quotaBenefit, '-', 1)}
        </div>
        <div>
          {t('Administrate.CustomDimension.DimensionClassificationType.Stake')}{' '}
          : {formatAsNumber(item.quotaStake, '-', 1)}
        </div>
        <div>
          {t('FutureProjects.ProjectIdea.Quota')} :{' '}
          {formatAsNumber(item.quota, '-', 1)}
        </div>
      </TooltipStyle>
    );
  }

  return null;
};

IdeaScatterChartTooltip.displayName = 'IdeaScatterChartTooltip';
