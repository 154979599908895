import {
  getUsersExport,
  selectUserExportIsPosting,
} from '@dimatech/features-core/lib/api/user/userExportSlice';
import { UserFilters } from '@dimatech/features-core/lib/components/UserFilters';
import { EntitiesListForUser } from '@dimatech/features-core/lib/features/administrate-users/components/EntitiesListForUser';
import { UserAdd } from '@dimatech/features-core/lib/features/administrate-users/components/UserAdd';
import { UserEditButtons } from '@dimatech/features-core/lib/features/administrate-users/components/UserEditButtons';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Loader, LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { TransArray } from '@dimatech/shared/lib/localization';
import {
  Admin,
  ApiError,
  Entity,
  Paginator,
  SortDirection,
} from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import {
  useCreateOrUpdateReviewerMutation,
  useLazyGetReviewersQuery,
  useRemoveReviewerMutation,
} from 'api/user/userApi';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  CommonRoles,
  SearchAdministrateUsers,
  SearchAdministrateUsersRole,
} from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel, BsPersonBadge, BsPersonPlus } from 'react-icons/bs';

// eslint-disable-next-line max-lines-per-function
export const Reviewers = ({
  handleNavigateSearch,
  isLoading,
  entities,
}: {
  handleNavigateSearch?: (e: React.SyntheticEvent, id: string) => void;
  isLoading?: boolean;
  entities?: Entity[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const dispatch = useAppDispatch();
  const isExportPosting = useAppSelector(selectUserExportIsPosting);

  const [filter, setFilter] = useState<SearchAdministrateUsers>();
  const [includeChildEntities, setIncludeChildEntities] = useState(true);
  const [selectedUser, setSelectedUser] = useState<Admin | undefined>();
  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (accessToken.isInRole(CommonRoles.GlobalAdmin) ||
      accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 50,
    orderBy: 'email',
    orderDirection: SortDirection.Asc,
  };

  const [getReviewers, { data, isFetching }] = useLazyGetReviewersQuery();
  const [getEntityReviewers] = useLazyGetReviewersQuery();
  const [createOrUpdateReviewer, { error }] =
    useCreateOrUpdateReviewerMutation();
  const [removeReviewer] = useRemoveReviewerMutation();

  const handleGetReviewers = (
    paginator: Paginator = initialPaginator,
    entityIds?: string[],
    email?: string
  ) => {
    if (accessToken.customerId) {
      getReviewers(
        {
          filter: {
            paginator,
            entityIds: entityIds || [],
            email: email || '',
          },
          _customerId: accessToken.customerId,
        },
        true
      );
    }
  };

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: (paginator) =>
      handleGetReviewers(
        paginator,
        filter?.entityIds,
        filter?.email?.toLowerCase()
      ),
  });

  const handleAdd = (user: Admin, entityId: string) => {
    setValidationErrors(undefined);

    if (user?.email?.includes(' ')) {
      setValidationErrors?.(['Email']);
      return;
    }

    accessToken.customerId &&
      getEntityReviewers(
        {
          filter: { entityIds: [entityId] },
          _customerId: accessToken.customerId,
        },
        true
      )
        .unwrap()
        .then((result) => {
          if (result.records.some((u) => u.email === user.email)) {
            setValidationErrors(['ErrorSameEmail']);
            return;
          } else {
            createOrUpdateReviewer({
              user: {
                email: user.email,
                entityId,
                includeChildEntities,
              },
            });
          }
        });

    setIncludeChildEntities(true);
  };

  const handleDelete = (user: Admin) => {
    if (user.email) {
      removeReviewer(user.email);
    }
  };

  const handleSelect = (user: Admin) => {
    setSelectedUser(selectedUser?.email === user.email ? undefined : user);
  };

  const handleSearch = () => {
    setPage(1);
    handleGetReviewers(
      initialPaginator,
      filter?.entityIds,
      filter?.email?.toLowerCase()
    );
  };

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (filter) {
      dispatch(
        getUsersExport({
          filter: {
            ...filter,
            role: SearchAdministrateUsersRole.Review,
          },
          name: t('UserRoles.PiosReviewer.Title'),
        })
      );
    }
  };

  useEffect(() => {
    handleGetReviewers();
    setFilter({
      email: undefined,
      entityIds: undefined,
      hasChanges: false,
    });
    setValidationErrors(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  useEffect(() => {
    if (filter?.hasChanges === false) {
      handleGetReviewers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (error) {
      setValidationErrors((error as ApiError)?.validationErrors);
    } else {
      setValidationErrors(undefined);
    }
  }, [error]);

  if (isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PiosReviewer.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PiosReviewer.Title')}
          text={<TransArray i18nKey="UserRoles.PiosReviewer.Help" />}
        />
      </HelpIconLeft>

      <LinkWithTooltip
        isPrimary={true}
        handleClick={() => setIsAdding(true)}
        title={t('Administrate.Entity.Reviewer.Add.Title')}
        tooltipTitle={t('Administrate.Entity.Reviewer.Add.TooltipTitle')}
        tooltip={t('Administrate.Entity.Reviewer.Add.Tooltip')}
        icon={<BsPersonPlus />}
        style={{ marginTop: 20, marginBottom: 10 }}
      />

      {data && data.totalRecords > 0 && (
        <>
          <LinkWithTooltip
            title={t('Administrate.Entity.Export.Download.Download')}
            tooltipTitle={t('Administrate.Entity.Export.Download.TooltipTitle')}
            tooltip={t('Administrate.Entity.Export.Download.Tooltip')}
            icon={isExportPosting ? <LoaderSmall /> : <BsFileExcel />}
            handleClick={(e) => !isExportPosting && handleExportDownload(e)}
            isDisabled={isExportPosting}
            style={{ paddingTop: 10 }}
          />
        </>
      )}

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(`Administrate.Entity.User.ValidationError.${validationError}`)}
            </div>
          ))}
        </AlertWarning>
      )}

      {isAdding && (
        <UserAdd
          setIsAdding={setIsAdding}
          handleAddUser={handleAdd}
          role={CommonRoles.PiosReviewer}
          includeChildEntities={includeChildEntities}
          setIncludeChildEntities={setIncludeChildEntities}
          isLoading={isLoading}
          entities={entities}
        />
      )}

      <UserFilters
        handleSearch={handleSearch}
        filter={filter}
        setFilter={setFilter}
      />

      {data?.totalRecords === 0 && (
        <div style={{ marginTop: 20 }}>
          {t(`Administrate.Entity.Reviewer.NoReviewers`)}
        </div>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={data?.totalRecords ?? 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />

      {data && data.totalRecords > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 15, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th sortKey="email" sorter={sorter}>
                  {t(`Administrate.Entity.Email`)}
                </Th>
                <ThRight sortKey="lastLoginDate" sorter={sorter}>
                  {t(`Administrate.Entity.LastLogin`)}
                </ThRight>
                {isCustomerAccountAdmin && <Th />}
              </tr>
            </thead>
            <tbody>
              {data.records?.map((item, index) => (
                <Fragment key={index}>
                  <Tr
                    key={index}
                    isSelected={selectedUser?.email === item.email}
                    isHoverable={true}
                    onSelect={() => handleSelect(item)}
                  >
                    <Td style={{ width: 25 }}>
                      <BsPersonBadge />
                    </Td>
                    <Td>{item.email as string}</Td>
                    <TdRight
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </TdRight>
                    {isCustomerAccountAdmin && (
                      <TdRight>
                        {item.email !== accessToken.user?.email && (
                          <div
                            style={{
                              verticalAlign: 'middle',
                              display: 'flex',
                              justifyContent: 'end',
                            }}
                          >
                            <UserEditButtons
                              user={item}
                              setValidationErrors={setValidationErrors}
                              role={CommonRoles.PiosReviewer}
                              deleteUser={handleDelete}
                            />
                          </div>
                        )}
                      </TdRight>
                    )}
                  </Tr>

                  {selectedUser && selectedUser.email === item.email && (
                    <Tr>
                      <Td colSpan={4} style={{ padding: 0 }}>
                        <EntitiesListForUser
                          list={item.entities}
                          role={CommonRoles.PiosReviewer}
                          handleNavigateSearch={handleNavigateSearch}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
      <Pagination
        currentPage={paginator.page}
        totalCount={data?.totalRecords ?? 0}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />
    </>
  );
};

Reviewers.displayName = 'Reviewers';
