import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Buttons,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { TransArray } from '@dimatech/shared/lib/localization';
import { useAddPortfolioOwnerMutation } from 'api/portfolio/portfolioOwnerApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Admin, PortfolioMutation } from 'models';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson, BsPersonPlus } from 'react-icons/bs';
import { isAdminReadOnly, isValidEmail } from 'utils';
import { PortfolioOwnersListEditButtons } from './PortfolioOwnersListEditButtons';

export const PortfolioOwnersList = ({
  portfolio,
  setPortfolio,
}: {
  portfolio: PortfolioMutation;
  setPortfolio: React.Dispatch<
    React.SetStateAction<PortfolioMutation | undefined>
  >;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);

  const [isAdding, setIsAdding] = useState(false);

  const [addPortfolioOwner, { error: addError }] =
    useAddPortfolioOwnerMutation();

  const [validationError, setValidationError] = useState<string | null>(null);
  const [owner, setOwner] = useState<Admin | null>(null);
  const isAddingPortfolio = selectedPortfolio && !selectedPortfolio.id;

  const handleSave = () => {
    if (isReadOnly) {
      return;
    }

    setValidationError(null);

    if (!owner?.email) {
      return;
    }

    if (!isValidEmail(owner.email)) {
      setValidationError('Owners');
      return;
    }

    if (owner.email.includes(' ')) {
      setValidationError('Owners');
      return;
    }

    if (portfolio.owners?.includes(owner.email)) {
      setValidationError('ErrorSameOwner');
      return;
    }

    if (isAddingPortfolio) {
      handleSaveOwnerPortfolio();
    } else {
      handleSaveOwner();
    }
  };

  const handleSaveOwnerPortfolio = () => {
    if (!owner?.email) {
      return;
    }

    setPortfolio({
      ...portfolio,
      owners: [...(portfolio.owners ?? []), `${owner.email}`],
    });

    setIsAdding(false);
    setOwner(null);
  };

  const handleSaveOwner = () => {
    if (isReadOnly || !portfolio?.id || !owner?.email) {
      return;
    }

    setPortfolio({
      ...portfolio,
      owners: [...(portfolio.owners ?? []), `${owner.email}`],
    });

    addPortfolioOwner({
      portfolioId: portfolio.id,
      owner: owner.email,
    })
      .unwrap()
      .then((result) => {
        if (selectedPortfolio?.owners) {
          dispatch(
            portfolioActions.selectedPortfolio({
              ...portfolio,
              owners: [...selectedPortfolio.owners, `${result.owner}`],
            })
          );
        }
      });

    setIsAdding(false);
    setOwner(null);
  };

  const handleCancel = () => {
    setOwner(null);
    setIsAdding(false);
    setValidationError(null);
  };

  return selectedPortfolio ? (
    <>
      <Heading3 style={{ marginTop: 40 }}>
        {t('UserRoles.PortfolioOwner.Title')}
      </Heading3>

      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PortfolioOwner.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PortfolioOwner.Title')}
          text={<TransArray i18nKey="UserRoles.PortfolioOwner.Help" />}
        />
      </HelpIconLeft>

      <div style={{ display: 'flex', marginTop: 20, marginBottom: 10 }}>
        <TooltipContent
          id="tt-add"
          title={t('Portfolio.Owner.Add.TooltipTitle')}
          text={t('Portfolio.Owner.Add.Tooltip')}
        />
        <BadgeMini data-tip data-for="tt-add" onClick={() => setIsAdding(true)}>
          <BsPersonPlus />
        </BadgeMini>

        <ButtonLink
          data-tip
          data-for="tt-add"
          onClick={() => setIsAdding(true)}
          style={{ marginLeft: 10 }}
        >
          {t('Portfolio.Owner.Add.Title')}
        </ButtonLink>
      </div>

      {selectedPortfolio.owners?.length === 0 && (
        <div style={{ marginTop: 20 }}>{t('Portfolio.Owner.NoOwners')}</div>
      )}

      {(isAdding || validationError || addError) && (
        <Modal title={t('Portfolio.Owner.Add.Title')}>
          <div>{t('Portfolio.Owner.Add.Text')}</div>

          {validationError && (
            <AlertWarning style={{ marginTop: 10, width: '100%' }}>
              <div>
                {t(`Portfolio.Owner.ValidationError.${validationError}`)}
              </div>
            </AlertWarning>
          )}

          <Label>{t('Portfolio.Owner.Add.Email')}</Label>
          <SearchUser user={owner} setUser={setOwner} />

          <Buttons>
            <ButtonSecondary type="button" onClick={handleCancel}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button type="button" onClick={handleSave} disabled={isReadOnly}>
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TableResponsiveContainer>
        <Table style={{ marginTop: 15 }}>
          <tbody>
            {portfolio.owners?.map((owner, index) => (
              <Tr key={index}>
                <Td style={{ width: 25 }}>
                  <BsPerson />
                </Td>
                <Td>{owner}</Td>
                <TdRight
                  style={{
                    verticalAlign: 'middle',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <PortfolioOwnersListEditButtons
                    owner={owner}
                    portfolioId={selectedPortfolio.id as string}
                    portfolio={portfolio}
                    setPortfolio={setPortfolio}
                    isReadOnly={isReadOnly}
                  />
                </TdRight>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableResponsiveContainer>
    </>
  ) : null;
};

PortfolioOwnersList.displayName = 'PortfolioOwnersList';
