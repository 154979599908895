var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Buttons, ButtonSecondary, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDeleteTagMutation } from '../../../api/tag/tagApi';
import { tagActions } from '../../../api/tag/tagSlice';
export var TagPropertiesEditButtons = function (_a) {
    var item = _a.item, setSelectedItem = _a.setSelectedItem, isReadOnly = _a.isReadOnly, handleDeleted = _a.handleDeleted;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var deleteTag = useDeleteTagMutation()[0];
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var handleDelete = function (e) {
        e.stopPropagation();
        if (isReadOnly) {
            return;
        }
        deleteTag(__assign({}, item))
            .unwrap()
            .then(function () { return handleDeleted === null || handleDeleted === void 0 ? void 0 : handleDeleted(); });
        setSelectedItem(undefined);
        dispatch(tagActions.selectedTag());
        setIsDeleting(false);
    };
    var handleConfirmDelete = function (e, show) {
        e.stopPropagation();
        setIsDeleting(show);
    };
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Tag.Delete.ConfirmTitle') }, { children: [_jsx("div", { children: t('Administrate.Tag.Delete.ConfirmText') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleDelete(e); }, disabled: isReadOnly }, { children: t('Common.UI.Yes') }))] })] }))), _jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, true); }, style: { marginLeft: '0' } }, { children: t('Common.Form.Delete') }))] }));
};
TagPropertiesEditButtons.displayName = 'TagPropertiesEditButtons';
