import { CacheTags, piosApi } from 'api/piosApi';
import { filterToProjectByPeriodQuery, filterToProjectQuery } from 'api/utils';
import {
  AdditionalData,
  OverviewByPeriod,
  PiosFilter,
  Project,
  ProjectOverviewByTag,
  ProjectOverviewResult,
  ProjectTimeline,
} from 'models';

const projectOverviewApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get projects overview
     */
    getProjectsOverview: build.query<
      ProjectOverviewResult,
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        const url = `overview/projects?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;
        const filters = filterToProjectQuery(filter);

        return `${url}${filters}`;
      },
      transformResponse: (
        {
          additionalData,
          records,
        }: {
          additionalData: AdditionalData;
          records: Project[];
        },
        _meta,
        arg
      ) => {
        return transformProjectOverviewResult(
          {
            additionalData,
            records,
          },
          arg
        );
      },
      providesTags: [CacheTags.ProjectOverview],
    }),

    /**
     * Get project timeline
     */
    getProjectsTimeline: build.query<
      ProjectTimeline[],
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        const url = `overview/timeline?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;
        const filters = filterToProjectQuery(filter);

        return `${url}${filters}`;
      },
      providesTags: [CacheTags.ProjectTimeline],
    }),

    /**
     * Get project overview by period
     */
    getProjectsOverviewByPeriod: build.query<
      OverviewByPeriod[],
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        // cspell: disable-next-line
        const url = `overview/projects/byperiod?pageNumber=1&pageSize=10000&orderBy.propertyName=period&orderBy.direction=Ascending`;
        const filters = filterToProjectByPeriodQuery(filter);

        return `${url}${filters}`;
      },
      transformResponse: ({ records }: { records: OverviewByPeriod[] }) => {
        return records;
      },
      providesTags: [CacheTags.ProjectOverviewByPeriod],
    }),

    /**
     * Get project overview by tag
     */
    getProjectsOverviewByTag: build.query<
      ProjectOverviewByTag[],
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      query: ({ filter, _customerId, _userId }) => {
        // cspell: disable-next-line
        const url = `overview/bytag?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;
        const filters = filterToProjectQuery(filter);

        return `${url}${filters}`;
      },
      providesTags: [CacheTags.ProjectOverviewByTag],
    }),

    /**
     * Get my projects overview
     */
    getMyProjectsOverview: build.query<
      ProjectOverviewResult,
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        const url = `overview/projects/my?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;

        return `${url}`;
      },
      transformResponse: (
        {
          additionalData,
          records,
        }: {
          additionalData: AdditionalData;
          records: Project[];
        },
        _meta,
        arg
      ) => {
        return transformProjectOverviewResult(
          {
            additionalData,
            records,
          },
          arg
        );
      },
      providesTags: [CacheTags.MyProjectOverview],
    }),

    /**
     * Get my project overview by period
     */
    getMyProjectsOverviewByPeriod: build.query<
      OverviewByPeriod[],
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        // cspell: disable-next-line
        const url = `overview/projects/my/byperiod?pageNumber=1&pageSize=10000&orderBy.propertyName=period&orderBy.direction=Ascending`;

        return `${url}`;
      },
      transformResponse: ({ records }: { records: OverviewByPeriod[] }) => {
        return records;
      },
      providesTags: [CacheTags.MyProjectOverviewByPeriod],
    }),
  }),
});

export const {
  useGetProjectsOverviewQuery,
  useGetProjectsOverviewByPeriodQuery,
  useGetProjectsOverviewByTagQuery,
  useGetProjectsTimelineQuery,
  useGetMyProjectsOverviewQuery,
  useGetMyProjectsOverviewByPeriodQuery,
} = projectOverviewApi;

export const transformProjectOverviewResult = (
  {
    additionalData,
    records,
  }: {
    additionalData: AdditionalData;
    records: Project[];
  },
  arg: {
    filter: PiosFilter;
    _customerId: string | undefined;
    _userId: string | undefined;
  }
): ProjectOverviewResult => {
  records.forEach((project) => {
    project.id = project.projectId;
    project.totalBudgetPeriod = !arg.filter.period
      ? undefined
      : project.periods?.find((period) => period.year === arg.filter.period)
          ?.totalBudget ?? undefined;
  });

  return {
    additionalData,
    projects: records.sort((a, b) => {
      if (a.budget === b.budget) {
        return (a.title as string) < (b.title as string) ? 1 : -1;
      }

      return (a.budget ?? 0) < (b.budget ?? 0) ? 1 : -1;
    }),
  };
};
