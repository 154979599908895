import { CacheTags, piosApi } from 'api/piosApi';
import { filterToProjectEffectRealisationQuery } from 'api/utils';
import { PiosFilter } from 'models';
import {
  ProjectEffectRealisation,
  ProjectEffectRealisationAdditionalData,
  ProjectEffectRealisationOverviewResult,
} from 'models/project-effect';
import { calculateProjectEffectRealisation } from 'utils/project-effect';

const projectEffectRealisationOverviewApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get project effect realisation overview
     */
    getProjectEffectRealisationsOverview: build.query<
      ProjectEffectRealisationOverviewResult,
      {
        filter: PiosFilter;
        _userId: string | undefined;
        _customerId: string | undefined;
      }
    >({
      // _customerId and  _userId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switched
      query: ({ filter, _userId, _customerId }) => {
        const url = `overview/effect-realization?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;

        const filters = filterToProjectEffectRealisationQuery(filter);

        return `${url}${filters}`;
      },
      providesTags: [CacheTags.ProjectEffectRealisationOverview],
      transformResponse: ({
        additionalData,
        records,
      }: {
        additionalData: ProjectEffectRealisationAdditionalData;
        records: ProjectEffectRealisation[];
      }) => {
        const projects = calculateProjectEffectRealisation(records).sort(
          (a, b) => {
            if (a.budget === b.budget) {
              return (a.title as string) < (b.title as string) ? 1 : -1;
            }

            return (a.budget ?? 0) < (b.budget ?? 0) ? 1 : -1;
          }
        );

        return {
          projects,
          additionalData,
        };
      },
    }),

    /**
     * Get my project effect realisation overview
     */
    getMyProjectEffectRealisationsOverview: build.query<
      ProjectEffectRealisationOverviewResult,
      {
        filter: PiosFilter;
        _userId: string | undefined;
        _customerId: string | undefined;
      }
    >({
      // _customerId and  _userId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switched
      query: ({ filter, _userId, _customerId }) => {
        const url = `overview/my-effect-realization?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;

        const filters = filterToProjectEffectRealisationQuery(filter);

        return `${url}${filters}`;
      },
      providesTags: [CacheTags.ProjectEffectRealisationOverview],
      transformResponse: ({
        additionalData,
        records,
      }: {
        additionalData: ProjectEffectRealisationAdditionalData;
        records: ProjectEffectRealisation[];
      }) => {
        const projects = calculateProjectEffectRealisation(records).sort(
          (a, b) => {
            if (a.budget === b.budget) {
              return (a.title as string) < (b.title as string) ? 1 : -1;
            }

            return (a.budget ?? 0) < (b.budget ?? 0) ? 1 : -1;
          }
        );

        return {
          projects,
          additionalData,
        };
      },
    }),
  }),
});

export const {
  useGetProjectEffectRealisationsOverviewQuery,
  useGetMyProjectEffectRealisationsOverviewQuery,
} = projectEffectRealisationOverviewApi;
