import { Input } from '@dimatech/shared/lib/components/form';
import { SortDirection } from '@dimatech/shared/lib/models';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { searchPortfolios } from 'api/portfolio/portfolioSearchSlice';
import { useAppDispatch } from 'hooks';
import { CSSProperties, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const SearchBox = ({
  style,
}: {
  style?: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');

  const handleSearch = () => {
    dispatch(
      searchPortfolios({
        name,
        paginator: {
          page: 1,
          pageSize: 25,
          orderBy: 'portfolioName',
          orderDirection: SortDirection.Asc,
        },
      })
    );

    navigate('/search', {
      state: { name },
    });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement> | undefined) => {
    if (e?.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Style style={style}>
      <Input
        value={name}
        placeholder={t('Search.Placeholder')}
        onChange={(e) => setName(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
      />
      <BsSearch onClick={handleSearch} />
    </Style>
  );
};

SearchBox.displayName = 'SearchBox';

const Style = styled.div`
  min-width: 300px;
  display: flex;
  align-items: center;
  margin-right: 40px;

  > input {
    max-width: 300px;
    padding-right: 20px;
  }

  > svg {
    margin-left: -20px;
    cursor: pointer;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    order: 2;
    margin-right: 0;
    width: 100%;

    > input {
      width: 100%;
      max-width: unset;
    }
  }
`;
