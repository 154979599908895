var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Table, Td, TdCentered, TdRight, Tr, } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson, BsTrash } from 'react-icons/bs';
export var Users = function (_a) {
    var feature = _a.feature, setFeature = _a.setFeature;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [feature.users && feature.users.length === 0 && (_jsx("div", __assign({ style: { paddingBottom: '6px' } }, { children: t('Administrate.FeatureFlag.Exception.NoException') }))), feature.users && feature.users.length > 0 && (_jsx(Table, __assign({ "$striped": true }, { children: _jsx("tbody", { children: feature.users.map(function (user, index) { return (_jsxs(Tr, { children: [_jsx(TdCentered, __assign({ style: { width: 30 } }, { children: _jsx(BsPerson, {}) })), _jsx(Td, { children: user }), _jsx(TdRight, __assign({ style: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(EditButtons, { user: user, feature: feature, setFeature: setFeature }) }))] }, index)); }) }) })))] }));
};
Users.displayName = 'Users';
var EditButtons = function (_a) {
    var feature = _a.feature, setFeature = _a.setFeature, user = _a.user;
    var t = useTranslation().t;
    var _b = useState(false), isDeleting = _b[0], setIsDeleting = _b[1];
    var handleDelete = function (e) {
        e.stopPropagation();
        var newUsers = feature.users.filter(function (u) { return u !== user; });
        setFeature(__assign(__assign({}, feature), { users: newUsers }));
        setIsDeleting(false);
    };
    var handleConfirmDelete = function (e, show) {
        e.stopPropagation();
        setIsDeleting(show);
    };
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.FeatureFlag.Exception.Delete.ConfirmTitle') }, { children: [_jsx("div", { children: t('Administrate.FeatureFlag.Exception.Delete.ConfirmText') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleDelete(e); } }, { children: t('Common.UI.Yes') }))] })] }))), _jsx(TooltipContent, { id: "tt-exception-delete", title: t('Administrate.FeatureFlag.Exception.Delete.TooltipTitle'), text: t('Administrate.FeatureFlag.Exception.Delete.Tooltip') }), _jsx("div", __assign({ style: {
                    width: 30,
                } }, { children: _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-exception-delete", onClick: function (e) { return handleConfirmDelete(e, true); } }, { children: _jsx(BsTrash, {}) })) }))] }));
};
