import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { SelectCustomer } from '@dimatech/features-core/lib/features/authentication/components';
import { MessagesBadge } from '@dimatech/features-core/lib/features/messenger';
import { Badge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { DevToolbar } from '@dimatech/shared/lib/components/dev-tools';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import {
  Header as SharedHeader,
  SwitchLocale,
  SwitchTheme,
} from '@dimatech/shared/lib/components/workspace';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { initialsFromEmail } from '@dimatech/shared/lib/utils';
import { dark } from 'assets/themes/dark';
import { light } from 'assets/themes/light';
import { useAppDispatch } from 'hooks';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowRight, BsGear } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SearchBox } from './Search';

export const Header = ({
  isSidePanelShown,
  setIsSidePanelShown,
}: {
  isSidePanelShown?: boolean;
  setIsSidePanelShown?: (isShown: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const { accessToken, webToken, logout } = useContext(AuthenticationContext);

  const initials = initialsFromEmail(accessToken.user?.email);

  return (
    <SharedHeader
      isSidePanelShown={isSidePanelShown}
      setIsSidePanelShown={setIsSidePanelShown}
    >
      <DevToolbar
        token={accessToken.token}
        userId={accessToken.user?.id}
        customerId={accessToken.customerId}
        tokenExpires={accessToken.expires}
        webToken={webToken.token}
        webTokenExpires={webToken.expires}
      />

      {accessToken.user && location.pathname !== '/search' && <SearchBox />}

      <Style>
        <SwitchTheme dark={dark.name} light={light.name} />
        <SwitchLocale />

        {accessToken.user && (
          <>
            <TooltipContent
              id="tt-user-profile"
              text={t('UserProfile.TooltipText')}
            />

            <TooltipContent
              id="tt-user-logout"
              text={t('Logout.TooltipText')}
            />

            <TooltipContent
              place="bottom"
              id="tt-user-info"
              title={accessToken.user.email}
              text={
                <>
                  <strong>{t('Profile.User.Account')}</strong>
                  <div style={{ marginBottom: 10 }}>
                    {accessToken.user.currentCustomer.name}
                  </div>
                  <strong>{t('Profile.User.Roles')}</strong>
                  <div>
                    {accessToken.user.currentCustomer.roles
                      .map((role) => t(`Roles.${role}`))
                      .join(', ')}
                  </div>
                </>
              }
            />

            <BadgeSmall
              data-tip
              data-for="tt-user-profile"
              onClick={() => navigate('/user-profile')}
            >
              <BsGear />
            </BadgeSmall>

            <MessagesBadge style={{ marginRight: 10 }} />

            <BadgeSmall
              data-tip
              data-for="tt-user-logout"
              onClick={() => logout()}
              style={{ marginLeft: 10 }}
            >
              <BsBoxArrowRight />
            </BadgeSmall>

            <Badge
              onClick={() => setIsVisible(!isVisible)}
              data-tip
              data-for="tt-user-info"
              style={{ marginLeft: 20 }}
            >
              {initials}
            </Badge>

            <User onClick={() => setIsVisible(!isVisible)}>
              <div data-tip data-for="tt-user-info">
                {accessToken.user.email}
              </div>
              <div>
                <SelectCustomer
                  handleCustomerChange={() =>
                    dispatch(appActions.resetApplication())
                  }
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                />
              </div>
            </User>
          </>
        )}
      </Style>
    </SharedHeader>
  );
};

Header.displayName = 'Header';

const Style = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const User = styled.div`
  margin-left: 10px;

  > div:first-of-type {
    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};

    @media screen and (max-width: ${Breakpoints.small}) {
      display: none;
    }
  }
`;
