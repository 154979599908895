import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Select } from '@dimatech/shared/lib/components/form';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import {
  DimensionDisplayType,
  DimensionType,
  Phase,
  ProjectDimension,
  ProjectDimensionMutation,
} from 'models';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const SelectCustomDimensions = ({
  reset,
  setReset,
  handleChangeDimensions,
  isProject = true,
  items,
}: {
  reset?: boolean;
  setReset?: (reset: boolean) => void;
  handleChangeDimensions: (dimensions: ProjectDimension[]) => void;
  isProject?: boolean;
  items?: ProjectDimension[] | null | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);
  const [dimensions, setDimensions] = useState<ProjectDimensionMutation[]>([]);

  const handleChange = (
    e: SyntheticEvent<HTMLSelectElement>,
    dimension: ProjectDimensionMutation
  ) => {
    const newDimensions = [...dimensions];
    const index = newDimensions.findIndex(
      (d) => d.projectDimensionId === dimension.projectDimensionId
    );

    if (index > -1) {
      newDimensions[index] = {
        ...newDimensions[index],
        value: e.currentTarget.value,
      };
    }

    setReset?.(false);
    setDimensions(newDimensions);
    handleChangeDimensions(newDimensions);
  };

  const { data, isFetching, isLoading } = useGetDimensionsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  useEffect(() => {
    const dimensions = data
      ?.filter(
        (dimension) =>
          dimension.type === DimensionType.Data &&
          (isProject
            ? dimension.projectPhases?.includes(Phase.NotSet)
            : dimension.projectPhases?.includes(Phase.Draft))
      )
      .map(
        (dimension) =>
          ({
            projectDimensionId: dimension.id,
            value: items?.find(
              (item) => item.projectDimensionId === dimension.id
            )?.value,
            ...dimension,
          } as ProjectDimensionMutation)
      );

    setDimensions(dimensions ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isProject]);

  useEffect(() => {
    if (reset) {
      const newDimensions = dimensions.map(
        (dimension) => ({ ...dimension, value: '' } as ProjectDimensionMutation)
      );
      setDimensions(newDimensions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  if (isFetching || isLoading) {
    return <LoaderSmall />;
  }

  return (
    <>
      {dimensions
        ?.filter(
          (dimension) => dimension.displayType === DimensionDisplayType.List
        )
        .map((dimension) => {
          return (
            <Style key={dimension.projectDimensionId} className="filter-field">
              <Select
                value={dimension.value}
                onChange={(e) => {
                  handleChange(e, dimension);
                }}
                style={{ width: '100%', minWidth: 150 }}
              >
                <option value="">
                  {t('Search.AnyDimensionName', {
                    dimensionName: dimension.name?.toLowerCase(),
                  })}
                </option>

                {dimension.values?.map((value) => (
                  <option key={value.id} value={value.name}>
                    {value.name}
                  </option>
                ))}
              </Select>

              {dimension.description && (
                <HelpIcon
                  showTiny={true}
                  style={{ marginLeft: 5 }}
                  title={dimension.name}
                  text={dimension.description}
                />
              )}
            </Style>
          );
        })}
    </>
  );
};

SelectCustomDimensions.displayName = 'SelectCustomDimensions';

const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
