import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertLowPrio } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  ButtonSecondary,
  Buttons,
  Select,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Admin } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { SelectPortfolio } from 'components/SelectPortfolio';
import { CommonRoles, Portfolio, Project } from 'models';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ProjectUserAdd = ({
  handleAddProjectUser,
  setIsAdding,
  role,
}: {
  handleAddProjectUser: (
    user: Admin,
    portfolioId: string,
    project: Project
  ) => void;
  setIsAdding: (isAdding: boolean) => void;
  role: CommonRoles;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [user, setUser] = useState<Admin | null>(null);
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [projectId, setProjectId] = useState<string>('');
  const [isValid, setIsValid] = useState(true);
  const [path, setPath] = useState('');

  const {
    data: projectsOverview,
    isFetching,
    isLoading,
  } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id && portfolio?.id
      ? {
          filter: {
            portfolioIds: [portfolio.id],
            entityIds: undefined,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsValid(true);

    if (!user?.email || !portfolio?.id || !projectId) {
      setIsValid(false);
      return;
    }

    const project = projectsOverview?.projects.find(
      (el) => el.id === projectId
    );

    if (portfolio.id && project) {
      handleAddProjectUser({ ...user }, portfolio.id, project);

      setIsAdding(false);
    }
  };

  const handleCancel = () => {
    setIsAdding(false);
  };

  const handleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    e.stopPropagation();

    if (!projectsOverview || !e.currentTarget.value) {
      return;
    }

    const project = projectsOverview.projects.find(
      (project) => project.id === e.currentTarget.value
    );

    if (project?.id) {
      setProjectId(project.id);
    }
    if (project === undefined) {
      setProjectId('');
    }
  };

  useEffect(() => {
    if (role === CommonRoles.PiosEffectRealizationResponsible) {
      setPath(`Project.EffectRealisationResponsible`);
    }
    if (role === CommonRoles.PiosProjectManager) {
      setPath(`Project.Manager`);
    }
    if (role === CommonRoles.PiosOrderingParty) {
      setPath(`Project.OrderingParty`);
    }
  }, [role]);

  return (
    <Modal
      title={t(`${path}.Add.Title`)}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsAdding(false)}
    >
      <div>{t(`${path}.Add.Text`)}</div>

      <AlertLowPrio style={{ margin: '10px 0' }}>
        {t(`Administrate.Users.EmailNotification`)}
      </AlertLowPrio>

      <SearchUser user={user} setUser={setUser} />

      <SelectPortfolio
        setPortfolio={setPortfolio}
        invalid={!isValid && !portfolio}
        style={{ marginTop: 20 }}
      />

      {(isLoading || isFetching) && <LoaderSmall />}

      {!isLoading && !isFetching && projectsOverview?.projects && (
        <Select
          value={projectId}
          onChange={(e) => handleChange(e)}
          invalid={!isValid && !projectId}
          style={{ marginTop: 20 }}
        >
          <option value={undefined}>{t('Search.SelectProject')}</option>
          {[...projectsOverview.projects]
            ?.sort((a, b) =>
              (a.title as string) > (b.title as string) ? 1 : -1
            )
            .map((project) => (
              <option key={project.id} value={project.id}>
                {project.title}
              </option>
            ))}
        </Select>
      )}

      <Buttons>
        <ButtonSecondary type="button" onClick={handleCancel}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={handleSave}>
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </Modal>
  );
};

ProjectUserAdd.displayName = 'ProjectUserAdd';
