import {
  features_core_en,
  features_core_sv,
} from '@dimatech/features-core/lib/localization/';
import { defaultOptions } from '@dimatech/shared/lib/localization';
import shared_en from '@dimatech/shared/lib/localization/lang/en.json';
import shared_sv from '@dimatech/shared/lib/localization/lang/sv.json';
import { Language, Translations } from '@dimatech/shared/lib/models';
import { enGB as en, sv } from 'date-fns/locale';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { mergeJson } from 'utils';
import pios_en from './lang/en.json';
import pios_project_effect_en from './lang/project-effect/en.json';
import pios_project_effect_sv from './lang/project-effect/sv.json';
import pios_project_idea_en from './lang/project-idea/en.json';
import pios_project_idea_sv from './lang/project-idea/sv.json';
import pios_project_en from './lang/project/en.json';
import pios_project_sv from './lang/project/sv.json';
import pios_roles_en from './lang/roles/en.json';
import pios_roles_sv from './lang/roles/sv.json';
import pios_sv from './lang/sv.json';

import pios_administrate_calculation_en from './lang/administrate-calculation/en.json';
import pios_administrate_calculation_sv from './lang/administrate-calculation/sv.json';
import pios_administrate_custom_dimension_en from './lang/administrate-custom-dimension/en.json';
import pios_administrate_custom_dimension_sv from './lang/administrate-custom-dimension/sv.json';
import pios_administrate_entity_en from './lang/administrate-entity/en.json';
import pios_administrate_entity_sv from './lang/administrate-entity/sv.json';
import pios_administrate_feature_flag_en from './lang/administrate-feature-flag/en.json';
import pios_administrate_feature_flag_sv from './lang/administrate-feature-flag/sv.json';
import pios_administrate_message_template_en from './lang/administrate-message-template/en.json';
import pios_administrate_message_template_sv from './lang/administrate-message-template/sv.json';
import pios_administrate_portfolio_en from './lang/administrate-portfolio/en.json';
import pios_administrate_portfolio_sv from './lang/administrate-portfolio/sv.json';
import pios_administrate_users_en from './lang/administrate-users/en.json';
import pios_administrate_users_sv from './lang/administrate-users/sv.json';
import pios_administrate_en from './lang/administrate/en.json';
import pios_administrate_sv from './lang/administrate/sv.json';
import pios_global_administrate_national_tags_en from './lang/global-administrate-national-tags/en.json';
import pios_global_administrate_national_tags_sv from './lang/global-administrate-national-tags/sv.json';
import pios_portfolio_en from './lang/portfolio/en.json';
import pios_portfolio_sv from './lang/portfolio/sv.json';
import pios_portfolios_en from './lang/portfolios/en.json';
import pios_portfolios_sv from './lang/portfolios/sv.json';
import pios_projects_en from './lang/projects/en.json';
import pios_projects_sv from './lang/projects/sv.json';
import pios_search_en from './lang/search/en.json';
import pios_search_sv from './lang/search/sv.json';

registerLocale(Language.English, en);
registerLocale(Language.Swedish, sv);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(defaultOptions);

// Merge all shared language files with the language files in this app
i18n.addResourceBundle(
  Language.Swedish,
  Translations.Common,
  mergeJson(features_core_sv, [
    shared_sv,
    pios_project_idea_sv,
    pios_project_effect_sv,
    pios_project_sv,
    pios_projects_sv,
    pios_portfolio_sv,
    pios_portfolios_sv,
    pios_search_sv,
    pios_administrate_sv,
    pios_administrate_calculation_sv,
    pios_administrate_custom_dimension_sv,
    pios_administrate_entity_sv,
    pios_administrate_feature_flag_sv,
    pios_administrate_message_template_sv,
    pios_administrate_portfolio_sv,
    pios_administrate_users_sv,
    pios_global_administrate_national_tags_sv,
    pios_roles_sv,
    pios_sv,
  ])
);

i18n.addResourceBundle(
  Language.English,
  Translations.Common,
  mergeJson(features_core_en, [
    shared_en,
    pios_project_idea_en,
    pios_project_effect_en,
    pios_project_en,
    pios_projects_en,
    pios_portfolio_en,
    pios_portfolios_en,
    pios_search_en,
    pios_administrate_en,
    pios_administrate_calculation_en,
    pios_administrate_custom_dimension_en,
    pios_administrate_entity_en,
    pios_administrate_feature_flag_en,
    pios_administrate_message_template_en,
    pios_administrate_portfolio_en,
    pios_administrate_users_en,
    pios_global_administrate_national_tags_en,
    pios_roles_en,
    pios_en,
  ])
);

export default i18n;
