var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Button, ButtonFooterWithToast, ButtonSecondary, Buttons, Input, Label, TextArea, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAddTagMutation, useMoveTagMutation, useUpdateTagMutation, } from '../../../api/tag/tagApi';
import { selectSelectedTag, tagActions } from '../../../api/tag/tagSlice';
import { SelectTag } from '../../../components/SelectTag';
import { isAdminReadOnly } from '../../../utils';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { TagPropertiesEditButtons } from './TagPropertiesEditButtons';
export var TagProperties = function (_a) {
    var _b, _c;
    var handleMoved = _a.handleMoved, handleDeleted = _a.handleDeleted, _d = _a.isDescriptionEnabled, isDescriptionEnabled = _d === void 0 ? true : _d;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = isAdminReadOnly(accessToken);
    var _f = useAddTagMutation(), addTag = _f[0], _g = _f[1], addPosting = _g.isLoading, addErrors = _g.error;
    var _h = useUpdateTagMutation(), updateTag = _h[0], _j = _h[1], updatePosting = _j.isLoading, updateErrors = _j.error;
    var moveTag = useMoveTagMutation()[0];
    var selectedTag = useSelector(selectSelectedTag);
    var _k = useState(), tag = _k[0], setTag = _k[1];
    var isAdding = selectedTag && !selectedTag.id;
    var isPosting = updatePosting || addPosting;
    var _l = useState(true), isValid = _l[0], setIsValid = _l[1];
    var _m = useState(false), hasChanges = _m[0], setHasChanges = _m[1];
    var _o = useState(false), isMoving = _o[0], setIsMoving = _o[1];
    var _p = useState(), tagId = _p[0], setTagId = _p[1];
    var _q = useState(false), isChangesSaved = _q[0], setIsChangesSaved = _q[1];
    useEffect(function () {
        setTag(selectedTag ? __assign({}, selectedTag) : undefined);
        setIsValid(true);
    }, [selectedTag]);
    var handleSave = function (e) {
        if (isReadOnly) {
            return;
        }
        setIsValid(true);
        if (!(tag === null || tag === void 0 ? void 0 : tag.displayName)) {
            setIsValid(false);
            return;
        }
        if (tag === null || tag === void 0 ? void 0 : tag.id) {
            updateTag(__assign({}, tag))
                .unwrap()
                .then(function () {
                dispatch(tagActions.selectedTag(__assign({}, tag)));
                setIsChangesSaved(true);
                setHasChanges(false);
            });
            return;
        }
        addTag(__assign({}, tag))
            .unwrap()
            .then(function (result) {
            dispatch(tagActions.selectedTag(__assign(__assign({}, tag), result)));
            setIsChangesSaved(true);
            setHasChanges(false);
        })
            .catch(function () {
            // Do nothing
        });
    };
    var handleCancel = function (e) {
        dispatch(tagActions.selectedTag());
    };
    var handleMove = function () {
        if (isReadOnly || !(tag === null || tag === void 0 ? void 0 : tag.id) || tag.parentId === tagId) {
            return;
        }
        moveTag({ tagId: tag === null || tag === void 0 ? void 0 : tag.id, parentId: tagId !== null && tagId !== void 0 ? tagId : '' })
            .unwrap()
            .then(function () { return handleMoved === null || handleMoved === void 0 ? void 0 : handleMoved(); });
        setIsMoving(false);
        setTagId(undefined);
    };
    var handleAddTag = function (_e, tag) {
        setTagId(tag === null || tag === void 0 ? void 0 : tag.id);
    };
    var handleKeyDown = function (e) {
        if ((e === null || e === void 0 ? void 0 : e.key) === 'Enter') {
            handleSave(e);
        }
        if ((e === null || e === void 0 ? void 0 : e.key) === 'Escape') {
            handleCancel(e);
        }
    };
    return (_jsxs(_Fragment, { children: [isValid && (_jsx(AlertErrors, { error: addErrors !== null && addErrors !== void 0 ? addErrors : updateErrors, translationPath: "Tag.ValidationError" })), _jsxs(Label, __assign({ htmlFor: "displayName" }, { children: [" ", t('Administrate.Tag.Name')] })), _jsx(Input, { maxLength: 100, autoFocus: true, type: "text", id: "displayName", name: "displayName", placeholder: t('Administrate.Tag.NamePlaceholder'), value: (_b = tag === null || tag === void 0 ? void 0 : tag.displayName) !== null && _b !== void 0 ? _b : '', invalid: !isValid && !(tag === null || tag === void 0 ? void 0 : tag.displayName), onKeyDown: handleKeyDown, onChange: function (e) {
                    setHasChanges(true);
                    setTag(__assign(__assign({}, tag), { displayName: e.currentTarget.value }));
                } }), isDescriptionEnabled && (_jsxs(_Fragment, { children: [_jsx(Label, __assign({ htmlFor: "description" }, { children: t('Administrate.Tag.Description') })), _jsx("div", __assign({ className: "size-s i" }, { children: t('Administrate.Tag.DescriptionInfo') })), _jsx(TextArea, { id: "description", name: "description", value: (_c = tag === null || tag === void 0 ? void 0 : tag.description) !== null && _c !== void 0 ? _c : '', onChange: function (e) {
                            setHasChanges(true);
                            setTag(__assign(__assign({}, tag), { description: e.currentTarget.value }));
                        } })] })), isMoving && !isAdding && (_jsxs(Modal, __assign({ title: t('Administrate.Tag.Move.Title') }, { children: [_jsx("div", { children: t('Administrate.Tag.Move.Text') }), _jsx(Label, { children: t('Administrate.Tag.Move.To', { name: tag === null || tag === void 0 ? void 0 : tag.displayName }) }), _jsx(SelectTag, { autoFocus: true, filter: function (t) { return t.id !== (tag === null || tag === void 0 ? void 0 : tag.id); }, onSelectTag: handleAddTag, placeholder: t('Administrate.Tag.Move.NoParent') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsMoving(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleMove, disabled: isReadOnly }, { children: t('Administrate.Tag.Button.Move') }))] })] }))), _jsxs(ButtonFooterWithToast, __assign({ isSaved: isChangesSaved, setIsSaved: setIsChangesSaved }, { children: [!isAdding && tag && (_jsxs(Buttons, __assign({ style: { justifyContent: 'flex-start' } }, { children: [_jsx(TagPropertiesEditButtons, { item: tag, handleDeleted: handleDeleted, setSelectedItem: setTag, isReadOnly: isReadOnly }), _jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsMoving(true); } }, { children: t('Administrate.Tag.Button.Move') }))] }))), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", disabled: isReadOnly || isPosting || !hasChanges, onClick: handleSave }, { children: t('Common.Form.Save') }))] })] }))] }));
};
TagProperties.displayName = 'TagProperties';
