var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsMessageTemplate, featuresCoreApi } from '../featuresCoreApi';
var commonMessageTemplateApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get message templates for current entity and locale
         */
        getCommonMessageTemplates: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function (_a) {
                var filter = _a.filter, _customerId = _a._customerId;
                return "messageTemplates/customer?locale=".concat(filter.locale);
            },
            providesTags: [CacheTagsMessageTemplate.CommonMessageTemplate],
        }),
        /**
         * Update message template
         */
        updateCommonMessageTemplate: build.mutation({
            query: function (template) { return ({
                url: "messageTemplates/customer/updateOrCreate",
                method: 'PUT',
                body: __assign({}, template),
            }); },
            invalidatesTags: [CacheTagsMessageTemplate.CommonMessageTemplate],
        }),
        /**
         * Get message templates preview
         */
        getPreviewCommonMessageTemplate: build.query({
            query: function (_a) {
                var locale = _a.locale, messageTemplateId = _a.messageTemplateId;
                return "messagetemplates/customer/preview?locale=".concat(locale, "&messageTemplateId=").concat(messageTemplateId);
            },
            providesTags: [CacheTagsMessageTemplate.CommonMessageTemplate],
        }),
        /**
         * Send preview customer message template
         */
        sendPreviewCommonMessageTemplate: build.mutation({
            query: function (_a) {
                var locale = _a.locale, messageTemplateId = _a.messageTemplateId, email = _a.email;
                return {
                    url: 'messagetemplates/customer/send',
                    method: 'POST',
                    body: {
                        locale: locale,
                        messageTemplateId: messageTemplateId,
                        email: email,
                    },
                };
            },
        }),
    }); },
});
export var useGetCommonMessageTemplatesQuery = commonMessageTemplateApi.useGetCommonMessageTemplatesQuery, useUpdateCommonMessageTemplateMutation = commonMessageTemplateApi.useUpdateCommonMessageTemplateMutation, useGetPreviewCommonMessageTemplateQuery = commonMessageTemplateApi.useGetPreviewCommonMessageTemplateQuery, useSendPreviewCommonMessageTemplateMutation = commonMessageTemplateApi.useSendPreviewCommonMessageTemplateMutation;
