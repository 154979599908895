import {
  useGetEntitiesQuery,
  useMoveEntityMutation,
} from '@dimatech/features-core/lib/api/entity/entityApi';
import {
  entityActions,
  selectSelectedEntity,
} from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Item, Tree } from '@dimatech/shared/lib/components/tree';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { portfolioActions } from 'api/portfolio/portfolioSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Entity } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBuilding, BsBuildings } from 'react-icons/bs';
import { getAllChildren, getTreePathToItem, isAdminReadOnly } from 'utils';

export const EntitiesTree = ({
  selectedEntityId,
  setSelectedEntityId,
  isDraggable,
}: {
  selectedEntityId?: string;
  setSelectedEntityId?: (entityId: string) => void;
  isDraggable?: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const { accessToken } = useContext(AuthenticationContext);
  const [tree, setTree] = useState<Item[] | undefined>();
  const [moveEntity, { error: moveErrors }] = useMoveEntityMutation();

  const isReadOnly = isAdminReadOnly(accessToken);

  const { data } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const handleSelect = (item: Item) => {
    const entity = data?.find((entity) => entity.id === item.id);

    dispatch(
      entityActions.selectedEntity({
        ...entity,
        childCount: item.items?.length ?? 0,
      } as Entity)
    );

    dispatch(portfolioActions.selectedPortfolio());
  };

  const handleDrop = (id: string, parentId: string) => {
    const draggedEntity = data?.find((item) => item.id === id);
    const hasChildren = data ? getAllChildren(data, [id]) : [];

    if (
      isReadOnly ||
      id === parentId ||
      draggedEntity?.parentId === parentId ||
      hasChildren.length >= 1
    ) {
      return;
    }

    moveEntity({ entityId: id, parentId });
  };

  const mapToItems = (
    entities: Entity[],
    parentIds: (string | null | undefined)[],
    openItems?: string[],
    depth = 0
  ): Item[] => {
    const items = entities
      .filter((entity) => parentIds.includes(entity.parentId))
      .map((entity) => {
        const children = mapToItems(
          entities,
          [entity.id || null],
          openItems,
          depth + 1
        );

        return {
          content: entity.name,
          id: entity.id,
          name: entity.name,
          items: children,
          icon: children.length > 0 ? <BsBuildings /> : <BsBuilding />,
          isOpen: depth < 1 || openItems?.includes(entity.id as string),
        } as Item;
      });

    return items;
  };

  useEffect(() => {
    if (data) {
      const visibleEntities = data.filter((entity) => !entity.isReadOnly);
      const orphans = visibleEntities
        .filter(
          (entity) =>
            !visibleEntities.some((child) => entity.parentId === child.id)
        )
        .map((orphan) => orphan.parentId);

      const entity = selectedEntityId
        ? data?.find((entity) => entity.id === selectedEntityId)
        : // Select root if no other entity is selected
          selectedEntity ?? data?.find((entity) => !entity.parentId);

      const openItems = entity?.parentId
        ? getTreePathToItem(visibleEntities, entity?.parentId)
        : undefined;

      setTree(mapToItems(visibleEntities, orphans, openItems));

      if (entity) {
        const children = visibleEntities
          ? getAllChildren(visibleEntities, [entity.id])
          : [];

        dispatch(
          entityActions.selectedEntity({
            ...entity,
            childCount: children.length,
          } as Entity)
        );
        setSelectedEntityId?.('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedEntityId]);

  return (
    <>
      {(!tree || tree.length === 0) && !selectedEntity && (
        <div>{t('Administrate.Entity.NoEntities')}</div>
      )}

      <AlertErrors
        error={moveErrors}
        style={{ padding: 10 }}
        translationPath="Administrate.Entity.ValidationError"
      />

      {tree && tree.length > 0 && (
        <Tree
          items={tree}
          onSelect={handleSelect}
          selected={selectedEntity?.id}
          highlightItems={
            !selectedEntity?.id && selectedEntity?.parentId
              ? [selectedEntity?.parentId]
              : []
          }
          onDrop={handleDrop}
          isDraggable={isDraggable}
        />
      )}
    </>
  );
};

EntitiesTree.displayName = 'EntitiesTree';
