import { CacheTags, piosApi } from 'api/piosApi';
import { Dimension } from 'models';

const dimensionApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get dimensions
     */
    getDimensions: build.query<Dimension[], string>({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: (_customerId) => `dimensions`,
      providesTags: [CacheTags.Dimension],
      transformResponse: (result: Dimension[], _meta, arg) => {
        return result.map((dimension) => ({
          ...dimension,
          values: dimension.values?.sort((a, b) =>
            (a.name ?? '') > (b.name ?? '') ? 1 : -1
          ),
        }));
      },
    }),

    /**
     * Add dimension
     */
    addDimension: build.mutation({
      query: (dimension: Dimension) => ({
        url: `dimensions`,
        method: 'POST',
        body: {
          ...dimension,
          values: dimension.values?.map((value) => value.name),
        },
      }),
      invalidatesTags: (_result, error) => (error ? [] : [CacheTags.Dimension]),
      transformResponse: (result: Dimension, _meta, arg) => {
        return {
          ...arg,
          ...result,
        } as Dimension;
      },
    }),

    /**
     * Update dimension
     */
    updateDimension: build.mutation({
      query: (dimension: Dimension) => ({
        url: `dimensions/${dimension.id}`,
        method: 'PUT',
        body: {
          ...dimension,
        },
      }),
      invalidatesTags: (_result, error) => (error ? [] : [CacheTags.Dimension]),
      transformResponse: (result: Dimension, _meta, arg) => {
        return {
          ...arg,
          ...result,
        } as Dimension;
      },
    }),

    /**
     * Delete dimension
     */
    deleteDimension: build.mutation({
      query: (id: string) => ({
        url: `dimensions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) =>
        error ? [] : [CacheTags.Dimension, CacheTags.ProjectHistory],
    }),
  }),
});

export const {
  useGetDimensionsQuery,
  useAddDimensionMutation,
  useDeleteDimensionMutation,
  useUpdateDimensionMutation,
} = dimensionApi;
