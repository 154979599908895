import { IdeaReview } from 'features/project-idea/IdeaReview';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectIdeasReview = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('FutureProjects.ProjectIdea.ReviewTitle')}>
      <IdeaReview />
    </View>
  );
};

ProjectIdeasReview.displayName = 'ProjectIdeasReview';
