import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  ButtonSecondary,
  Input,
  InputNumber,
} from '@dimatech/shared/lib/components/form';
import {
  LoaderEllipsis,
  LoaderSmall,
} from '@dimatech/shared/lib/components/loader';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { htmlDecode } from '@dimatech/shared/lib/utils';
import { useEvaluateGoalMutation } from 'api/project/projectGoalApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Permission, PiosColors, Project, ProjectGoal } from 'models';
import { nanoid } from 'nanoid';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFlag, BsPlus, BsX } from 'react-icons/bs';
import styled from 'styled-components';
import {
  hasPermission,
  innovationEfficiencyToPercent,
  internalExternalToPercent,
} from 'utils';
import { GoalBenefits } from './GoalBenefits';
import { GoalEditListDelete } from './GoalEditListDelete';
import { InnovationEfficiencyClassificationEdit } from './InnovationEfficiencyClassificationEdit';
import { InternalExternalClassificationEdit } from './InternalExternalClassificationEdit';

/* eslint-disable max-lines-per-function */

export const GoalEditListEffectRealisation = ({
  project,
  goals,
  isValid,
  setGoals,
  setHasChanges,
}: {
  project: Project;
  goals: ProjectGoal[];
  isValid: boolean;
  setGoals: (goals: ProjectGoal[]) => void;
  setHasChanges: (hasChanges: boolean) => void;
}) => {
  const { t } = useTranslation();

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isGoalAiEvaluationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isGoalAiEvaluationEnabled];

  const [evaluate, { isLoading: isEvaluating, error }] =
    useEvaluateGoalMutation();

  const isViewLimitedAsEffectRealisationResponsible =
    !hasPermission(Permission.Edit, project) &&
    !hasPermission(Permission.EditIdea, project);
  hasPermission(Permission.EditEffectRealization, project);

  const canEditExpected =
    hasPermission(Permission.Edit, project) ||
    hasPermission(Permission.EditIdea, project);
  const canEditActual = hasPermission(
    Permission.EditEffectRealization,
    project
  );

  const [selectedGoalId, setSelectedGoalId] = useState<string>();
  const [evaluatingGoalId, setEvaluatingGoalId] = useState<string>();

  const handleAdd = () => {
    const newGoals = [...(goals ?? [])];

    newGoals.push({
      uid: nanoid(6),
      isDirty: true,
    });

    setHasChanges(true);
    setGoals(newGoals);
  };

  const handleDelete = (goal: ProjectGoal) => {
    if (goal.uid) {
      const index = goals.findIndex((item) => item.uid === goal.uid);

      if (index > -1) {
        const newGoals = [...goals];

        newGoals.splice(index, 1);

        setHasChanges(true);
        setGoals(newGoals);
      }
    }
  };

  const handleSelectGoal = (toGoalId: string, keepExpanded: boolean) => {
    if (!keepExpanded && toGoalId === selectedGoalId) {
      setSelectedGoalId(undefined);
    } else {
      setSelectedGoalId(toGoalId);
    }
  };

  const evaluateGoal = (goal: ProjectGoal) => {
    if (!isGoalAiEvaluationEnabledFlagOn) {
      return;
    }

    setEvaluatingGoalId(goal.id);

    evaluate(goal)
      .unwrap()
      .then((result) => {
        const evaluationResponses = [
          {
            goal: goal.name ?? '',
            response: result?.replaceAll('\n', '<br/>'),
          },
          ...(goal.evaluationResponses ?? []),
        ];

        handleChange({
          ...goal,
          evaluationResponses,
        });

        setEvaluatingGoalId(undefined);
      });
  };

  const handleChange = (goal: ProjectGoal) => {
    const newGoals = [...goals];
    const index = newGoals.findIndex((item) => item.uid === goal.uid);

    if (index === -1) {
      return;
    }

    newGoals[index] = {
      ...goal,
      isDirty: true,
    };

    setHasChanges(true);

    setGoals(newGoals);
  };

  if (!isProjectEffectRealisationEnabledFlagOn) {
    return null;
  }

  return (
    <div style={{ marginBottom: 10 }}>
      {goals.length === 0 && <div>{t('Portfolio.Goal.NoGoals')}</div>}

      {isGoalAiEvaluationEnabledFlagOn && error && (
        <AlertErrors
          style={{ marginBottom: 10 }}
          error={error}
          customValidationErrors={[
            t('Portfolio.Goal.Evaluate.ValidationError.Generic'),
          ]}
        />
      )}

      {goals.length > 0 && (
        <>
          <div>{t('Portfolio.Goal.TextForGoalsAndBenefits')}</div>
          <TableResponsiveContainer>
            <Style>
              <thead>
                <tr>
                  <Th style={{ width: 25 }} />
                  <Th style={{ width: '100%' }}>{t('Portfolio.Goal.Name')}</Th>

                  <ThRight>
                    {t('ImplementedProjects.TakeHomePeriodYear')}
                  </ThRight>

                  <Th style={{ width: 120 }}>
                    {t('Project.EfficiencyInnovation')}
                  </Th>
                  <Th style={{ width: 120 }}>
                    {t('Project.InternalExternal')}
                  </Th>

                  {!isViewLimitedAsEffectRealisationResponsible && <Th />}
                </tr>
              </thead>

              <tbody>
                {goals.map((goal, index) => (
                  <Fragment key={goal.id ?? goal.uid}>
                    <Tr
                      isExpanded={selectedGoalId === goal.id}
                      isExpandable={true}
                      onSelect={(e) => {
                        handleSelectGoal(
                          goal.id as string,
                          ['INPUT', 'SELECT', 'BUTTON'].includes(
                            (e.target as HTMLElement).nodeName
                          )
                        );
                      }}
                    >
                      <TdCentered className="narrow">
                        <BsFlag />
                      </TdCentered>
                      <Td>
                        <div style={{ display: 'flex', gap: 10 }}>
                          {isViewLimitedAsEffectRealisationResponsible &&
                            goal.name}

                          {!isViewLimitedAsEffectRealisationResponsible && (
                            <Input
                              type="text"
                              id="name"
                              name="name"
                              value={goal.name ?? ''}
                              style={{ minWidth: 200 }}
                              invalid={!isValid && !goal.name}
                              onChange={(e) => {
                                handleChange({
                                  ...goal,
                                  name: e.currentTarget.value,
                                });
                              }}
                            />
                          )}

                          {isGoalAiEvaluationEnabledFlagOn &&
                            !isViewLimitedAsEffectRealisationResponsible && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: 7,
                                }}
                              >
                                <ButtonSecondary
                                  type="button"
                                  onClick={() => evaluateGoal(goal)}
                                  disabled={isEvaluating || !goal.name}
                                >
                                  {t('Portfolio.Goal.Evaluate.Evaluate')}
                                </ButtonSecondary>

                                <HelpIcon
                                  style={{ marginLeft: 5 }}
                                  title={t(
                                    'Portfolio.Goal.Evaluate.EvaluateTooltip'
                                  )}
                                  text={t('Portfolio.Goal.Evaluate.Help')}
                                />
                              </div>
                            )}
                        </div>
                      </Td>

                      <TdRight>
                        <InputNumber
                          style={{ width: 50 }}
                          id="takeHomePeriod"
                          name="takeHomePeriod"
                          maxValue={100}
                          defaultValue={goal.takeHomePeriod}
                          onValueChange={({ floatValue }) => {
                            handleChange({
                              ...goal,
                              takeHomePeriod: floatValue,
                            });
                          }}
                        />
                      </TdRight>

                      <Td>
                        {isViewLimitedAsEffectRealisationResponsible && (
                          <Progress
                            width={120}
                            value={innovationEfficiencyToPercent(
                              goal.innovationEfficiencyClassification
                            )}
                            color={PiosColors.efficiency}
                            backgroundColor={PiosColors.innovation}
                          />
                        )}

                        {!isViewLimitedAsEffectRealisationResponsible && (
                          <InnovationEfficiencyClassificationEdit
                            id="innovationEfficiency"
                            goal={goal}
                            canEdit={true}
                            invalid={
                              !isValid &&
                              !goal.innovationEfficiencyClassification
                            }
                            style={{ minWidth: 120 }}
                            setValue={(value) => {
                              handleChange({
                                ...goal,
                                innovationEfficiencyClassification: value,
                              });
                            }}
                          />
                        )}
                      </Td>
                      <Td>
                        {isViewLimitedAsEffectRealisationResponsible && (
                          <Progress
                            width={120}
                            value={internalExternalToPercent(
                              goal.internalExternalClassification
                            )}
                            color={PiosColors.internal}
                            backgroundColor={PiosColors.external}
                          />
                        )}

                        {!isViewLimitedAsEffectRealisationResponsible && (
                          <InternalExternalClassificationEdit
                            id="internalExternal"
                            goal={goal}
                            canEdit={true}
                            invalid={
                              !isValid && !goal.internalExternalClassification
                            }
                            style={{ minWidth: 120 }}
                            setValue={(value) => {
                              handleChange({
                                ...goal,
                                internalExternalClassification: value,
                              });
                            }}
                          />
                        )}
                      </Td>

                      {!isViewLimitedAsEffectRealisationResponsible && (
                        <TdRight>
                          <GoalEditListDelete
                            goal={goal}
                            handleDelete={handleDelete}
                          />
                        </TdRight>
                      )}
                    </Tr>

                    {isGoalAiEvaluationEnabledFlagOn &&
                      (goal.evaluationResponses ||
                        (isEvaluating && evaluatingGoalId === goal.id)) && (
                        <Tr>
                          <Td />
                          <Td>
                            <EvaluateResponseStyle>
                              {isGoalAiEvaluationEnabledFlagOn &&
                                isEvaluating &&
                                evaluatingGoalId === goal.id && (
                                  <div style={{ display: 'flex', gap: 10 }}>
                                    <LoaderSmall />
                                    <LoaderEllipsis className="i">
                                      {t('Portfolio.Goal.Evaluate.Loading')}
                                    </LoaderEllipsis>
                                  </div>
                                )}

                              {goal.evaluationResponses?.map(
                                (response, index) => (
                                  <div key={index} className="response">
                                    <div>{response.goal}</div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          htmlDecode(response.response) ?? '',
                                      }}
                                    />
                                  </div>
                                )
                              )}

                              <LinkWithTooltip
                                handleClick={() => {
                                  handleChange({
                                    ...goal,
                                    evaluationResponses: undefined,
                                  });
                                }}
                                icon={<BsX />}
                                title={t('Common.UI.Clear')}
                              />
                            </EvaluateResponseStyle>
                          </Td>
                        </Tr>
                      )}

                    {selectedGoalId === goal.id && (
                      <Tr>
                        <BenefitTableStyleCell colSpan={6}>
                          <div
                            style={{
                              marginBottom: index !== goals.length - 1 ? 30 : 0,
                            }}
                          >
                            <GoalBenefits
                              goal={goal}
                              handleChangeGoal={handleChange}
                              canEditExpected={canEditExpected}
                              canEditActual={canEditActual}
                            />
                          </div>
                        </BenefitTableStyleCell>
                      </Tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </Style>
          </TableResponsiveContainer>
        </>
      )}

      {!isViewLimitedAsEffectRealisationResponsible && (
        <LinkWithTooltip
          isPrimary={true}
          handleClick={handleAdd}
          title={t('Portfolio.Goal.AddGoalsAndBenefits.Title')}
          tooltipTitle={t('Portfolio.Goal.AddGoalsAndBenefits.TooltipTitle')}
          tooltip={t('Portfolio.Goal.AddGoalsAndBenefits.Tooltip')}
          icon={<BsPlus />}
          style={{ marginTop: 30 }}
        />
      )}
    </div>
  );
};

GoalEditListEffectRealisation.displayName = 'GoalEditListEffectRealisation';

const Style = styled(Table)`
  > tbody {
    > tr {
      height: 60px;

      > td {
        vertical-align: middle;
      }

      td.total {
        border-bottom: 1px solid
          ${({ theme }: { theme: Theme }) => theme.colors.border};
      }
    }
  }
`;

const EvaluateResponseStyle = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 0px 0px 4px 4px;

  box-shadow: ${({ theme }: { theme: Theme }) => theme.boxShadows.default};
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background};

  > div {
    padding: 10px;

    &.response {
      > div:first-of-type {
        font-weight: bold;
      }
    }
  }
`;

const BenefitTableStyleCell = styled(Td)`
  padding: 0 0 20px 0;

  > div {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.surfaceVariant};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

    padding: 10px;
  }
`;
