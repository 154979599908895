import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeLarge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { HistoryChangeLog } from '@dimatech/shared/lib/components/history';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  CardFocused,
  CardFocusedBody,
  CardRow,
  CardTile,
  ViewHeader,
  ViewHeaderActions,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetProjectHistoryQuery } from 'api/portfolio/portfolioHistoryApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { HistoryLog, Phase, Project as ProjectModel } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsX } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { parseHistoryChange } from 'utils';

export const ProjectHistory = (): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id = '', projectId = '' } = useParams();
  const { accessToken } = useContext(AuthenticationContext);
  const { locale } = useContext(LocalizationContext);

  const [project, setProject] = useState<ProjectModel>();
  const [projectHistory, setProjectHistory] = useState<HistoryLog[]>();

  const phases = Object.keys(Phase).map((value) => value as Phase);

  const { data: projectsOverview } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: {
            portfolioIds: [id],
            phases,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: history,
    isLoading,
    isFetching,
  } = useGetProjectHistoryQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          portfolioId: id,
          projectId,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handleCancel = () => {
    navigate(`/project/${id}/${projectId}`);
  };

  useEffect(() => {
    if (projectsOverview?.projects && projectId) {
      const selected = projectsOverview.projects.find(
        (p) => p.id === projectId
      );

      setProject(selected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsOverview, projectId]);

  useEffect(() => {
    if (history) {
      const parsed = history.map((historyUpdate) => ({
        ...historyUpdate,
        changes: historyUpdate.changes.map((change) => ({
          ...change,
          metaData: parseHistoryChange(change, accessToken.locale, t),
        })),
      }));

      setProjectHistory(parsed);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locale]);

  return (
    <>
      <ViewHeader>
        <div>
          <TooltipContent id="tt-navigate-back" text={t('Common.UI.Back')} />
          <BadgeSmall
            data-tip
            data-for="tt-navigate-back"
            onClick={handleCancel}
            $inverted={true}
            style={{ marginRight: 15 }}
          >
            <BsArrowLeft />
          </BadgeSmall>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Heading1>
            {t('History.HistoryFor', { name: project?.title })}
          </Heading1>

          {project && !accessToken.isBaseLicense && (
            <ButtonLink onClick={handleCancel}>
              {project.portfolioName}
            </ButtonLink>
          )}
        </div>

        <ViewHeaderActions>
          <div
            style={{
              display: 'flex',
              gap: 10,
            }}
          >
            <TooltipContent id="tt-cancel" text={t('Common.Form.Cancel')} />

            <BadgeLarge
              data-tip
              data-for="tt-cancel"
              onClick={handleCancel}
              $inverted={true}
            >
              <BsX />
            </BadgeLarge>
          </div>
        </ViewHeaderActions>

        <ViewRowBreak style={{ margin: 0 }} />
      </ViewHeader>

      <CardFocused>
        <CardFocusedBody isLoading={isLoading || isFetching}>
          <Style>
            <CardRow>
              <CardTile>
                {projectHistory && (
                  <HistoryChangeLog
                    history={projectHistory}
                    currentUserEmail={accessToken.user?.email || ''}
                  />
                )}
              </CardTile>
            </CardRow>
          </Style>
        </CardFocusedBody>
      </CardFocused>
    </>
  );
};

ProjectHistory.displayName = 'ProjectHistory';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;
`;
