import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { PortfoliosTable } from 'components/PortfoliosTable';
import { useContext, useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const MyPortfoliosList = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const saveToPngElementId = useId();

  const { data, isFetching } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user
      ? {
          filter: {
            onlyMine: true,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  if (data && data.portfolios.length === 0) {
    return null;
  }

  return (
    <Card
      title={t('MyPortfolios.List.Title')}
      helpText={<Trans i18nKey={'MyPortfolios.List.Help'} />}
      canBeSavedAsPng={true}
      saveToPngElementId={saveToPngElementId}
    >
      <CardBody isLoading={isFetching}>
        {data && (
          <PortfoliosTable
            portfolios={data.portfolios}
            saveToPngElementId={saveToPngElementId}
          />
        )}
      </CardBody>
    </Card>
  );
};

MyPortfoliosList.displayName = 'MyPortfoliosList';
