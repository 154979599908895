import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { selectFilter } from 'api/piosSlice';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppSelector } from 'hooks';
import { ProjectIdea, ProjectIdeaAction } from 'models';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isReadOnly } from 'utils';
import { IdeaArchiveListEditButtons } from './IdeaArchiveListEditButtons';
import { IdeaStatus } from './IdeaStatus';

// TODO: Fix, split code
/* eslint-disable max-lines-per-function */

export const IdeaArchiveList = ({
  projects,
}: {
  projects: ProjectIdea[];
}): JSX.Element => {
  const { t } = useTranslation();
  const selectAndNavigate = useSelectAndNavigate();
  const filter = useAppSelector(selectFilter);

  const {
    items: sortedIdeas,
    setPage,
    paginator,
    sorter,
  } = useUiSortablePaginator({
    orderBy: 'title',
    pageSize: 25,
    data: {
      totalRecords: projects.length,
      records: projects,
    },
  });

  const handleSelect = (idea: ProjectIdea) => {
    selectAndNavigate(ProjectIdeaAction.Archive, idea);
  };

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        {t('FutureProjects.ProjectIdeaArchive.Text')}
      </div>

      {sortedIdeas.length === 0 && (
        <div>{t('FutureProjects.ProjectIdea.NoProjectIdeas')}</div>
      )}

      {sortedIdeas.length > 0 && (
        <>
          <Pagination
            currentPage={paginator.page}
            totalCount={projects.length}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
            style={{ marginBottom: 20 }}
          />

          <TableResponsiveContainer>
            <Table>
              <thead>
                <tr>
                  <ThCentered
                    className="narrow"
                    sortKey="projectPhase"
                    sorter={sorter}
                    style={{ width: 50 }}
                  >
                    {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                  </ThCentered>
                  <Th
                    className="narrow"
                    sortKey="externalId"
                    sorter={sorter}
                    style={{ width: 60 }}
                  >
                    {t('Project.Id')}
                  </Th>
                  <Th>
                    <SortElements>
                      <SortElement sortKey="title" sorter={sorter}>
                        {t('Project.Project')}
                      </SortElement>
                      <SortElement sortKey="portfolioName" sorter={sorter}>
                        {t('Portfolio.Portfolio')}
                      </SortElement>
                    </SortElements>
                  </Th>
                  <Th sortKey="entityName" sorter={sorter}>
                    {t('Portfolio.Entity')}
                  </Th>

                  <ThRight sortKey="startYear" sorter={sorter}>
                    {t('Project.StartYear')}
                  </ThRight>
                  <ThRight sortKey="endYear" sorter={sorter}>
                    {t('Project.EndYear')}
                  </ThRight>
                  <Th />
                </tr>
              </thead>

              <tbody>
                {sortedIdeas.map((idea) => (
                  <Fragment key={idea.id}>
                    <Tr className="main" onSelect={() => handleSelect(idea)}>
                      <TdCentered className="narrow" style={{ width: 80 }}>
                        <IdeaStatus status={idea.projectPhase} />
                      </TdCentered>
                      <Td className="narrow" style={{ width: 80 }}>
                        {idea.externalId}
                      </Td>
                      <Td>
                        <div className="b">{idea.title}</div>
                        <div className="i">{idea.portfolioName}</div>
                      </Td>
                      <Td>{idea.entityName}</Td>

                      <TdRight>{idea.startYear}</TdRight>
                      <TdRight>{idea.endYear}</TdRight>
                      <TdRight>
                        {!isReadOnly(idea) && (
                          <IdeaArchiveListEditButtons idea={idea} />
                        )}
                      </TdRight>
                    </Tr>
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </TableResponsiveContainer>

          <Pagination
            currentPage={paginator.page}
            totalCount={projects.length}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />
        </>
      )}
    </>
  );
};

IdeaArchiveList.displayName = 'IdeaArchiveList';
