import { Heading2 } from '@dimatech/shared/lib/components/typography';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ViewCentered } from 'views';

export const CookieInformation = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('Cookies.Information.Title')} style={{ width: 350 }}>
      <Heading2>{t('Cookies.Information.Title')}</Heading2>
      <Trans
        i18nKey={'Cookies.Information.Text'}
        values={{ productName: t('Account.Pios.Name') }}
      />
      <div>
        <a href="http://www.pts.se/cookies">
          {t('Cookies.Information.ReadMore')}
        </a>
      </div>

      <div style={{ marginTop: 20 }}>
        <Link to="/login">{t('Common.UI.Back')}</Link>
      </div>
    </ViewCentered>
  );
};

CookieInformation.displayName = 'CookieInformation';
