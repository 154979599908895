import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetProjectsOverviewByPeriodQuery } from 'api/project/projectOverviewApi';
import { BudgetAndBalanceOverTime } from 'components/BudgetAndBalanceOverTime';
import { useAppSelector } from 'hooks';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const ProjectsBudgetAndBalanceOverTime = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const { data, isFetching } = useGetProjectsOverviewByPeriodQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: { ...filter, period: undefined },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  if (data && data.length === 0) {
    return null;
  }

  return (
    <Card
      helpTextTitle={t('Projects.BudgetAndBalanceOverTime.Title')}
      helpText={<Trans i18nKey={'Projects.BudgetAndBalanceOverTime.Help'} />}
      canBeSavedAsPng={true}
      style={{ paddingTop: 10 }}
    >
      <CardBody isLoading={isFetching}>
        {data && <BudgetAndBalanceOverTime overviewByPeriod={data} />}
      </CardBody>
    </Card>
  );
};

ProjectsBudgetAndBalanceOverTime.displayName =
  'ProjectsBudgetAndBalanceOverTime';
