import { CacheTags, piosApi } from 'api/piosApi';
import { Admin, SearchAdministrateUsers } from 'models';

const projectManagerApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get all project managers
     */
    getManagers: build.query<
      Admin[],
      {
        filter: SearchAdministrateUsers;
        _customerId: string;
      }
    >({
      query: ({ filter, _customerId }) =>
        `portfolio/projects/managers?1=1${
          filter.email ? `&email=${filter.email}` : ''
        }${
          filter.portfolioIds && filter.portfolioIds.length > 0
            ? filter.portfolioIds
                .map((portfolio) => `&portfolioIds=${portfolio}`)
                .join('')
            : ''
        }`,
      providesTags: [CacheTags.ProjectManager],
    }),

    /**
     * Create project manager
     */
    addManager: build.mutation({
      query: ({
        portfolioId,
        projectId,
        projectUsers,
      }: {
        portfolioId: string;
        projectId: string;
        projectUsers: string[];
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/managers`,
        method: 'PUT',
        body: { projectUsers },
      }),
      invalidatesTags: [
        CacheTags.ProjectManager,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Delete project manager
     */
    deleteManager: build.mutation({
      query: ({
        portfolioId,
        projectId,
        projectUsers,
      }: {
        portfolioId: string;
        projectId: string;
        projectUsers: string[];
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/managers`,
        method: 'DELETE',
        body: { projectUsers },
      }),
      invalidatesTags: [
        CacheTags.ProjectManager,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Remove project manager from all projects
     */
    removeManager: build.mutation({
      query: ({
        portfolioId,
        projectUser,
      }: {
        portfolioId?: string;
        projectUser: string;
      }) => ({
        url: `portfolio/projects/managers`,
        method: 'DELETE',
        body: {
          portfolioId,
          projectUser,
        },
      }),
      invalidatesTags: [
        CacheTags.ProjectManager,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),
  }),
});

export const {
  useGetManagersQuery,
  useLazyGetManagersQuery,
  useAddManagerMutation,
  useDeleteManagerMutation,
  useRemoveManagerMutation,
} = projectManagerApi;
