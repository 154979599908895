import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortfolioMutation } from 'models';
import { RootState } from 'store';

const initialState: {
  selectedPortfolio?: PortfolioMutation;
  selectedPortfolioOwner: string | null;
} = {
  selectedPortfolioOwner: null,
};

export const { reducer: portfolioReducer, actions: portfolioActions } =
  createSlice({
    name: 'portfolio/get',
    initialState,
    reducers: {
      selectedPortfolio: (
        state,
        action: PayloadAction<PortfolioMutation | undefined>
      ) => {
        state.selectedPortfolio = action.payload;
      },
      selectedPortfolioOwner: (state, action: PayloadAction<string | null>) => {
        state.selectedPortfolioOwner = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedPortfolio = initialState.selectedPortfolio;
        state.selectedPortfolioOwner = initialState.selectedPortfolioOwner;
      });
    },
  });

export const selectSelectedPortfolio = (
  state: RootState
): PortfolioMutation | undefined => state.portfolio.selectedPortfolio;

export const selectSelectedPortfolioOwner = (state: RootState): string | null =>
  state.portfolio.selectedPortfolioOwner;
