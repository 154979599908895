import { Select } from '@dimatech/shared/lib/components/form';
import { InternalExternal, ProjectGoal } from 'models';
import { CSSProperties, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

export const InternalExternalClassificationEdit = ({
  goal,
  canEdit,
  setValue,
  id,
  style,
  invalid = false,
}: {
  goal: ProjectGoal;
  canEdit: boolean;
  setValue: (internalExternal?: InternalExternal) => void;
  id?: string;
  style?: CSSProperties;
  invalid?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const handleInternalExternalClassification = (
    event: SyntheticEvent<HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value
      ? (event.currentTarget.value as InternalExternal)
      : undefined;

    setValue(value);
  };

  return canEdit ? (
    <Select
      name={id}
      id={id}
      onChange={handleInternalExternalClassification}
      style={{
        minWidth: 200,
        ...style,
      }}
      invalid={invalid}
      value={goal.internalExternalClassification}
    >
      <option value="">- {t('Common.UI.Select')}</option>
      {Object.values(InternalExternal).map((value) => (
        <option key={value} value={value}>
          {t(`Portfolio.Goal.Classification.InternalExternal.${value}`)}
        </option>
      ))}
    </Select>
  ) : (
    <>
      {goal.internalExternalClassification
        ? t(
            `Portfolio.Goal.Classification.InternalExternal.${
              goal.internalExternalClassification || InternalExternal.Equal
            }`
          )
        : '-'}
    </>
  );
};

InternalExternalClassificationEdit.displayName =
  'InternalExternalClassificationEdit';
