import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { piosActions } from 'api/piosSlice';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Permission,
  Phase,
  PortfolioOverview,
  Project,
  ProjectIdeaAction,
} from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { isReadOnly } from 'utils';

export const ProjectAdd = ({
  portfolio,
  projects,
}: {
  portfolio: PortfolioOverview;
  projects: Project[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
  const isPublishPubliclyByDefaultEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyByDefaultEnabled];

  const handleAdd = () => {
    navigate(`/project/${portfolio.portfolioId}/new`, {
      state: { from: location.pathname },
    });
  };

  const handleAddIdea = () => {
    dispatch(
      piosActions.setSelectedProjectIdeaAction(ProjectIdeaAction.Create)
    );

    dispatch(
      piosActions.setSelectedProjectIdea({
        portfolioId: portfolio.portfolioId,
        projectPhase: Phase.Draft,
        permissions: [Permission.EditIdea, Permission.EditEffectRealization],
        isPublishedPublicly:
          !!isPublishPubliclyEnabledFlagOn &&
          !!isPublishPubliclyByDefaultEnabledFlagOn,
      })
    );

    navigate(
      `/project-idea/${ProjectIdeaAction.Create}/${portfolio.portfolioId}`
    );
  };

  return !isReadOnly(portfolio) ? (
    <div style={{ display: 'flex', gap: 20 }}>
      <LinkWithTooltip
        isPrimary={true}
        handleClick={handleAdd}
        title={t('Project.Add.Title')}
        tooltipTitle={t('Project.Add.TooltipTitle')}
        tooltip={t('Project.Add.Tooltip')}
        icon={<BsPlus />}
      />
      {!accessToken.isBaseLicense && (
        <LinkWithTooltip
          isPrimary={true}
          handleClick={handleAddIdea}
          title={t('FutureProjects.ProjectIdea.Add.Title')}
          tooltipTitle={t('FutureProjects.ProjectIdea.Add.TooltipTitle')}
          tooltip={t('FutureProjects.ProjectIdea.Add.Tooltip')}
          icon={<BsPlus />}
        />
      )}
    </div>
  ) : null;
};

ProjectAdd.displayName = 'ProjectAdd';
