import { ProjectsBenefits as ProjectEffectProjectsBenefitsFeature } from 'features/project-effect/ProjectsBenefits';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectEffectProjectsBenefits = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('ImplementedProjects.ProjectsBenefits.Title')}>
      <ProjectEffectProjectsBenefitsFeature />
    </View>
  );
};

ProjectEffectProjectsBenefits.displayName = 'ProjectEffectProjectsBenefits';
