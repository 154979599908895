import { CardTile } from '@dimatech/shared/lib/components/workspace';
import { Calculation, Dimension, ProjectIdea } from 'models';
import styled from 'styled-components';
import { ProjectDimensionsTable } from './ProjectDimensionsTable';

export const ProjectIdeaData = ({
  idea,
  classifications,
  calculations,
  dimensions,
}: {
  idea: ProjectIdea;
  classifications: Dimension[];
  calculations?: Calculation[];
  dimensions?: Dimension[];
}): JSX.Element | null => {
  const ideaDimensions = idea?.customDimensions?.filter((item1) =>
    dimensions?.some((item2) => item1?.projectDimensionId === item2.id)
  );

  return (
    <>
      <Style>
        {ideaDimensions && ideaDimensions.length > 0 && (
          <CardTile>
            <ProjectDimensionsTable dimensions={ideaDimensions} />
          </CardTile>
        )}
      </Style>
    </>
  );
};

ProjectIdeaData.displayName = 'ProjectIdeaData';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  table {
    td {
      &:last-of-type {
        > div {
          justify-content: flex-start;
        }
      }
    }
  }
`;
