var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@dimatech/shared/lib/components/form';
import { listToTree } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Fragment, useContext, useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTagsQuery } from '../api/tag/tagApi';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
export var SelectTag = function (_a) {
    var _b;
    var onSelectTag = _a.onSelectTag, style = _a.style, placeholder = _a.placeholder, filter = _a.filter, autoFocus = _a.autoFocus;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var data = useGetTagsQuery((_b = accessToken.customerId) !== null && _b !== void 0 ? _b : skipToken).data;
    var _c = useState(), tree = _c[0], setTree = _c[1];
    var handleSelectTag = function (e) {
        var tagId = e.currentTarget.value;
        var tag = data === null || data === void 0 ? void 0 : data.find(function (tag) { return tag.id === tagId; });
        onSelectTag(e, tag);
    };
    var toOptions = function (tags, depth) {
        if (depth === void 0) { depth = 0; }
        var options = tags.map(function (tag) { return (_jsxs(Fragment, { children: [_jsxs("option", __assign({ value: tag.id }, { children: ['\u00A0'.repeat(depth * 2), depth > 0 ? '- ' : '', tag.displayName] })), toOptions(tag.children, depth + 1)] }, tag.id)); });
        return options;
    };
    useEffect(function () {
        if (data) {
            setTree(listToTree(data, [null], filter));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (_jsxs(Select, __assign({ autoFocus: !!autoFocus, onChange: function (e) { return handleSelectTag(e); }, style: style }, { children: [_jsx("option", __assign({ value: "" }, { children: placeholder ? placeholder : t('Search.SelectTag') })), tree && toOptions(tree)] })));
};
SelectTag.displayName = 'SelectTag';
