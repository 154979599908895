import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useDeleteCalculationMutation } from 'api/calculation/calculationApi';
import { calculationActions } from 'api/calculation/calculationSlice';
import { useAppDispatch } from 'hooks';
import { CalculationType } from 'models';
import { CalculationMutation } from 'models/calculation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CalculationPropertiesEditButtons = ({
  item,
  setSelectedItem,
  isReadOnly,
}: {
  item: CalculationMutation;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<CalculationMutation | undefined>
  >;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteCalculation] = useDeleteCalculationMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    deleteCalculation({ ...item });
    setSelectedItem(undefined);
    dispatch(calculationActions.selectedCalculation());

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Administrate.Calculation.Delete.ConfirmTitle')}>
          <div>{t('Administrate.Calculation.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {item.type !== CalculationType.System && (
        <ButtonSecondary
          type="button"
          onClick={(e) => handleConfirmDelete(e, true)}
          style={{ marginLeft: '0' }}
        >
          {t('Common.Form.Delete')}
        </ButtonSecondary>
      )}
    </>
  );
};

CalculationPropertiesEditButtons.displayName =
  'CalculationPropertiesEditButtons';
