import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { SearchAdministrateUsers } from 'models';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getProjectOrderingPartyExport = createAsyncThunk(
  'portfolio/projects/orderingparties/export',
  async ({
    filter,
    name,
  }: {
    filter: SearchAdministrateUsers;
    name: string;
  }) => {
    const url = `portfolio/projects/orderingparties/export`;
    const filters = `1=1${filter.email ? `&email=${filter.email}` : ''}${
      filter.portfolioIds && filter.portfolioIds.length > 0
        ? filter.portfolioIds
            .map((portfolio) => `&portfolioIds=${portfolio}`)
            .join('')
        : ''
    }`;
    return axios({
      url: `${config.apiUrl.product.pios}${url}?${filters}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = `${name}.xlsx`;

      const file = new File([response.data], fileName);

      saveAs(file, fileName);
    });
  }
);

export const {
  reducer: projectOrderingPartyExportReducer,
  actions: projectOrderingPartyExportActions,
} = createSlice({
  name: 'portfolio/projects/orderingparties/export',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getProjectOrderingPartyExport.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getProjectOrderingPartyExport.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getProjectOrderingPartyExport.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectProjectOrderingPartyExportIsPosting = (
  state: RootState
): boolean => state.projectOrderingPartyExport.isPosting;
