import { CardTile } from '@dimatech/shared/lib/components/workspace';
import { Dimension, DimensionType, Project } from 'models';
import { mergeDimensions } from 'utils/dimension';
import { ProjectDimensionsTable } from './ProjectDimensionsTable';

export const ProjectDimensionsList = ({
  project,
  customDimensions,
  isProject,
}: {
  project: Project;
  customDimensions: Dimension[];
  isProject: boolean;
}): JSX.Element | null => {
  const dimensions = mergeDimensions(
    customDimensions,
    isProject,
    project,
    DimensionType.Data
  ).sort((a, b) => ((a.displayType ?? '') > (b.displayType ?? '') ? 1 : -1));

  const tableCount = Math.ceil(dimensions.length / 2);
  const dimensionsTableOne = dimensions.slice(0, tableCount);
  const dimensionsTableTwo = dimensions.slice(tableCount, dimensions.length);

  return (
    <>
      <CardTile style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
        <ProjectDimensionsTable dimensions={dimensionsTableOne} />
      </CardTile>
      <CardTile style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
        <ProjectDimensionsTable dimensions={dimensionsTableTwo} />
      </CardTile>
    </>
  );
};

ProjectDimensionsList.displayName = 'ProjectDimensionsList';
