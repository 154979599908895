import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project } from 'models';
import { useTranslation } from 'react-i18next';
import { BsGlobeEuropeAfrica } from 'react-icons/bs';
import { withTheme } from 'styled-components';

export const ProjectPublishedPubliclyIcon = withTheme(
  ({
    project,
    theme,
  }: {
    project: Project;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    const isPublishPubliclyEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];

    if (
      !isPublishPubliclyEnabledFlagOn ||
      !project.isPublishedPublicly ||
      project.projectPhase === Phase.Draft ||
      project.projectPhase === Phase.Ready
    ) {
      return null;
    }

    return (
      <HelpIcon
        showTiny={true}
        style={{
          marginLeft: 5,
          backgroundColor: Color.CaribbeanGreenLight,
          color: theme.colors.onSuccess,
          width: 19,
          height: 19,
        }}
        title={t('Project.IsPublishedPublicly.PublishedField.Title')}
        text={t('Project.IsPublishedPublicly.PublishedField.Text')}
        icon={
          <BsGlobeEuropeAfrica style={{ fontSize: theme.fonts.size.base }} />
        }
      />
    );
  }
);

ProjectPublishedPubliclyIcon.displayName = 'ProjectPublishedPubliclyIcon';
