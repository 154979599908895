import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { TagMutation } from '@dimatech/shared/lib/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  selectedNationalTag?: TagMutation;
} = {};

export const { reducer: nationalTagReducer, actions: nationalTagActions } =
  createSlice({
    name: 'nationalTag',
    initialState,
    reducers: {
      selectedNationalTag: (
        state,
        action: PayloadAction<TagMutation | undefined>
      ) => {
        state.selectedNationalTag = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedNationalTag = initialState.selectedNationalTag;
      });
    },
  });

export const selectSelectedNationalTag = (state: {
  nationalTag: { selectedNationalTag?: TagMutation };
}): TagMutation | undefined => state.nationalTag.selectedNationalTag;
