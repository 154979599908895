import {
  Table,
  TableResponsiveContainer,
  Td,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import {
  dimensionActions,
  selectSelectedDimension,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dimension } from 'models';
import { useTranslation } from 'react-i18next';
import { BsCardList } from 'react-icons/bs';
import { projectIdeaPhases, projectOngoingPhases } from 'utils';

export const DimensionsList = ({
  dimensions,
}: {
  dimensions: Dimension[];
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedDimension = useAppSelector(selectSelectedDimension);

  const handleSelect = (dimension: Dimension) => {
    dispatch(dimensionActions.selectDimension(dimension));
  };

  return (
    <>
      {dimensions && dimensions?.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ minWidth: 400 }}>
            <thead>
              <tr>
                <Th style={{ width: 25 }} />
                <Th>{t(`Administrate.CustomDimension.Name`)}</Th>
                <Th>{t(`Administrate.CustomDimension.Type`)}</Th>
                <Th>{t(`Administrate.CustomDimension.ProjectType`)}</Th>
              </tr>
            </thead>
            <tbody>
              {dimensions?.map((dimension, index) => {
                const types: string[] = [];

                if (
                  dimension.projectPhases?.some((phase) =>
                    projectOngoingPhases.includes(phase)
                  )
                ) {
                  types.push(t(`Project.ProjectType.Regular`));
                }
                if (
                  dimension.projectPhases?.some((phase) =>
                    projectIdeaPhases.includes(phase)
                  )
                ) {
                  types.push(t(`Project.ProjectType.Idea`));
                }

                return (
                  <Tr
                    key={index}
                    onSelect={() => handleSelect(dimension)}
                    isSelected={dimension.id === selectedDimension?.id}
                  >
                    <Td>
                      <BsCardList />
                    </Td>
                    <Td>{dimension.name}</Td>
                    <Td>
                      {t(
                        `Administrate.CustomDimension.DimensionDisplayType.${dimension.displayType}`
                      )}
                    </Td>
                    <Td>{types.join(', ')}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

DimensionsList.displayName = 'DimensionsList';
