import { GaugeDual } from '@dimatech/features-core/lib/components/GaugeDual';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CurrencyIcon } from '@dimatech/shared/lib/components/CurrencyIcon';
import { Number } from '@dimatech/shared/lib/components/Number';
import {
  ViewRow,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { selectFilter } from 'api/piosSlice';
import { useAppSelector } from 'hooks';
import { AdditionalData, PiosColors } from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsPeople } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { formatAsNumber } from 'utils';
import { StatisticsStyle } from './StatisticsStyle';

export const StatisticsByYear = withTheme(
  ({
    theme,
    data,
  }: {
    theme: Theme;
    data: AdditionalData;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);
    const filter = useAppSelector(selectFilter);

    if (filter.period === undefined) {
      return null;
    }

    return (
      <StatisticsStyle>
        {data && data.totalBudgetPeriod !== null && (
          <>
            <ViewRow>
              <div>
                <GaugeDual
                  helpTitle={t('StatisticsYearly.EfficiencyInnovation', {
                    year: filter.period,
                  })}
                  help={
                    <Trans i18nKey="StatisticsYearly.EfficiencyInnovationHelp" />
                  }
                  value1={{
                    value: data.efficiencyInnovationPeriod,
                    name: t('Portfolio.Efficiency'),
                    color: PiosColors.efficiency,
                  }}
                  value2={{
                    value: data.efficiencyInnovationPeriod
                      ? 100 - data.efficiencyInnovationPeriod
                      : null,
                    name: t('Portfolio.Innovation'),
                    color: PiosColors.innovation,
                  }}
                />
                <div>
                  {t('StatisticsYearly.EfficiencyInnovation', {
                    year: filter.period,
                  })}
                </div>
              </div>

              <div>
                <GaugeDual
                  helpTitle={t('StatisticsYearly.InternalExternal', {
                    year: filter.period,
                  })}
                  help={
                    <Trans i18nKey="StatisticsYearly.InternalExternalHelp" />
                  }
                  value1={{
                    value: data.internalExternalPeriod,
                    name: t('Portfolio.Internal'),
                    color: PiosColors.internal,
                  }}
                  value2={{
                    value: data.internalExternalPeriod
                      ? 100 - data.internalExternalPeriod
                      : null,
                    name: t('Portfolio.External'),
                    color: PiosColors.external,
                  }}
                />
                <div>
                  {t('StatisticsYearly.InternalExternal', {
                    year: filter.period,
                  })}
                </div>
              </div>
            </ViewRow>
            <ViewRowBreak className="show-xs" />
          </>
        )}

        <ViewRow>
          <Number
            icon={<CurrencyIcon currency={accessToken.locale.currency} />}
            color={theme.colors.primary}
            text={t('StatisticsYearly.TotalYearlyBudgetForYear', {
              year: filter.period,
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={
              data ? `${formatAsNumber(data.totalBudgetPeriod, '-', 0)}` : '-'
            }
          />

          <ViewRowBreak className="show-xs" />

          <Number
            icon={<BsPeople />}
            color={theme.colors.primary}
            text={t('StatisticsYearly.TotalYearlyStaffingBudgetForYear', {
              year: filter.period,
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={
              data
                ? `${formatAsNumber(data.totalStaffingBudgetPeriod, '-', 0)}`
                : '-'
            }
          />

          {/* This is only for layout reasons, to make it better align with other statistics elements */}
          <div className="hide-xs" />
        </ViewRow>
      </StatisticsStyle>
    );
  }
);

StatisticsByYear.displayName = 'StatisticsByYear';
