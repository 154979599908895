import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { useUiSortablePaginator } from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilterByEffectRealisation } from 'api/piosSlice';
import { useGetMyProjectEffectRealisationsOverviewQuery } from 'api/project/projectEffectRealisationOverviewApi';
import { ProjectPhase } from 'components/ProjectPhase';
import { ProjectStatus as ProjectStatusComponent } from 'components/ProjectStatus';
import { useAppSelector } from 'hooks';
import { ProjectStatus } from 'models';
import { ProjectEffectRealisation } from 'models/project-effect';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { formatAsCurrency, formatAsNumber, getEntitiesInPath } from 'utils';
import { ProjectEdit } from './ProjectEdit';

/* eslint-disable max-lines-per-function */

export const MyProjectsList = withTheme(
  ({
    saveToPngElementId,
    theme,
  }: {
    saveToPngElementId: string;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);
    const filter = useAppSelector(selectFilterByEffectRealisation);

    const [projects, setProjects] = useState<ProjectEffectRealisation[]>();
    const [selectedProject, setSelectedProject] =
      useState<ProjectEffectRealisation>();

    const { data, isFetching, isLoading } =
      useGetMyProjectEffectRealisationsOverviewQuery(
        accessToken.customerId && accessToken.user?.id
          ? {
              filter: {
                ...filter,
              },
              _customerId: accessToken.customerId,
              _userId: accessToken.user.id,
            }
          : skipToken
      );

    const {
      data: entities,
      isFetching: isFetchingEntities,
      isLoading: isLoadingEntities,
    } = useGetEntitiesQuery(
      accessToken.customerId && accessToken.user?.id
        ? {
            _customerId: accessToken.customerId,
            _userId: accessToken.user?.id,
          }
        : skipToken
    );

    const { items, sorter } = useUiSortablePaginator({
      orderBy: 'title',
      pageSize: 500,
      data: {
        totalRecords: (projects ?? []).length,
        records: projects ?? [],
      },
    });

    const handleSelectProject = (project: ProjectEffectRealisation) => {
      if (project.projectId === selectedProject?.projectId) {
        setSelectedProject(undefined);
      } else {
        setSelectedProject(project);
      }
    };

    useEffect(() => {
      if (!entities || !data) {
        return;
      }

      const entitiesInPath = getEntitiesInPath(entities, null);

      setProjects(
        data.projects.map((project) => ({
          ...project,
          entityPath: entitiesInPath.find(
            (entity) => project.entityId === entity.id
          )?.path,
        }))
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities, data]);

    if (isLoading || isFetching || isFetchingEntities || isLoadingEntities) {
      return <Loader>{t('Common.UI.Loading')}</Loader>;
    }

    return (
      <>
        {projects && projects.length === 0 && (
          <div style={{ marginBottom: 20 }}>
            {t('ImplementedProjects.NoProjects')}
          </div>
        )}

        <TableResponsiveContainer style={{ marginTop: 20 }}>
          <Table id={saveToPngElementId}>
            {items.length > 0 && (
              <thead>
                <tr>
                  <ThCentered
                    style={{ width: 55 }}
                    sortKey="status"
                    sorter={sorter}
                  >
                    {t('Project.Status.Status')}
                  </ThCentered>
                  <Th
                    className="narrow"
                    sortKey="projectPhase"
                    sorter={sorter}
                    style={{ width: 100 }}
                  >
                    {t('Project.Phase.Phase')}
                  </Th>
                  <Th
                    style={{ width: 100 }}
                    className="narrow"
                    sortKey="externalId"
                    sorter={sorter}
                  >
                    {t('Project.Id')}
                  </Th>
                  <Th>
                    <SortElements>
                      <SortElement sortKey="title" sorter={sorter}>
                        {t('Project.Project')}
                      </SortElement>
                      <>
                        <SortElement sortKey="portfolioName" sorter={sorter}>
                          {t('Portfolio.Portfolio')}
                        </SortElement>
                      </>
                    </SortElements>
                  </Th>
                  <Th sortKey="entityPath" sorter={sorter}>
                    {t('Portfolio.Entity')}
                  </Th>
                  <ThRight
                    style={{ width: 150 }}
                    className="narrow"
                    sortKey="budget"
                    sorter={sorter}
                  >
                    {t('Project.Budget', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    style={{ width: 110 }}
                    sortKey="estimatedBenefit"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.ExpectedBenefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    style={{ width: 110 }}
                    sortKey="actualBenefit"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.ActualBenefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    style={{ width: 110 }}
                    sortKey="result"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.Deviation', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    style={{ width: 80 }}
                    sortKey="startYear"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.ProjectStartYear')}
                  </ThRight>
                  <ThRight
                    style={{ width: 80 }}
                    sortKey="endYear"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.ProjectEndYear')}
                  </ThRight>
                  <ThRight
                    style={{ width: 80 }}
                    sortKey="takeHomePeriodYear"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.TakeHomePeriodYear')}
                  </ThRight>
                  <ThRight
                    style={{ width: 80 }}
                    sortKey="takeHomePeriodEndYear"
                    sorter={sorter}
                  >
                    {t('ImplementedProjects.TakeHomePeriodEndYear')}
                  </ThRight>
                </tr>
              </thead>
            )}

            <tbody>
              {items.map((project, index) => (
                <Fragment key={index}>
                  <Tr
                    onSelect={() => {
                      handleSelectProject(project);
                    }}
                  >
                    <TdCentered
                      data-value={t(
                        `Project.Status.${
                          project.projectStatus ?? ProjectStatus.Zero
                        }`
                      )}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ProjectStatusComponent status={project.projectStatus} />
                    </TdCentered>
                    <Td className="narrow">
                      <ProjectPhase phase={project.projectPhase} />
                    </Td>
                    <Td className="narrow">{project.externalId}</Td>
                    <Td>
                      <div className="b">{project.title}</div>
                      <div className="i">{project.portfolioName}</div>
                    </Td>
                    <Td data-value={project.entityPath}>
                      {project.entityName}
                      {project.entityPath !== project.entityName && (
                        <div className="size-xs i">{project.entityPath}</div>
                      )}
                    </Td>
                    <TdRight data-value={project.budget} className="narrow">
                      {formatAsNumber(project.budget, null)}
                    </TdRight>
                    <TdRight data-value={project.totalExpectedBenefit}>
                      {formatAsCurrency(project.totalExpectedBenefit)}
                    </TdRight>
                    <TdRight data-value={project.totalActualBenefit}>
                      {formatAsCurrency(project.totalActualBenefit)}
                    </TdRight>
                    <TdRight data-value={project.deviation}>
                      <div
                        style={{
                          display: 'flex',
                          gap: 5,
                          justifyContent: 'flex-end',
                        }}
                      >
                        {formatAsCurrency(project.deviation)}
                      </div>
                    </TdRight>
                    <TdRight data-value={project.startYear}>
                      {project.startYear ?? '-'}
                    </TdRight>
                    <TdRight data-value={project.endYear}>
                      {project.endYear ?? '-'}
                    </TdRight>
                    <TdRight>{project.maxTakeHomePeriod}</TdRight>
                    <TdRight>{project.takeHomePeriodEndYear ?? '-'}</TdRight>
                  </Tr>

                  {selectedProject?.projectId === project.projectId &&
                    project.projectId && (
                      <Tr>
                        <Td
                          colSpan={100}
                          style={{
                            paddingBottom: 60,
                            paddingTop: 20,
                            paddingRight: 20,
                            paddingLeft: 20,
                            backgroundColor: theme.colors.surfaceVariant,
                          }}
                        >
                          <ProjectEdit project={project} />
                        </Td>
                      </Tr>
                    )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      </>
    );
  }
);

MyProjectsList.displayName = 'MyProjectsList';
