import { eachYearOfInterval } from 'date-fns';
import {
  InnovationEfficiency,
  InternalExternal,
  Project,
  ProjectIdea,
  ProjectPeriod,
} from 'models';

export const innovationEfficiencyToPercent = (
  innovationEfficiency: InnovationEfficiency | undefined
): number => {
  switch (innovationEfficiency) {
    case InnovationEfficiency.FullyInnovation:
      return 0;
    case InnovationEfficiency.MostlyInnovation:
      return 25;
    case InnovationEfficiency.Equal:
      return 50;
    case InnovationEfficiency.MostlyEfficiency:
      return 75;
    case InnovationEfficiency.FullyEfficiency:
      return 100;
    default:
      return 0;
  }
};

export const internalExternalToPercent = (
  internalExternal: InternalExternal | undefined
): number => {
  switch (internalExternal) {
    case InternalExternal.FullyInternal:
      return 100;
    case InternalExternal.MostlyInternal:
      return 75;
    case InternalExternal.Equal:
      return 50;
    case InternalExternal.MostlyExternal:
      return 25;
    case InternalExternal.FullyExternal:
      return 0;
    default:
      return 0;
  }
};

export const calculateNetBenefit = (idea: ProjectIdea) => {
  const benefit = idea.benefit ?? 0;
  const cost = idea.cost ?? 0;

  return benefit - cost;
};

export const calculateYearlyNetBenefit = (idea: ProjectIdea) => {
  const netBenefit = calculateNetBenefit(idea);

  return netBenefit / (idea.takeHomePeriod ?? 1);
};

export const tryAddYearToPeriod = (periods: ProjectPeriod[], year: number) => {
  if (!periods.some((p) => p.year === year)) {
    periods.push({ year });
  }
};

export const calculatePeriodsAndBudgets = (
  project?: Project,
  periods?: ProjectPeriod[],
  distributeBudget?: boolean,
  isPeriodMonth?: boolean
): ProjectPeriod[] | null => {
  if (!project) {
    return null;
  }

  const projectPeriods = periods?.map((period) => ({ ...period })) ?? [];

  if (!project.startYear && !project.endYear) {
    return null;
  }

  if (
    project.startYear &&
    project.endYear &&
    project.startYear < project.endYear
  ) {
    const dates = eachYearOfInterval({
      start: new Date(project.startYear, 1, 1),
      end: new Date(project.endYear, 1, 1),
    });

    dates.forEach((date) => {
      tryAddYearToPeriod(projectPeriods, date.getFullYear());
    });
  } else if (project.startYear) {
    tryAddYearToPeriod(projectPeriods, project.startYear);
  } else if (project.endYear) {
    tryAddYearToPeriod(projectPeriods, project.endYear);
  }

  if (distributeBudget) {
    let totalMonth = projectPeriods.length * 12;
    if (isPeriodMonth) {
      totalMonth -= project.startMonth ? project.startMonth - 1 : 0;
      totalMonth -= project.endMonth ? 12 - project.endMonth : 0;

      projectPeriods.forEach((period) => {
        if (
          period.year === project.startYear &&
          period.year === project.endYear
        ) {
          period.totalBudget = Math.round(project.budget ?? 0);
        } else if (period.year === project.startYear) {
          period.totalBudget = Math.round(
            ((project.budget ?? 0) / totalMonth) *
              (project.startMonth ? 13 - project.startMonth : 12)
          );
        } else if (period.year === project.endYear) {
          period.totalBudget = Math.round(
            ((project.budget ?? 0) / totalMonth) *
              (project.endMonth ? project.endMonth : 12)
          );
        } else {
          period.totalBudget = Math.round(
            ((project.budget ?? 0) / totalMonth) * 12
          );
        }
      });
    } else {
      projectPeriods.forEach((period) => {
        period.totalBudget = Math.round(
          (project.budget ?? 0) / projectPeriods.length
        );
      });
    }
  }

  projectPeriods.forEach((period) => {
    period.staffingBudget = project.periods?.find(
      (p) => p.year === period.year
    )?.staffingBudget;
  });

  return projectPeriods
    .filter(
      (p) =>
        (!project.startYear || p.year >= project.startYear) &&
        project.endYear &&
        p.year <= project.endYear
    )
    .sort((a, b) => (a.year > b.year ? 1 : -1));
};

// NOTE: These are currently not used since we re-fetch data from the server. This might change in the future.

// export const recalculateInnovationEfficiency = (goals: Goal[]): number => {
//   const goalsWithValue = goals.filter(
//     (item) => !!item.innovationEfficiencyClassification
//   ).length;

//   let value = 0;
//   goals.forEach((item) => {
//     value += innovationEfficiencyToPercent(
//       item.innovationEfficiencyClassification
//     );
//   });

//   return value / goalsWithValue;
// };

// export const recalculateInternalExternal = (goals: Goal[]): number => {
//   const goalsWithValue = goals.filter(
//     (item) => !!item.internalExternalClassification
//   ).length;

//   let value = 0;
//   goals.forEach((item) => {
//     value += internalExternalToPercent(item.internalExternalClassification);
//   });

//   return value / goalsWithValue;
// };
