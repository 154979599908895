var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { flags } from '../../feature-flags';
import { Color } from '../../themes';
import { CellStyle, HeaderStyle } from './GanttStyle';
import { GanttTooltip } from './GanttTooltip';
export var GanttItem = function (_a) {
    var years = _a.years, item = _a.item, highlightedYear = _a.highlightedYear, setHighlightedYear = _a.setHighlightedYear, handleSelect = _a.handleSelect;
    var isStartAndEndMonthEnabledFlagOn = useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];
    return (_jsxs("tr", { children: [_jsx(ItemHeaderStyle, __assign({ onClick: function () { return handleSelect(item); } }, { children: item.title })), years.map(function (year) {
                var _a;
                var section = item.sections.find(function (section) { return section.startYear <= year && section.endYear >= year; });
                var color = (_a = section === null || section === void 0 ? void 0 : section.color) !== null && _a !== void 0 ? _a : Color.SeaGreen;
                return (_jsx(ItemCellStyle, __assign({ isHighlighted: highlightedYear === year, onClick: function () { return handleSelect(item); }, onMouseEnter: function () { return setHighlightedYear(year); }, onMouseLeave: function () { return setHighlightedYear(undefined); }, "data-html": !!section, "data-tip": section &&
                        ReactDOMServer.renderToString(_jsx(GanttTooltip, { title: year.toString(), subtitle: section.title, texts: section.tooltip })) }, { children: section && (_jsx("div", __assign({ className: "".concat(year === item.startYear ? 'first' : '', " ").concat(year === item.endYear ? 'last' : ''), style: {
                            backgroundColor: color,
                            marginLeft: isStartAndEndMonthEnabledFlagOn
                                ? "".concat(year === item.startYear && section.startMonth
                                    ? ((section.startMonth - 1) / 12) * 100 + '%'
                                    : '0%')
                                : '',
                            marginRight: isStartAndEndMonthEnabledFlagOn
                                ? "".concat(year === item.endYear && section.endMonth
                                    ? ((12 - section.endMonth) / 12) * 100 + '%'
                                    : '0%')
                                : '',
                        } }, { children: "\u00A0" }))) }), year));
            })] }));
};
var ItemHeaderStyle = styled(HeaderStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  border-right: 1px solid\n    ", ";\n\n  padding-left: 10px;\n\n  cursor: pointer;\n"], ["\n  border-top: 1px solid ", ";\n  border-right: 1px solid\n    ", ";\n\n  padding-left: 10px;\n\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
});
var ItemCellStyle = styled(CellStyle)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n\n  &:last-child {\n    border-right: 1px solid\n      ", ";\n  }\n\n  > div {\n    cursor: pointer;\n  }\n"], ["\n  border-top: 1px solid ", ";\n\n  &:last-child {\n    border-right: 1px solid\n      ", ";\n  }\n\n  > div {\n    cursor: pointer;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
});
var templateObject_1, templateObject_2;
