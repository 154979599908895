import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosQuery } from 'api/portfolio/portfolioApi';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { ProjectsTable } from 'components/ProjectsTable';
import { Phase } from 'models';
import { useContext, useEffect, useId, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProjectAdd } from './components/ProjectAdd';

export const ProjectsBaseLicense = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [portfolioId, setPortfolioId] = useState<string>('');

  const saveToPngElementId = useId();

  const phases = Object.keys(Phase).map((value) => value as Phase);

  const { data: portfolios } = useGetPortfoliosQuery(
    accessToken.customerId ? { _customerId: accessToken.customerId } : skipToken
  );

  const { data: portfolio } = useGetPortfolioOverviewQuery(
    accessToken.customerId && accessToken.user?.id && portfolioId
      ? {
          filter: { portfolioIds: [portfolioId] },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const { data, isFetching } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: { phases },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  useEffect(() => {
    if (portfolios) {
      const id: string | undefined =
        portfolios.length > 0 ? portfolios[0].id : undefined;
      if (id) {
        setPortfolioId(id);
      }
    }
  }, [portfolios]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Projects.Title')}</Heading1>
      </ViewHeader>

      {portfolio && data?.projects.length === 0 && (
        <Card>
          <CardBody>
            <ProjectAdd projects={[]} portfolio={portfolio} />
          </CardBody>
        </Card>
      )}

      {data?.projects && data.projects.length === 0 && (
        <Card>
          <CardBody>{t('Projects.NoProjects')}</CardBody>
        </Card>
      )}
      {data?.projects && data.projects.length > 0 && (
        <Card
          title={t('ProjectsBaseLicense.List.Title')}
          helpTextTitle={t('ProjectsBaseLicense.List.Title')}
          helpText={<Trans i18nKey={'ProjectsBaseLicense.List.Help'} />}
          canBeSavedAsPng={true}
          saveToPngElementId={saveToPngElementId}
          style={{ paddingTop: 10 }}
        >
          <CardBody isLoading={isFetching}>
            {portfolio && (
              <div style={{ marginBottom: 30 }}>
                <ProjectAdd projects={[]} portfolio={portfolio} />
              </div>
            )}
            {data && (
              <ProjectsTable
                projects={data.projects}
                saveToPngElementId={saveToPngElementId}
              />
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

ProjectsBaseLicense.displayName = 'ProjectsBaseLicense';
