import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useAppSelector } from 'hooks';
import { Node, Portfolio } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectPortfolios = ({
  portfolioIds,
  setPortfolioIds,
}: {
  portfolioIds?: string[];
  setPortfolioIds: (portfolioIds: string[]) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);
  const [nodes, setNodes] = useState<Node<Portfolio>[] | undefined>();

  const { data: portfoliosOverview, isLoading } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: {
            entityIds: filter.entityIds,
            portfolioIds: undefined,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleChange = (selectedNodes: Node<Portfolio>[]) => {
    setPortfolioIds(selectedNodes.map((node) => node.id as string));
  };

  useEffect(() => {
    if (portfoliosOverview?.portfolios) {
      setNodes(
        [...(portfoliosOverview?.portfolios ?? [])]
          .sort((a, b) => a.portfolioName.localeCompare(b.portfolioName))
          .map((portfolio) => ({
            id: portfolio.portfolioId as string,
            name: portfolio.portfolioName as string,
            parentId: null,
            payload: portfolio,
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfoliosOverview?.portfolios]);

  if (isLoading) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      {nodes && (
        <MultiSelect
          placeholder={t('Search.AnyPortfolios')}
          options={nodes}
          setSelected={(selectedNodes) => {
            handleChange(selectedNodes);
          }}
          selected={nodes.filter((node) => portfolioIds?.includes(node.id))}
        />
      )}
    </>
  );
};

SelectPortfolios.displayName = 'SelectPortfolios';
