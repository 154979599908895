import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { Label } from '@dimatech/shared/lib/components/form';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { CardTile } from '@dimatech/shared/lib/components/workspace';
import { DimensionType, ProjectDimensionMutation } from 'models';
import { CSSProperties } from 'styled-components';
import { ProjectDataTable } from './ProjectDataTable';
import { ProjectDimensionEdit } from './ProjectDimensionEdit';

export const ProjectDimensionsEdit = ({
  setProjectDimensions,
  setHasChanges,
  projectDimensions: dimensions,
}: {
  setProjectDimensions: (dimensions: ProjectDimensionMutation[]) => void;
  setHasChanges: (hasChanges: boolean) => void;
  projectDimensions: ProjectDimensionMutation[];
}): JSX.Element | null => {
  const tableCount = Math.ceil(dimensions.length / 2);
  const dimensionsTableOne = dimensions.slice(0, tableCount);
  const dimensionsTableTwo = dimensions.slice(tableCount, dimensions.length);

  const handleChange = (value: string, dimension: ProjectDimensionMutation) => {
    setHasChanges(true);

    const newDimensions = [...dimensions];
    const index = newDimensions.findIndex(
      (d) => d.projectDimensionId === dimension.projectDimensionId
    );

    if (index > -1) {
      newDimensions[index] = {
        ...dimension,
        value,
      };
    }

    setProjectDimensions(newDimensions);
  };

  return dimensions.length > 0 ? (
    <>
      <DimensionTable
        dimensions={dimensionsTableOne}
        handleChange={handleChange}
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      />
      <DimensionTable
        dimensions={dimensionsTableTwo}
        handleChange={handleChange}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      />
    </>
  ) : null;
};

ProjectDimensionsEdit.displayName = 'ProjectDimensionsEdit';

const DimensionTable = ({
  dimensions,
  handleChange,
  style,
}: {
  dimensions: ProjectDimensionMutation[];
  handleChange: (value: string, dimension: ProjectDimensionMutation) => void;
  style?: CSSProperties;
}) => {
  return (
    <CardTile style={{ ...style }}>
      <ProjectDataTable>
        <tbody>
          {dimensions
            .filter((d) => d.type === DimensionType.Data)
            .map((dimension) => (
              <Tr key={dimension.projectDimensionId}>
                <Td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Label
                      htmlFor={`dimension_${dimension.projectDimensionId}`}
                    >
                      {dimension.name}
                    </Label>

                    {dimension.description && (
                      <HelpIcon
                        showTiny={true}
                        style={{ marginLeft: 7 }}
                        title={dimension.name}
                        text={dimension.description}
                      />
                    )}
                  </div>
                </Td>
                <Td>
                  <ProjectDimensionEdit
                    dimension={dimension}
                    handleChange={handleChange}
                  />
                </Td>
              </Tr>
            ))}
        </tbody>
      </ProjectDataTable>
    </CardTile>
  );
};
