import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Label } from '@dimatech/shared/lib/components/form';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { CardRow, CardTile } from '@dimatech/shared/lib/components/workspace';
import { useUpdateProjectMutation } from 'api/project/projectApi';
import { ProjectStatus } from 'components/ProjectStatus';
import { ProjectDocumentList } from 'features/project-document/components/ProjectDocumentList';
import { ProjectDataTable } from 'features/project/components/ProjectDataTable';
import { ProjectPeriodList } from 'features/project/components/ProjectPeriodList';
import { ProjectPhaseEdit } from 'features/project/components/ProjectPhaseEdit';
import { ProjectStatusEdit } from 'features/project/components/ProjectStatusEdit';
import { Phase, ProjectStatus as ProjectStatusModel } from 'models';
import { ProjectEffectRealisation } from 'models/project-effect';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBriefcase, BsClipboardData } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { isPiosReadOnly, isProjectOngoing, isReadOnly } from 'utils';
import { ProjectGoalList } from './ProjectGoalList';

export const ProjectEdit = ({
  project,
}: {
  project: ProjectEffectRealisation;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const navigate = useNavigate();
  const location = useLocation();

  const [updateProject] = useUpdateProjectMutation();

  const handleNavigateProject = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/project/${project.portfolioId}/${project.projectId}`, {
      state: { from: location.pathname },
    });
  };

  const handleNavigateProjectIdea = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate('/project-idea/view/', {
      state: { ideaId: project.projectId },
    });
  };

  const handleNavigatePortfolio = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (project) {
      navigate(`/portfolio/${project.portfolioId}`, {
        state: { from: location.pathname },
      });
    }
  };

  const handleUpdateProjectStatus = (status?: ProjectStatusModel | null) => {
    updateProject({
      ...project,
      id: project.projectId,
      projectStatus: status,
    });
  };

  const handleUpdateProjectPhase = (phase?: Phase | null) => {
    updateProject({
      ...project,
      id: project.projectId,
      projectPhase: phase,
    });
  };

  return (
    <>
      <CardRow style={{ marginBottom: 15 }}>
        <CardTile>
          <ProjectGoalList project={project} />
        </CardTile>
      </CardRow>

      <CardRow style={{ marginBottom: 15 }}>
        <CardTile>
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>
                  <Label>{t('Project.Phase.Phase')}</Label>
                </Td>
                <Td>
                  <ProjectPhaseEdit
                    project={project}
                    handleChange={(phase) => {
                      handleUpdateProjectPhase(phase);
                    }}
                    isViewReadOnly={isViewReadOnly || isReadOnly(project)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Label>{t('Project.Status.Status')}</Label>
                </Td>
                <Td>
                  {!isViewReadOnly && !isReadOnly(project) ? (
                    <ProjectStatusEdit
                      project={project}
                      setProject={(project) => {
                        handleUpdateProjectStatus(project.projectStatus);
                      }}
                    />
                  ) : (
                    <ProjectStatus
                      status={project.projectStatus}
                      isLabelShown={true}
                    />
                  )}
                </Td>
              </Tr>
            </tbody>
          </ProjectDataTable>
        </CardTile>

        <CardTile>
          {isProjectOngoing(project.projectPhase) ? (
            <LinkWithTooltip
              tooltip={t('Administrate.Users.Navigate.OpenProject')}
              title={t('Administrate.Users.Navigate.OpenProject')}
              icon={<BsClipboardData />}
              handleClick={(e) => handleNavigateProject(e)}
            />
          ) : (
            <LinkWithTooltip
              tooltip={t('Administrate.Users.Navigate.OpenIdea')}
              title={t('Administrate.Users.Navigate.OpenIdea')}
              icon={<BsClipboardData />}
              handleClick={(e) => handleNavigateProjectIdea(e)}
            />
          )}

          <LinkWithTooltip
            tooltip={t('Administrate.Users.Navigate.OpenPortfolio')}
            title={t('Administrate.Users.Navigate.OpenPortfolio')}
            style={{ marginTop: 20 }}
            icon={<BsBriefcase />}
            handleClick={(e) => handleNavigatePortfolio(e)}
          />
        </CardTile>
      </CardRow>

      <CardRow>
        <CardTile>
          <ProjectPeriodList project={project} />
        </CardTile>

        <CardTile>
          <ProjectDocumentList
            projectId={project.projectId}
            canEdit={!isViewReadOnly && !isReadOnly(project)}
          />
        </CardTile>
      </CardRow>
    </>
  );
};

ProjectEdit.displayName = 'ProjectEdit';
