import { CommonHistoryObjectType } from '@dimatech/shared/lib/models';

export enum InnovationEfficiency {
  FullyEfficiency = 'FullyEfficiency',
  MostlyEfficiency = 'MostlyEfficiency',
  Equal = 'EqualParts',
  MostlyInnovation = 'MostlyInnovation',
  FullyInnovation = 'FullyInnovation',
}

export enum InternalExternal {
  FullyInternal = 'FullyInternal',
  MostlyInternal = 'MostlyInternal',
  Equal = 'EqualParts',
  MostlyExternal = 'MostlyExternal',
  FullyExternal = 'FullyExternal',
}

export enum BenefitType {
  Finance = 'Finance',
  Redistribution = 'Redistribution',
  Quality = 'Quality',
  Environment = 'Environment',
  Other = 'Other',
}

export enum ProjectIdeaAction {
  Identify = 'identify',
  Qualify = 'qualify',
  Classify = 'classify',
  Prioritise = 'prioritise',
  Create = 'create',
  Edit = 'edit',
  History = 'history',
  Archive = 'archive',
  Review = 'review',
  View = 'view',
}

export enum ProjectStatus {
  Zero = 'Zero',
  One = 'One',
  Two = 'Two',
  Three = 'Three',
}

export enum DimensionType {
  Data = 'Data',
  ClassificationBenefit = 'ClassificationBenefit',
  ClassificationStake = 'ClassificationStake',
}

export enum DimensionDisplayType {
  List = 'List',
  Text = 'Text',
  Numeric = 'Numeric',
  Rating = 'Rating',
}

export enum CalculationType {
  System = 'System',
  User = 'User',
}

export enum ProjectDocumentType {
  Document = 'Document',
  Link = 'Link',
}

export enum ProjectDocumentLinkType {
  Document = 'Document',
  Spreadsheet = 'Spreadsheet',
  Presentation = 'Presentation',
  Video = 'Video',
  Other = 'Other',
}

export enum Phase {
  NotSet = 'NotSet',
  Draft = 'Draft',
  Ready = 'Ready',
  Published = 'Published',
  ReadyForReview = 'ReadyForReview',
  NotStartedDecided = 'NotStartedDecided',
  Started = 'Started',
  Paused = 'Paused',
  Finished = 'Finished',
  FinishedEffectRealizationStarted = 'FinishedEffectRealizationStarted',
  FinishedEffectRealizationConcluded = 'FinishedEffectRealizationConcluded',
}

export enum PiosHistoryObjectType {
  Documents = 'Documents',
  Projects = 'Projects',
  CustomDimensions = 'CustomDimensions',
  Goals = 'Goals',
  Tags = 'Tags',
  NationalTags = 'NationalTags',
}

export const HistoryObjectType = {
  ...CommonHistoryObjectType,
  ...PiosHistoryObjectType,
};

// This is needed to make typescript happy treating HistoryObjectType as enum
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HistoryObjectType = CommonHistoryObjectType | PiosHistoryObjectType;

export enum View {
  Portfolios = 'Portfolios',
  MyPortfolios = 'MyPortfolios',
  Projects = 'Projects',
  MyProjects = 'MyProjects',
  ProjectEffectMyProjects = 'ProjectEffectMyProjects',
  ProjectIdeasReview = 'ProjectIdeasReview',
}

export enum Permission {
  None = 'None',
  Edit = 'Edit',
  Review = 'Review',
  Archive = 'Archive',
  Convert = 'Convert',
  EditIdea = 'EditIdea',
  EditEffectRealization = 'EditEffectRealization',
}

export enum OverviewTabIndex {
  Summary = 1,
  Balance = 2,
  BalanceOverTime = 3,
  Tag = 4,
  Table = 5,
  Timeline = 6,
}
