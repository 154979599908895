import { ProjectEdit as ProjectEditFeature } from 'features/project/ProjectEdit';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectEdit = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Project.Project')}>
      <ProjectEditFeature />
    </View>
  );
};

ProjectEdit.displayName = 'ProjectEdit';
