import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { SortDirection } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { searchPortfolios } from 'api/portfolio/portfolioSearchSlice';
import { useAppDispatch } from 'hooks';
import React, { CSSProperties, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const Owners = ({
  owners,
  style,
}: {
  owners: string[];
  style?: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSearch = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    owner: string
  ) => {
    event.stopPropagation();

    dispatch(
      searchPortfolios({
        owner,
        period: undefined,
        paginator: {
          page: 1,
          pageSize: 50,
          orderBy: 'portfolioName',
          orderDirection: SortDirection.Asc,
        },
      })
    );

    navigate('/search', {
      state: { owner },
    });
  };

  return (
    <Style style={style}>
      {owners?.map((owner) => (
        <Fragment key={owner}>
          <TooltipContent
            id={`tt-owner-search-${owner}`}
            title={t('Portfolio.Owner.Search.TooltipTitle')}
            text={t('Portfolio.Owner.Search.Tooltip', { owner })}
          />
          {!accessToken.isBaseLicense ? (
            <ButtonLink
              data-tip
              data-for={`tt-owner-search-${owner}`}
              onClick={(e) => handleSearch(e, owner)}
            >
              {owner}
            </ButtonLink>
          ) : (
            <>{owner}</>
          )}
        </Fragment>
      ))}
    </Style>
  );
};

Owners.displayName = 'Owners';

const Style = styled.span`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};

  > button {
    margin-left: 5px;
    text-align: left;
    white-space: nowrap;

    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
  }
`;
