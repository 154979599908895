import { usersFilterToQuery } from '@dimatech/features-core/lib/api/user/userApi';
import { SearchAdministrateUsers } from '@dimatech/features-core/lib/models/user';
import { CacheTags, piosApi } from 'api/piosApi';
import { Admin, CommonRoles, PaginatedResult } from 'models';

const userApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get reviewers
     */

    getReviewers: build.query<
      PaginatedResult<Admin>,
      {
        filter: SearchAdministrateUsers;
        _customerId: string;
      }
    >({
      query: ({ filter, _customerId }) =>
        `user/list?filter.role=Review${usersFilterToQuery(filter)}`,
      providesTags: [CacheTags.Reviewer],
    }),

    /**
     * Add reviewer
     */
    createOrUpdateReviewer: build.mutation({
      query: ({ user }: { user: Admin }) => ({
        url: `user`,
        method: 'POST',
        body: { ...user, isReviewer: true },
      }),
      invalidatesTags: [CacheTags.Reviewer],
    }),

    /**
     * Delete reviewer
     */
    deleteReviewer: build.mutation({
      query: ({ user }: { user: Admin }) => ({
        url: `user`,
        method: 'DELETE',
        body: { ...user, isReviewer: true },
      }),
      invalidatesTags: [CacheTags.Reviewer],
    }),

    /**
     * Remove reviewer
     */
    removeReviewer: build.mutation({
      query: (email: string) => ({
        url: `user/remove`,
        method: 'DELETE',
        body: { email, role: CommonRoles.PiosReviewer },
      }),
      invalidatesTags: [CacheTags.Reviewer],
    }),
  }),
});

export const {
  useDeleteReviewerMutation,
  useCreateOrUpdateReviewerMutation,
  useGetReviewersQuery,
  useRemoveReviewerMutation,
  useLazyGetReviewersQuery,
} = userApi;
