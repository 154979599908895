import { useGetTagsQuery } from '@dimatech/features-core/lib/api/tag/tagApi';
import { SelectEntitiesWithoutSurvey } from '@dimatech/features-core/lib/components/SelectEntitiesWithoutSurvey';
import { SelectTags } from '@dimatech/features-core/lib/components/SelectTags';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Autocomplete } from '@dimatech/shared/lib/components/form';
import { Switch } from '@dimatech/shared/lib/components/form/Switch';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { HeaderFilter } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  piosActions,
  selectFilter,
  selectFilterHasChanges,
} from 'api/piosSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Phase,
  ProjectDimension,
  ProjectStatus as ProjectStatusEnum,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { ProjectStatus } from './ProjectStatus';
import { SelectCustomDimensions } from './SelectCustomDimensions';
import { SelectIdeaStatuses } from './SelectIdeaStatuses';
import { SelectPeriod } from './SelectPeriod';
import { SelectPeriodMonth } from './SelectPeriodMonth';
import { SelectPeriodRange } from './SelectPeriodRange';
import { SelectPhases } from './SelectPhases';
import { SelectPortfolios } from './SelectPortfolios';

/* eslint-disable max-lines-per-function */

export const SelectExtendedFilters = ({
  isPeriodFilterShown = true,
  isPeriodMonthFilterShown = false,
  isPeriodRangeFilterShown = false,
  isIncludeMineOnlyFilterShown = true,
  isEntityFilterShown = true,
  isTagFilterShown = false,
  isPhaseFilterShown = false,
  isStatusFilterShown = false,
  isResetFilterShown = false,
  isCustomDimensionFiltersShown = false,
  isProjectEffectTakeHomeFilterShown = false,
  isProjectIdeaStatusesFilterShown = false,
  isProjectReadyForExportFilterShown = false,
  isProjectPublishedPubliclyFilterShown = false,
  isProjectEffectRealisationPhasesFilterShown = false,
  isPortfolioFilterShown = false,
  isProject = true,
  isFilterForPortfolios,
}: {
  isPeriodFilterShown?: boolean;
  isPeriodMonthFilterShown?: boolean;
  isPeriodRangeFilterShown?: boolean;
  isIncludeMineOnlyFilterShown?: boolean;
  isEntityFilterShown?: boolean;
  isTagFilterShown?: boolean;
  isPhaseFilterShown?: boolean;
  isStatusFilterShown?: boolean;
  isResetFilterShown?: boolean;
  isCustomDimensionFiltersShown?: boolean;
  isProjectEffectTakeHomeFilterShown?: boolean;
  isProjectIdeaStatusesFilterShown?: boolean;
  isProjectReadyForExportFilterShown?: boolean;
  isProjectPublishedPubliclyFilterShown?: boolean;
  isProjectEffectRealisationPhasesFilterShown?: boolean;
  isPortfolioFilterShown?: boolean;
  isProject?: boolean;
  isFilterForPortfolios?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const filterSettings = useAppSelector(selectFilterHasChanges);

  const [reset, setReset] = useState<boolean>(false);

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];
  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicApiEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isStartAndEndMonthEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];
  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];

  const { data: tags } = useGetTagsQuery(
    isTagsEnabledFlagOn && accessToken.customerId
      ? accessToken.customerId
      : skipToken
  );

  const handleChangePeriod = (period?: number) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterPeriod(period));
  };

  const handleChangePeriodMonth = (period?: number) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterPeriodMonth(period));
  };

  const handleChangePeriodRange = (period: { from?: number; to?: number }) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterPeriodRange(period));
  };

  const handleChangeEntity = (entityIds: string[]) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterEntityIds(entityIds));
    // Reset portfolios when entity changes
    dispatch(piosActions.setFilterPortfolioIds([]));
  };

  const handleChangePortfolios = (portfolioIds: string[]) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterPortfolioIds(portfolioIds));
  };

  const handleChangePhase = (phases: Phase[]) => {
    dispatch(piosActions.setFilterHasChanges(true));
    if (
      isProjectEffectRealisationEnabledFlagOn &&
      isProjectEffectRealisationPhasesFilterShown
    ) {
      dispatch(piosActions.setFilterPhaseEffectRealisation(phases));
    } else {
      dispatch(piosActions.setFilterPhase(phases));
    }
  };

  const handleChangeIdeaStatus = (statuses: Phase[]) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterIdeaStatus(statuses));
  };

  const handleChangeDimensions = (dimensions: ProjectDimension[]) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterDimensions(dimensions));
  };

  const handleChangeStatus = (status: ProjectStatusEnum | null) => {
    dispatch(piosActions.setFilterHasChanges(true));
    setReset(false);
    dispatch(piosActions.setFilterStatus(status));
  };

  const handleChangeShowMineOnly = (checked: boolean) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(
      piosActions.setFilter({
        ...filter,
        onlyMine: checked,
      })
    );
  };

  const handleChangeShowEffectTakeHomePlanOnly = (checked: boolean) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(
      piosActions.setFilter({
        ...filter,
        hasEffectTakeHomePlan: checked,
      })
    );
  };

  const handleChangeShowIsReadyForExport = (checked: boolean) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(
      piosActions.setFilter({
        ...filter,
        isReadyForExport: checked,
      })
    );
  };

  const handleChangeShowIsPublishedPublicly = (checked: boolean) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(
      piosActions.setFilter({
        ...filter,
        isPublishedPublicly: checked,
      })
    );
  };

  const handleChangeTag = (tagIds: string[]) => {
    dispatch(piosActions.setFilterHasChanges(true));
    dispatch(piosActions.setFilterTagIds(tagIds));
  };

  const handleResetFilter = () => {
    dispatch(piosActions.setFilterHasChanges(false));
    setReset(true);
    dispatch(piosActions.resetFilter());
  };

  const qualities = [
    { status: null },
    { status: ProjectStatusEnum.Zero },
    { status: ProjectStatusEnum.One },
    { status: ProjectStatusEnum.Two },
    { status: ProjectStatusEnum.Three },
  ];

  useEffect(() => {
    const dimensions = filter.dimensions?.filter(
      (dimension: ProjectDimension) =>
        isProject
          ? dimension.projectPhases?.includes(Phase.NotSet)
          : dimension.projectPhases?.includes(Phase.Draft)
    );
    if (dimensions) {
      dispatch(piosActions.setFilterDimensions(dimensions));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProject]);

  return (
    <HeaderFilter>
      {isEntityFilterShown && (
        <SelectEntitiesWithoutSurvey
          entityIds={filter.entityIds}
          setEntityIds={handleChangeEntity}
          filter={(entity) => isProject || !!entity.canViewProjectIdeas}
        />
      )}

      {isPortfolioFilterShown && (
        <SelectPortfolios
          portfolioIds={filter.portfolioIds}
          setPortfolioIds={handleChangePortfolios}
        />
      )}

      {isPeriodFilterShown && (
        <SelectPeriod
          isAnyAllowed={true}
          period={filter.period}
          setPeriod={handleChangePeriod}
        />
      )}

      {isPeriodMonthFilterShown &&
        isStartAndEndMonthEnabledFlagOn &&
        filter.period && (
          <div>
            <SelectPeriodMonth
              period={filter.periodMonth}
              setPeriod={handleChangePeriodMonth}
            />
          </div>
        )}

      {isPeriodRangeFilterShown && (
        <div>
          <SelectPeriodRange
            isAnyAllowed={true}
            period={filter.periodRange}
            setPeriod={handleChangePeriodRange}
          />
        </div>
      )}

      {isIncludeMineOnlyFilterShown && (
        <div>
          <Switch
            label={t('Search.ShowMineOnly.Label')}
            tooltipTitle={t(
              `Search.ShowMineOnly.${
                isFilterForPortfolios
                  ? 'TooltipTitleForPortfolio'
                  : 'TooltipTitleForProject'
              }`
            )}
            tooltipText={<Trans i18nKey="Search.ShowMineOnly.Tooltip" />}
            setIsChecked={handleChangeShowMineOnly}
            isChecked={filter.onlyMine === true}
          />
        </div>
      )}

      {isPublicApiEnabledFlagOn && isProjectReadyForExportFilterShown && (
        <div>
          <Switch
            label={t('Search.IsReadyForExport.Label')}
            tooltipTitle={t('Search.IsReadyForExport.TooltipTitle')}
            tooltipText={<Trans i18nKey="Search.IsReadyForExport.Tooltip" />}
            setIsChecked={handleChangeShowIsReadyForExport}
            isChecked={!!filter.isReadyForExport}
          />
        </div>
      )}

      {isPublishPubliclyEnabledFlagOn && isProjectPublishedPubliclyFilterShown && (
        <div>
          <Switch
            label={t('Search.IsPublishedPublicly.Label')}
            tooltipTitle={t('Search.IsPublishedPublicly.TooltipTitle')}
            tooltipText={<Trans i18nKey="Search.IsPublishedPublicly.Tooltip" />}
            setIsChecked={handleChangeShowIsPublishedPublicly}
            isChecked={!!filter.isPublishedPublicly}
          />
        </div>
      )}

      {isProjectEffectTakeHomeFilterShown && (
        <div>
          <Switch
            label={t('Search.ShowEffectTakeHomePlanOnly.Label')}
            tooltipTitle={t('Search.ShowEffectTakeHomePlanOnly.TooltipTitle')}
            tooltipText={
              <Trans i18nKey="Search.ShowEffectTakeHomePlanOnly.Tooltip" />
            }
            setIsChecked={handleChangeShowEffectTakeHomePlanOnly}
            isChecked={!!filter.hasEffectTakeHomePlan}
          />
        </div>
      )}

      {isTagsEnabledFlagOn && isTagFilterShown && tags && tags.length > 0 && (
        <div>
          <SelectTags tagIds={filter?.tagIds} setTagIds={handleChangeTag} />
        </div>
      )}

      {isPhaseFilterShown && (
        <div>
          <SelectPhases
            phases={
              // Include different selected phases if effect realisation is enabled
              (isProjectEffectRealisationEnabledFlagOn &&
              isProjectEffectRealisationPhasesFilterShown
                ? filter.phasesEffectRealisation
                : filter.phases) ?? []
            }
            setPhases={handleChangePhase}
          />
        </div>
      )}

      {isProjectIdeaStatusesFilterShown && (
        <div>
          <SelectIdeaStatuses
            statuses={filter.phasesIdea ?? []}
            setStatuses={handleChangeIdeaStatus}
          />
        </div>
      )}

      {isStatusFilterShown && (
        <div>
          <Autocomplete
            reset={reset}
            placeholder={t(`Project.AllQualities`)}
            items={qualities}
            selectedItem={
              qualities.find((status) => status.status === filter.status) ??
              qualities[0]
            }
            handleChange={(
              item: { status: ProjectStatusEnum | null } | null
            ) => {
              handleChangeStatus(item?.status ?? null);
            }}
            renderItem={(item: { status: ProjectStatusEnum | null }) => (
              <div style={{ textAlign: 'center' }}>
                {item.status === null && <div>{t(`Project.AllQualities`)}</div>}
                {item.status !== null && <ProjectStatus status={item.status} />}
              </div>
            )}
            renderSelectedItem={(
              item: { status: ProjectStatusEnum | null } | null
            ) => (
              <>
                {item?.status === null && (
                  <div>{t(`Project.AllQualities`)}</div>
                )}
                {item?.status !== null && (
                  <ProjectStatus status={item?.status} />
                )}
              </>
            )}
          />
        </div>
      )}

      {isCustomDimensionFiltersShown && (
        <SelectCustomDimensions
          reset={reset}
          setReset={setReset}
          isProject={isProject}
          handleChangeDimensions={handleChangeDimensions}
          items={filter.dimensions}
        />
      )}

      {isResetFilterShown && (
        <div style={{ marginTop: 5, marginLeft: 7 }}>
          <LinkWithTooltip
            isDisabled={!filterSettings}
            isInverted={true}
            handleClick={handleResetFilter}
            tooltip={t('Search.ResetFilters')}
            icon={<BsArrowCounterclockwise />}
          />
        </div>
      )}
    </HeaderFilter>
  );
};

SelectExtendedFilters.displayName = 'SelectExtendedFilters';
