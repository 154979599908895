var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { ChartTooltip } from '@dimatech/shared/lib/components/tooltip/ChartTooltip';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import styled, { withTheme } from 'styled-components';
export var GaugeMulti = withTheme(function (_a) {
    var values = _a.values, name = _a.name, helpTitle = _a.helpTitle, help = _a.help, theme = _a.theme;
    var hasValue = values.some(function (value) { return value.value !== undefined && value.value !== 0; });
    return (_jsxs(Style, { children: [_jsxs("div", { children: [_jsxs(PieChart, __assign({ width: 180, height: 90 }, { children: [_jsxs(Pie, __assign({ data: __spreadArray(__spreadArray([], values, true), [{ value: !hasValue ? 100 : null }], false), cx: 80, cy: 80, startAngle: 180, endAngle: 0, innerRadius: 50, outerRadius: 67, stroke: "none", dataKey: "value", animationDuration: 500 }, { children: [values.map(function (value, index) { return (_jsx(Cell, { fill: value.color }, index)); }), !hasValue && (_jsx(Cell, { fill: "none", strokeWidth: 1, stroke: theme.colors.border }))] })), _jsx(Tooltip, { formatter: function (value) { return formatAsPercent(value, '-', 0); }, content: _jsx(ChartTooltip, {}) })] })), _jsx("div", { children: _jsx("div", { children: name }) })] }), _jsx(HelpIcon, { text: help, title: helpTitle })] }));
});
GaugeMulti.displayName = 'GaugeMulti';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n\n  > div:first-of-type {\n    width: 170px;\n\n    > div {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      text-align: center;\n      font-size: ", ";\n    }\n\n    h1 {\n      margin-top: -40px;\n      margin-bottom: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  > div:last-of-type {\n    margin-left: -30px;\n    z-index: 10;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n\n  > div:first-of-type {\n    width: 170px;\n\n    > div {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      text-align: center;\n      font-size: ", ";\n    }\n\n    h1 {\n      margin-top: -40px;\n      margin-bottom: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  > div:last-of-type {\n    margin-left: -30px;\n    z-index: 10;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xs;
});
var templateObject_1;
