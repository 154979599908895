import { CacheTags, piosApi } from 'api/piosApi';
import {
  filterToPortfolioByPeriodQuery,
  filterToPortfolioQuery,
} from 'api/utils';
import {
  AdditionalData,
  PiosFilter,
  PortfolioOverview,
  PortfolioOverviewByPeriod,
  PortfolioOverviewResult,
} from 'models';

const portfolioOverviewApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get portfolio overview
     */
    getPortfolioOverview: build.query<
      PortfolioOverview | null,
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        const url = `overview/portfolios?pageNumber=1&pageSize=10000&orderBy.propertyName=portfolioName&orderBy.direction=Ascending`;
        const filters = filterToPortfolioQuery(filter);

        return `${url}${filters}`;
      },
      transformResponse: (
        { records }: { records: PortfolioOverview[] },
        _meta,
        arg
      ) =>
        records.length > 0
          ? records.find(
              (record) => record.portfolioId === arg.filter?.portfolioIds?.[0]
            ) ?? records[0]
          : null,
      providesTags: [CacheTags.PortfolioOverview],
    }),

    /**
     * Get portfolios overview
     */
    getPortfoliosOverview: build.query<
      PortfolioOverviewResult,
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        const url = `overview/portfolios?pageNumber=1&pageSize=10000&orderBy.propertyName=portfolioName&orderBy.direction=Ascending`;
        const filters = filterToPortfolioQuery(filter);

        return `${url}${filters}`;
      },
      transformResponse: ({
        additionalData,
        records,
      }: {
        additionalData: AdditionalData;
        records: PortfolioOverview[];
      }) => ({
        additionalData,
        portfolios: records.sort((a, b) => {
          if (a.totalBudget === b.totalBudget) {
            return a.portfolioName.localeCompare(b.portfolioName);
          }

          return (a.totalBudget ?? 0) < (b.totalBudget ?? 0) ? 1 : -1;
        }),
      }),
      providesTags: [CacheTags.PortfolioOverview],
    }),

    /**
     * Get portfolios overview by period
     */
    getPortfoliosOverviewByPeriod: build.query<
      PortfolioOverviewByPeriod[],
      {
        filter: PiosFilter;
        _customerId: string | undefined;
        _userId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ filter, _customerId, _userId }) => {
        const url = `overview/portfolios/byperiod?1=1`;
        const filters = filterToPortfolioByPeriodQuery(filter as PiosFilter);

        return `${url}${filters}`;
      },
      providesTags: [CacheTags.PortfolioOverviewByPeriod],
    }),
  }),
});

export const {
  useGetPortfolioOverviewQuery,
  useGetPortfoliosOverviewQuery,
  useGetPortfoliosOverviewByPeriodQuery,
} = portfolioOverviewApi;
