import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Checkbox,
  Input,
  InputNumber,
  Label,
  TextArea,
} from '@dimatech/shared/lib/components/form';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { CardRow, CardTile } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isReadOnly } from 'utils';
import { Style, TableStyle } from './ProjectEdit';
import { ProjectPhaseEdit } from './ProjectPhaseEdit';
import { ProjectPublishedPubliclyConfirmation } from './ProjectPublishedPubliclyConfirmation';
import { ProjectPublishedPubliclyIcon } from './ProjectPublishedPubliclyIcon';

export const ProjectEditBaseLicense = ({
  project,
  setProject,
  setHasChanges,
  isValid,
}: {
  project: Project;
  setProject: (project: Project) => void;
  setHasChanges: (hasChanges: boolean) => void;
  isValid: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);

  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];

  const [isChangingPublishedPublicly, setIsChangingPublishedPublicly] =
    useState(false);

  const handelChangePublishedPublicly = () => {
    setIsChangingPublishedPublicly(false);
    setHasChanges(true);
    setProject({
      ...project,
      isPublishedPublicly: !project.isPublishedPublicly,
    });
  };

  return project && !isReadOnly(project) ? (
    <Style>
      <CardRow>
        <CardTile>
          <Label htmlFor="title">
            {t('Project.Title')}
            <ProjectPublishedPubliclyIcon project={project} />
          </Label>
          <Input
            autoFocus={!project.id}
            type="text"
            id="title"
            name="title"
            value={project.title ?? ''}
            invalid={!isValid && !project.title}
            onChange={(e) => {
              setHasChanges(true);
              setProject({ ...project, title: e.currentTarget.value });
            }}
          />
        </CardTile>
      </CardRow>

      <CardRow style={{ gap: 0 }}>
        <CardTile
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <TableStyle>
            <tbody>
              <Tr>
                <Td>
                  <Label htmlFor="projectPhase">
                    {t('Project.Phase.Phase')}
                    <ProjectPublishedPubliclyIcon project={project} />
                  </Label>
                </Td>
                <Td>
                  <ProjectPhaseEdit
                    project={project}
                    handleChange={(phase) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        projectPhase: phase,
                      });
                    }}
                  />
                </Td>
              </Tr>
              {isPublishPubliclyEnabledFlagOn && (
                <>
                  <Tr>
                    <Td>
                      <Label htmlFor="isPublishedPublicly">
                        {t('Project.IsPublishedPublicly.Label')}
                      </Label>
                    </Td>
                    <Td>
                      {project.projectPhase !== Phase.Draft &&
                      project.projectPhase !== Phase.Ready ? (
                        <Checkbox
                          id="isPublishedPublicly"
                          name="isPublishedPublicly"
                          checked={!!project.isPublishedPublicly}
                          style={{ marginRight: 7 }}
                          onChange={() => {
                            setIsChangingPublishedPublicly(true);
                          }}
                        />
                      ) : (
                        <Checkbox checked={false} disabled={true} />
                      )}
                    </Td>
                  </Tr>
                  {(project.projectPhase === Phase.Draft ||
                    project.projectPhase === Phase.Ready) && (
                    <Tr style={{ height: '10px' }}>
                      <Td colSpan={2}>
                        <HelpText>
                          {t('Project.IsPublishedPublicly.HelpText')}
                        </HelpText>
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </tbody>
          </TableStyle>
        </CardTile>

        <CardTile
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <TableStyle>
            <tbody>
              <Tr>
                <Td>
                  <Label htmlFor="budget">
                    {t('Project.Budget', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                    <ProjectPublishedPubliclyIcon project={project} />
                  </Label>
                </Td>
                <Td>
                  <InputNumber
                    id="budget"
                    name="budget"
                    value={project.budget ?? ''}
                    onValueChange={({ value }) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        budget: !isNaN(parseInt(value)) ? +value : undefined,
                      });
                    }}
                  />
                </Td>
              </Tr>
            </tbody>
          </TableStyle>
        </CardTile>
      </CardRow>

      {isPublishPubliclyEnabledFlagOn && isChangingPublishedPublicly && (
        <ProjectPublishedPubliclyConfirmation
          project={project}
          setIsChangingPublishedPublicly={setIsChangingPublishedPublicly}
          handelChangePublishedPublicly={handelChangePublishedPublicly}
        />
      )}

      <CardRow>
        <CardTile>
          <div>
            <Label htmlFor="description">
              {t('Project.Description')}
              <ProjectPublishedPubliclyIcon project={project} />
            </Label>
          </div>
          <div>
            <TextArea
              style={{ height: 150 }}
              id="description"
              name="description"
              value={project.description ?? ''}
              onChange={(e) => {
                setHasChanges(true);
                setProject({
                  ...project,
                  description: e.currentTarget.value,
                });
              }}
            />
          </div>
        </CardTile>

        <CardTile>
          <div>
            <Label htmlFor="benefitsAndEffects">
              {t('Project.BenefitsAndEffects')}
              <ProjectPublishedPubliclyIcon project={project} />
            </Label>
          </div>
          <div>
            <TextArea
              style={{ height: 150 }}
              id="benefitsAndEffects"
              name="benefitsAndEffects"
              value={project.benefitsAndEffects ?? ''}
              onChange={(e) => {
                setHasChanges(true);
                setProject({
                  ...project,
                  benefitsAndEffects: e.currentTarget.value,
                });
              }}
            />
          </div>
        </CardTile>
      </CardRow>
    </Style>
  ) : null;
};

ProjectEditBaseLicense.displayName = 'ProjectEditBaseLicense';

const HelpText = styled.div`
  font-style: italic;
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
`;
