var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedMessageTemplate } from '../../api/message-template/messageTemplateSlice';
import { CommonMessageTemplateProperties } from './components/CommonMessageTemplateProperties';
import { CommonMessageTemplatesList } from './components/CommonMessageTemplatesList';
export var CommonMessageTemplate = function () {
    var t = useTranslation().t;
    var selectedMessageTemplate = useSelector(selectSelectedMessageTemplate);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginTop: 10, marginBottom: 20 } }, { children: t('Administrate.MessageTemplate.CommonText') })), _jsxs("div", __assign({ style: { maxWidth: 700 } }, { children: [_jsx(CommonMessageTemplatesList, {}), selectedMessageTemplate && _jsx(CommonMessageTemplateProperties, {})] }))] }));
};
CommonMessageTemplate.displayName = 'CommonMessageTemplate';
