import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  ButtonFooter,
  Buttons,
  ButtonSecondary,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { ViewRowBreak } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { piosActions, selectSelectedProjectIdea } from 'api/piosSlice';
import { DebugInfo } from 'components/DebugInfo';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dimension, Permission, Phase } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { hasPermission, isProjectIdeaArchiveAvailable } from 'utils';
import { IdeaArchiveConfirmation } from './IdeaArchiveConfirmation';
import { IdeaConvertConfirmation } from './IdeaConvertConfirmation';
import { IdeaDraftConfirmation } from './IdeaDraftConfirmation';
import { IdeaPublishConfirmation } from './IdeaPublishConfirmation';
import { IdeaRadarChart } from './IdeaRadarChart';
import { IdeaReadyConfirmation } from './IdeaReadyConfirmation';
import { IdeaReadyForReviewConfirmation } from './IdeaReadyForReviewConfirmation';
import { IdeaScatterChart } from './IdeaScatterChart';

export const IdeaListPrioritiseConvertButtons = ({
  handleSave,
  classifications,
  hasChanges,
  portfolioId,
  setPortfolioId,
}: {
  handleSave: (
    status: Phase | 'Project' | 'Archived' | undefined | null
  ) => void;
  classifications: Dimension[];
  hasChanges: boolean;
  portfolioId?: string;
  setPortfolioId: (portfolioId?: string) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const selectedIdea = useAppSelector(selectSelectedProjectIdea);

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

  const [status, setStatus] = useState<
    Phase | 'Project' | 'Archived' | undefined | null
  >(selectedIdea?.projectPhase);
  const [changingStatusTo, setChangingStatusTo] = useState<
    Phase | 'Project' | 'Archived' | undefined | null
  >();

  const handleCancel = () => {
    dispatch(piosActions.setSelectedProjectIdea(undefined));
  };

  const handleConfirm = () => {
    if (selectedIdea?.projectPhase !== status) {
      setChangingStatusTo(status);

      return;
    }

    handleSave(undefined);
  };

  const handleConvert = (shouldSave: boolean) => {
    if (shouldSave) {
      handleSave(status);
    }

    setChangingStatusTo(undefined);
  };

  useEffect(() => {
    if (!location.pathname.includes('/review')) {
      dispatch(
        piosActions.setHasUnsavedChanges(status !== selectedIdea?.projectPhase)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, selectedIdea?.projectPhase, location]);

  if (!selectedIdea) {
    return null;
  }

  return (
    <ConvertIdeaStyle>
      <div>
        <DebugInfo idea={selectedIdea} />

        <IdeaArchiveConfirmation
          isArchiving={changingStatusTo === 'Archived'}
          handleSave={handleConvert}
        />
        <IdeaConvertConfirmation
          isConverting={changingStatusTo === 'Project'}
          setPortfolioId={setPortfolioId}
          portfolioId={portfolioId}
          handleSave={handleConvert}
        />
        <IdeaDraftConfirmation
          isDrafting={changingStatusTo === Phase.Draft}
          handleSave={handleConvert}
        />
        <IdeaPublishConfirmation
          isPublishing={changingStatusTo === Phase.Published}
          handleSave={handleConvert}
        />
        <IdeaReadyConfirmation
          isReadying={changingStatusTo === Phase.Ready}
          handleSave={handleConvert}
        />
        <IdeaReadyForReviewConfirmation
          isReadyingForReview={changingStatusTo === Phase.ReadyForReview}
          handleSave={handleConvert}
        />

        <div style={{ marginBottom: 20 }}>
          {t('FutureProjects.ProjectIdea.PrioritiseHelp')}
        </div>

        {(selectedIdea.projectPhase === Phase.Published ||
          selectedIdea.projectPhase === Phase.Ready ||
          selectedIdea.projectPhase === Phase.ReadyForReview ||
          selectedIdea.projectPhase === Phase.Draft) && (
          <>
            <div className="status">
              <Checkbox
                id="draft"
                checked={status === Phase.Draft}
                onChange={() => setStatus(Phase.Draft)}
                disabled={!hasPermission(Permission.Review, selectedIdea)}
              />

              <Label htmlFor="draft">
                {t('FutureProjects.ProjectIdea.Draft.Name')}
              </Label>
            </div>
            <div style={{ marginBottom: 20 }}>
              {t('FutureProjects.ProjectIdea.Draft.Text')}
            </div>
          </>
        )}

        <>
          <div className="status">
            <Checkbox
              id="ready"
              checked={status === Phase.Ready}
              onChange={() => setStatus(Phase.Ready)}
              disabled={!hasPermission(Permission.Review, selectedIdea)}
            />

            <Label htmlFor="ready">
              {t('FutureProjects.ProjectIdea.Ready.Name')}
            </Label>
          </div>
          <div style={{ marginBottom: 20 }}>
            {t('FutureProjects.ProjectIdea.Ready.Text')}
          </div>
        </>

        {!isProjectIdeasReviewersEnabledFlagOn &&
          (selectedIdea.projectPhase === Phase.Draft ||
            selectedIdea.projectPhase === Phase.Published ||
            selectedIdea.projectPhase === Phase.Ready) && (
            <>
              <div className="status">
                <Checkbox
                  id="publish"
                  checked={status === Phase.Published}
                  onChange={() => setStatus(Phase.Published)}
                  disabled={!hasPermission(Permission.Review, selectedIdea)}
                />

                <Label htmlFor="publish">
                  {t('FutureProjects.ProjectIdea.Publish.Name')}
                </Label>
              </div>
              <div style={{ marginBottom: 20 }}>
                {t('FutureProjects.ProjectIdea.Publish.Text')}
              </div>
            </>
          )}

        {isProjectIdeasReviewersEnabledFlagOn && (
          <>
            <div className="status">
              <Checkbox
                id="readyForReview"
                checked={status === Phase.ReadyForReview}
                onChange={() => setStatus(Phase.ReadyForReview)}
                disabled={!hasPermission(Permission.Review, selectedIdea)}
              />

              <Label htmlFor="readyForReview">
                {t('FutureProjects.ProjectIdea.ReadyForReview.Name')}
              </Label>
            </div>
            <div style={{ marginBottom: 20 }}>
              {t('FutureProjects.ProjectIdea.ReadyForReview.Text')}
            </div>
          </>
        )}

        <div className="status">
          <Checkbox
            id="convert"
            checked={status === 'Project'}
            onChange={() => setStatus('Project')}
            disabled={!hasPermission(Permission.Review, selectedIdea)}
          />

          <Label htmlFor="convert">
            {t('FutureProjects.ProjectIdea.Convert.Name')}
          </Label>
        </div>
        <div style={{ marginBottom: 20 }}>
          {t('FutureProjects.ProjectIdea.Convert.Text')}
        </div>

        <ViewRowBreak />

        {hasPermission(Permission.Archive, selectedIdea) &&
          isProjectIdeaArchiveAvailable(accessToken) && (
            <>
              <div className="status">
                <Checkbox
                  id="archive"
                  checked={status === 'Archived'}
                  onChange={() => setStatus('Archived')}
                  disabled={!hasPermission(Permission.Review, selectedIdea)}
                />

                <Label htmlFor="archive">
                  {t('FutureProjects.ProjectIdea.Archive.Name')}
                </Label>
              </div>
              <div style={{ marginBottom: 10 }}>
                {t('FutureProjects.ProjectIdea.Archive.Text')}
              </div>
            </>
          )}

        <ButtonFooter style={{ marginTop: 20 }}>
          <Buttons style={{ marginLeft: 0 }}>
            <ButtonSecondary onClick={handleCancel}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              onClick={handleConfirm}
              disabled={status === selectedIdea.projectPhase && !hasChanges}
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </ButtonFooter>
      </div>

      <div>
        <IdeaScatterChart ideas={[selectedIdea]} />
        <IdeaRadarChart
          project={selectedIdea}
          classifications={classifications}
        />
      </div>
    </ConvertIdeaStyle>
  );
};

IdeaListPrioritiseConvertButtons.displayName =
  'IdeaListPrioritiseConvertButtons';

const ConvertIdeaStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .status {
    display: flex;
    margin-bottom: 5px;

    label {
      font-weight: bold;
      margin-left: 10px;
      margin-top: 0;
    }
  }

  > div {
    width: 50%;
  }

  > div:last-of-type {
    display: flex;
    align-items: center;
    align-content: center;
  }
`;
