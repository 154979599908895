import { Table } from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const ProjectDataTable = styled(Table)`
  tr {
    height: 30px;
  }

  td {
    padding: 0 20px 0 0;
    border: none;
    vertical-align: middle;

    label {
      margin-top: 0;
    }

    svg {
      font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
    }

    &:first-of-type {
      width: 40%;
      font-weight: bold;
    }

    &:last-of-type {
      width: 60%;

      svg {
        margin-right: 5px;
      }

      > div {
        display: flex;
        align-items: center;
      }
    }
  }
`;
