var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { ChartTooltip } from '@dimatech/shared/lib/components/tooltip/ChartTooltip';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import styled, { withTheme } from 'styled-components';
export var GaugeDual = withTheme(function (_a) {
    var value1 = _a.value1, value2 = _a.value2, helpTitle = _a.helpTitle, help = _a.help, _b = _a.isAnimationActive, isAnimationActive = _b === void 0 ? true : _b, theme = _a.theme;
    var hasValue = value1.value !== undefined &&
        value1.value !== null &&
        value2.value !== undefined &&
        value2.value !== null;
    return (_jsxs(Style, { children: [_jsxs("div", { children: [_jsxs(PieChart, __assign({ width: 180, height: 90 }, { children: [_jsxs(Pie, __assign({ data: [value1, value2, { value: !hasValue ? 100 : null }], cx: 80, cy: 80, startAngle: 180, endAngle: 0, innerRadius: 50, outerRadius: 67, stroke: "none", dataKey: "value", animationDuration: 500, isAnimationActive: isAnimationActive }, { children: [_jsx(Cell, { fill: value1.color }), _jsx(Cell, { fill: value2.color }), !hasValue && (_jsx(Cell, { fill: "none", strokeWidth: 1, stroke: theme.colors.border }))] })), _jsx(Tooltip, { formatter: function (value) { return formatAsPercent(value, '-', 0); }, content: _jsx(ChartTooltip, {}) })] })), _jsxs("div", { children: [_jsxs("div", { children: [value1.name, " ", formatAsPercent(value1.value, '-', 0)] }), _jsxs("div", { children: [value2.name, " ", formatAsPercent(value2.value, '-', 0)] })] })] }), _jsx(HelpIcon, { text: help, title: helpTitle })] }));
});
GaugeDual.displayName = 'GaugeDual';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  > div:first-of-type {\n    width: 180px;\n\n    > div {\n      width: 100%;\n      display: flex;\n      justify-content: space-between;\n      font-size: ", ";\n    }\n\n    h1 {\n      margin-top: -40px;\n      margin-bottom: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  > div:last-of-type {\n    margin-left: -30px;\n    z-index: 10;\n  }\n"], ["\n  display: flex;\n\n  > div:first-of-type {\n    width: 180px;\n\n    > div {\n      width: 100%;\n      display: flex;\n      justify-content: space-between;\n      font-size: ", ";\n    }\n\n    h1 {\n      margin-top: -40px;\n      margin-bottom: 5px;\n      margin-left: 5px;\n    }\n  }\n\n  > div:last-of-type {\n    margin-left: -30px;\n    z-index: 10;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xs;
});
var templateObject_1;
