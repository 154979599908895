var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsFeatureFlag, featuresCoreApi } from '../featuresCoreApi';
var featureApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get feature flag
         */
        getFeatureFlag: build.query({
            query: function (_a) {
                var featureFlagKey = _a.featureFlagKey, _customerId = _a._customerId;
                return "feature/".concat(featureFlagKey);
            },
            providesTags: [CacheTagsFeatureFlag.FeatureFlag],
            transformResponse: function (result, _, arg) {
                return __assign(__assign({}, result), { key: arg.featureFlagKey, users: result.users.sort(function (a, b) { return (a > b ? 1 : -1); }) });
            },
        }),
        /**
         * Update feature flag
         */
        updateFeatureFlag: build.mutation({
            query: function (_a) {
                var feature = _a.feature;
                return ({
                    url: "feature/".concat(feature.key),
                    method: 'POST',
                    body: feature,
                });
            },
            invalidatesTags: [CacheTagsFeatureFlag.FeatureFlag],
        }),
    }); },
});
export var useGetFeatureFlagQuery = featureApi.useGetFeatureFlagQuery, useUpdateFeatureFlagMutation = featureApi.useUpdateFeatureFlagMutation;
