import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Heading4, Heading5 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGoalsQuery } from 'api/project/projectGoalApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project, ProjectPeriod } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAsCurrency, formatDate } from 'utils';
import { ProjectPublishedPubliclyIcon } from './ProjectPublishedPubliclyIcon';

export const ProjectPeriodList = ({
  project,
}: {
  project: Project;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [periods, setPeriods] = useState<ProjectPeriod[]>();
  const [projectPeriodsTotal, setProjectPeriodsTotal] = useState<number>();
  const [projectPeriodsStaffingTotal, setProjectPeriodsStaffingTotal] =
    useState<number>();

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isStartAndEndMonthEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];

  const { data: goals } = useGetGoalsQuery(
    accessToken.customerId &&
      project.id &&
      isProjectEffectRealisationEnabledFlagOn
      ? {
          projectId: project.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const currentYear = new Date().getFullYear();
  const monthName = (month?: number) => {
    if (!month || isNaN(month)) {
      return '-';
    } else {
      return formatDate(new Date(currentYear, month - 1, 1), 'LLLL');
    }
  };

  useEffect(() => {
    setProjectPeriodsTotal(
      periods?.reduce((sum, period) => (period.totalBudget ?? 0) + sum, 0)
    );
    setProjectPeriodsStaffingTotal(
      periods?.reduce((sum, period) => (period.staffingBudget ?? 0) + sum, 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods]);

  useEffect(() => {
    setPeriods(
      (project.periods ? [...project.periods] : []).sort((a, b) =>
        a.year > b.year ? 1 : -1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <>
      <Heading4 style={{ marginBottom: 15 }}>
        {t('Project.Period.Title')}
      </Heading4>

      <Style>
        <div className="field">
          <div>
            <Heading5 style={{ display: 'flex' }}>
              {t('Project.Budget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
              <ProjectPublishedPubliclyIcon project={project} />
            </Heading5>
            <div>{formatAsCurrency(project.budget)}</div>
          </div>

          <div>
            <Heading5>{t('Project.StartYear')}</Heading5>
            <div>{project.startYear ?? '-'}</div>
          </div>

          {isStartAndEndMonthEnabledFlagOn && (
            <div>
              <Heading5>{t('Project.StartMonth')}</Heading5>
              <div>{monthName(project.startMonth)}</div>
            </div>
          )}

          <div>
            <Heading5>{t('Project.EndYear')}</Heading5>
            <div>{project.endYear ?? '-'}</div>
          </div>

          {isStartAndEndMonthEnabledFlagOn && (
            <div>
              <Heading5>{t('Project.EndMonth')}</Heading5>
              <div>{monthName(project.endMonth)}</div>
            </div>
          )}

          {isProjectEffectRealisationEnabledFlagOn && (
            <>
              <div>
                <Heading5>
                  {t('ImplementedProjects.ExpectedBenefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </Heading5>
                {formatAsCurrency(
                  goals?.reduce(
                    (total, goal) => total + (goal.totalExpectedBenefit ?? 0),
                    0
                  )
                )}
              </div>
              <div>
                <Heading5>
                  {t('ImplementedProjects.ActualBenefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </Heading5>
                {formatAsCurrency(
                  goals?.reduce(
                    (total, goal) => total + (goal.totalActualBenefit ?? 0),
                    0
                  )
                )}
              </div>

              {(project.projectPhase === Phase.Finished ||
                project.projectPhase ===
                  Phase.FinishedEffectRealizationStarted ||
                project.projectPhase ===
                  Phase.FinishedEffectRealizationConcluded) && (
                <div>
                  <Heading5>
                    {t('ImplementedProjects.Deviation', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </Heading5>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {formatAsCurrency(
                      goals?.reduce(
                        (total, goal) => total + (goal.deviation ?? 0),
                        0
                      )
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Style>

      {periods && periods.length === 0 && (
        <div>{t('Project.Period.NoPeriods')}</div>
      )}

      {periods && periods.length > 0 && (
        <div className="b" style={{ marginBottom: 10 }}>
          {t('Project.Period.YearlyBudget', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
        </div>
      )}

      <TableResponsiveContainer>
        <Table>
          <thead>
            {periods && periods.length > 0 && (
              <tr>
                {periods.map((period) => (
                  <TdRight
                    key={period.year}
                    style={{ width: '1%', minWidth: 80 }}
                  >
                    {period.year}
                  </TdRight>
                ))}
                <TdRight style={{ width: '1%', minWidth: 100 }}>
                  {t('Project.Period.TotalBudget', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </TdRight>
                <TdRight style={{ width: '1%', minWidth: 130 }}>
                  {t('Project.Budget', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </TdRight>
                <TdRight style={{ width: '1%', minWidth: 100 }}>
                  {t('Project.Period.Deviation')}
                </TdRight>
                <TdRight />
              </tr>
            )}
          </thead>

          <tbody>
            {periods && periods.length > 0 && (
              <>
                <Tr>
                  {periods.map((period) => (
                    <TdRight
                      key={period.year}
                      style={{ width: '1%', minWidth: 80 }}
                    >
                      {formatAsCurrency(period.totalBudget)}
                    </TdRight>
                  ))}
                  <TdRight style={{ width: '1%', minWidth: 100 }}>
                    {formatAsCurrency(projectPeriodsTotal)}
                  </TdRight>
                  <TdRight style={{ width: '1%', minWidth: 100 }}>
                    {formatAsCurrency(project.budget)}
                  </TdRight>
                  <TdRight style={{ minWidth: 100 }}>
                    {formatAsCurrency(
                      (project.budget ?? 0) - (projectPeriodsTotal ?? 0)
                    )}
                  </TdRight>
                  <Td />
                </Tr>

                <Tr>
                  <Th colSpan={100}>
                    {t('Project.Period.StaffingBudget', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </Th>
                </Tr>
                <Tr>
                  {periods.map((period) => (
                    <TdRight
                      key={period.year}
                      style={{ width: '1%', minWidth: 80 }}
                    >
                      {formatAsCurrency(period.staffingBudget)}
                    </TdRight>
                  ))}
                  <TdRight style={{ width: '1%', minWidth: 100 }}>
                    {formatAsCurrency(projectPeriodsStaffingTotal)}
                  </TdRight>
                  <Td colSpan={3} />
                </Tr>
              </>
            )}
          </tbody>
        </Table>
      </TableResponsiveContainer>
    </>
  );
};

ProjectPeriodList.displayName = 'ProjectPeriodList';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .field {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    > div {
      text-align: right;
      padding: 0 20px 10px 0;
    }
  }
`;
