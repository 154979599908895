export var toBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function () {
            var bytes = Array.from(new Uint8Array(reader.result));
            var base64 = window.btoa(bytes.map(function (item) { return String.fromCharCode(item); }).join(''));
            resolve(base64);
        };
        reader.onerror = function (error) { return reject(error); };
    });
};
export var toFileSize = function (bytes, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (bytes === 0) {
        return '';
    }
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['b', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(sizes[i]);
};
