import { GlobalAdministrateNationalTags as GlobalAdministrateNationalTagsFeature } from 'features/global-administrate-national-tags';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const GlobalAdministrateNationalTags = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('GlobalAdministrate.NationalTag.Title')}>
      <GlobalAdministrateNationalTagsFeature />
    </View>
  );
};

GlobalAdministrateNationalTags.displayName = 'GlobalAdministrateNationalTags';
