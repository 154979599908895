import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { Statistics } from 'components/Statistics';
import { StatisticsByYear } from 'components/StatisticsByYear';
import { StatisticsForBenefits } from 'components/StatisticsForBenefits';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';

export const ProjectsKeyStatistics = (): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const { data, isFetching } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  if (data && data.projects.length === 0) {
    return null;
  }

  return (
    <Card
      style={{
        paddingTop: 10,
      }}
    >
      <CardBody isLoading={isFetching} style={{ padding: 0 }}>
        {data && (
          <>
            <Statistics
              data={{
                ...data.additionalData,
                projectsCount: data.projects.length,
              }}
            />

            {filter.period !== undefined && (
              <StatisticsByYear
                data={{
                  ...data.additionalData,
                  projectsCount: data.projects.length,
                }}
              />
            )}

            {isProjectEffectRealisationEnabledFlagOn && (
              <StatisticsForBenefits
                data={{
                  ...data.additionalData,
                  projectsCount: data.projects.length,
                }}
              />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

ProjectsKeyStatistics.displayName = 'ProjectsKeyStatistics';
