var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { flags } from '../../feature-flags';
import { Color } from '../../themes';
import { GanttItem } from './GanttItem';
import { CellStyle, HeaderStyle } from './GanttStyle';
import { GanttTooltip } from './GanttTooltip';
export var GanttCategory = function (_a) {
    var years = _a.years, category = _a.category, highlightedYear = _a.highlightedYear, setHighlightedYear = _a.setHighlightedYear, handleSelectItem = _a.handleSelectItem, handleSelectCategory = _a.handleSelectCategory;
    var isStartAndEndMonthEnabledFlagOn = useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];
    return (_jsxs(_Fragment, { children: [_jsxs("tr", { children: [_jsxs(CategoryHeaderStyle, __assign({ onClick: function () { return handleSelectCategory(category); } }, { children: [_jsx("div", __assign({ className: "b" }, { children: category.title })), category.subtitle && (_jsx("div", __assign({ className: "size-s i" }, { children: category.subtitle })))] })), years.map(function (year) {
                        var _a, _b;
                        return (_jsx(CategoryCellStyle, __assign({ isHighlighted: highlightedYear === year, onClick: function () { return handleSelectCategory(category); }, onMouseEnter: function () { return setHighlightedYear(year); }, onMouseLeave: function () { return setHighlightedYear(undefined); } }, { children: category.years.includes(year) && (_jsx("div", __assign({ className: "".concat(year === category.startYear ? 'first' : '', " ").concat(year === category.endYear ? 'last' : ''), style: {
                                    backgroundColor: (_a = category.color) !== null && _a !== void 0 ? _a : Color.Amethyst,
                                    marginLeft: isStartAndEndMonthEnabledFlagOn
                                        ? "".concat(year === category.startYear && category.startMonth
                                            ? ((category.startMonth - 1) / 12) * 100 + '%'
                                            : '0%')
                                        : '',
                                    marginRight: isStartAndEndMonthEnabledFlagOn
                                        ? "".concat(year === category.endYear && category.endMonth
                                            ? ((12 - category.endMonth) / 12) * 100 + '%'
                                            : '0%')
                                        : '',
                                }, "data-html": true, "data-tip": ReactDOMServer.renderToString(_jsx(GanttTooltip, { title: year.toString(), subtitle: category.title, texts: [(_b = category.subtitle) !== null && _b !== void 0 ? _b : ''] })) }, { children: "\u00A0" }))) }), year));
                    })] }), category.items.map(function (item, j) {
                return (_jsx(GanttItem, { years: years, item: item, setHighlightedYear: setHighlightedYear, highlightedYear: highlightedYear, handleSelect: handleSelectItem }, j));
            })] }));
};
var CategoryHeaderStyle = styled(HeaderStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  border-right: 1px solid\n    ", ";\n\n  padding-left: 5px;\n\n  cursor: pointer;\n"], ["\n  border-top: 1px solid ", ";\n  border-right: 1px solid\n    ", ";\n\n  padding-left: 5px;\n\n  cursor: pointer;\n"])), Color.Grey20, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
});
var CategoryCellStyle = styled(CellStyle)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n\n  &:last-child {\n    border-right: 1px solid\n      ", ";\n  }\n\n  > div {\n    cursor: pointer;\n  }\n"], ["\n  border-top: 1px solid ", ";\n\n  &:last-child {\n    border-right: 1px solid\n      ", ";\n  }\n\n  > div {\n    cursor: pointer;\n  }\n"])), Color.Grey20, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
});
var templateObject_1, templateObject_2;
