import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import { Button, ButtonSecondary } from '@dimatech/shared/lib/components/form';
import {
  useAddNoteMutation,
  useUpdateNoteMutation,
} from 'api/project/projectNoteApi';
import {
  projectNoteActions,
  selectSelectedProjectNote,
} from 'api/project/projectNoteSlice';
import { config } from 'config/index';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ProjectNote } from 'models';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isPiosReadOnly } from 'utils';

export const NoteEdit = ({
  canEdit,
  style,
}: {
  canEdit: boolean;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);
  const selectedNote = useAppSelector(selectSelectedProjectNote);

  const [addNote, { isLoading: addPosting }] = useAddNoteMutation();
  const [updateNote, { isLoading: updatePosting }] = useUpdateNoteMutation();

  const isPosting = updatePosting || addPosting;

  const [hasChanges, setHasChanges] = useState(false);
  const [note, setNote] = useState<ProjectNote | undefined>();
  const [isValid, setIsValid] = useState(true);
  const [isTextValid, setIsTextValid] = useState(true);

  useEffect(() => {
    setNote(selectedNote ? { ...selectedNote } : undefined);
    setIsValid(true);
    setIsTextValid(true);
  }, [selectedNote]);

  const handleCancel = () => {
    dispatch(projectNoteActions.selectProjectNote());
  };

  const handleSave = () => {
    setIsValid(true);
    setIsTextValid(true);

    const limitInBytes = config.ui.textEditor.maxBytes;
    const textBytes = new TextEncoder().encode(note?.content);

    if (isViewReadOnly || !note) {
      return;
    }

    if (!note.content) {
      setIsValid(false);
      return;
    }

    if (textBytes.length > limitInBytes) {
      setIsTextValid(false);
      return;
    }

    if (note.id) {
      updateNote({ ...note });
    } else {
      addNote({ ...note });
    }

    setHasChanges(false);

    dispatch(projectNoteActions.selectProjectNote());
  };

  return canEdit && note ? (
    <Style>
      {!isTextValid && (
        <AlertWarning style={{ marginBottom: 20 }}>
          {t(`TextEditor.ValidationError.LimitExceeded`)}
        </AlertWarning>
      )}

      <div>
        <TextEditor
          value={note?.content ?? ''}
          setValue={(val) => {
            setHasChanges(true);
            setNote({ ...note, content: val });
          }}
          messageId={note.id ?? note.uid}
          allowLinks={false}
          invalid={!isValid && !note?.content}
          validateHtmlIsEmpty={true}
        />
      </div>
      <ButtonSecondary type="button" onClick={handleCancel}>
        {t('Common.Form.Cancel')}
      </ButtonSecondary>
      <Button
        onClick={handleSave}
        type="button"
        disabled={isViewReadOnly || isPosting || !hasChanges}
      >
        {t('Common.Form.Save')}
      </Button>
    </Style>
  ) : null;
};

NoteEdit.displayName = 'NoteEdit';

const Style = styled.div`
  margin: 10px 0 20px 0;

  > div {
    display: flex;
    align-items: center;

    > div {
      padding: 0 20px 10px 0;
    }

    > div:first-of-type {
      flex-grow: 1;
    }
  }

  > button {
    margin-top: 10px;
    min-width: 100px;

    &:last-of-type {
      margin-left: 20px;
    }
  }
`;
