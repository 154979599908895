import { GaugeDual } from '@dimatech/features-core/lib/components/GaugeDual';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CurrencyIcon } from '@dimatech/shared/lib/components/CurrencyIcon';
import { Number } from '@dimatech/shared/lib/components/Number';
import {
  ViewRow,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { AdditionalData, PiosColors } from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsClipboardData } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { formatAsNumber } from 'utils';
import { StatisticsStyle } from './StatisticsStyle';

export const Statistics = withTheme(
  ({
    theme,
    data,
  }: {
    theme: Theme;
    data: AdditionalData;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    return (
      <StatisticsStyle>
        <ViewRow>
          <div>
            <GaugeDual
              helpTitle={t('Statistics.EfficiencyInnovation')}
              help={<Trans i18nKey="Statistics.EfficiencyInnovationHelp" />}
              value1={{
                value: data.efficiencyInnovation,
                name: t('Portfolio.Efficiency'),
                color: PiosColors.efficiency,
              }}
              value2={{
                value: data.efficiencyInnovation
                  ? 100 - data.efficiencyInnovation
                  : null,
                name: t('Portfolio.Innovation'),
                color: PiosColors.innovation,
              }}
            />
            <div>{t('Statistics.EfficiencyInnovation')}</div>
          </div>

          <div>
            <GaugeDual
              helpTitle={t('Statistics.InternalExternal')}
              help={<Trans i18nKey="Statistics.InternalExternalHelp" />}
              value1={{
                value: data.internalExternal,
                name: t('Portfolio.Internal'),
                color: PiosColors.internal,
              }}
              value2={{
                value: data.internalExternal
                  ? 100 - data.internalExternal
                  : null,
                name: t('Portfolio.External'),
                color: PiosColors.external,
              }}
            />
            <div>{t('Statistics.InternalExternal')}</div>
          </div>
        </ViewRow>

        <ViewRowBreak className="show-xs" />

        <ViewRow>
          <Number
            icon={<CurrencyIcon currency={accessToken.locale.currency} />}
            color={theme.colors.primary}
            text={t('Statistics.TotalBudget', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={`${formatAsNumber(data.totalBudget)}`}
          />

          <ViewRowBreak className="show-xs" />

          <Number
            icon={<CurrencyIcon currency={accessToken.locale.currency} />}
            color={theme.colors.primary}
            text={t('Statistics.AverageBudget', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            help={<Trans i18nKey="Statistics.AverageBudgetHelp" />}
            formattedValue={`${formatAsNumber(data.averageBudget, '-', 0)}`}
          />

          <ViewRowBreak className="show-xs" />

          <Number
            icon={<BsClipboardData />}
            color={theme.colors.primary}
            text={t('Statistics.TotalProjects')}
            formattedValue={`${formatAsNumber(data.projectsCount)}`}
          />
        </ViewRow>
      </StatisticsStyle>
    );
  }
);

Statistics.displayName = 'Statistics';
