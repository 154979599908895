import { CommonMessageTemplate } from '@dimatech/features-core/lib/features/administrate-message-template/CommonMessageTemplate';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useTranslation } from 'react-i18next';

export const AdministrateMessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <ViewHeader>
        <Heading1>
          {t('Administrate.MessageTemplate.MessageTemplates')}
        </Heading1>
      </ViewHeader>
      <Card>
        <CardBody>
          <CommonMessageTemplate />
        </CardBody>
      </Card>
    </>
  );
};

AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplate';
