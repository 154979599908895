import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { getEntitiesInPath } from '@dimatech/features-core/lib/utils';
import {
  Gantt,
  GanttCategory,
  GanttItem,
  GanttItemSection,
} from '@dimatech/shared/lib/components/gantt';
import { TableResponsiveContainer } from '@dimatech/shared/lib/components/table';
import { Color } from '@dimatech/shared/lib/themes';
import {
  formatAsCurrency,
  formatAsPercentRange,
  groupBy,
} from '@dimatech/shared/lib/utils';
import { Entity, Phase, ProjectTimeline } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { isProjectOngoing } from 'utils';

export const Timeline = ({
  projects,
  entities,
  saveToPngElementId,
}: {
  projects: ProjectTimeline[];
  entities: Entity[];
  saveToPngElementId: string;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [categories, setCategories] = useState<GanttCategory[]>();

  const config = {
    labels: [
      { color: Color.Amethyst, text: t('Portfolio.Portfolio') },
      { color: Color.SeaGreen, text: t('Project.Project') },
      {
        color: Color.BrightTurquoise,
        text: t('ImplementedProjects.TakeHomePeriod'),
      },
    ],
  };

  const projectTooltip = (project: ProjectTimeline) => [
    `${t('Project.Budget', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    })}: ${formatAsCurrency(project.budget)}`,
    `${t('Portfolio.InternalExternal')}: ${formatAsPercentRange(
      project.internalExternal
    )}`,
    `${t('Portfolio.EfficiencyInnovation')}: ${formatAsPercentRange(
      project.efficiencyInnovation
    )}`,
    `${t('ImplementedProjects.ExpectedBenefit', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    })}: ${formatAsCurrency(project.totalExpectedBenefit)}`,
    `${t('ImplementedProjects.ActualBenefit', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    })}: ${formatAsCurrency(project.totalActualBenefit)}`,
    `${t('ImplementedProjects.Deviation', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    })}: ${formatAsCurrency(project.deviation)}`,
  ];

  useEffect(() => {
    if (!entities || !projects) {
      return;
    }

    const entitiesInPath = getEntitiesInPath(entities, null);

    const mapped = projects.map((project) => ({
      ...project,
      deviation:
        (project.totalActualBenefit ?? 0) - (project.totalExpectedBenefit ?? 0),
      entityPath: entitiesInPath.find(
        (entity) => project.entityId === entity.id
      )?.path,
    }));

    const groupedByPortfolio = groupBy(
      mapped.filter((project) => project.startYear && project.endYear),
      (project) => project.portfolioId as string,
      (project) => ({
        title: project.portfolioName,
        entityPath: project.entityPath ?? project.entityName,
      })
    );

    const categories = Object.keys(groupedByPortfolio).map((key) => {
      const proj = groupedByPortfolio[key].items;

      return {
        id: key,
        title: groupedByPortfolio[key].payload?.title as string,
        subtitle: groupedByPortfolio[key].payload?.entityPath,
        tooltip: [groupedByPortfolio[key].payload?.title as string],
        items: proj.map((project) => {
          const sections: GanttItemSection[] = [
            {
              title: project.title as string,
              startYear: project.startYear as number,
              endYear: project.endYear as number,
              startMonth: project.startMonth,
              endMonth: project.endMonth,
              tooltip: projectTooltip(project),
            },
          ];

          if (project.maxTakeHomePeriod !== undefined) {
            sections.push({
              title: project.title as string,
              startYear: (project.endYear as number) + 1,
              endYear:
                (project.endYear as number) + (project.maxTakeHomePeriod ?? 0),
              startMonth: project.startMonth,
              endMonth: project.endMonth,
              tooltip: projectTooltip(project),
              color: Color.BrightTurquoise,
            });
          }

          return {
            categoryId: key,
            id: project.projectId as string,
            title: project.title as string,
            sections,
            projectPhase: project.projectPhase,
          };
        }),
      };
    });

    setCategories(categories);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, projects]);

  const handleSelectItem = (item: GanttItem) => {
    if (isProjectOngoing(item?.projectPhase as Phase)) {
      navigate(`/project/${item.categoryId}/${item.id}`, {
        state: { from: location.pathname },
      });
    } else {
      navigate('/project-idea/view/', {
        state: { ideaId: item.id },
      });
    }
  };

  const handleSelectCategory = (category: GanttCategory) => {
    navigate(`/portfolio/${category.id}`, {
      state: { from: location.pathname },
    });
  };

  if (categories && categories.length === 0) {
    return null;
  }

  return (
    <>
      {categories && categories.length > 0 && (
        <TableResponsiveContainer>
          <Gantt
            categories={categories}
            config={config}
            saveToPngElementId={saveToPngElementId}
            handleSelectItem={handleSelectItem}
            handleSelectCategory={handleSelectCategory}
          />
        </TableResponsiveContainer>
      )}
    </>
  );
};

Timeline.displayName = 'Timeline';
