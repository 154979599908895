import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetProjectsTimelineQuery } from 'api/project/projectOverviewApi';
import { Timeline } from 'components/Timeline';
import { useAppSelector } from 'hooks';
import { useContext, useId } from 'react';
import { useTranslation } from 'react-i18next';

export const PortfoliosProjectsTimeline = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const saveToPngElementId = useId();

  const { data, isFetching } = useGetProjectsTimelineQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const { data: entities, isFetching: isFetchingEntities } =
    useGetEntitiesQuery(
      accessToken.customerId && accessToken.user?.id
        ? {
            _customerId: accessToken.customerId,
            _userId: accessToken.user?.id,
          }
        : skipToken
    );

  if (data && data.length === 0) {
    return null;
  }

  return (
    <Card
      style={{ paddingTop: 10 }}
      canBeSavedAsPng={true}
      saveToPngElementId={saveToPngElementId}
      helpTextTitle={t('Projects.Timeline.Title')}
      helpText={t('Projects.Timeline.Text')}
    >
      <CardBody isLoading={isFetching || isFetchingEntities}>
        {data && entities && (
          <Timeline
            projects={data}
            entities={entities}
            saveToPngElementId={saveToPngElementId}
          />
        )}
      </CardBody>
    </Card>
  );
};

PortfoliosProjectsTimeline.displayName = 'PortfoliosProjectsTimeline';
