import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useDeleteNoteMutation } from 'api/project/projectNoteApi';
import { projectNoteActions } from 'api/project/projectNoteSlice';
import { useAppDispatch } from 'hooks';
import { ProjectNote } from 'models';
import { CSSProperties, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { isPiosReadOnly } from 'utils';

export const NoteListEditButtons = ({
  note,
  style,
}: {
  note: ProjectNote;
  style?: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [deleteNote] = useDeleteNoteMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isViewReadOnly) {
      return;
    }

    setIsDeleting(false);
    deleteNote({ ...note });
  };

  const handleEdit = (e: React.SyntheticEvent, note: ProjectNote) => {
    e.stopPropagation();

    dispatch(projectNoteActions.selectProjectNote({ ...note }));
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Portfolio.Note.Delete.ConfirmTitle')}>
          <div>{t('Portfolio.Note.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isViewReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-note-edit"
        title={t('Portfolio.Note.Edit.TooltipTitle')}
        text={t('Portfolio.Note.Edit.Tooltip')}
      />
      <TooltipContent
        id="tt-note-delete"
        title={t('Portfolio.Note.Delete.TooltipTitle')}
        text={t('Portfolio.Note.Delete.Tooltip')}
      />

      <div
        style={{
          width: 60,
          display: 'flex',
          justifyContent: 'space-between',
          ...style,
        }}
      >
        <BadgeMini
          data-tip
          data-for="tt-note-edit"
          $inverted={true}
          onClick={(e) => handleEdit(e, note)}
        >
          <BsPencil />
        </BadgeMini>

        <BadgeMini
          data-tip
          data-for="tt-note-delete"
          $inverted={true}
          onClick={(e) => handleConfirmDelete(e, true)}
        >
          <BsTrash />
        </BadgeMini>
      </div>
    </>
  );
};

NoteListEditButtons.displayName = 'NoteListEditButtons';
