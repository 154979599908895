import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { isPiosReadOnly } from 'utils';

export const ProjectOrderingPartyActionDelete = ({
  email,
  setValidationErrors,
  handleDelete,
}: {
  email: string;
  setValidationErrors: (validationErrors: string[] | undefined) => void;
  handleDelete: (email: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isPiosReadOnly(accessToken);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteUser = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    if (!email) {
      return;
    }

    handleDelete(email);
    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setValidationErrors(undefined);
    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t(`Project.OrderingParty.Delete.ConfirmTitle`)}
          handleKeyEnter={(e) => handleDeleteUser(e)}
          handleKeyEsc={(e) => handleConfirmDelete(e, false)}
        >
          <div>{t(`Project.OrderingParty.Delete.ConfirmText`)}</div>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleDeleteUser(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-ordering-party-delete"
        title={t(`Project.OrderingParty.Delete.TooltipTitle`)}
        text={t(`Project.OrderingParty.Delete.Tooltip`)}
      />

      <BadgeMini
        data-tip
        data-for="tt-ordering-party-delete"
        onClick={(e) => handleConfirmDelete(e, true)}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};

ProjectOrderingPartyActionDelete.displayName =
  'ProjectOrderingPartyActionDelete';
