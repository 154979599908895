import { updateCurrentUrl } from '@dimatech/shared/lib/utils';
import { piosActions } from 'api/piosSlice';
import { useAppDispatch } from 'hooks';
import { ProjectIdea, ProjectIdeaAction } from 'models';

export const useSelectAndNavigate = () => {
  const dispatch = useAppDispatch();

  const selectAndNavigate = (
    action: ProjectIdeaAction,
    idea?: ProjectIdea,
    portfolioId?: string
  ) => {
    dispatch(piosActions.setSelectedProjectIdeaAction(action));

    dispatch(
      piosActions.setSelectedProjectIdea(idea ? { ...idea } : undefined)
    );

    updateCurrentUrl(`/project-idea/${action}/${portfolioId ?? ''}`);
  };

  return selectAndNavigate;
};
