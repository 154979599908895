import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { InputNumber, Label } from '@dimatech/shared/lib/components/form';
import { Theme } from '@dimatech/shared/lib/themes';
import { ProjectPeriod } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAsCurrency } from 'utils';

export const ProjectPeriodEdit = ({
  budget,
  setProjectPeriods,
  setHasChanges,
  projectPeriods,
}: {
  budget?: number;
  setProjectPeriods: (periods: ProjectPeriod[]) => void;
  setHasChanges: (hasChanges: boolean) => void;
  projectPeriods: ProjectPeriod[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const [projectPeriodsTotal, setProjectPeriodsTotal] = useState<number>();
  const [projectPeriodsStaffingTotal, setProjectPeriodsStaffingTotal] =
    useState<number>();

  const handleChange = (value: string, period: ProjectPeriod, name: string) => {
    setHasChanges(true);

    const periods = [...projectPeriods];
    const index = periods.findIndex((p) => p.year === period.year);

    if (index > -1) {
      periods[index] = {
        ...period,
        [name]: !isNaN(parseInt(value)) ? +value : undefined,
      };
    }

    setProjectPeriods(periods);
  };

  useEffect(() => {
    setProjectPeriodsTotal(
      projectPeriods.reduce((sum, period) => (period.totalBudget ?? 0) + sum, 0)
    );
    setProjectPeriodsStaffingTotal(
      projectPeriods.reduce(
        (sum, period) => (period.staffingBudget ?? 0) + sum,
        0
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectPeriods]);

  return projectPeriods.length > 0 ? (
    <Style>
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {t('Project.Period.YearlyBudget', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
        </div>

        <div>
          {projectPeriods.map((period) => (
            <div key={period.year}>
              <Label htmlFor={`budget_${period.year}`}>{period.year}</Label>
              <InputNumber
                id={`budget_${period.year}`}
                type="number"
                value={period.totalBudget ?? ''}
                onValueChange={({ value }) => {
                  handleChange(value, period, 'totalBudget');
                }}
              />
            </div>
          ))}

          <div className="budget-sum">
            <Label>
              {t('Project.Period.TotalBudget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
            </Label>
            <div>{formatAsCurrency(projectPeriodsTotal)}</div>
          </div>

          <div className="budget-sum">
            <Label>{t('Project.Period.Deviation')}</Label>

            <div>
              {formatAsCurrency((budget ?? 0) - (projectPeriodsTotal ?? 0))}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          {t('Project.Period.StaffingBudget', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
        </div>

        <div>
          {projectPeriods.map((period) => (
            <div key={period.year}>
              <Label htmlFor={`staff-budget_${period.year}`}>
                {period.year}
              </Label>
              <InputNumber
                id={`staff-budget_${period.year}`}
                type="number"
                value={period.staffingBudget ?? ''}
                onValueChange={({ value }) => {
                  handleChange(value, period, 'staffingBudget');
                }}
              />
            </div>
          ))}

          <div className="budget-sum">
            <Label>
              {t('Project.Period.TotalBudget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
            </Label>
            <div>{formatAsCurrency(projectPeriodsStaffingTotal)}</div>
          </div>
        </div>
      </div>
    </Style>
  ) : null;
};

ProjectPeriodEdit.displayName = 'ProjectPeriodEdit';

const Style = styled.div`
  width: 100%;

  > div {
    padding: 20px !important;
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colors.surfaceVariant};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

    label {
      font-weight: normal;
      justify-content: flex-end;
    }

    > div:last-of-type {
      display: flex;
      flex-wrap: wrap;
      text-align: right;

      > div {
        padding-right: 15px;
      }

      > .budget-sum {
        min-width: 80px;
        text-align: right;

        > div {
          margin-top: 10px;
        }
      }

      input {
        max-width: 100px;
      }
    }
  }
`;
