import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  CardFocused,
  CardFocusedBody,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { useTitle } from '@dimatech/shared/lib/hooks';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { DebugInfo } from 'components/DebugInfo';
import { ProjectActions } from 'components/ProjectActions';
import { Phase, Project as ProjectModel } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isReadOnly } from 'utils';
import { ProjectView } from './components/ProjectView';
import { ProjectViewBaseLicense } from './components/ProjectViewBaseLicense';

export const Project = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { id = '', projectId = '' } = useParams();

  const { accessToken } = useContext(AuthenticationContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { setTitle } = useTitle();

  const [project, setProject] = useState<ProjectModel>();

  const phases = Object.keys(Phase).map((value) => value as Phase);
  const {
    data: projectsOverview,
    isLoading,
    isFetching,
  } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: {
            portfolioIds: [id],
            phases,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: portfolio,
    isLoading: isLoadingPortfolio,
    isFetching: isFetchingPortfolio,
  } = useGetPortfolioOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: { portfolioIds: [id] },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleBack = () => {
    if (!accessToken.isBaseLicense) {
      navigate(
        (location.state as { from: string })?.from ??
          `/portfolio/${portfolio?.portfolioId}`
      );
    } else {
      navigate((location.state as { from: string })?.from ?? `/projects`);
    }
  };

  useEffect(() => {
    if (projectsOverview?.projects && projectId) {
      const selected = projectsOverview.projects.find(
        (p) => p.id === projectId
      );

      setProject(selected);
      setTitle(`${t('Project.Project')} - ${selected?.title}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsOverview, projectId]);

  return (
    <>
      <Style>
        <div>
          <BackButton handleBack={handleBack} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Heading1>{project?.title}</Heading1>

          {portfolio && !accessToken.isBaseLicense && (
            <ButtonLink
              onClick={() => navigate(`/portfolio/${portfolio.portfolioId}`)}
            >
              {portfolio.portfolioName}
            </ButtonLink>
          )}
        </div>

        {project && portfolio && !isReadOnly(project) && (
          <>
            <ViewHeaderActions style={{ marginBottom: 0 }}>
              <ProjectActions
                project={project}
                showLargeIcons={true}
                userCanEditPortfolio={!isReadOnly(portfolio)}
              />
            </ViewHeaderActions>
          </>
        )}
      </Style>

      <DebugInfo portfolio={portfolio} project={project} />

      <CardFocused>
        <CardFocusedBody
          isLoading={
            isFetching || isLoading || isLoadingPortfolio || isFetchingPortfolio
          }
        >
          {!accessToken.isBaseLicense ? (
            <ProjectView project={project} />
          ) : (
            <ProjectViewBaseLicense project={project} />
          )}
        </CardFocusedBody>
      </CardFocused>
    </>
  );
};

Project.displayName = 'Project';

const Style = styled(ViewHeader)`
  > div:last-of-type {
    @media screen and (max-width: ${Breakpoints.small}) {
      width: 100%;
      justify-content: start;
    }
  }
`;
