import {
  Input,
  InputNumber,
  Select,
} from '@dimatech/shared/lib/components/form';
import { DimensionDisplayType, ProjectDimensionMutation } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const ProjectDimensionEdit = ({
  handleChange,
  dimension,
  isLabelShown = true,
  style,
}: {
  handleChange: (value: string, dimension: ProjectDimensionMutation) => void;
  dimension: ProjectDimensionMutation;
  isLabelShown?: boolean;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      {dimension.displayType === DimensionDisplayType.Text && (
        <Input
          id={`dimension_${dimension.projectDimensionId}`}
          type="text"
          style={{
            width: '100%',
          }}
          value={dimension.value ?? ''}
          onChange={(e) => {
            handleChange(e.currentTarget.value, dimension);
          }}
        />
      )}

      {dimension.displayType === DimensionDisplayType.Numeric && (
        <InputNumber
          id={`dimension_${dimension.projectDimensionId}`}
          style={{
            width: '100%',
            maxWidth: 100,
          }}
          value={dimension.value ?? ''}
          onValueChange={({ value }) => {
            handleChange(value, dimension);
          }}
        />
      )}

      {(dimension.displayType === DimensionDisplayType.List ||
        dimension.displayType === DimensionDisplayType.Rating) && (
        <Select
          name={`dimension_${dimension.projectDimensionId}`}
          id={`dimension_${dimension.projectDimensionId}`}
          value={dimension?.value ?? ''}
          onChange={(e) => {
            handleChange(e.currentTarget.value, dimension);
          }}
          style={{ width: '100%', maxWidth: 250 }}
        >
          <option value="">{t('Project.Dimension.SelectValue')}</option>

          {dimension.values?.map((value) => (
            <option key={value.id} value={value.name}>
              {value.name}
            </option>
          ))}
        </Select>
      )}
    </>
  );
};
