import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Select } from '@dimatech/shared/lib/components/form';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const ProjectPhaseEdit = ({
  project,
  handleChange,
  isViewReadOnly,
}: {
  project: Project;
  handleChange: (pase?: Phase | null) => void;
  isViewReadOnly?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

  return (
    <Select
      name="projectPhase"
      id="projectPhase"
      value={project.projectPhase ?? undefined}
      onChange={(e) => {
        handleChange(
          e.currentTarget.value ? (e.currentTarget.value as Phase) : null
        );
      }}
      style={{ minWidth: 170, width: '100%' }}
      disabled={isViewReadOnly}
    >
      <option value={Phase.NotSet} />
      {Object.keys(Phase)
        .filter(
          (value) =>
            value !== Phase.NotSet &&
            // Filter out effect realisation phases if effect realisation flag is off
            (isProjectEffectRealisationEnabledFlagOn ||
              (value !== Phase.FinishedEffectRealizationStarted &&
                value !== Phase.FinishedEffectRealizationConcluded))
        )
        .filter((key) =>
          accessToken.isBaseLicense
            ? key !== Phase.ReadyForReview
            : isProjectIdeasReviewersEnabledFlagOn
            ? key !== Phase.Published
            : key !== Phase.ReadyForReview
        )
        .map((value) => (
          <option key={value} value={value}>
            {
              // Display different name for Finished phase if effect realisation is enabled
              isProjectEffectRealisationEnabledFlagOn &&
              value === Phase.Finished
                ? t(`Project.Phase.FinishedEffectRealization`)
                : t(`Project.Phase.${value}`)
            }
          </option>
        ))}
    </Select>
  );
};

ProjectPhaseEdit.displayName = 'ProjectPhaseEdit';
