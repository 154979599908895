import { YearPicker } from '@dimatech/shared/lib/components/form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDate } from 'utils';

export const SelectPeriodRange = ({
  period,
  setPeriod,
  placeholder,
  isAnyAllowed,
  inverted = false,
}: {
  period?: { from?: number; to?: number };
  setPeriod: ({ from, to }: { from?: number; to?: number }) => void;
  placeholder?: string;
  isAnyAllowed?: boolean;
  inverted?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const normalise = (date?: number): string =>
    !date || isNaN(date)
      ? isAnyAllowed
        ? undefined
        : formatDate(new Date(), 'date')
      : formatDate(new Date(date, 1, 1), 'date');

  const normalised = {
    from: normalise(period?.from),
    to: normalise(period?.to),
  };

  return (
    <Style>
      <YearPicker
        inverted={inverted}
        date={normalised.from}
        setDate={(date: Date | null) => {
          setPeriod({
            ...period,
            from: date ? date.getFullYear() : undefined,
          });
        }}
        style={{ marginRight: 0 }}
        placeholder={t('Search.FromYear')}
      />
      -
      <YearPicker
        inverted={inverted}
        date={normalised.to}
        setDate={(date: Date | null) => {
          setPeriod({
            ...period,
            to: date ? date.getFullYear() : undefined,
          });
        }}
        style={{ margin: 0 }}
        placeholder={t('Search.ToYear')}
      />
    </Style>
  );
};

SelectPeriodRange.displayName = 'SelectPeriodRange';

const Style = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  input {
    max-width: 70px !important;
  }

  > div:first-of-type input {
    margin-right: 0;
  }
`;
