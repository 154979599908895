import { AlertLowPrio } from '@dimatech/shared/lib/components/Alert';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Permission, PortfolioOverview, Project, ProjectIdea } from 'models';
import { useState } from 'react';
import { BsCaretDown, BsCaretUp } from 'react-icons/bs';
import { hasPermission, isReadOnly } from 'utils';

export const DebugInfo = ({
  project,
  idea,
  portfolio,
}: {
  project?: Project | null;
  idea?: ProjectIdea | null;
  portfolio?: PortfolioOverview | null;
}): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isShowDevDebugInfoFlagOn =
    useFlags()[flags.permanent.shared.showDevDebugInfo];

  if (!isShowDevDebugInfoFlagOn) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <AlertLowPrio
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: 'pointer' }}
        >
          <div>{isExpanded ? <BsCaretUp /> : <BsCaretDown />} DEBUG INFO</div>

          {isExpanded && (
            <>
              {portfolio && (
                <div>
                  portfolio.isReadOnly:
                  {isReadOnly(portfolio).toString()}
                </div>
              )}

              {project && (
                <>
                  <div>
                    project.isReadOnly: {isReadOnly(project).toString()}
                  </div>
                  <div>
                    project.canBeConverted:{' '}
                    {hasPermission(Permission.Convert, project).toString()}
                  </div>
                  <div>
                    isUserOnlyEffectRealisationResponsible:{' '}
                    {(
                      !hasPermission(Permission.Edit, project) &&
                      hasPermission(Permission.EditEffectRealization, project)
                    ).toString()}
                  </div>
                </>
              )}

              {idea && (
                <>
                  <div>
                    idea.isReadOnly:
                    {isReadOnly(idea).toString()}
                  </div>
                  <div>
                    idea.canReview:
                    {hasPermission(Permission.Review, idea).toString()}
                  </div>
                  <div>
                    idea.canArchive:
                    {hasPermission(Permission.Archive, idea).toString()}
                  </div>
                  <div>
                    idea.canBeConverted:{' '}
                    {hasPermission(Permission.Convert, idea).toString()}
                  </div>
                  <div>
                    isUserOnlyEffectRealisationResponsible:{' '}
                    {(
                      !hasPermission(Permission.EditIdea, idea) &&
                      hasPermission(Permission.EditEffectRealization, idea)
                    ).toString()}
                  </div>
                </>
              )}
            </>
          )}
        </AlertLowPrio>
      </CardBody>
    </Card>
  );
};

DebugInfo.displayName = 'DebugInfo';
