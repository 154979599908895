import { ProjectStatus } from 'components/ProjectStatus';
import { Project, ProjectStatus as ProjectStatusEnum } from 'models';

export const ProjectStatusEdit = ({
  project,
  setProject,
}: {
  project: Project;
  setProject: (project: Project) => void;
}): JSX.Element | null => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          height: 40,
          width: 90,
          fontSize: 18,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ProjectStatus
          style={{ cursor: 'pointer' }}
          handleClick={(projectStatus) =>
            setProject({
              ...project,
              projectStatus,
            })
          }
        />
        <ProjectStatus
          isSelected={project.projectStatus === ProjectStatusEnum.Three}
          status={ProjectStatusEnum.Three}
          style={{ cursor: 'pointer' }}
          handleClick={(projectStatus) =>
            setProject({
              ...project,
              projectStatus,
            })
          }
        />
        <ProjectStatus
          isSelected={project.projectStatus === ProjectStatusEnum.Two}
          status={ProjectStatusEnum.Two}
          style={{ cursor: 'pointer' }}
          handleClick={(projectStatus) =>
            setProject({
              ...project,
              projectStatus,
            })
          }
        />
        <ProjectStatus
          isSelected={project.projectStatus === ProjectStatusEnum.One}
          status={ProjectStatusEnum.One}
          style={{ cursor: 'pointer' }}
          handleClick={(projectStatus) =>
            setProject({
              ...project,
              projectStatus,
            })
          }
        />
      </div>
    </div>
  );
};

ProjectStatusEdit.displayName = 'ProjectStatusEdit';
