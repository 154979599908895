import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { UnsavedChangesWarning } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetGoalsQuery,
  useUpdateGoalMutation,
} from 'api/project/projectGoalApi';
import { GoalBenefits } from 'features/project-goal/components/GoalBenefits';
import produce from 'immer';
import { Permission, ProjectEffectRealisation, ProjectGoal } from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFlag } from 'react-icons/bs';
import styled from 'styled-components';
import { formatAsCurrency, hasPermission, isPiosReadOnly } from 'utils';
import { calculateGoalEffectRealisation } from 'utils/project-effect';

export const ProjectGoalList = ({
  project,
}: {
  project: ProjectEffectRealisation;
}): JSX.Element => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);
  const canEditExpected = hasPermission(Permission.Edit, project);
  const canEditActual = hasPermission(
    Permission.EditEffectRealization,
    project
  );

  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isUnsavedChangesWarningShown, setIsUnsavedChangesWarningShown] =
    useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState<string>();
  const [goals, setGoals] = useState<ProjectGoal[]>([]);

  const [updateGoal, { isLoading: isPosting, isSuccess }] =
    useUpdateGoalMutation();

  const { data, isFetching } = useGetGoalsQuery(
    accessToken.customerId && project.projectId
      ? {
          projectId: project.projectId,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handleSelectGoal = (toGoalId: string) => {
    if (toGoalId === selectedGoalId) {
      setSelectedGoalId(undefined);
    } else {
      setSelectedGoalId(toGoalId);
    }
  };

  const handleChange = (goal: ProjectGoal) => {
    const index = goals.findIndex((item) => item.id === goal.id);

    const newGoals = produce(goals, (draftState) => {
      draftState[index] = { ...goal };
    });

    setHasChanges(true);
    setGoals(calculateGoalEffectRealisation(newGoals));
  };

  const handleSave = () => {
    if (!canEditExpected && !canEditActual) {
      return;
    }

    if (!selectedGoalId || isViewReadOnly) {
      return;
    }

    const goal = goals.find((item) => item.id === selectedGoalId);

    updateGoal({ ...goal });
  };

  const handleConfirmCancel = () => {
    if (hasChanges) {
      setIsUnsavedChangesWarningShown(true);
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsUnsavedChangesWarningShown(false);
    setSelectedGoalId(undefined);
    setHasChanges(false);
    setGoals(data ?? []);
  };

  useEffect(() => {
    if (data) {
      setGoals(data);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setHasChanges(false);
    }
  }, [isSuccess]);

  if (isFetching) {
    return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
  }

  return (
    <>
      {goals && goals.length === 0 && <div>{t('Portfolio.Goal.NoGoals')}</div>}

      {isUnsavedChangesWarningShown && (
        <UnsavedChangesWarning
          handleContinue={handleCancel}
          handleCancel={() => setIsUnsavedChangesWarningShown(false)}
        />
      )}

      {goals && goals.length > 0 && (
        <TableResponsiveContainer>
          <TableStyle>
            {goals && goals.length > 0 && (
              <thead>
                <tr>
                  <Th style={{ width: 25 }} />

                  <Th>{t('Portfolio.Goal.Name')}</Th>

                  <ThRight style={{ width: 130 }}>
                    {t('ImplementedProjects.ExpectedBenefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight style={{ width: 110 }}>
                    {t('ImplementedProjects.ActualBenefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight style={{ width: 110 }}>
                    {t('ImplementedProjects.Deviation', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight style={{ width: 110 }}>
                    {t('ImplementedProjects.TakeHomePeriodYear')}
                  </ThRight>
                </tr>
              </thead>
            )}
            <tbody>
              {goals?.map((goal) => {
                return (
                  <Fragment key={goal.id}>
                    <Tr
                      isExpanded={selectedGoalId === goal.id}
                      isExpandable={true}
                      onSelect={() => {
                        handleSelectGoal(goal.id as string);
                      }}
                    >
                      <TdCentered className="narrow">
                        <BsFlag />
                      </TdCentered>

                      <Td>{goal.name}</Td>

                      <TdRight>
                        {formatAsCurrency(goal.totalExpectedBenefit)}
                      </TdRight>
                      <TdRight>
                        {formatAsCurrency(goal.totalActualBenefit)}
                      </TdRight>

                      <TdRight style={{ width: 80 }}>
                        <div
                          style={{
                            display: 'flex',
                            gap: 5,
                            justifyContent: 'flex-end',
                          }}
                        >
                          {formatAsCurrency(goal.deviation)}
                        </div>
                      </TdRight>
                      <TdRight>{goal.takeHomePeriod}</TdRight>
                    </Tr>

                    {selectedGoalId === goal.id && (
                      <Tr>
                        <BenefitTableStyleCell colSpan={100}>
                          <div>
                            <GoalBenefits
                              goal={goal}
                              handleChangeGoal={handleChange}
                              canEditExpected={canEditExpected}
                              canEditActual={canEditActual}
                            />
                          </div>

                          {(canEditExpected || canEditActual) && (
                            <ButtonFooterWithToast
                              isSaved={isChangesSaved}
                              setIsSaved={setIsChangesSaved}
                            >
                              <Buttons>
                                <ButtonSecondary
                                  type="button"
                                  onClick={handleConfirmCancel}
                                >
                                  {t('Common.Form.Cancel')}
                                </ButtonSecondary>
                                <Button
                                  type="button"
                                  disabled={
                                    isViewReadOnly || isPosting || !hasChanges
                                  }
                                  onClick={handleSave}
                                >
                                  {t('Common.Form.Save')}
                                </Button>
                              </Buttons>
                            </ButtonFooterWithToast>
                          )}
                        </BenefitTableStyleCell>
                      </Tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </TableStyle>
        </TableResponsiveContainer>
      )}
    </>
  );
};

ProjectGoalList.displayName = 'ProjectGoalList';

const TableStyle = styled(Table)`
  margin-bottom: 30px;

  > tbody {
    > tr {
      height: 50px;

      > td {
        vertical-align: middle;
      }
    }
  }
`;

const BenefitTableStyleCell = styled(Td)`
  padding: 0 0 50px 0;
  border: none;

  > div {
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.surface};
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSurface};

    padding: 10px;
  }
`;
