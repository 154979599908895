import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PortfolioHeader } from './components/PortfolioHeader';
import { PortfolioProjectsList } from './components/PortfolioProjectsList';

export const MyPortfolio = (): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);
  const { id = '' } = useParams();

  const { data: portfolio } = useGetPortfolioOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: { portfolioIds: [id] },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  return (
    <>
      {portfolio && <PortfolioHeader portfolio={portfolio} />}

      <PortfolioProjectsList />
    </>
  );
};

MyPortfolio.displayName = 'MyPortfolio';
