import { Admin } from '@dimatech/shared/lib/models';
import { CacheTags, piosApi } from 'api/piosApi';
import { SearchAdministrateUsers } from 'models';

const portfolioOwnerApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get portfolio owners
     */

    getPortfolioOwners: build.query<
      Admin[],
      {
        filter: SearchAdministrateUsers;
        _customerId?: string | undefined;
      }
    >({
      query: ({ filter, _customerId }) =>
        `portfolios/owners?1=1${filter.entityIds
          ?.map((entity) => `&entityIds=${entity}`)
          .join('')}${filter.email ? `&email=${filter.email}` : ''}`,
      providesTags: [CacheTags.PortfolioOwner],
    }),

    /**
     * Add portfolio owner
     */
    addPortfolioOwner: build.mutation({
      query: ({
        portfolioId,
        owner,
      }: {
        portfolioId: string;
        owner: string;
      }) => ({
        url: `portfolios/${portfolioId}/owners`,
        method: 'PUT',
        body: { owners: [owner] },
      }),
      invalidatesTags: [
        CacheTags.Portfolio,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOwner,
        CacheTags.PortfolioHistory,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     * Delete portfolio owner
     */
    deletePortfolioOwner: build.mutation({
      query: ({
        portfolioId,
        owner,
      }: {
        portfolioId: string;
        owner: string;
      }) => ({
        url: `portfolios/${portfolioId}/owners`,
        method: 'DELETE',
        body: { owners: [owner] },
      }),
      invalidatesTags: [
        CacheTags.Portfolio,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOwner,
        CacheTags.PortfolioHistory,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg,
        };
      },
    }),

    /**
     * Remove all portfolio ownership for user
     */
    removePortfolioOwnership: build.mutation({
      query: ({
        owner,
        entityId,
        includeChildEntities,
      }: {
        owner: string;
        entityId?: string;
        includeChildEntities: boolean;
      }) => ({
        url: `portfolios/owners`,
        method: 'DELETE',
        body: { owner, entityId, includeChildEntities },
      }),
      invalidatesTags: [
        CacheTags.PortfolioOwner,
        CacheTags.PortfolioHistory,
        CacheTags.Portfolio,
      ],
    }),
  }),
});

export const {
  useAddPortfolioOwnerMutation,
  useDeletePortfolioOwnerMutation,
  useRemovePortfolioOwnershipMutation,
  useGetPortfolioOwnersQuery,
  useLazyGetPortfolioOwnersQuery,
} = portfolioOwnerApi;
