import { tagActions } from '@dimatech/features-core/lib/api/tag/tagSlice';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { dimensionActions } from 'api/dimension/dimensionSlice';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Calculations } from './Calculations';
import { Classifications } from './Classifications';
import { Dimensions } from './Dimensions';
import { Tags } from './Tags';

enum TabIndexes {
  Tags = 0,
  Dimensions = 1,
  Classifications = 2,
  Calculations = 3,
}

export const AdministrateCustomDimensions = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('classifications')
      ? TabIndexes.Classifications
      : location.pathname.endsWith('calculations')
      ? TabIndexes.Calculations
      : location.pathname.endsWith('tags')
      ? TabIndexes.Tags
      : TabIndexes.Dimensions
  );

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    dispatch(dimensionActions.selectDimension());
    dispatch(dimensionActions.selectClassification());
    dispatch(tagActions.selectedTag());

    navigate(
      `/administrate/custom-${
        index === TabIndexes.Classifications
          ? 'classifications'
          : index === TabIndexes.Calculations
          ? 'calculations'
          : index === TabIndexes.Dimensions
          ? 'dimensions'
          : 'tags'
      }`
    );
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.CustomDimension.Header')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.Dimensions}
          handleSelect={() => handleSelectTab(TabIndexes.Dimensions)}
        >
          {t('Administrate.CustomDimension.Title')}
        </Tab>
        {isTagsEnabledFlagOn && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.Tags}
            handleSelect={() => handleSelectTab(TabIndexes.Tags)}
            style={{ minWidth: 80 }}
          >
            {t('Administrate.Tag.Tags')}
          </Tab>
        )}

        <Tab
          isSelected={selectedTabIndex === TabIndexes.Classifications}
          handleSelect={() => handleSelectTab(TabIndexes.Classifications)}
        >
          {t('Administrate.CustomDimension.TitleClassification')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.Calculations}
          handleSelect={() => handleSelectTab(TabIndexes.Calculations)}
        >
          {t('Administrate.Calculation.Calculations')}
        </Tab>
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === TabIndexes.Dimensions && <Dimensions />}

          {selectedTabIndex === TabIndexes.Tags && isTagsEnabledFlagOn && (
            <Tags />
          )}

          {selectedTabIndex === TabIndexes.Classifications && (
            <Classifications />
          )}

          {selectedTabIndex === TabIndexes.Calculations && <Calculations />}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateCustomDimensions.displayName = 'AdministrateCustomDimensions';
