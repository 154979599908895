import { Portfolios as PortfoliosFeature } from 'features/portfolio/Portfolios';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Portfolios = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Portfolios.Title')}>
      <PortfoliosFeature />
    </View>
  );
};

Portfolios.displayName = 'Portfolios';
