import { AllFeatureFlags } from '@dimatech/shared/lib/components/dev-tools';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const GlobalAdministrateAllFeatureFlags = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.FeatureFlag.Title')}>
      <AllFeatureFlags />
    </View>
  );
};

GlobalAdministrateAllFeatureFlags.displayName =
  'GlobalAdministrateAllFeatureFlags';
