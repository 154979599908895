import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
  Input,
  Label,
  MultiSelect,
  Select,
  TextArea,
} from '@dimatech/shared/lib/components/form';
import {
  useAddDimensionMutation,
  useUpdateDimensionMutation,
} from 'api/dimension/dimensionApi';
import {
  dimensionActions,
  selectSelectedDimension,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dimension, DimensionDisplayType, Phase } from 'models';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  hasDuplicates,
  isAdminReadOnly,
  projectIdeaPhases,
  projectOngoingPhases,
} from 'utils';
import { DimensionListValues } from './DimensionListValues';
import { DimensionPropertiesEditButtons } from './DimensionPropertiesEditButtons';

/* eslint-disable max-lines-per-function */

export const DimensionProperties = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addDimension, { isLoading: addPosting, error: addErrors }] =
    useAddDimensionMutation();
  const [updateDimension, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateDimensionMutation();

  const selectedDimension = useAppSelector(selectSelectedDimension);
  const [dimension, setDimension] = useState<Dimension | undefined>();
  const isAdding = selectedDimension && !selectedDimension.id;
  const isPosting = updatePosting || addPosting;

  const [isValid, setIsValid] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>();

  const options = [Phase.NotSet, Phase.Draft];

  const optionsProjectTypes = options
    .map((value) => ({
      id: value as Phase,
      name:
        value === Phase.NotSet
          ? t(`Project.ProjectType.Regular`)
          : t(`Project.ProjectType.Idea`),
      parentId: null,
      payload:
        value === Phase.NotSet ? projectOngoingPhases : projectIdeaPhases,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const handleSave = async () => {
    if (isReadOnly) {
      return;
    }

    setIsValid(true);
    setValidationErrors(undefined);

    if (
      !dimension?.name ||
      !dimension?.displayType ||
      dimension?.projectPhases?.length === 0
    ) {
      setIsValid(false);
      return;
    }

    if (dimension.displayType === DimensionDisplayType.List) {
      if (!dimension.values || dimension.values.length === 0) {
        setIsValid(false);
        setValidationErrors(['MissingValues']);
        return;
      }

      const gotDuplicates = hasDuplicates(
        dimension.values,
        (a, b) => a.name === b.name
      );

      if (gotDuplicates) {
        setValidationErrors(['DuplicateValues']);
        setIsValid(false);
        return;
      }

      const hasEmptyValue = dimension.values.some(
        (value) => !value.name?.trim()
      );

      if (hasEmptyValue) {
        setValidationErrors(['EmptyValue']);
        setIsValid(false);
        return;
      }
    }

    if (dimension?.id) {
      updateDimension({
        ...dimension,
      })
        .unwrap()
        .then(() => {
          dispatch(
            dimensionActions.selectDimension({
              ...dimension,
            })
          );
          setIsChangesSaved(true);
          setHasChanges(false);
        });

      return;
    }

    addDimension({
      ...dimension,
    })
      .unwrap()
      .then((result) => {
        dispatch(
          dimensionActions.selectDimension({
            ...dimension,
            ...result,
          })
        );
        setIsChangesSaved(true);
        setHasChanges(false);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const handleCancel = () => {
    dispatch(dimensionActions.selectDimension());
  };

  const handleSelectType = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    setHasChanges(true);
    setDimension({
      ...dimension,
      displayType: e.currentTarget.value as DimensionDisplayType,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === 'Enter') {
      handleSave();
    }

    if (e?.key === 'Escape') {
      handleCancel();
    }
  };

  useEffect(() => {
    setDimension(
      selectedDimension
        ? {
            ...selectedDimension,
            values: selectedDimension.values?.map((value) => ({
              ...value,
              uid: value.id ?? nanoid(6),
            })),
          }
        : undefined
    );
  }, [selectedDimension]);

  return (
    <>
      <div style={{ marginBottom: 20, maxWidth: 500 }}>
        {t('Administrate.CustomDimension.Help')}
      </div>

      <AlertErrors
        error={addErrors ?? updateErrors}
        customValidationErrors={validationErrors}
        translationPath="Administrate.CustomDimension.ValidationError"
      />

      <Label htmlFor="name"> {t('Administrate.CustomDimension.Name')}</Label>
      <Input
        maxLength={100}
        autoFocus
        type="text"
        id="name"
        name="name"
        placeholder={t('Administrate.CustomDimension.NamePlaceholder')}
        value={dimension?.name ?? ''}
        invalid={!isValid && !dimension?.name}
        style={{ width: '100%', maxWidth: 400 }}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setHasChanges(true);
          setDimension({
            ...dimension,
            name: e.currentTarget.value,
          });
        }}
      />

      <Label htmlFor="type">
        {t('Administrate.CustomDimension.ProjectType')}
      </Label>
      <MultiSelect
        style={{ marginRight: 10 }}
        placeholderAll={t('Project.ProjectType.All')}
        placeholder={t('Administrate.CustomDimension.ProjectTypePlaceholder')}
        options={optionsProjectTypes}
        setSelected={(selectedNodes) => {
          const phases = selectedNodes.flatMap(
            (phase) => phase.payload as Phase[]
          );

          setHasChanges(true);
          setDimension({
            ...dimension,
            projectPhases: phases,
          });
        }}
        isValid={dimension?.projectPhases && dimension.projectPhases.length > 0}
        showSelectAll={false}
        selected={optionsProjectTypes.filter((type) =>
          type.payload.some((payloadItem) =>
            dimension?.projectPhases?.includes(payloadItem)
          )
        )}
      />

      <Label style={{ marginTop: 30 }} htmlFor="displayType">
        {t('Administrate.CustomDimension.Type')}
      </Label>
      <Select
        name="displayType"
        id="displayType"
        value={dimension?.displayType ?? ''}
        onChange={handleSelectType}
        disabled={!!dimension?.id}
        invalid={!isValid && !dimension?.displayType}
        style={{ width: '100%', maxWidth: 300 }}
      >
        <option value="">{t('Administrate.CustomDimension.SelectType')}</option>

        {Object.values(DimensionDisplayType)
          // TODO: Allow rating in the future
          .filter((value) => value !== DimensionDisplayType.Rating)
          .map((value) => (
            <option key={value} value={value}>
              {t(`Administrate.CustomDimension.DimensionDisplayType.${value}`)}
            </option>
          ))}
      </Select>

      {dimension?.id && (
        <div className="size-s i">
          {t('Administrate.CustomDimension.TypeCannotBeChanged')}
        </div>
      )}

      <Label style={{ marginTop: 30 }} htmlFor="description">
        {t('Administrate.CustomDimension.Description')}
      </Label>
      <div className="size-s i">
        {t('Administrate.CustomDimension.DescriptionInfo')}
      </div>
      <TextArea
        id="description"
        name="description"
        value={dimension?.description ?? ''}
        onChange={(e) => {
          setHasChanges(true);
          setDimension({
            ...dimension,
            description: e.currentTarget.value,
          });
        }}
      />

      {dimension?.displayType === DimensionDisplayType.List && (
        <DimensionListValues
          dimension={dimension}
          setDimension={setDimension}
          setHasChanges={setHasChanges}
        />
      )}

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && dimension && (
          <Buttons style={{ justifyContent: 'flex-start' }}>
            <DimensionPropertiesEditButtons
              item={dimension}
              setSelectedItem={setDimension}
              isReadOnly={isReadOnly}
            />
          </Buttons>
        )}

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={isReadOnly || isPosting || !hasChanges}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

DimensionProperties.displayName = ' DimensionProperties';
