import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { ProjectStatus as ProjectStatusEnum } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const ProjectStatus = withTheme(
  ({
    theme,
    status,
    isSelected = false,
    isLabelShown = false,
    style,
    handleClick,
  }: {
    theme: Theme;
    status?: ProjectStatusEnum | null;
    isSelected?: boolean;
    isLabelShown?: boolean;
    style?: CSSProperties;
    handleClick?: (status: ProjectStatusEnum | null | undefined) => void;
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <Style
        style={{
          borderBottom: `3px solid ${
            isSelected ? theme.colors.secondary : 'transparent'
          }`,
        }}
      >
        <TooltipContent id="tt-status-zero" text={t('Project.Status.Zero')} />
        <TooltipContent id="tt-status-one" text={t('Project.Status.One')} />
        <TooltipContent id="tt-status-two" text={t('Project.Status.Two')} />
        <TooltipContent id="tt-status-three" text={t('Project.Status.Three')} />

        {(status === ProjectStatusEnum.Zero || !status) && (
          <BsCircle
            data-tip
            data-for="tt-status-zero"
            style={style}
            onClick={() => handleClick?.(ProjectStatusEnum.Zero)}
          />
        )}

        {status === ProjectStatusEnum.One && (
          <BsCircleFill
            data-tip
            data-for="tt-status-one"
            style={{ ...style, color: Color.RadicalRedLight }}
            onClick={() => handleClick?.(status)}
          />
        )}

        {status === ProjectStatusEnum.Two && (
          <BsCircleFill
            data-tip
            data-for="tt-status-two"
            style={{ ...style, color: Color.KojiOrangeLight }}
            onClick={() => handleClick?.(status)}
          />
        )}

        {status === ProjectStatusEnum.Three && (
          <BsCircleFill
            data-tip
            data-for="tt-status-three"
            style={{ ...style, color: Color.CaribbeanGreenLight }}
            onClick={() => handleClick?.(status)}
          />
        )}

        {isLabelShown && (
          <span style={{ marginLeft: 7 }}>
            {t(`Project.Status.${status ?? ProjectStatusEnum.Zero}`)}
          </span>
        )}
      </Style>
    );
  }
);

ProjectStatus.displayName = 'ProjectStatus';

const Style = styled.div`
  svg {
    min-width: 16px;
    margin-right: 0 !important;
    vertical-align: middle;
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
  }
`;
