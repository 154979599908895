import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Label } from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { selectFilter } from 'api/piosSlice';
import { useAppSelector } from 'hooks';
import { ProjectIdea } from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAsCurrency, formatAsNumber } from 'utils';
import { IdeaStatus } from './IdeaStatus';

export const IdeaListQualifyEffectRealisation = ({
  ideas,
}: {
  ideas: ProjectIdea[];
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const {
    items: sortedIdeas,
    setPage,
    paginator,
    sorter,
  } = useUiSortablePaginator({
    orderBy: 'title',
    pageSize: 25,
    data: {
      totalRecords: ideas.length,
      records: ideas,
    },
  });

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <AlertWarning>
        {t('FutureProjects.ProjectIdea.QualifyDeprecatedWarning')}
      </AlertWarning>

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        {t('FutureProjects.ProjectIdea.QualifyText')}
      </div>

      {ideas.length === 0 && (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {t('FutureProjects.ProjectIdea.NoProjectIdeas')}
        </div>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={ideas.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />

      <TableResponsiveContainer>
        <Style>
          {sortedIdeas.length > 0 && (
            <thead>
              <tr>
                <ThCentered
                  className="narrow"
                  sortKey="projectPhase"
                  sorter={sorter}
                  style={{ width: 50 }}
                >
                  {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                </ThCentered>
                <Th
                  className="narrow"
                  sortKey="externalId"
                  sorter={sorter}
                  style={{ width: 60 }}
                >
                  {t('Project.Id')}
                </Th>
                <Th>
                  <SortElements>
                    <SortElement sortKey="title" sorter={sorter}>
                      {t('FutureProjects.ProjectIdea.ProjectIdea')}
                    </SortElement>
                    <SortElement sortKey="portfolioName" sorter={sorter}>
                      {t('Portfolio.Portfolio')}
                    </SortElement>
                  </SortElements>
                </Th>
                <ThRight
                  sortKey="takeHomePeriod"
                  sorter={sorter}
                  style={{ width: 180 }}
                >
                  {t('FutureProjects.ProjectIdea.TakeHomePeriodYear')}
                </ThRight>
                <ThRight
                  sortKey="benefit"
                  sorter={sorter}
                  style={{ width: 150 }}
                >
                  {t('FutureProjects.ProjectIdea.Benefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                <ThRight sortKey="cost" sorter={sorter} style={{ width: 150 }}>
                  {t('FutureProjects.ProjectIdea.Cost', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                <ThRight
                  sortKey="netBenefit"
                  sorter={sorter}
                  style={{ width: 150 }}
                >
                  {t('FutureProjects.ProjectIdea.NetBenefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                <ThRight
                  sortKey="netBenefitYearly"
                  sorter={sorter}
                  style={{ width: 150 }}
                >
                  {t('FutureProjects.ProjectIdea.NetBenefitPerYear', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
              </tr>
            </thead>
          )}

          <tbody>
            {sortedIdeas.map((idea) => (
              <Tr key={idea.id}>
                <TdCentered className="narrow">
                  <IdeaStatus status={idea.projectPhase} />
                </TdCentered>
                <Td className="narrow">{idea.externalId}</Td>
                <Td>
                  <div className="b">{idea.title}</div>
                  <div className="i">{idea.portfolioName}</div>
                </Td>

                <TdRight>{idea.takeHomePeriod}</TdRight>
                <TdRight>
                  {formatAsCurrency(idea.benefit)}
                  <Label
                    className="size-s b"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    {t('FutureProjects.ProjectIdea.RiskPercentage')}
                  </Label>
                  {formatAsNumber(idea.benefitRisk, '0')}
                </TdRight>
                <TdRight>
                  {formatAsCurrency(idea.cost)}
                  <Label
                    className="size-s b"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    {t('FutureProjects.ProjectIdea.RiskPercentage')}
                  </Label>
                  {formatAsNumber(idea.costRisk, '0')}
                </TdRight>
                <TdRight>{formatAsCurrency(idea.netBenefit)}</TdRight>
                <TdRight>{formatAsCurrency(idea.netBenefitYearly)}</TdRight>
              </Tr>
            ))}
          </tbody>
        </Style>
      </TableResponsiveContainer>

      <Pagination
        currentPage={paginator.page}
        totalCount={ideas.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />
    </>
  );
};

IdeaListQualifyEffectRealisation.displayName =
  'IdeaListQualifyEffectRealisation';

const Style = styled(Table)`
  .field {
    display: flex;
    align-items: flex-start;

    > div {
      padding: 0 20px 0 0;
    }
  }

  input {
    margin-top: 0;
    margin-bottom: 0;
  }

  td > input:first-of-type {
    margin-top: 3px;
  }

  td > input:last-of-type {
    margin-bottom: 3px;
  }

  label {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
