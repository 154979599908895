import { Color, Theme } from '@dimatech/shared/lib/themes';
import styled, { CSSProperties, withTheme } from 'styled-components';

export const IdeaProgress = withTheme(
  ({
    value,
    theme,
    color,
    backgroundColor,
    singleValue,
    formatter,
    width = '80px',
    showEmpty = false,
    style,
  }: {
    value: number | undefined | null;
    theme: Theme;
    color?: string;
    backgroundColor?: string;
    singleValue?: boolean;
    formatter?: (value: number) => string | null;
    width?: string | number;
    showEmpty?: boolean;
    style?: CSSProperties;
  }): JSX.Element | null => {
    if (showEmpty && (value === undefined || value === null)) {
      return (
        <Centered style={style}>
          <div
            style={{
              width,
              border: `1px solid ${theme.colors.border}`,
            }}
          />
        </Centered>
      );
    }

    if (value === undefined || value === null) {
      return null;
    }

    return (
      <Centered style={style}>
        <div
          style={{
            width,
            backgroundColor: backgroundColor || theme.colors.secondaryDim,
          }}
        >
          <div
            style={{
              width: `${(value / 5) * 100}%`,
              backgroundColor: color || Color.CaribbeanGreenLight,
            }}
          />
          <div
            className="size-xs"
            style={{
              justifyContent: singleValue ? 'center' : 'space-between',
            }}
          >
            <span>{formatter ? formatter(value) : `${Math.round(value)}`}</span>
            {!singleValue && (
              <span>
                {formatter
                  ? formatter(100 - value)
                  : `${100 - Math.round(value)}`}
              </span>
            )}
          </div>
        </div>
      </Centered>
    );
  }
);

IdeaProgress.displayName = 'IdeaProgress';

const Centered = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  div {
    height: 25px;
  }

  > div {
    width: 130px;
    border-radius: 3px;

    > div:first-of-type {
      position: relative;
      border-radius: 3px 0 0 3px;
    }

    > div:last-of-type {
      top: -22px;
      position: relative;
      display: flex;
      padding: 0 10px;
      color: ${Color.White};
    }
  }
`;
