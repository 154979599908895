import { AdministrateFeatureFlag as AdministrateFeatureFlagFeature } from 'features/administrate-feature-flag';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateFeatureFlag = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.FeatureFlag.Title')}>
      <AdministrateFeatureFlagFeature />
    </View>
  );
};

AdministrateFeatureFlag.displayName = 'AdministrateFeatureFlag';
