import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filterToPortfolioQuery } from 'api/utils';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { PiosFilter } from 'models';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getPortfolioExportBundle = createAsyncThunk(
  'portfolio/exportBundle/get',
  async ({
    filter,
    bundleName,
  }: {
    filter: PiosFilter;
    bundleName: string;
  }) => {
    const url = `${config.apiUrl.product.pios}portfolios/export/bundle?1=1`;
    const filters = `${filterToPortfolioQuery(
      filter
    )}&filter.includeIdeas=true`;

    return axios({
      url: `${url}${filters}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = `${bundleName}.xlsx`;

      const file = new File([response.data], fileName);

      saveAs(file, fileName);
    });
  }
);

export const {
  reducer: portfolioExportBundleReducer,
  actions: portfolioExportBundleActions,
} = createSlice({
  name: 'portfolio/exportBundle/get',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getPortfolioExportBundle.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getPortfolioExportBundle.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getPortfolioExportBundle.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectPortfolioExportBundleContentError = (
  state: RootState
): string | null | undefined => state.portfolioExportBundle.error;

export const selectPortfolioExportBundleIsPosting = (
  state: RootState
): boolean => state.portfolioExportBundle.isPosting;
