var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertSuccess } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import { Button, ButtonFooterWithToast, ButtonSecondary, Buttons, Input, Label, } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { config } from '@dimatech/shared/lib/config';
import { getLocale } from '@dimatech/shared/lib/localization';
import { extractTextFromHTML } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CommonRoles } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useGetPreviewCommonMessageTemplateQuery, useSendPreviewCommonMessageTemplateMutation, useUpdateCommonMessageTemplateMutation, } from '../../../api/message-template/commonMessageTemplateApi';
import { messageTemplateActions, selectSelectedMessageTemplate, } from '../../../api/message-template/messageTemplateSlice';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { MessageTemplatePreviewModal } from './MessageTemplatePreviewModal';
import { SendMessagePreviewModal } from './SendMessagePreviewModal';
export var CommonMessageTemplateProperties = withTheme(
// eslint-disable-next-line max-lines-per-function
function (_a) {
    var _b, _c, _d, _e, _f;
    var theme = _a.theme;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isReadOnly = !accessToken.isInRole([
        CommonRoles.GlobalAdmin,
        CommonRoles.CustomerAccountAdmin,
    ]);
    var _g = useUpdateCommonMessageTemplateMutation(), updateTemplate = _g[0], _h = _g[1], isPosting = _h.isLoading, error = _h.error;
    var _j = useSendPreviewCommonMessageTemplateMutation(), sendTemplate = _j[0], _k = _j[1], isPostingSendTemplate = _k.isLoading, errorSendTemplate = _k.error, isSuccessSendTemplate = _k.isSuccess;
    var selectedMessageTemplate = useSelector(selectSelectedMessageTemplate);
    var _l = useState(), messageTemplate = _l[0], setMessageTemplate = _l[1];
    var _m = useState(true), isValid = _m[0], setIsValid = _m[1];
    var _o = useState(true), isTextValid = _o[0], setIsTextValid = _o[1];
    var _p = useState(true), isSignatureValid = _p[0], setIsSignatureValid = _p[1];
    var _q = useState(false), isPreviewing = _q[0], setIsPreviewing = _q[1];
    var _r = useState(false), isShowingSendMessagePreviewModal = _r[0], setIsShowingSendMessagePreviewModal = _r[1];
    var _s = useState(false), hasUnsavedChanges = _s[0], setHasUnsavedChanges = _s[1];
    var _t = useState(false), isChangesSaved = _t[0], setIsChangesSaved = _t[1];
    var messageTemplateBody = extractTextFromHTML((_b = messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.body) !== null && _b !== void 0 ? _b : '');
    var messageTemplateSignature = extractTextFromHTML((_c = messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.signature) !== null && _c !== void 0 ? _c : '');
    var _u = useGetPreviewCommonMessageTemplateQuery(isPreviewing && messageTemplate
        ? {
            locale: "".concat(getLocale()),
            messageTemplateId: messageTemplate.messageTemplateId,
        }
        : skipToken), dataPreview = _u.data, isFetching = _u.isFetching;
    var handleConfirmSave = function () {
        setIsValid(true);
        setIsTextValid(true);
        setIsSignatureValid(true);
        var limitInBytes = config.ui.textEditor.maxBytes;
        var textBytesMessageTemplateBody = new TextEncoder().encode(messageTemplateBody);
        var textBytesMessageTemplateSignature = new TextEncoder().encode(messageTemplateSignature);
        if (textBytesMessageTemplateBody.length > limitInBytes &&
            textBytesMessageTemplateSignature.length > limitInBytes) {
            setIsTextValid(false);
            setIsSignatureValid(false);
            return;
        }
        if (textBytesMessageTemplateBody.length > limitInBytes) {
            setIsTextValid(false);
            return;
        }
        if (textBytesMessageTemplateSignature.length > limitInBytes) {
            setIsSignatureValid(false);
            return;
        }
        if (!(messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.title) ||
            !messageTemplateBody ||
            !messageTemplateSignature) {
            setIsValid(false);
            return;
        }
        handleSave();
    };
    var handleSave = function () {
        if (isReadOnly || !(messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.messageTemplateId)) {
            return;
        }
        updateTemplate(__assign({}, messageTemplate));
        setIsChangesSaved(true);
        setHasUnsavedChanges(false);
    };
    var handleCancel = function (e) {
        dispatch(messageTemplateActions.selectedMessageTemplate());
        navigate('/administrate/message-template');
    };
    var handlePreview = function (e, show) {
        e.stopPropagation();
        setIsPreviewing(show);
    };
    var handleShowSend = function (e, show) {
        e.stopPropagation();
        setIsShowingSendMessagePreviewModal(show);
    };
    var handleSend = function (user) {
        if (messageTemplate) {
            sendTemplate({
                locale: "".concat(getLocale()),
                messageTemplateId: messageTemplate.messageTemplateId,
                email: user === null || user === void 0 ? void 0 : user.email,
            });
        }
        setIsShowingSendMessagePreviewModal(false);
    };
    useEffect(function () {
        setMessageTemplate(selectedMessageTemplate);
        setIsValid(true);
        setIsTextValid(true);
        setIsSignatureValid(true);
    }, [selectedMessageTemplate]);
    return (_jsxs("div", __assign({ style: { marginTop: 30, width: '100%' } }, { children: [isValid && (_jsx(AlertErrors, { error: error, translationPath: "Administrate.MessageTemplate.ValidationError" })), isSuccessSendTemplate && (_jsx(AlertSuccess, { children: t('Administrate.MessageTemplate.Send.Successful') })), errorSendTemplate && (_jsx(AlertErrors, { error: errorSendTemplate, translationPath: "Administrate.MessageTemplate.ValidationError" })), !isTextValid && (_jsx(AlertErrors, { customValidationErrors: ['BodyLimitExceeded'], translationPath: "Administrate.MessageTemplate.ValidationError" })), !isSignatureValid && (_jsx(AlertErrors, { customValidationErrors: ['SignatureLimitExceeded'], translationPath: "Administrate.MessageTemplate.ValidationError" })), messageTemplate && (_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "title" }, { children: t('Administrate.MessageTemplate.TemplateTitle') })), _jsx(Input, { type: "text", id: "title", name: "title", value: (_d = messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.title) !== null && _d !== void 0 ? _d : '', invalid: !isValid && !(messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.title), onChange: function (e) {
                            setMessageTemplate(__assign(__assign({}, messageTemplate), { title: e.currentTarget.value }));
                            setHasUnsavedChanges(true);
                        } }), _jsx(Label, __assign({ htmlFor: "body" }, { children: t('Administrate.MessageTemplate.TemplateBody') })), _jsx(TextEditorStyle, { children: _jsx(TextEditor, { value: (_e = messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.body) !== null && _e !== void 0 ? _e : '', setValue: function (val) {
                                setMessageTemplate(__assign(__assign({}, messageTemplate), { body: val }));
                                setHasUnsavedChanges(true);
                            }, messageId: messageTemplate.messageTemplateId, validateHtmlIsEmpty: true, style: {
                                borderBottomColor: !isValid && !messageTemplateBody
                                    ? theme.colors.error
                                    : theme.colors.secondary,
                            } }) }), _jsx(Label, __assign({ htmlFor: "signature" }, { children: t('Administrate.MessageTemplate.TemplateSignature') })), _jsx(TextEditorStyle, { children: _jsx(TextEditor, { value: (_f = messageTemplate === null || messageTemplate === void 0 ? void 0 : messageTemplate.signature) !== null && _f !== void 0 ? _f : '', setValue: function (val) {
                                setMessageTemplate(__assign(__assign({}, messageTemplate), { signature: val }));
                                setHasUnsavedChanges(true);
                            }, messageId: messageTemplate.messageTemplateId, validateHtmlIsEmpty: true, style: {
                                borderBottomColor: !isValid && !messageTemplateSignature
                                    ? theme.colors.error
                                    : theme.colors.secondary,
                            } }) })] })), _jsxs(ButtonFooterWithToast, __assign({ isSaved: isChangesSaved, setIsSaved: setIsChangesSaved }, { children: [_jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handlePreview(e, true); } }, { children: t('Administrate.MessageTemplate.Preview.Preview') })), _jsx(ButtonSecondary, __assign({ type: "button", disabled: isReadOnly || isPostingSendTemplate, onClick: function (e) { return handleShowSend(e, true); } }, { children: t('Administrate.MessageTemplate.Send.Send') }))] }), isShowingSendMessagePreviewModal && messageTemplate && (_jsx(SendMessagePreviewModal, { setIsShowingSendMessagePreviewModal: setIsShowingSendMessagePreviewModal, hasUnsavedChanges: hasUnsavedChanges, handleSend: handleSend })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", disabled: isReadOnly || isPosting || !hasUnsavedChanges, onClick: handleConfirmSave }, { children: t('Common.Form.Save') }))] })] })), isFetching && _jsx(LoaderOverlay, { children: t('Common.UI.Loading') }), isPreviewing && messageTemplate && dataPreview && !isFetching && (_jsx(MessageTemplatePreviewModal, { setIsPreviewing: setIsPreviewing, messageTemplate: messageTemplate, data: dataPreview }))] })));
});
CommonMessageTemplateProperties.displayName = 'CommonMessageTemplateProperties';
var TextEditorStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div {\n    padding: 10px;\n    border: 1px solid ", ";\n  }\n"], ["\n  > div {\n    padding: 10px;\n    border: 1px solid ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
});
var templateObject_1;
