import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { Theme } from '@dimatech/shared/lib/themes';
import i18n from 'localization';
import { ProjectIdea } from 'models';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Label,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled, { withTheme } from 'styled-components';
import { IdeaScatterChartTooltip } from './IdeaScatterChartTooltip';

export const IdeaScatterChart = withTheme(
  ({ theme, ideas }: { theme: Theme; ideas: ProjectIdea[] }): JSX.Element => {
    const { t } = useTranslation();

    const formatter = (value: string) =>
      `${(+value).toLocaleString(i18n.language)}`;

    return ideas.length === 0 ? (
      <div>{t('Portfolio.NoProjectsInPortfolio')}</div>
    ) : (
      <Style>
        <Chart>
          <ScatterChart>
            <CartesianGrid
              stroke={theme.colors.chart.axisStroke}
              strokeWidth={0.5}
            />
            <XAxis
              type="number"
              dataKey="quotaStake"
              name={t(
                'Administrate.CustomDimension.DimensionClassificationType.Stake'
              )}
              domain={[1, 5]}
              tickCount={5}
              tickLine={false}
              tickFormatter={formatter}
              stroke={theme.colors.chart.axisStroke}
            >
              <Label
                value={
                  t(
                    'Administrate.CustomDimension.DimensionClassificationType.Stake'
                  ) as string
                }
                position="insideBottom"
                offset={-5}
                fill={theme.colors.onSurface}
              />
            </XAxis>

            <YAxis
              type="number"
              dataKey="quotaBenefit"
              name={t(
                'Administrate.CustomDimension.DimensionClassificationType.Benefit'
              )}
              domain={[1, 5]}
              tickCount={5}
              tickLine={false}
              tickFormatter={formatter}
              stroke={theme.colors.chart.axisStroke}
            >
              <Label
                value={
                  t(
                    'Administrate.CustomDimension.DimensionClassificationType.Benefit'
                  ) as string
                }
                offset={15}
                position="insideLeft"
                angle={-90}
                fill={theme.colors.onSurface}
              />
            </YAxis>

            {ideas.map((item, index) => {
              return (
                <Scatter
                  key={index}
                  data={[item]}
                  name={item.title}
                  fill={
                    theme.colors.chart.colors[
                      index % theme.colors.chart.colors.length
                    ]
                  }
                  opacity={0.8}
                />
              );
            })}
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<IdeaScatterChartTooltip />}
            />
          </ScatterChart>
        </Chart>
      </Style>
    );
  }
);

IdeaScatterChart.displayName = 'IdeaScatterChart';

const Style = styled.div`
  padding: 10px;
  padding-top: 20px;
  padding-left: 0;
  display: flex;
  justify-content: center;

  > div {
    height: 210px;
    width: 230px;
  }
`;
