import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeLarge, BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import {
  useDeleteProjectIdeaMutation,
  useMoveProjectIdeaMutation,
} from 'api/project/projectIdeaApi';
import { SelectPortfolio } from 'components/SelectPortfolio';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PortfolioOverview, ProjectIdea, ProjectIdeaAction } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArrowsMove,
  BsClockHistory,
  BsPencil,
  BsTrash,
} from 'react-icons/bs';
import { isPiosReadOnly, isReadOnly } from 'utils';

export const IdeaListEditButtons = ({
  idea,
  showLargeIcons = false,
}: {
  idea: ProjectIdea;
  showLargeIcons?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const selectAndNavigate = useSelectAndNavigate();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [deleteProjectIdea] = useDeleteProjectIdeaMutation();
  const [moveProjectIdea] = useMoveProjectIdeaMutation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isCopying, setIsCopying] = useState(true);

  const [newPortfolioId, setNewPortfolioId] = useState<string>();
  const [portfolio, setPortfolio] = useState<PortfolioOverview>();

  const isHistoryEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isHistoryEnabled];

  const { data: portfoliosOverview } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: {},
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isViewReadOnly) {
      return;
    }

    setIsDeleting(false);

    deleteProjectIdea({ ...idea })
      .unwrap()
      .then(() => {
        selectAndNavigate(ProjectIdeaAction.Identify);
      });
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  const handleMove = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isViewReadOnly || !newPortfolioId) {
      return;
    }

    moveProjectIdea({
      portfolioId: idea.portfolioId as string,
      projectId: idea.projectId as string,
      newPortfolioId,
      copy: isCopying,
    })
      .unwrap()
      .then(() => {
        selectAndNavigate(ProjectIdeaAction.Identify);
      });

    setIsMoving(false);
  };

  const handleConfirmMove = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsMoving(show);
  };

  const handleEdit = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    selectAndNavigate(ProjectIdeaAction.Edit, idea);
  };

  const handleViewHistory = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!isHistoryEnabledFlagOn) {
      return;
    }

    selectAndNavigate(ProjectIdeaAction.History, idea);
  };

  useEffect(() => {
    const portfolio = portfoliosOverview?.portfolios.find(
      (portfolio) => portfolio.portfolioId === idea.portfolioId
    );

    setPortfolio(portfolio);
  }, [portfoliosOverview, idea]);

  return (
    <>
      {isDeleting && (
        <Modal title={t('FutureProjects.ProjectIdea.Delete.ConfirmTitle')}>
          <div>{t('FutureProjects.ProjectIdea.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isViewReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {isMoving && (
        <Modal
          title={t('FutureProjects.ProjectIdea.MoveOrCopy.Title')}
          handleKeyEnter={handleMove}
          handleKeyEsc={(e) => handleConfirmMove(e, false)}
        >
          <div>{t('FutureProjects.ProjectIdea.MoveOrCopy.Text')}</div>

          <SelectPortfolio
            setPortfolio={(portfolio) => setNewPortfolioId(portfolio?.id)}
            style={{ marginTop: 20 }}
          />

          <div>
            <Label htmlFor="copy">
              <Checkbox
                name="copy"
                id="copy"
                checked={isCopying}
                onChange={(e) => setIsCopying(e.currentTarget.checked)}
                style={{ marginRight: 10 }}
              />
              {t('FutureProjects.ProjectIdea.MoveOrCopy.Copy')}
            </Label>
          </div>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmMove(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={handleMove}
              disabled={
                (idea.portfolioId === newPortfolioId && !isCopying) ||
                !newPortfolioId
              }
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-project-edit"
        title={t('FutureProjects.ProjectIdea.Edit.TooltipTitle')}
        text={t('FutureProjects.ProjectIdea.Edit.Tooltip')}
      />
      <TooltipContent
        id="tt-project-delete"
        title={t('FutureProjects.ProjectIdea.Delete.TooltipTitle')}
        text={t('FutureProjects.ProjectIdea.Delete.Tooltip')}
      />
      <TooltipContent
        id="tt-project-move"
        title={t('FutureProjects.ProjectIdea.MoveOrCopy.TooltipTitle')}
        text={t('FutureProjects.ProjectIdea.MoveOrCopy.Tooltip')}
      />
      <TooltipContent
        id="tt-project-history"
        title={t('FutureProjects.ProjectIdea.History.TooltipTitle')}
        text={t('FutureProjects.ProjectIdea.History.Tooltip')}
      />

      {!showLargeIcons && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          {portfolio && !isReadOnly(portfolio) && (
            <BadgeMini
              data-tip
              data-for="tt-project-move"
              onClick={(e) => handleConfirmMove(e, true)}
            >
              <BsArrowsMove />
            </BadgeMini>
          )}

          <BadgeMini
            data-tip
            data-for="tt-project-edit"
            onClick={(e) => handleEdit(e)}
          >
            <BsPencil />
          </BadgeMini>

          {portfolio && !isReadOnly(portfolio) && (
            <BadgeMini
              data-tip
              data-for="tt-project-delete"
              onClick={(e) => handleConfirmDelete(e, true)}
            >
              <BsTrash />
            </BadgeMini>
          )}
        </div>
      )}

      {showLargeIcons && (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          {isHistoryEnabledFlagOn && (
            <BadgeLarge
              data-tip
              data-for="tt-project-history"
              onClick={(e) => handleViewHistory(e)}
              $inverted={true}
            >
              <BsClockHistory />
            </BadgeLarge>
          )}

          {portfolio && !isReadOnly(portfolio) && (
            <BadgeLarge
              data-tip
              data-for="tt-project-move"
              onClick={(e) => handleConfirmMove(e, true)}
              $inverted={true}
            >
              <BsArrowsMove />
            </BadgeLarge>
          )}

          <BadgeLarge
            data-tip
            data-for="tt-project-edit"
            onClick={(e) => handleEdit(e)}
            $inverted={true}
          >
            <BsPencil />
          </BadgeLarge>

          {portfolio && !isReadOnly(portfolio) && (
            <BadgeLarge
              data-tip
              data-for="tt-project-delete"
              onClick={(e) => handleConfirmDelete(e, true)}
              $inverted={true}
            >
              <BsTrash />
            </BadgeLarge>
          )}
        </div>
      )}
    </>
  );
};

IdeaListEditButtons.displayName = 'IdeaListEditButtons';
