import { Impersonate } from '@dimatech/features-core/lib/features/authentication/components';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useDeletePortfolioOwnerMutation } from 'api/portfolio/portfolioOwnerApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PortfolioMutation } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

export const PortfolioOwnersListEditButtons = ({
  portfolioId,
  owner,
  portfolio,
  setPortfolio,
  isReadOnly,
}: {
  portfolioId: string;
  owner: string;
  portfolio: PortfolioMutation;
  setPortfolio: React.Dispatch<
    React.SetStateAction<PortfolioMutation | undefined>
  >;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);

  const [deletePortfolioOwner] = useDeletePortfolioOwnerMutation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly || !portfolio.owners) {
      return;
    }

    const owners = [...portfolio.owners];
    const index = owners.findIndex((el) => el === owner);

    if (index > -1) {
      owners.splice(index, 1);

      setPortfolio({
        ...portfolio,
        owners,
      });

      if (portfolio.id) {
        deletePortfolioOwner({
          portfolioId,
          owner,
        })
          .unwrap()
          .then((result) => {
            if (selectedPortfolio?.owners) {
              const owners = selectedPortfolio.owners.filter(
                (owner) => owner !== result.owner
              );
              dispatch(
                portfolioActions.selectedPortfolio({
                  ...portfolio,
                  owners,
                })
              );
            }
          });
      }
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Portfolio.Owner.Delete.ConfirmTitle')}>
          <div>{t('Portfolio.Owner.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <Impersonate email={owner} />

      <TooltipContent
        id="tt-owner-delete"
        title={t('Portfolio.Owner.Delete.TooltipTitle')}
        text={t('Portfolio.Owner.Delete.Tooltip')}
      />

      <BadgeMini
        data-tip
        data-for="tt-owner-delete"
        onClick={(e) => handleConfirmDelete(e, true)}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};

PortfolioOwnersListEditButtons.displayName = 'PortfolioOwnersListEditButtons';
