import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { formatAsCurrency, formatAsNumber } from '@dimatech/shared/lib/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Calculation, CalculationType, Dimension, ProjectIdea } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ProjectDataTable } from './ProjectDataTable';

export const ProjectIdeaQualifyList = ({
  idea,
  classifications,
  calculations,
}: {
  idea: ProjectIdea;
  classifications: Dimension[];
  calculations?: Calculation[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  return (
    <>
      <Style>
        <div>
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>{t('FutureProjects.ProjectIdea.Quota')}</Td>
                <Td>{formatAsNumber(idea.quota, '-', 1)}</Td>
              </Tr>

              {calculations &&
                calculations.some((c) => c.type !== CalculationType.System) && (
                  <Tr>
                    <Td>
                      <HelpIconLeft>
                        {t('FutureProjects.ProjectIdea.Calculation')}

                        <HelpIcon
                          showTiny={true}
                          style={{ marginLeft: 5 }}
                          title={t('FutureProjects.ProjectIdea.Calculation')}
                          text={t(
                            'FutureProjects.ProjectIdea.CalculationDescription'
                          )}
                        />
                      </HelpIconLeft>
                    </Td>
                    <Td>
                      <HelpIconLeft>
                        {idea.calculation?.name ?? '-'}

                        {idea.calculation?.description && (
                          <HelpIcon
                            showTiny={true}
                            style={{ marginLeft: 5 }}
                            title={idea.calculation?.name}
                            text={idea.calculation?.description}
                          />
                        )}
                      </HelpIconLeft>
                    </Td>
                  </Tr>
                )}
            </tbody>
          </ProjectDataTable>
        </div>

        <div>
          {isProjectEffectRealisationEnabledFlagOn && (
            <AlertWarning>
              {t('FutureProjects.ProjectIdea.QualifyDataWarning')}
            </AlertWarning>
          )}

          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>{t('FutureProjects.ProjectIdea.TakeHomePeriodYear')}</Td>
                <Td>{formatAsNumber(idea.takeHomePeriod, '-', 0)}</Td>
              </Tr>
              <Tr>
                <Td style={{ paddingTop: 20 }}>
                  {t('FutureProjects.ProjectIdea.Cost', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </Td>
                <Td>{formatAsCurrency(idea.cost)}</Td>
              </Tr>
              <Tr>
                <Td>{t('FutureProjects.ProjectIdea.RiskPercentageCost')}</Td>
                <Td>{formatAsNumber(idea.costRisk, '0')}</Td>
              </Tr>
              <Tr>
                <Td style={{ paddingTop: 20 }}>
                  {t('FutureProjects.ProjectIdea.NetBenefit', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </Td>
                <Td>{formatAsCurrency(idea.netBenefit)}</Td>
              </Tr>
              <Tr>
                <Td>{t('FutureProjects.ProjectIdea.RiskPercentageBenefit')}</Td>
                <Td>{formatAsNumber(idea.benefitRisk, '0')}</Td>
              </Tr>
              <Tr>
                <Td style={{ paddingTop: 20 }}>
                  {t('FutureProjects.ProjectIdea.NetBenefitPerYear', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </Td>
                <Td>{formatAsCurrency(idea.netBenefitYearly)}</Td>
              </Tr>
            </tbody>
          </ProjectDataTable>
        </div>
      </Style>
    </>
  );
};

ProjectIdeaQualifyList.displayName = 'ProjectIdeaQualifyList';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > div {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
