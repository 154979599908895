import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { ProjectGoal } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

export const GoalEditListDelete = ({
  goal,
  handleDelete,
}: {
  goal: ProjectGoal;
  handleDelete: (goal: ProjectGoal) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t('Portfolio.Goal.Delete.ConfirmTitle')}
          handleKeyEsc={() => setIsDeleting(false)}
        >
          <div>{t('Portfolio.Goal.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={() => {
                setIsDeleting(false);
                handleDelete(goal);
              }}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-goal-delete"
        title={t('Portfolio.Goal.Delete.TooltipTitle')}
        text={t('Portfolio.Goal.Delete.Tooltip')}
      />

      <BadgeMini
        data-tip
        data-for="tt-goal-delete"
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginTop: 10 }}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};

GoalEditListDelete.displayName = 'GoalEditListDelete';
