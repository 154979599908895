import { CacheTags, piosApi } from 'api/piosApi';
import { Project, ProjectIdea } from 'models';

const projectIdeaApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Create new project idea
     */
    addProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/projects`,
        method: 'POST',
        body: {
          ...idea,
        },
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.PortfolioHistory,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
      ],
      transformResponse: (result: Project, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Update project idea
     */
    updateProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/projects/${idea.id}`,
        method: 'PUT',
        body: {
          ...idea,
        },
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
      ],
    }),

    /**
     * Convert project to project idea
     */
    convertToIdea: build.mutation({
      query: ({
        project,
        newPortfolioId,
      }: {
        project: Project;
        newPortfolioId?: string;
      }) => ({
        url: `portfolio/${project.portfolioId}/projects/${
          project.id
        }/convert/idea${
          newPortfolioId ? `?newPortfolioId=${newPortfolioId}` : ''
        }`,
        method: 'POST',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.ProjectHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Convert project idea to regular project
     */
    convertToProject: build.mutation({
      query: ({
        idea,
        newPortfolioId,
      }: {
        idea: ProjectIdea;
        newPortfolioId?: string;
      }) => ({
        url: `portfolio/${idea.portfolioId}/projects/${
          idea.id
        }/convert/project${
          newPortfolioId ? `?newPortfolioId=${newPortfolioId}` : ''
        }`,
        method: 'POST',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.ProjectHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Delete project idea
     */
    deleteProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/projects/${idea.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.PortfolioHistory,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
      ],
    }),

    /**
     * Move project
     */
    moveProjectIdea: build.mutation({
      query: ({
        portfolioId,
        projectId,
        newPortfolioId,
        copy,
      }: {
        portfolioId: string;
        projectId?: string;
        newPortfolioId?: string;
        copy?: boolean;
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/${newPortfolioId}?copy=${copy}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.PortfolioHistory,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          id: arg.projectId,
          newPortfolioId: arg.newPortfolioId,
        };
      },
    }),

    /**
     * Archive project idea
     */
    archiveProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/projects/${idea.id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectIdeaOverviewArchive,
        CacheTags.ProjectHistory,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
      ],
    }),

    /**
     * Draft project idea
     */
    draftProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/ideas/${idea.id}/draft`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
      ],
    }),

    /**
     * Mark idea as ready
     */
    readyProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/ideas/${idea.id}/ready`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
      ],
    }),

    /**
     * Mark idea as ready for review
     */
    readyForReviewProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/ideas/${idea.id}/review`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
      ],
    }),

    /**
     * Publish project idea
     */
    publishProjectIdea: build.mutation({
      query: (idea: ProjectIdea) => ({
        url: `portfolio/${idea.portfolioId}/ideas/${idea.id}/publish`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectHistory,
      ],
    }),

    /**
     * Review project idea
     */
    reviewProjectIdea: build.mutation({
      query: ({
        idea,
        newPortfolioId,
      }: {
        idea: ProjectIdea;
        convertToProject?: boolean;
        newPortfolioId?: string;
      }) => ({
        url: `portfolio/${idea.portfolioId}/projects/${idea.id}/review`,
        method: 'PUT',
        body: {
          customDimensions: idea.customDimensions,
          projectPhase: idea.projectPhase,
          newPortfolioId,
        },
      }),
      invalidatesTags: [
        CacheTags.ProjectIdeaOverview,
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.ProjectHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectTimeline,
      ],
    }),
  }),
});

export const {
  useAddProjectIdeaMutation,
  useUpdateProjectIdeaMutation,
  useDeleteProjectIdeaMutation,
  useMoveProjectIdeaMutation,
  useConvertToProjectMutation,
  useConvertToIdeaMutation,
  useArchiveProjectIdeaMutation,
  useDraftProjectIdeaMutation,
  usePublishProjectIdeaMutation,
  useReadyProjectIdeaMutation,
  useReadyForReviewProjectIdeaMutation,
  useReviewProjectIdeaMutation,
} = projectIdeaApi;
