import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectNote } from 'models';
import { RootState } from 'store';

const initialState: {
  selectedProjectNote?: ProjectNote;
} = {};

export const { reducer: projectNoteReducer, actions: projectNoteActions } =
  createSlice({
    name: 'projectNote',
    initialState,
    reducers: {
      selectProjectNote: (
        state,
        action: PayloadAction<ProjectNote | undefined>
      ) => {
        state.selectedProjectNote = action.payload;
      },
    },
  });

export const selectSelectedProjectNote = (
  state: RootState
): ProjectNote | undefined =>
  state.projectNote.selectedProjectNote || undefined;
