import { MonthPicker } from '@dimatech/shared/lib/components/form';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

export const SelectPeriodMonth = ({
  period,
  setPeriod,
}: {
  period?: number;
  setPeriod: (period?: number) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const date =
    !period || isNaN(period)
      ? ''
      : formatDate(new Date(currentYear, period - 1, 1), 'MM');

  return (
    <MonthPicker
      date={date}
      setDate={(date: Date | null) => {
        setPeriod(date ? date.getMonth() + 1 : undefined);
      }}
      style={{ marginRight: 0 }}
      placeholder={t('Search.AnyMonth')}
    />
  );
};

SelectPeriodMonth.displayName = 'SelectPeriodMonth';
