var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useContext } from 'react';
import { useGetEntitiesQuery } from '../api/entity/entityApi';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
import { SelectEntity } from './SelectEntity';
export var SelectEntityWithoutSurvey = function (props) {
    var _a, _b;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _c = useGetEntitiesQuery(accessToken.customerId && ((_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.id)
        ? { _customerId: accessToken.customerId, _userId: (_b = accessToken.user) === null || _b === void 0 ? void 0 : _b.id }
        : skipToken), data = _c.data, isLoading = _c.isLoading;
    return _jsx(SelectEntity, __assign({}, props, { data: data, isLoading: isLoading }));
};
SelectEntityWithoutSurvey.displayName = 'SelectEntityWithoutSurvey';
