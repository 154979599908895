import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useTranslation } from 'react-i18next';

export const IdeaReadyConfirmation = ({
  isReadying,
  handleSave,
}: {
  isReadying: boolean;
  handleSave: (isConfirmed: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (!isReadying) {
    return null;
  }

  return (
    <Modal
      title={t('FutureProjects.ProjectIdea.Ready.Title')}
      handleKeyEnter={() => handleSave(true)}
      handleKeyEsc={() => handleSave(false)}
    >
      <div>{t('FutureProjects.ProjectIdea.Ready.ConfirmText')}</div>

      <Buttons>
        <ButtonSecondary type="button" onClick={() => handleSave(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={() => handleSave(true)}>
          {t('Common.UI.Yes')}
        </Button>
      </Buttons>
    </Modal>
  );
};

IdeaReadyConfirmation.displayName = 'IdeaReadyConfirmation';
