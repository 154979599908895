import { UserAdd } from '@dimatech/features-core/lib/features/administrate-users/components/UserAdd';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Impersonate,
  SendForgotPasswordEmail,
} from '@dimatech/features-core/lib/features/authentication/components';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin } from '@dimatech/shared/lib/models';
import { CommonRoles } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonBadge, BsPersonPlus } from 'react-icons/bs';
import { isPiosReadOnly } from 'utils';
import { EffectRealisationResponsibleActionDelete } from './EffectRealisationResponsibleActionDelete';

export const EffectRealisationResponsibleList = ({
  effectRealisationResponsible,
  setEffectRealisationResponsible,
  setHasChanges,
  isEditAvailable = false,
}: {
  effectRealisationResponsible?: string[];
  setEffectRealisationResponsible?: (
    effectRealisationResponsible: string[]
  ) => void;
  setHasChanges?: (hasChanges: boolean) => void;
  isEditAvailable?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();

  const handleAdd = (user: Admin) => {
    setValidationErrors(undefined);

    if (isViewReadOnly || !isEditAvailable) {
      return;
    }

    if (!user?.email || user?.email?.includes(' ')) {
      setValidationErrors?.(['Email']);
      return;
    }

    if (effectRealisationResponsible?.some((ef) => ef === user.email)) {
      setValidationErrors(['ErrorSameEmail']);
      return;
    }

    setEffectRealisationResponsible?.([
      ...(effectRealisationResponsible ?? []),
      `${user.email}`,
    ]);
    setHasChanges?.(true);
  };

  const handleDelete = (userEmail: string) => {
    if (isViewReadOnly || !isEditAvailable) {
      return;
    }

    const projectEffectRealisationResponsible =
      effectRealisationResponsible?.filter(
        (effectRealisationResponsible) =>
          effectRealisationResponsible !== userEmail
      );

    setEffectRealisationResponsible?.(
      projectEffectRealisationResponsible ?? []
    );
    setHasChanges?.(true);
  };

  return (
    <>
      <div className="b" style={{ marginBottom: 10 }}>
        {t('UserRoles.PiosEffectRealizationResponsible.Title')}
      </div>

      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PiosEffectRealizationResponsible.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PiosEffectRealizationResponsible.Title')}
          text={
            <TransArray i18nKey="UserRoles.PiosEffectRealizationResponsible.Help" />
          }
        />
      </HelpIconLeft>

      {effectRealisationResponsible &&
        effectRealisationResponsible.length === 0 && (
          <div>
            {t(
              'Project.EffectRealisationResponsible.NoEffectRealisationResponsible'
            )}
          </div>
        )}

      {isEditAvailable && (
        <LinkWithTooltip
          isPrimary={true}
          handleClick={() => setIsAdding(true)}
          title={t('Project.EffectRealisationResponsible.Add.Title')}
          tooltipTitle={t(
            'Project.EffectRealisationResponsible.Add.TooltipTitle'
          )}
          tooltip={t('Project.EffectRealisationResponsible.Add.Tooltip')}
          icon={<BsPersonPlus />}
          style={{ marginTop: 20, marginBottom: 10 }}
        />
      )}

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(
                `Project.EffectRealisationResponsible.ValidationError.${validationError}`
              )}
            </div>
          ))}
        </AlertWarning>
      )}

      {isAdding && (
        <UserAdd
          setIsAdding={setIsAdding}
          handleAddUser={handleAdd}
          role={CommonRoles.PiosEffectRealizationResponsible}
          isEntityFilterShown={false}
        />
      )}

      {effectRealisationResponsible && effectRealisationResponsible.length > 0 && (
        <TableResponsiveContainer style={{ width: 'auto' }}>
          <Table style={{ marginTop: 15, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th>{t(`Project.Email`)}</Th>
                <ThRight />
              </tr>
            </thead>

            <tbody>
              {effectRealisationResponsible?.map((email, index) => (
                <Tr key={index}>
                  <Td style={{ width: 25 }}>
                    <BsPersonBadge />
                  </Td>
                  <Td>{email}</Td>

                  <TdRight>
                    <div
                      style={{
                        verticalAlign: 'middle',
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      {!isEditAvailable && (
                        <>
                          <Impersonate email={email} />
                          <SendForgotPasswordEmail email={email} />
                        </>
                      )}

                      {isEditAvailable && (
                        <EffectRealisationResponsibleActionDelete
                          email={email}
                          setValidationErrors={setValidationErrors}
                          handleDelete={handleDelete}
                        />
                      )}
                    </div>
                  </TdRight>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

EffectRealisationResponsibleList.displayName =
  'EffectRealisationResponsibleList';
