import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tag } from '@dimatech/shared/lib/components/Tag';
import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { SortableColumn } from '@dimatech/shared/lib/hooks';
import { Project, Tag as TagModel } from 'models';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsNumber } from 'utils';
import { ProjectPhase } from './ProjectPhase';

export const ProjectsTableBaseLicense = ({
  saveToPngElementId,
  items,
  sorter,
  handleSelect,
}: {
  saveToPngElementId?: string;
  items: Project[];
  sorter: SortableColumn<Project>;
  handleSelect: (toPortfolioId: string, toProjectId: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  return (
    <Table id={saveToPngElementId}>
      <thead>
        <tr>
          <Th className="narrow" sortKey="title" sorter={sorter}>
            {t('Project.Project')}
          </Th>

          <Th className="narrow" sortKey="projectPhase" sorter={sorter}>
            {t('Project.Phase.Phase')}
          </Th>

          <ThRight className="narrow" sortKey="budget" sorter={sorter}>
            {t('Project.Budget', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
          </ThRight>

          <Th>{t('Project.Tag.Tags')}</Th>
        </tr>
      </thead>

      <tbody>
        {items.map((project) => (
          <Fragment key={project.id}>
            <Tr
              onSelect={() =>
                handleSelect(
                  project.portfolioId as string,
                  project.id as string
                )
              }
            >
              <Td className="narrow">{project.title}</Td>

              <Td className="narrow">
                <ProjectPhase phase={project.projectPhase} />
              </Td>

              <TdRight className="narrow">
                {formatAsNumber(project.budget, null)}
              </TdRight>

              <Td>
                {[...(project.nationalTags ?? [])]
                  ?.sort((tagA, tagB) =>
                    tagA.displayName > tagB.displayName ? 1 : -1
                  )
                  .map((tag: TagModel) => (
                    <Tag key={tag.id} tag={tag} />
                  ))}
              </Td>
            </Tr>
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

ProjectsTableBaseLicense.displayName = 'ProjectsTableBaseLicense';
