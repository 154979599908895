import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { SelectPortfolio } from 'components/SelectPortfolio';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Permission, Phase, ProjectIdeaAction } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { isPiosReadOnly } from 'utils';

export const IdeaAdd = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const selectAndNavigate = useSelectAndNavigate();

  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [isAdding, setIsAdding] = useState(false);
  const [portfolioId, setPortfolioId] = useState<string>();

  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
  const isPublishPubliclyByDefaultEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyByDefaultEnabled];

  const handleAdd = () => {
    if (isViewReadOnly) {
      return;
    }
    setIsAdding(true);
  };

  const handleCreate = () => {
    if (!portfolioId) {
      return;
    }

    selectAndNavigate(
      ProjectIdeaAction.Create,
      {
        portfolioId,
        projectPhase: Phase.Draft,
        permissions: [Permission.EditIdea, Permission.EditEffectRealization],
        isPublishedPublicly:
          !!isPublishPubliclyEnabledFlagOn &&
          !!isPublishPubliclyByDefaultEnabledFlagOn,
      },
      portfolioId
    );

    setIsAdding(false);
  };

  return (
    <>
      {isAdding && (
        <Modal
          title={t('FutureProjects.ProjectIdea.Add.Title')}
          handleKeyEnter={handleCreate}
          handleKeyEsc={() => setIsAdding(false)}
        >
          <div>{t('FutureProjects.ProjectIdea.Add.Text')}</div>

          <SelectPortfolio
            setPortfolio={(portfolio) => setPortfolioId(portfolio?.id)}
            style={{ marginTop: 20 }}
          />

          <Buttons>
            <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={handleCreate}
              disabled={!portfolioId}
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <LinkWithTooltip
        isPrimary={true}
        isDisabled={isViewReadOnly}
        handleClick={handleAdd}
        title={t('FutureProjects.ProjectIdea.Add.Title')}
        tooltipTitle={t('FutureProjects.ProjectIdea.Add.TooltipTitle')}
        tooltip={t('FutureProjects.ProjectIdea.Add.Tooltip')}
        icon={<BsPlus />}
        style={{ marginTop: 20 }}
      />
    </>
  );
};

IdeaAdd.displayName = 'IdeaAdd';
