import { CacheTags, piosApi } from 'api/piosApi';
import { Admin, SearchAdministrateUsers } from 'models';

const projectOrderingPartyApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get all project ordering parties
     */
    getOrderingParties: build.query<
      Admin[],
      {
        filter: SearchAdministrateUsers;
        _customerId: string;
      }
    >({
      query: ({ filter, _customerId }) =>
        `portfolio/projects/orderingparties?1=1${
          filter.email ? `&email=${filter.email}` : ''
        }${
          filter.portfolioIds && filter.portfolioIds.length > 0
            ? filter.portfolioIds
                .map((portfolio) => `&portfolioIds=${portfolio}`)
                .join('')
            : ''
        }`,
      providesTags: [CacheTags.OrderingParty],
    }),

    /**
     * Create project ordering party
     */
    addOrderingParty: build.mutation({
      query: ({
        portfolioId,
        projectId,
        projectUsers,
      }: {
        portfolioId: string;
        projectId: string;
        projectUsers: string[];
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/orderingparties`,
        method: 'PUT',
        body: { projectUsers },
      }),
      invalidatesTags: [
        CacheTags.OrderingParty,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Delete project ordering party
     */
    deleteOrderingParty: build.mutation({
      query: ({
        portfolioId,
        projectId,
        projectUsers,
      }: {
        portfolioId: string;
        projectId: string;
        projectUsers: string[];
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/orderingparties`,
        method: 'DELETE',
        body: { projectUsers },
      }),
      invalidatesTags: [
        CacheTags.OrderingParty,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Remove project ordering party from all projects
     */
    removeOrderingParty: build.mutation({
      query: ({
        portfolioId,
        projectUser,
      }: {
        portfolioId?: string;
        projectUser: string;
      }) => ({
        url: `portfolio/projects/orderingparties`,
        method: 'DELETE',
        body: {
          portfolioId,
          projectUser,
        },
      }),
      invalidatesTags: [
        CacheTags.OrderingParty,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),
  }),
});

export const {
  useGetOrderingPartiesQuery,
  useLazyGetOrderingPartiesQuery,
  useAddOrderingPartyMutation,
  useDeleteOrderingPartyMutation,
  useRemoveOrderingPartyMutation,
} = projectOrderingPartyApi;
