import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { Statistics } from 'components/Statistics';
import { StatisticsByYear } from 'components/StatisticsByYear';
import { StatisticsForBenefits } from 'components/StatisticsForBenefits';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';

export const PortfoliosKeyStatistics = (): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const { data, isFetching } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const { data: projects, isFetching: isFetchingProjects } =
    useGetProjectsOverviewQuery(
      accessToken.customerId && accessToken.user?.id
        ? {
            filter,
            _customerId: accessToken.customerId,
            _userId: accessToken.user.id,
          }
        : skipToken
    );

  if (data && data.portfolios.length === 0) {
    return null;
  }

  return (
    <Card
      style={{
        paddingTop: 10,
      }}
    >
      <CardBody
        isLoading={isFetching || isFetchingProjects}
        style={{ padding: 0 }}
      >
        {data && projects && (
          <>
            <Statistics
              data={{
                ...data.additionalData,
                projectsCount: projects.projects.length,
              }}
            />

            {filter.period !== undefined && (
              <StatisticsByYear
                data={{
                  ...projects.additionalData,
                  projectsCount: projects.projects.length,
                }}
              />
            )}

            {isProjectEffectRealisationEnabledFlagOn && (
              <StatisticsForBenefits
                data={{
                  ...projects.additionalData,
                  projectsCount: projects.projects.length,
                }}
              />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

PortfoliosKeyStatistics.displayName = 'PortfoliosKeyStatistics';
