import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TagMutation } from '@dimatech/shared/lib/models';
import { useDeleteNationalTagMutation } from 'api/nationalTag/nationalTagApi';
import { nationalTagActions } from 'api/nationalTag/nationalTagSlice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const TagPropertiesEditButtons = ({
  item,
  setSelectedItem,
  isReadOnly,
  handleDeleted,
}: {
  item: TagMutation;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<TagMutation | undefined>
  >;
  isReadOnly: boolean;
  handleDeleted?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteTag] = useDeleteNationalTagMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    deleteTag({ ...item })
      .unwrap()
      .then(() => handleDeleted?.());

    setSelectedItem(undefined);
    dispatch(nationalTagActions.selectedNationalTag());

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('GlobalAdministrate.NationalTag.Delete.ConfirmTitle')}>
          <div>{t('GlobalAdministrate.NationalTag.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <ButtonSecondary
        type="button"
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginLeft: '0' }}
      >
        {t('Common.Form.Delete')}
      </ButtonSecondary>
    </>
  );
};

TagPropertiesEditButtons.displayName = 'TagPropertiesEditButtons';
