import {
  Calculation,
  Dimension,
  DimensionCalculation,
  DimensionDisplayType,
  DimensionType,
  Phase,
  Project,
  ProjectDimensionMutation,
  ProjectIdea,
} from 'models';

/**
 * Merge custom dimensions with project dimensions to create one array of dimensions
 * that includes the value of the project dimension and the custom dimension
 * @param dimensions
 * @param project
 * @returns
 */
export const mergeDimensions = (
  dimensions: Dimension[],
  isProject: boolean,
  project?: Project,
  dimensionType?: DimensionType
): ProjectDimensionMutation[] => {
  return dimensions
    .filter(
      (dimension) =>
        (isProject
          ? dimension.projectPhases?.includes(Phase.NotSet)
          : dimension.projectPhases?.includes(Phase.Draft)) &&
        (!dimensionType || dimension.type === dimensionType)
    )
    .map((dimension) => {
      const projectDimension = project?.customDimensions?.find(
        (pd) => pd.projectDimensionId === dimension.id
      );

      return {
        projectDimensionId: dimension.id,
        ...dimension,
        value: projectDimension?.value,
      } as ProjectDimensionMutation;
    });
};

/**
 * Merge dimension with calculations
 * @param dimension
 * @param calculations
 * @returns
 */
export const mergeDimensionCalculations = (
  dimension: Dimension,
  calculations: Calculation[]
): DimensionCalculation[] => {
  return calculations.map(
    (calculation) =>
      ({
        calculationId: calculation.id,
        calculationName: calculation.name,
        calculationDescription: calculation.description,
        weight: dimension.calculationWeights?.find(
          (cw) => cw.calculationId === calculation.id
        )?.weight,
      } as DimensionCalculation)
  );
};

export const calculateProjectIdeasQuota = ({
  ideas,
  dimensions,
  calculations,
}: {
  ideas: ProjectIdea[];
  dimensions: Dimension[];
  calculations?: Calculation[];
}) => {
  const calculated = ideas.map((idea) => {
    const quotas = calculateProjectIdeaQuotas({
      idea,
      dimensions,
    });

    const calculation = calculations
      ? calculations.find(
          (calculation) => calculation.id === idea.calculationId
        )
      : undefined;

    return { ...idea, calculation, ...quotas };
  });

  return calculated;
};

export const calculateProjectIdeaQuotas = ({
  idea,
  dimensions,
}: {
  idea: ProjectIdea;
  dimensions: Dimension[];
}) => {
  const sum = idea.customDimensions?.reduce(
    (sum, projectDimension) => {
      const dimension = dimensions.find(
        (dimension) => dimension.id === projectDimension.projectDimensionId
      );

      if (!dimension || !projectDimension.value) {
        return sum;
      }

      if (
        dimension.type === DimensionType.Data ||
        dimension.displayType !== DimensionDisplayType.Rating
      ) {
        return sum;
      }

      if (isNaN(parseInt(projectDimension.value))) {
        return sum;
      }

      const calculationWeight = dimension.calculationWeights
        ? dimension.calculationWeights.find(
            (calculationWeight) =>
              calculationWeight.calculationId === idea.calculationId
          )?.weight
        : undefined;

      const weight = calculationWeight ?? 1;
      const value = +projectDimension.value;

      if (dimension.type === DimensionType.ClassificationBenefit) {
        return {
          ...sum,
          benefit: {
            value: value * weight + sum.benefit.value,
            weight: weight + sum.benefit.weight,
          },
        };
      }

      if (dimension.type === DimensionType.ClassificationStake) {
        return {
          ...sum,
          stake: {
            value: value * weight + sum.stake.value,
            weight: weight + sum.stake.weight,
          },
        };
      }

      return sum;
    },
    {
      benefit: {
        value: 0,
        weight: 0,
      },
      stake: {
        value: 0,
        weight: 0,
      },
    }
  ) ?? {
    benefit: {
      value: 0,
      weight: 1,
    },
    stake: {
      value: 0,
      weight: 1,
    },
  };

  const quotaBenefit =
    sum.benefit.value / (sum.benefit.weight === 0 ? 1 : sum.benefit.weight);
  const quotaStake =
    sum.stake.value / (sum.stake.weight === 0 ? 1 : sum.stake.weight);

  const quota = quotaBenefit - quotaStake;

  return {
    quota,
    quotaBenefit,
    quotaStake,
  };
};
