import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tags } from '@dimatech/shared/lib/components/Tag';
import { flags } from '@dimatech/shared/lib/feature-flags';
import {
  useAddProjectNationalTagMutation,
  useDeleteProjectNationalTagMutation,
} from 'api/project/projectNationalTagApi';
import { SelectNationalTag } from 'components/SelectNationalTag';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Project, Tag } from 'models';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isPiosReadOnly } from 'utils';
import { ProjectPublishedPubliclyIcon } from './ProjectPublishedPubliclyIcon';

export const ProjectNationalTagList = ({
  canEdit,
  handleChangeTag,
  project,
}: {
  canEdit: boolean;
  handleChangeTag?: (tags: Tag[]) => void;
  project: Project;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const tags = project.nationalTags;
  const projectId = project.id as string;
  const portfolioId = project.portfolioId as string;

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const [deleteProjectNationalTag] = useDeleteProjectNationalTagMutation();
  const [addProjectNationalTag] = useAddProjectNationalTagMutation();

  const handleDeleteTag = (tag: Tag) => {
    if (isViewReadOnly) {
      return;
    }

    deleteProjectNationalTag({
      portfolioId,
      projectId,
      tag,
    })
      .unwrap()
      .then(() => {
        handleChangeTag?.(tags?.filter((t) => t.id !== tag.id) ?? []);
      });
  };

  const handleAddTag = (
    e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => {
    if (isViewReadOnly || !tag) {
      return;
    }

    addProjectNationalTag({
      portfolioId,
      projectId,
      tag,
    })
      .unwrap()
      .then(() => {
        handleChangeTag?.([...(tags ?? []), tag]);
      });

    e.currentTarget.selectedIndex = 0;
  };

  if (!isAreasOfOperationEnabledFlagOn) {
    return null;
  }

  return (
    <>
      <div className="b" style={{ marginBottom: 10, display: 'flex' }}>
        {t('Project.NationalTag.Tags')}
        <ProjectPublishedPubliclyIcon project={project} />
      </div>

      <div style={{ marginBottom: 10 }}>{t('Project.NationalTag.Help')}</div>

      {tags && tags.length === 0 && (
        <div>{t('Project.NationalTag.NoTags')}</div>
      )}

      {tags && tags.length > 0 && (
        <Tags onDelete={canEdit ? handleDeleteTag : undefined} tags={tags} />
      )}

      {canEdit && (
        <SelectNationalTag
          onSelectNationalTag={handleAddTag}
          style={{ minWidth: 150, width: 'fit-content', marginTop: 10 }}
          placeholder={t('Project.NationalTag.Add')}
        />
      )}
    </>
  );
};

ProjectNationalTagList.displayName = 'ProjectNationalTagList';
