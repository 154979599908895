import { FeatureFlag } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureFlag';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import screenshot from './feature-flag-period-month-1.png';
import screenshot2 from './feature-flag-period-month-2.png';

export const FeatureProjectPeriodMonth = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Style>
          <Trans i18nKey="Administrate.FeatureFlag.Features.ProjectPeriodMonth.Description" />

          <Image style={{ maxWidth: 180 }}>
            <img
              src={screenshot}
              alt={t(
                'Administrate.FeatureFlag.Features.GoalAiEvaluation.Title'
              )}
            />
          </Image>

          <Image style={{ maxWidth: 550 }}>
            <img
              src={screenshot2}
              alt={t(
                'Administrate.FeatureFlag.Features.GoalAiEvaluation.Title'
              )}
            />
          </Image>
        </Style>

        <FeatureFlag
          featureFlagKey={flags.permanent.app.pios.isStartAndEndMonthEnabled}
        />
      </CardBody>
    </Card>
  );
};

FeatureProjectPeriodMonth.displayName = 'FeatureProjectPeriodMonth';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Image = styled.div`
  max-height: 300px;
  margin: 10px;
  box-shadow: ${(props) => props.theme.boxShadows.default};

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`;
