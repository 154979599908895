var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, TableResponsiveContainer, Td, TdCentered, TdRight, Th, ThCentered, ThRight, Tr, } from '@dimatech/shared/lib/components/table';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillKeyFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { customerApiKeyActions, selectSelectedApiKey, } from '../../../api/customer/customerApiKeySlice';
import { ApiKeyEditFields } from './ApiKeyEditFields';
import { ApiKeysListEditButtons } from './ApiKeysListEditButtons';
export var ApiKeysList = function (_a) {
    var apiKeys = _a.apiKeys, isAdding = _a.isAdding, setIsAdding = _a.setIsAdding, setError = _a.setError;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var selectedApiKey = useSelector(selectSelectedApiKey);
    var handleSelect = function (item) {
        dispatch(customerApiKeyActions.selectedApiKey((selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.id) === item.id ? undefined : item));
    };
    return (_jsxs(_Fragment, { children: [apiKeys && apiKeys.length === 0 && (_jsx("div", __assign({ style: { marginTop: 15, marginBottom: 10 } }, { children: t('Administrate.Account.Api.NoApiKey') }))), apiKeys && apiKeys.length > 0 && (_jsx(TableResponsiveContainer, { children: _jsxs(Table, __assign({ style: { marginTop: 15, marginBottom: 10 } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Th, {}), _jsx(Th, { children: t('Administrate.Account.Api.Name') }), _jsx(ThCentered, { children: t('Administrate.Account.Api.Description') }), _jsx(ThCentered, { children: t('Administrate.Account.Api.Roles') }), _jsx(ThRight, { children: t('Administrate.Account.Api.Key') }), _jsx(Th, {})] }) }), _jsx("tbody", { children: apiKeys.map(function (apiKey, index) {
                                var _a, _b;
                                return (_jsxs(Fragment, { children: [_jsxs(Tr, __assign({ isSelected: (selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.id) === apiKey.id, onSelect: function () { return handleSelect(apiKey); } }, { children: [_jsx(Td, __assign({ style: { width: 25 } }, { children: _jsx(BsFillKeyFill, {}) })), _jsx(Td, { children: apiKey.name }), _jsx(TdCentered, { children: apiKey.description }), _jsx(TdCentered, { children: (_b = __spreadArray([], ((_a = apiKey.roles) !== null && _a !== void 0 ? _a : []), true).map(function (role) { return t("Roles.".concat(role)); })) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return (a > b ? 1 : -1); }).join(', ') }), _jsx(TdRight, { children: apiKey.key }), _jsx(TdRight, { children: _jsx("div", __assign({ style: {
                                                            verticalAlign: 'middle',
                                                            display: 'flex',
                                                            justifyContent: 'end',
                                                        } }, { children: _jsx(ApiKeysListEditButtons, { apiKey: apiKey, setError: setError }) })) })] }), index), (selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.id) === apiKey.id && (_jsx(_Fragment, { children: _jsx(Tr, __assign({ isHoverable: false }, { children: _jsx(Td, __assign({ colSpan: 5, style: {
                                                        padding: '0 0 10px 0',
                                                    } }, { children: _jsx(ApiKeyEditFields, { setIsAdding: setIsAdding, isAdding: isAdding, setError: setError }) })) })) }))] }, apiKey.id));
                            }) })] })) }))] }));
};
ApiKeysList.displayName = 'ApiKeysList';
