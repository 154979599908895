import { CacheTagsCustomer, featuresCoreApi } from '../featuresCoreApi';
var customerApiApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Generate new api key
         */
        generateApiKey: build.mutation({
            query: function (apiKey) {
                return {
                    url: "customer/apiKey",
                    method: 'PUT',
                    body: apiKey,
                };
            },
            transformResponse: function (_a) {
                var key = _a.key;
                return key;
            },
            invalidatesTags: [CacheTagsCustomer.ApiKey],
        }),
        /**
         * Update api key
         */
        updateApiKey: build.mutation({
            query: function (apiKey) {
                return {
                    url: "customer/apiKey/".concat(apiKey.id),
                    method: 'POST',
                    body: apiKey,
                };
            },
            invalidatesTags: [CacheTagsCustomer.ApiKey],
        }),
        /**
         * Revoke api key
         */
        revokeApiKey: build.mutation({
            query: function (id) {
                return {
                    url: "customer/apiKey/".concat(id),
                    method: 'DELETE',
                };
            },
            invalidatesTags: [CacheTagsCustomer.ApiKey],
        }),
        /**
         * Get api key
         */
        getApiKey: build.query({
            query: function (_customerId) { return "customer/apiKey"; },
            providesTags: [CacheTagsCustomer.ApiKey],
        }),
    }); },
});
export var useGenerateApiKeyMutation = customerApiApi.useGenerateApiKeyMutation, useRevokeApiKeyMutation = customerApiApi.useRevokeApiKeyMutation, useGetApiKeyQuery = customerApiApi.useGetApiKeyQuery, useUpdateApiKeyMutation = customerApiApi.useUpdateApiKeyMutation;
