import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonFooter,
  ButtonSecondary,
  Buttons,
  InputNumber,
  Label,
} from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  piosActions,
  selectFilter,
  selectSelectedProjectIdea,
} from 'api/piosSlice';
import { useUpdateProjectIdeaMutation } from 'api/project/projectIdeaApi';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ProjectIdea, ProjectIdeaAction } from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import {
  calculateNetBenefit,
  calculateYearlyNetBenefit,
  formatAsCurrency,
  formatAsNumber,
  isReadOnly,
} from 'utils';
import { IdeaStatus } from './IdeaStatus';

// TODO: Fix, split code
/* eslint-disable max-lines-per-function */

export const IdeaListQualify = withTheme(
  ({ ideas, theme }: { ideas: ProjectIdea[]; theme: Theme }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);
    const dispatch = useAppDispatch();
    const selectAndNavigate = useSelectAndNavigate();
    const filter = useAppSelector(selectFilter);

    const selectedIdea = useAppSelector(selectSelectedProjectIdea);

    const [hasChanges, setHasChanges] = useState(false);

    const [updateIdea] = useUpdateProjectIdeaMutation();

    const {
      items: sortedIdeas,
      setPage,
      paginator,
      sorter,
    } = useUiSortablePaginator({
      orderBy: 'title',
      pageSize: 25,
      data: {
        totalRecords: ideas.length,
        records: ideas,
      },
    });

    const handleChange = (idea: ProjectIdea) => {
      dispatch(
        piosActions.setSelectedProjectIdea({
          ...idea,
          netBenefitYearly: calculateYearlyNetBenefit(idea),
          netBenefit: calculateNetBenefit(idea),
        })
      );

      setHasChanges(true);
    };

    const handleSave = (idea: ProjectIdea) => {
      setHasChanges(false);

      updateIdea(idea);
    };

    const handleCancel = () => {
      setHasChanges(false);

      selectAndNavigate(ProjectIdeaAction.Qualify);
    };

    const handleSelect = (idea: ProjectIdea) => {
      if (selectedIdea?.projectId === idea.projectId) {
        return;
      }

      selectAndNavigate(ProjectIdeaAction.Qualify, idea);
    };

    useEffect(() => {
      setPage(1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
      dispatch(piosActions.setHasUnsavedChanges(hasChanges));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasChanges]);

    return (
      <>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          {t('FutureProjects.ProjectIdea.QualifyText')}
        </div>

        {ideas.length === 0 && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {t('FutureProjects.ProjectIdea.NoProjectIdeas')}
          </div>
        )}

        <Pagination
          currentPage={paginator.page}
          totalCount={ideas.length}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
        />

        <TableResponsiveContainer>
          <Style>
            {sortedIdeas.length > 0 && (
              <thead>
                <tr>
                  <ThCentered
                    className="narrow"
                    sortKey="projectPhase"
                    sorter={sorter}
                    style={{ width: 50 }}
                  >
                    {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                  </ThCentered>
                  <Th
                    className="narrow"
                    sortKey="externalId"
                    sorter={sorter}
                    style={{ width: 60 }}
                  >
                    {t('Project.Id')}
                  </Th>
                  <Th>
                    <SortElements>
                      <SortElement sortKey="title" sorter={sorter}>
                        {t('FutureProjects.ProjectIdea.ProjectIdea')}
                      </SortElement>
                      <SortElement sortKey="portfolioName" sorter={sorter}>
                        {t('Portfolio.Portfolio')}
                      </SortElement>
                    </SortElements>
                  </Th>
                  <ThRight
                    sortKey="takeHomePeriod"
                    sorter={sorter}
                    style={{ width: 180 }}
                  >
                    {t('FutureProjects.ProjectIdea.TakeHomePeriodYear')}
                  </ThRight>
                  <ThRight
                    sortKey="benefit"
                    sorter={sorter}
                    style={{ width: 150 }}
                  >
                    {t('FutureProjects.ProjectIdea.Benefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    sortKey="cost"
                    sorter={sorter}
                    style={{ width: 150 }}
                  >
                    {t('FutureProjects.ProjectIdea.Cost', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    sortKey="netBenefit"
                    sorter={sorter}
                    style={{ width: 150 }}
                  >
                    {t('FutureProjects.ProjectIdea.NetBenefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThRight
                    sortKey="netBenefitYearly"
                    sorter={sorter}
                    style={{ width: 150 }}
                  >
                    {t('FutureProjects.ProjectIdea.NetBenefitPerYear', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>

                  <Th style={{ width: 20 }} />
                </tr>
              </thead>
            )}

            <tbody>
              {sortedIdeas.map((idea) => (
                <Fragment key={idea.id}>
                  <Tr
                    onSelect={(e) => {
                      if (
                        ['INPUT', 'SELECT'].includes(
                          (e.target as HTMLElement).nodeName
                        )
                      ) {
                        return;
                      }

                      handleSelect(idea);
                    }}
                  >
                    <TdCentered className="narrow">
                      <IdeaStatus status={idea.projectPhase} />
                    </TdCentered>
                    <Td className="narrow">{idea.externalId}</Td>
                    <Td>
                      <div className="b">{idea.title}</div>
                      <div className="i">{idea.portfolioName}</div>
                    </Td>

                    {!isReadOnly(idea) &&
                      selectedIdea &&
                      selectedIdea?.id === idea.id && (
                        <>
                          <TdRight>
                            <InputNumber
                              style={{ width: 80, textAlign: 'right' }}
                              value={selectedIdea.takeHomePeriod}
                              onValueChange={(e) => {
                                if (
                                  e.floatValue !== selectedIdea.takeHomePeriod
                                ) {
                                  handleChange({
                                    ...selectedIdea,
                                    takeHomePeriod: e.floatValue,
                                  });
                                }
                              }}
                            />
                          </TdRight>
                          <TdRight>
                            <InputNumber
                              style={{ width: 100, textAlign: 'right' }}
                              value={selectedIdea.benefit}
                              onValueChange={(e) => {
                                if (e.floatValue !== selectedIdea.benefit) {
                                  handleChange({
                                    ...selectedIdea,
                                    benefit: e.floatValue,
                                  });
                                }
                              }}
                            />
                            <Label
                              className="size-s b"
                              style={{ justifyContent: 'flex-end' }}
                            >
                              {t('FutureProjects.ProjectIdea.RiskPercentage')}
                            </Label>
                            <InputNumber
                              style={{ width: 80, textAlign: 'right' }}
                              value={selectedIdea.benefitRisk}
                              onValueChange={(e) => {
                                if (e.floatValue !== selectedIdea.benefitRisk) {
                                  handleChange({
                                    ...selectedIdea,
                                    benefitRisk: e.floatValue,
                                  });
                                }
                              }}
                            />
                          </TdRight>
                          <TdRight>
                            <InputNumber
                              style={{ width: 100, textAlign: 'right' }}
                              value={selectedIdea.cost}
                              onValueChange={(e) => {
                                if (e.floatValue !== selectedIdea.cost) {
                                  handleChange({
                                    ...selectedIdea,
                                    cost: e.floatValue,
                                  });
                                }
                              }}
                            />
                            <Label
                              className="size-s b"
                              style={{ justifyContent: 'flex-end' }}
                            >
                              {t('FutureProjects.ProjectIdea.RiskPercentage')}
                            </Label>
                            <InputNumber
                              style={{ width: 80, textAlign: 'right' }}
                              value={selectedIdea.costRisk}
                              onValueChange={(e) => {
                                if (e.floatValue !== selectedIdea.costRisk) {
                                  handleChange({
                                    ...selectedIdea,
                                    costRisk: e.floatValue,
                                  });
                                }
                              }}
                            />
                          </TdRight>
                          <TdRight style={{ paddingTop: 10 }}>
                            {formatAsCurrency(selectedIdea.netBenefit)}
                          </TdRight>
                          <TdRight style={{ paddingTop: 10 }}>
                            {formatAsCurrency(selectedIdea.netBenefitYearly)}
                          </TdRight>
                          <Td />
                        </>
                      )}

                    {(isReadOnly(idea) || selectedIdea?.id !== idea.id) && (
                      <>
                        <TdRight>{idea.takeHomePeriod}</TdRight>
                        <TdRight>
                          {formatAsCurrency(idea.benefit)}
                          <Label
                            className="size-s b"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {t('FutureProjects.ProjectIdea.RiskPercentage')}
                          </Label>
                          {formatAsNumber(idea.benefitRisk, '0')}
                        </TdRight>
                        <TdRight>
                          {formatAsCurrency(idea.cost)}
                          <Label
                            className="size-s b"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {t('FutureProjects.ProjectIdea.RiskPercentage')}
                          </Label>
                          {formatAsNumber(idea.costRisk, '0')}
                        </TdRight>
                        <TdRight>{formatAsCurrency(idea.netBenefit)}</TdRight>
                        <TdRight>
                          {formatAsCurrency(idea.netBenefitYearly)}
                        </TdRight>
                        <TdRight>
                          {!isReadOnly(idea) && (
                            <BadgeMini
                              data-tip
                              data-for="tt-project-edit"
                              onClick={() => {
                                setHasChanges(false);
                                handleSelect(idea);
                              }}
                            >
                              <BsPencil />
                            </BadgeMini>
                          )}
                        </TdRight>
                      </>
                    )}
                  </Tr>

                  {!isReadOnly(idea) &&
                    selectedIdea &&
                    selectedIdea?.id === idea.id && (
                      <Tr>
                        <TdRight
                          colSpan={200}
                          style={{
                            backgroundColor: theme.colors.surfaceVariant,
                          }}
                        >
                          <ButtonFooter style={{ margin: '-10px 0 30px 0' }}>
                            <Buttons>
                              <ButtonSecondary onClick={handleCancel}>
                                {hasChanges
                                  ? t('Common.Form.Cancel')
                                  : t('Common.UI.Close')}
                              </ButtonSecondary>
                              <Button
                                onClick={() => handleSave(selectedIdea)}
                                disabled={!hasChanges}
                              >
                                {t('Common.Form.Save')}
                              </Button>
                            </Buttons>
                          </ButtonFooter>
                        </TdRight>
                      </Tr>
                    )}
                </Fragment>
              ))}
            </tbody>
          </Style>
        </TableResponsiveContainer>

        <Pagination
          currentPage={paginator.page}
          totalCount={ideas.length}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
        />
      </>
    );
  }
);

IdeaListQualify.displayName = 'IdeaListQualify';

const Style = styled(Table)`
  .field {
    display: flex;
    align-items: flex-start;

    > div {
      padding: 0 20px 0 0;
    }
  }

  input {
    margin-top: 0;
    margin-bottom: 0;
  }

  td > input:first-of-type {
    margin-top: 3px;
  }

  td > input:last-of-type {
    margin-bottom: 3px;
  }

  label {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
