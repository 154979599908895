import { Project as ProjectFeature } from 'features/project/Project';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Project = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Project.Project')}>
      <ProjectFeature />
    </View>
  );
};

Project.displayName = 'Project';
