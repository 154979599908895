import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfoliosOverviewByPeriodQuery } from 'api/portfolio/portfolioOverviewApi';
import { BudgetAndBalanceOverTime } from 'components/BudgetAndBalanceOverTime';
import { useAppSelector } from 'hooks';
import { OverviewByPeriod } from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PortfoliosBudgetAndBalanceOverTime = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const [overviewByPeriod, setOverviewByPeriod] =
    useState<OverviewByPeriod[]>();

  const { data, isFetching } = useGetPortfoliosOverviewByPeriodQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: { ...filter, period: undefined },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  useEffect(() => {
    if (data) {
      const overviewByPeriod = data.map((item) => ({
        ...item,
        averageBudget: item.averagePortfolioBudget,
        averageStaffingBudget: item.averagePortfolioStaffingBudget,
        efficiencyInnovation: item.portfolioEfficiencyInnovation,
        internalExternal: item.portfolioInternalExternal,
      }));

      setOverviewByPeriod(overviewByPeriod);
    }
  }, [data]);

  if (data && data.length === 0) {
    return null;
  }

  return (
    <Card
      helpTextTitle={t('Portfolios.BudgetAndBalanceOverTime.Title')}
      helpText={<Trans i18nKey={'Portfolios.BudgetAndBalanceOverTime.Help'} />}
      canBeSavedAsPng={true}
      style={{ paddingTop: 10 }}
    >
      <CardBody isLoading={isFetching}>
        {overviewByPeriod && (
          <BudgetAndBalanceOverTime overviewByPeriod={overviewByPeriod} />
        )}
      </CardBody>
    </Card>
  );
};

PortfoliosBudgetAndBalanceOverTime.displayName =
  'PortfoliosBudgetAndBalanceOverTime';
