import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Button, Buttons } from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const UpgradeLicense = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useContext(AuthenticationContext);

  const licenseName = accessToken.user?.currentCustomer.license.name as string;

  const handleUpgrade = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/upgrade-license`, {
      state: { from: location.pathname },
    });
  };

  return (
    <>
      <Heading3>{t('UpgradeLicense.Title')}</Heading3>

      <div style={{ fontWeight: 'bold', marginTop: 10 }}>
        {t('UpgradeLicense.CurrentLicense', {
          name: t(`LicenseType.${licenseName.replace(/\s/g, '_')}`),
        })}
      </div>

      <p>{t('UpgradeLicense.Text1')}</p>

      <Buttons>
        <Button type="button" onClick={(e) => handleUpgrade(e)}>
          {t(`UpgradeLicense.Upgrade`)}
        </Button>
      </Buttons>
    </>
  );
};

UpgradeLicense.displayName = 'UpgradeLicense';
