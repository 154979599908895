import { Select } from '@dimatech/shared/lib/components/form';
import { Tag } from '@dimatech/shared/lib/models';
import { listToTree } from '@dimatech/shared/lib/utils';
import { useGetNationalTagsQuery } from 'api/nationalTag/nationalTagApi';
import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectNationalTag = ({
  onSelectNationalTag,
  style,
  placeholder,
  filter,
  autoFocus,
}: {
  onSelectNationalTag: (
    e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => void;
  style?: CSSProperties;
  placeholder?: string;
  filter?: (tag: Tag) => boolean;
  autoFocus?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { data } = useGetNationalTagsQuery();
  const [tree, setTree] = useState<Tag[] | undefined>();

  const handleSelectTag = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tagId = e.currentTarget.value;
    const tag = data?.find((tag) => tag.id === tagId);

    onSelectNationalTag(e, tag);
  };

  const toOptions = (tags: Tag[], depth = 0): JSX.Element[] => {
    const options = tags.map((tag: Tag) => (
      <Fragment key={tag.id}>
        <option value={tag.id}>
          {'\u00A0'.repeat(depth * 2)}
          {depth > 0 ? '- ' : ''}
          {tag.displayName}
        </option>
        {toOptions(tag.children, depth + 1)}
      </Fragment>
    ));

    return options;
  };

  useEffect(() => {
    if (data) {
      setTree(listToTree(data, [null], filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Select
      autoFocus={!!autoFocus}
      onChange={(e) => handleSelectTag(e)}
      style={style}
    >
      <option value="">
        {placeholder ? placeholder : t('Search.SelectNationalTag')}
      </option>
      {tree && toOptions(tree)}
    </Select>
  );
};

SelectNationalTag.displayName = 'SelectNationalTag';
