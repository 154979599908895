import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import {
  calculationActions,
  selectSelectedCalculation,
} from 'api/calculation/calculationSlice';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CalculationType, Dimension, DimensionType } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { isAdminReadOnly } from 'utils';
import { mergeDimensionCalculations } from 'utils/dimension';
import { TabContentStyle } from './TabContentStyle';
import { CalculationProperties } from './components/CalculationProperties';
import { CalculationsList } from './components/CalculationsList';

export const Calculations = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [classifications, setClassifications] = useState<Dimension[]>([]);
  const selectedCalculation = useAppSelector(selectSelectedCalculation);

  const { data, isFetching, isLoading } = useGetDimensionsQuery(
    accessToken.customerId ?? skipToken
  );

  const { data: calculations } = useGetCalculationsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const standardCalculation = calculations?.find(
    (calculation) => calculation.type === CalculationType.System
  );

  const handleCreate = () => {
    if (isReadOnly) {
      return;
    }

    dispatch(calculationActions.selectedCalculation({}));
  };

  useEffect(() => {
    setClassifications([
      ...(data
        ?.filter(
          (dimension) =>
            dimension.type === DimensionType.ClassificationBenefit ||
            dimension.type === DimensionType.ClassificationStake
        )
        .map((dimension) => ({
          ...dimension,
          calculationWeights: mergeDimensionCalculations(
            dimension,
            calculations ?? []
          ),
          values: dimension.values?.map((value) => ({
            ...value,
            uid: value.id,
          })),
        })) ?? []),
    ]);
  }, [data, calculations]);

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        {t('Administrate.Calculation.Text')}
      </div>

      <LinkWithTooltip
        isPrimary={true}
        isDisabled={isReadOnly}
        handleClick={handleCreate}
        title={t('Administrate.Calculation.Add.TooltipTitle')}
        tooltipTitle={t('Administrate.Calculation.Add.TooltipTitle')}
        tooltip={t('Administrate.Calculation.Add.Tooltip')}
        icon={<BsPlus />}
        style={{ marginBottom: 20 }}
      />

      {(isFetching || isLoading) && <LoaderOverlay />}

      {(!calculations || calculations.length === 0) && !selectedCalculation ? (
        <>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.Calculation.NoCalculations')}
          </div>
          <ButtonLink type="button" onClick={handleCreate}>
            {t('Administrate.Calculation.NoCalculationsAddFirst')}
          </ButtonLink>
        </>
      ) : (
        <TabContentStyle>
          <CalculationsList />

          {selectedCalculation && (
            <div style={{ flexGrow: 1, maxWidth: 800 }}>
              <CalculationProperties
                classifications={classifications}
                standardCalculationId={standardCalculation?.id}
              />
            </div>
          )}
        </TabContentStyle>
      )}
    </>
  );
};

Calculations.displayName = 'Calculations';
