import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Theme } from '@dimatech/shared/lib/themes';
import i18n from 'localization';
import { OverviewByPeriod } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { withTheme } from 'styled-components';
import { formatAsPercent, toShortCurrency } from 'utils';
import { BudgetAndBalanceOverTimeTooltip } from './BudgetAndBalanceOverTimeTooltip';

export const BudgetAndBalanceOverTime = withTheme(
  ({
    theme,
    overviewByPeriod,
  }: {
    theme: Theme;
    overviewByPeriod: OverviewByPeriod[];
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const formatter = (value: string) =>
      `${toShortCurrency(
        +value,
        (value) => value.toLocaleString(i18n.language),
        t(`Common.Currency.${accessToken.locale.currency}.Series`).split(',')
      )}`;

    const data = [...overviewByPeriod].sort(
      (a, b) => parseInt(a.period) - parseInt(b.period)
    );

    const keys = [
      {
        key: t('Statistics.AverageBudget', {
          currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
        }),
      },
      { key: t('Project.EfficiencyInnovation') },
      { key: t('Project.InternalExternal') },
    ];

    const [indicators, setIndicators] = useState(
      keys.reduce((a: Record<string, boolean>, { key }) => {
        a[key] = false;
        return a;
      }, {})
    );

    const handleSwitch = (e: string) => {
      setIndicators({
        ...indicators,
        [e]: !indicators[e],
      });
    };

    return (
      <Chart style={{ height: 450, width: '100%', minWidth: 380 }}>
        <ComposedChart
          data={data}
          margin={{
            top: 0,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid
            stroke={theme.colors.chart.gridStroke}
            strokeWidth={0.5}
          />

          <XAxis
            dataKey="period"
            name={t('Portfolio.Period.Period')}
            tickLine={false}
            stroke={theme.colors.chart.axisStroke}
          >
            <Label
              value={t('Portfolio.Period.Period') as string}
              position="insideBottom"
              offset={0}
              fill={theme.colors.onSurface}
            />
          </XAxis>

          <YAxis
            yAxisId="left"
            tickFormatter={formatter}
            tickLine={false}
            stroke={theme.colors.chart.axisStroke}
          >
            <Label
              value={
                t('Statistics.AverageBudget', {
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                }) as string
              }
              offset={0}
              angle={-90}
              position="insideLeft"
              fill={theme.colors.onSurface}
            />
          </YAxis>

          <YAxis
            yAxisId="right"
            orientation="right"
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0', 0)}
            stroke={theme.colors.chart.axisStroke}
            domain={[0, 100]}
          >
            <Label
              value={t('Project.InternalExternal') as string}
              offset={-20}
              position="right"
              angle={-90}
              fill={theme.colors.onSurface}
            />
            <Label
              value={t('Project.EfficiencyInnovation') as string}
              offset={-5}
              position="right"
              angle={-90}
              fill={theme.colors.onSurface}
            />
          </YAxis>

          <Area
            yAxisId="left"
            name={
              t('Statistics.AverageBudget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              }) as string
            }
            animationDuration={700}
            type={'monotone'}
            dataKey="averageBudget"
            opacity={0.5}
            fill={theme.colors.primary}
            stroke="none"
            hide={
              indicators[
                t('Statistics.AverageBudget', {
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                })
              ] === true
            }
          />

          <Line
            yAxisId="right"
            name={t('Project.EfficiencyInnovation') as string}
            animationDuration={700}
            type={'monotone'}
            connectNulls
            dataKey="efficiencyInnovation"
            fill={theme.colors.chart.colors[0]}
            stroke={theme.colors.chart.colors[0]}
            hide={indicators[t('Project.EfficiencyInnovation')] === true}
          />

          <Line
            yAxisId="right"
            name={t('Portfolio.InternalExternal') as string}
            animationDuration={700}
            type={'monotone'}
            connectNulls
            dataKey="internalExternal"
            fill={theme.colors.chart.colors[1]}
            stroke={theme.colors.chart.colors[1]}
            hide={indicators[t('Portfolio.InternalExternal')] === true}
          />

          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<BudgetAndBalanceOverTimeTooltip />}
          />

          <Legend
            verticalAlign="bottom"
            height={10}
            iconType="circle"
            onClick={(e) => handleSwitch(e.value)}
            formatter={(value: string) => (
              <span style={{ cursor: 'pointer' }}>{value}</span>
            )}
          />
        </ComposedChart>
      </Chart>
    );
  }
);

BudgetAndBalanceOverTime.displayName = 'BudgetAndBalanceOverTime';
