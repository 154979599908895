var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from 'recharts';
import { withTheme } from 'styled-components';
export var RadarChartAxisTick = withTheme(function (_a) {
    var x = _a.x, y = _a.y, payload = _a.payload, tickFormatter = _a.tickFormatter, theme = _a.theme;
    var modifierY = 0;
    var textAnchor = payload.coordinate > -90 ? 'start' : 'end';
    var verticalAnchor = 'middle';
    if (payload.coordinate === 90) {
        textAnchor = 'middle';
        verticalAnchor = 'end';
        modifierY = -5;
    }
    if (payload.coordinate === -90) {
        textAnchor = 'middle';
        verticalAnchor = 'start';
        modifierY = 5;
    }
    return (_jsx(Text, __assign({ width: 130, x: x, y: y + modifierY, textAnchor: textAnchor, verticalAnchor: verticalAnchor, fill: theme.colors.onSurface }, { children: tickFormatter ? tickFormatter(payload.value) : payload.value })));
});
RadarChartAxisTick.displayName = 'RadarChartAxisTick';
