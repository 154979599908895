import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { FileIcon } from '@dimatech/shared/lib/components/FileIcon';
import { LinkIcon } from '@dimatech/shared/lib/components/LinkIcon';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  Td,
  TdCentered,
  TdRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDocumentsQuery } from 'api/project/projectDocumentApi';
import { getProjectDocumentContent } from 'api/project/projectDocumentContentSlice';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProjectDocument, ProjectDocumentType } from 'models';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowUpRight, BsPlus } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { toFileSize } from 'utils';
import { ProjectDocumentFileProperties } from './ProjectDocumentFileProperties';
import { ProjectDocumentLinkProperties } from './ProjectDocumentLinkProperties';
import { ProjectDocumentListEditButtons } from './ProjectDocumentListEditButtons';

export const ProjectDocumentList = withTheme(
  ({
    theme,
    projectId,
    canEdit,
  }: {
    theme: Theme;
    projectId: string;
    canEdit: boolean;
  }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isAddingFile, setIsAddingFile] = useState(false);
    const [isAddingLink, setIsAddingLink] = useState(false);

    const { accessToken } = useContext(AuthenticationContext);

    const isProjectDocumentUploadEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectDocumentUploadEnabled];

    const { data: documents, isFetching } = useGetDocumentsQuery(
      accessToken.customerId && projectId
        ? {
            projectId,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

    const handleDownload = (
      e: React.SyntheticEvent,
      document: ProjectDocument
    ) => {
      e.preventDefault();
      e.stopPropagation();

      if (!document.id || !isProjectDocumentUploadEnabledFlagOn) {
        return;
      }

      dispatch(
        getProjectDocumentContent({
          projectId,
          projectDocument: document,
        })
      );
    };

    if (isFetching) {
      return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
    }

    return (
      <>
        <Heading4 style={{ marginBottom: 10 }}>
          {isProjectDocumentUploadEnabledFlagOn
            ? t('Portfolio.Document.Title')
            : t('Portfolio.Document.TitleLinks')}
        </Heading4>

        {documents &&
          documents.length === 0 &&
          !isAddingFile &&
          !isAddingLink && (
            <div>
              {isProjectDocumentUploadEnabledFlagOn
                ? t('Portfolio.Document.NoDocuments')
                : t('Portfolio.Document.NoLinks')}
            </div>
          )}

        <Table>
          <tbody>
            {documents?.map((document) => (
              <Tr key={document.id}>
                <TdCentered
                  className="narrow"
                  style={{
                    width: 25,
                    paddingRight: 0,
                  }}
                >
                  {document.type === ProjectDocumentType.Document && (
                    <FileIcon fileName={document.name as string} />
                  )}

                  {document.type === ProjectDocumentType.Link && (
                    <LinkIcon linkType={document.linkType as string} />
                  )}
                </TdCentered>

                <Td>
                  {document.type === ProjectDocumentType.Document && (
                    <ButtonLink onClick={(e) => handleDownload(e, document)}>
                      {document.name}
                    </ButtonLink>
                  )}

                  {document.type === ProjectDocumentType.Link && (
                    <>
                      <a
                        href={document.linkContent}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {document.name}
                      </a>{' '}
                      <BsBoxArrowUpRight
                        style={{ fontSize: theme.fonts.size.s, marginLeft: 5 }}
                      />
                    </>
                  )}
                </Td>
                {isProjectDocumentUploadEnabledFlagOn && (
                  <TdRight>{toFileSize(document.size ?? 0)}</TdRight>
                )}
                <Td>
                  <ProjectDocumentListEditButtons
                    canEdit={canEdit}
                    document={document}
                    projectId={projectId}
                    handleDownload={handleDownload}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>

        {canEdit && !isAddingFile && !isAddingLink && (
          <div style={{ display: 'flex', marginTop: 30 }}>
            {isProjectDocumentUploadEnabledFlagOn && (
              <LinkWithTooltip
                isPrimary={true}
                handleClick={() => setIsAddingFile(!isAddingFile)}
                title={t('Portfolio.Document.Add.Title')}
                tooltipTitle={t('Portfolio.Document.Add.TooltipTitle')}
                tooltip={t('Portfolio.Document.Add.Tooltip')}
                icon={<BsPlus />}
                style={{ marginRight: 20 }}
              />
            )}

            <LinkWithTooltip
              isPrimary={true}
              handleClick={() => setIsAddingLink(!isAddingLink)}
              title={t('Portfolio.Document.AddLink.Title')}
              tooltipTitle={t('Portfolio.Document.AddLink.TooltipTitle')}
              tooltip={t('Portfolio.Document.AddLink.Tooltip')}
              icon={<BsPlus />}
            />
          </div>
        )}

        {isAddingFile && (
          <ProjectDocumentFileProperties
            style={{ marginTop: documents && documents.length > 0 ? 20 : 0 }}
            projectId={projectId}
            setIsAddingFile={setIsAddingFile}
          />
        )}

        {isAddingLink && (
          <ProjectDocumentLinkProperties
            style={{ marginTop: documents && documents.length > 0 ? 20 : 0 }}
            projectId={projectId}
            setIsAddingLink={setIsAddingLink}
          />
        )}
      </>
    );
  }
);

ProjectDocumentList.displayName = 'ProjectDocumentList';
