import {
  NavigationItem,
  NavigationSeparator,
} from '@dimatech/shared/lib/components/workspace';
import { useTranslation } from 'react-i18next';
import { BsCameraVideo, BsFileText, BsQuestionCircle } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

export const NavigationHelp = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <NavigationSeparator />

      <NavigationItem $selected={location.pathname === '/help'}>
        <BsQuestionCircle />
        <Link to="/help">{t('Navigation.Help')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/video-library'}>
        <BsCameraVideo />
        <Link to="/video-library">{t('Navigation.VideoLibrary')}</Link>
      </NavigationItem>

      <NavigationItem
        $selected={location.pathname.startsWith('/release-notes')}
      >
        <BsFileText />
        <Link to="/release-notes">{t('Navigation.ReleaseNotes')}</Link>
      </NavigationItem>
    </>
  );
};

NavigationHelp.displayName = 'NavigationHelp';
