import { AccessToken } from '@dimatech/features-core/lib/models/token';
import { CommonRoles, Permission, Project } from 'models';

/**
 * Returns true if the user is in a read only role such as demo user or researcher
 * and therefore should see all functionality but cannot actually edit anything
 * @param accessToken
 * @returns
 */
export const isPiosReadOnly = (accessToken: AccessToken) => {
  return accessToken.isInRole([CommonRoles.DemoUser, CommonRoles.Researcher]);
};

/**
 * Returns true if the user is in a role that can administrate everything
 * for current customer, for now its just global admins and customer account admins
 */
export const isAccountAdmin = (accessToken: AccessToken) => {
  return accessToken.isInRole([
    CommonRoles.GlobalAdmin,
    CommonRoles.CustomerAccountAdmin,
  ]);
};

/**
 * Returns true if the user is project user
 */
export const isProjectUser = (accessToken: AccessToken) => {
  return accessToken.isInRole([
    CommonRoles.PiosProjectManager,
    CommonRoles.PiosEffectRealizationResponsible,
  ]);
};

/**
 * Returns true if the user is in a role that can edit portfolios
 * @param accessToken
 * @returns
 */
export const isPortfolioEditAvailable = (accessToken: AccessToken) => {
  return accessToken.isInRole([
    CommonRoles.GlobalAdmin,
    CommonRoles.CustomerAdmin,
    CommonRoles.CustomerAccountAdmin,
    CommonRoles.DemoUser,
    CommonRoles.Researcher,
    CommonRoles.PortfolioOwner,
  ]);
};

/**
 * Returns true if the user is in a role that can access project idea archive
 */
export const isProjectIdeaArchiveAvailable = (accessToken: AccessToken) => {
  return (
    isPortfolioEditAvailable(accessToken) ||
    accessToken.isInRole([CommonRoles.PiosProjectManager])
  );
};

/**
 * Returns true if the user is a effect realisation responsible for the project
 */
export const isUserEffectRealisationResponsibleForProject = (
  accessToken: AccessToken,
  project?: Project
) => {
  if (!accessToken.user?.email) {
    return false;
  }

  return !!project?.effectRealizationResponsible?.includes(
    accessToken.user.email
  );
};

/**
 * Returns true if the user is a project manager for the project
 */
export const isUserProjectManagerForProject = (
  accessToken: AccessToken,
  project?: Project
) => {
  if (!accessToken.user?.email) {
    return false;
  }

  return !!project?.projectManagers?.includes(accessToken.user.email);
};

export const hasPermission = (
  value: Permission,
  item: { permissions?: Permission[] }
) => {
  if (!item?.permissions) {
    return false;
  }

  return item.permissions.includes(value);
};

/**
 * Returns true if the user do not have any permissions on the item
 */
export const isReadOnly = (item: { permissions?: Permission[] }) => {
  return hasPermission(Permission.None, item);
};
