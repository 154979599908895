var _a;
import { createSlice } from '@reduxjs/toolkit';
import { appActions } from '../appSlice';
var initialState = {};
export var customerApiKeyReducer = (_a = createSlice({
    name: 'customer/apiKey',
    initialState: initialState,
    reducers: {
        selectedApiKey: function (state, action) {
            state.selectedApiKey = action.payload;
        },
    },
    extraReducers: function (build) {
        build.addCase(appActions.resetApplication, function (state) {
            state.selectedApiKey = initialState.selectedApiKey;
        });
    },
}), _a.reducer), customerApiKeyActions = _a.actions;
export var selectSelectedApiKey = function (state) { return state.customer.apiKey.selectedApiKey; };
