import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Select } from '@dimatech/shared/lib/components/form';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosQuery } from 'api/portfolio/portfolioApi';
import { Portfolio } from 'models';
import {
  CSSProperties,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isReadOnly } from 'utils';

export const SelectPortfolio = ({
  portfolioId,
  setPortfolio,
  style,
  isDisabled,
  entityId,
  invalid,
  showOnlyEditablePortfolios = true,
}: {
  portfolioId?: string;
  setPortfolio: (portfolio: Portfolio | undefined) => void;
  style?: CSSProperties;
  isDisabled?: boolean;
  entityId?: string;
  invalid?: boolean;
  showOnlyEditablePortfolios?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

  const { data } = useGetPortfoliosQuery(
    accessToken.customerId && entityId
      ? { entityId, _customerId: accessToken.customerId }
      : accessToken.customerId
      ? { _customerId: accessToken.customerId }
      : skipToken
  );

  const handleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    e.stopPropagation();

    if (!data || !e.currentTarget.value) {
      return;
    }

    const portfolio = data.find(
      (portfolio) => portfolio.id === e.currentTarget.value
    );

    if (portfolio) {
      setPortfolio(portfolio);
    }
    if (portfolio === undefined) {
      setPortfolio(undefined);
    }
  };

  useEffect(() => {
    if (data) {
      if (showOnlyEditablePortfolios) {
        setPortfolios(data.filter((portfolio) => !isReadOnly(portfolio)));

        return;
      }

      setPortfolios(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showOnlyEditablePortfolios]);

  return portfolios ? (
    <Select
      value={portfolioId}
      onChange={(e) => handleChange(e)}
      style={style}
      disabled={!!isDisabled}
      invalid={invalid}
    >
      <option value={undefined}>{t('Search.SelectPortfolio')}</option>
      {[...portfolios]
        ?.sort((a, b) => ((a.name as string) > (b.name as string) ? 1 : -1))
        .map((portfolio) => (
          <option key={portfolio.id} value={portfolio.id}>
            {portfolio.name}{' '}
            {portfolio.externalId && <>({portfolio.externalId})</>}
          </option>
        ))}
    </Select>
  ) : null;
};

SelectPortfolio.displayName = 'SelectPortfolio';
