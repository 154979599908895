import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Table,
  TableResponsiveContainer,
  Td,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import {
  calculationActions,
  selectSelectedCalculation,
} from 'api/calculation/calculationSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Calculation, CalculationType } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCalculator, BsCalculatorFill } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { shorten } from 'utils';

export const CalculationsList = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { accessToken } = useContext(AuthenticationContext);
    const selectedCalculation = useAppSelector(selectSelectedCalculation);

    const { data: calculations } = useGetCalculationsQuery(
      accessToken.customerId ?? skipToken
    );

    const handleSelect = (calculation: Calculation) => {
      dispatch(calculationActions.selectedCalculation(calculation));
    };

    return (
      <>
        {calculations && calculations.length > 0 && (
          <div>
            <TableResponsiveContainer>
              <Table style={{ minWidth: 400 }}>
                <thead>
                  <tr>
                    <Th style={{ width: 25 }} />
                    <Th>{t(`Administrate.Calculation.Name`)}</Th>
                    <Th>{t(`Administrate.Calculation.Description`)}</Th>
                  </tr>
                </thead>
                <tbody>
                  {calculations?.map((calculation, index) => (
                    <Tr
                      key={index}
                      onSelect={() => handleSelect(calculation)}
                      isSelected={calculation.id === selectedCalculation?.id}
                    >
                      <Td>
                        {calculation.type === CalculationType.System && (
                          <BsCalculatorFill />
                        )}
                        {calculation.type !== CalculationType.System && (
                          <BsCalculator />
                        )}
                      </Td>
                      <Td>
                        <HelpIconLeft>
                          {calculation.name}
                          {calculation.type === CalculationType.System && (
                            <HelpIcon
                              showTiny={true}
                              style={{ marginLeft: 5 }}
                              text={t(
                                'Administrate.Calculation.StandardRule.Text'
                              )}
                            />
                          )}
                        </HelpIconLeft>
                      </Td>
                      <Td>{shorten(calculation.description, 60)}</Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </TableResponsiveContainer>
          </div>
        )}
      </>
    );
  }
);

CalculationsList.displayName = 'CalculationsList';
