import { appReducer } from '@dimatech/features-core/lib/api/appSlice';
import { customer } from '@dimatech/features-core/lib/api/customer/customerSlice';
import { entityReducer } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { featuresCoreApi } from '@dimatech/features-core/lib/api/featuresCoreApi';
import { messageTemplateReducer } from '@dimatech/features-core/lib/api/message-template/messageTemplateSlice';
import { messageReducer } from '@dimatech/features-core/lib/api/message/messageSlice';
import { tagReducer } from '@dimatech/features-core/lib/api/tag/tagSlice';
import { userExportReducer } from '@dimatech/features-core/lib/api/user/userExportSlice';
import { userReducer } from '@dimatech/features-core/lib/api/user/userSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { calculationReducer } from 'api/calculation/calculationSlice';
import { dimensionReducer } from 'api/dimension/dimensionSlice';
import { nationalTagReducer } from 'api/nationalTag/nationalTagSlice';
import { piosApi } from 'api/piosApi';
import { piosReducer } from 'api/piosSlice';
import { ownerExportReducer } from 'api/portfolio/ownerExportSlice';
import { portfolioExportBundleReducer } from 'api/portfolio/portfolioExportBundleSlice';
import { portfolioExportReducer } from 'api/portfolio/portfolioExportSlice';
import { portfolioSearchReducer } from 'api/portfolio/portfolioSearchSlice';
import { portfolioReducer } from 'api/portfolio/portfolioSlice';
import { projectDocumentContentReducer } from 'api/project/projectDocumentContentSlice';
import { projectEffectRealisationResponsibleExportReducer } from 'api/project/projectEffectRealisationResponsibleExportSlice';
import { projectsExportRawReducer } from 'api/project/projectExportRawSlice';
import { projectsExportReducer } from 'api/project/projectExportSlice';
import { projectIdeasExportRawReducer } from 'api/project/projectIdeaExportRawSlice';
import { projectManagerExportReducer } from 'api/project/projectManagerExportSlice';
import { projectNoteReducer } from 'api/project/projectNoteSlice';
import { projectOrderingPartyExportReducer } from 'api/project/projectOrderingPartyExportSlice';

export const rootReducer = combineReducers({
  app: appReducer,
  pios: piosReducer,
  portfolio: portfolioReducer,
  entity: entityReducer,
  tag: tagReducer,
  calculation: calculationReducer,
  dimension: dimensionReducer,
  projectNote: projectNoteReducer,
  projectDocumentContent: projectDocumentContentReducer,
  portfolioExport: portfolioExportReducer,
  portfolioExportBundle: portfolioExportBundleReducer,
  portfolioSearch: portfolioSearchReducer,
  customer,
  message: messageReducer,
  user: userReducer,
  projectsExport: projectsExportReducer,
  projectsExportRaw: projectsExportRawReducer,
  projectIdeasExportRaw: projectIdeasExportRawReducer,
  userExport: userExportReducer,
  ownerExport: ownerExportReducer,
  projectManagerExport: projectManagerExportReducer,
  projectEffectRealisationResponsibleExport:
    projectEffectRealisationResponsibleExportReducer,
  projectOrderingPartyExport: projectOrderingPartyExportReducer,
  messageTemplate: messageTemplateReducer,
  nationalTag: nationalTagReducer,
  [piosApi.reducerPath]: piosApi.reducer,
  [featuresCoreApi.reducerPath]: featuresCoreApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
