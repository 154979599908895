import { AdministrateCustomDimensions as AdministrateCustomDimensionsFeature } from 'features/administrate-custom-dimensions';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateCustomDimensions = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.CustomDimension.Title')}>
      <AdministrateCustomDimensionsFeature />
    </View>
  );
};

AdministrateCustomDimensions.displayName = 'AdministrateCustomDimensions';
