import { UserAdd } from '@dimatech/features-core/lib/features/administrate-users/components/UserAdd';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Impersonate,
  SendForgotPasswordEmail,
} from '@dimatech/features-core/lib/features/authentication/components';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin } from '@dimatech/shared/lib/models';
import { CommonRoles } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonBadge, BsPersonPlus } from 'react-icons/bs';
import { isPiosReadOnly } from 'utils';
import { ProjectOrderingPartyActionDelete } from './ProjectOrderingPartyActionDelete';

export const ProjectOrderingPartiesList = ({
  orderingParties,
  setOrderingParties,
  setHasChanges,
  isEditAvailable = false,
}: {
  orderingParties?: string[];
  setOrderingParties?: (orderingParties: string[]) => void;
  setHasChanges?: (hasChanges: boolean) => void;
  isEditAvailable?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();

  const handleAdd = (user: Admin) => {
    setValidationErrors(undefined);

    if (isViewReadOnly || !isEditAvailable) {
      return;
    }

    if (!user?.email || user?.email?.includes(' ')) {
      setValidationErrors?.(['Email']);
      return;
    }

    if (
      orderingParties?.some((orderingParty) => orderingParty === user.email)
    ) {
      setValidationErrors(['ErrorSameEmail']);
      return;
    }

    setOrderingParties?.([...(orderingParties ?? []), `${user.email}`]);
    setHasChanges?.(true);
  };

  const handleDelete = (userEmail: string) => {
    if (isViewReadOnly || !isEditAvailable) {
      return;
    }

    const projectOrderingParties = orderingParties?.filter(
      (orderingParty) => orderingParty !== userEmail
    );

    setOrderingParties?.(projectOrderingParties ?? []);
    setHasChanges?.(true);
  };

  return (
    <>
      <div className="b" style={{ marginBottom: 10 }}>
        {t('UserRoles.PiosOrderingParty.Title')}
      </div>

      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PiosOrderingParty.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PiosOrderingParty.Title')}
          text={<TransArray i18nKey="UserRoles.PiosOrderingParty.Help" />}
        />
      </HelpIconLeft>

      {orderingParties && orderingParties.length === 0 && (
        <div>{t('Project.OrderingParty.NoOrderingParties')}</div>
      )}

      {isEditAvailable && (
        <LinkWithTooltip
          isPrimary={true}
          handleClick={() => setIsAdding(true)}
          title={t('Project.OrderingParty.Add.Title')}
          tooltipTitle={t('Project.OrderingParty.Add.TooltipTitle')}
          tooltip={t('Project.OrderingParty.Add.Tooltip')}
          icon={<BsPersonPlus />}
          style={{ marginTop: 20, marginBottom: 10 }}
        />
      )}

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(`Project.OrderingParty.ValidationError.${validationError}`)}
            </div>
          ))}
        </AlertWarning>
      )}

      {isAdding && (
        <UserAdd
          setIsAdding={setIsAdding}
          handleAddUser={handleAdd}
          role={CommonRoles.PiosOrderingParty}
          isEntityFilterShown={false}
        />
      )}

      {orderingParties && orderingParties.length > 0 && (
        <TableResponsiveContainer style={{ width: 'auto' }}>
          <Table style={{ marginTop: 15, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th>{t(`Project.Email`)}</Th>
                <ThRight />
              </tr>
            </thead>

            <tbody>
              {orderingParties?.map((email, index) => (
                <Tr key={index}>
                  <Td style={{ width: 25 }}>
                    <BsPersonBadge />
                  </Td>
                  <Td>{email}</Td>

                  <TdRight>
                    <div
                      style={{
                        verticalAlign: 'middle',
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      {!isEditAvailable && (
                        <>
                          <Impersonate email={email} />
                          <SendForgotPasswordEmail email={email} />
                        </>
                      )}

                      {isEditAvailable && (
                        <ProjectOrderingPartyActionDelete
                          email={email}
                          setValidationErrors={setValidationErrors}
                          handleDelete={handleDelete}
                        />
                      )}
                    </div>
                  </TdRight>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

ProjectOrderingPartiesList.displayName = 'ProjectOrderingPartiesList';
