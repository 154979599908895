import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  getPortfolioExportBundle,
  selectPortfolioExportBundleIsPosting,
} from 'api/portfolio/portfolioExportBundleSlice';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFileExcel } from 'react-icons/fa';
import { isPiosReadOnly } from 'utils';
import { MyPortfoliosList } from './components/MyPortfoliosList';

export const MyPortfolios = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const isPosting = useAppSelector(selectPortfolioExportBundleIsPosting);

  const { data } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user
      ? {
          filter: {
            onlyMine: true,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const hasData = data?.portfolios && data.portfolios.length > 0;

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isViewReadOnly || !accessToken.user) {
      return;
    }

    dispatch(
      getPortfolioExportBundle({
        filter: { onlyMine: true },
        bundleName: t('MyPortfolios.Title'),
      })
    );
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('MyPortfolios.Title')}</Heading1>

        {hasData && (
          <ViewHeaderActions>
            <LinkWithTooltip
              handleClick={(e) => !isPosting && handleExportDownload(e)}
              title={t('MyPortfolios.Export.Download.TooltipTitle')}
              tooltipTitle={t('MyPortfolios.Export.Download.TooltipTitle')}
              tooltip={t('Portfolios.Export.Download.Tooltip')}
              icon={isPosting ? <LoaderSmall /> : <FaRegFileExcel />}
              isInverted={true}
              isDisabled={isPosting}
            />
          </ViewHeaderActions>
        )}
      </ViewHeader>

      {data?.portfolios && data.portfolios.length === 0 && (
        <Card>
          <CardBody>{t('MyPortfolios.NoPortfolios')}</CardBody>
        </Card>
      )}

      <MyPortfoliosList />
    </>
  );
};

MyPortfolios.displayName = 'MyPortfolios';
