import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Item, Tree } from '@dimatech/shared/lib/components/tree';
import { TagMutation } from '@dimatech/shared/lib/models';
import { getAllChildren } from '@dimatech/shared/lib/utils';
import {
  useGetNationalTagsQuery,
  useMoveNationalTagMutation,
} from 'api/nationalTag/nationalTagApi';
import {
  nationalTagActions,
  selectSelectedNationalTag,
} from 'api/nationalTag/nationalTagSlice';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTag, BsTags } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { isAdminReadOnly } from 'utils';

export const TagsTree = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedTag = useSelector(selectSelectedNationalTag);
  const { accessToken } = useContext(AuthenticationContext);
  const [tree, setTree] = useState<Item[] | undefined>();
  const [moveTag, { error: moveErrors }] = useMoveNationalTagMutation();

  const isReadOnly = isAdminReadOnly(accessToken);

  const { data: tags } = useGetNationalTagsQuery();

  const handleSelect = (item: Item) => {
    const { icon, items, ...tag } = item;
    dispatch(nationalTagActions.selectedNationalTag(tag));
  };

  const handleDrop = (id: string, parentId: string) => {
    const draggedTag = tags?.find((item) => item.id === id);
    const hasChildren = tags ? getAllChildren(tags, [id]) : [];

    if (
      isReadOnly ||
      id === parentId ||
      draggedTag?.parentId === parentId ||
      hasChildren.length >= 1
    ) {
      return;
    }

    moveTag({ tagId: id, parentId });
  };

  const mapToItems = (tags: TagMutation[], parentId: string | null): Item[] => {
    const items = tags
      .filter((tag) => tag.parentId === parentId)
      .map((tag) => {
        const children = mapToItems(tags, tag.id || null);

        return {
          content: tag.displayName,
          id: tag.id,
          parentId: tag.parentId,
          displayName: tag.displayName,
          description: tag.description,
          items: children,
          icon: children.length > 0 ? <BsTags /> : <BsTag />,
          isOpen: true,
        } as Item;
      });

    return items;
  };

  useEffect(() => {
    const items = tags ? mapToItems(tags, null) : [];

    setTree(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  return (
    <>
      {(!tree || tree.length === 0) && !selectedTag && (
        <div>{t('GlobalAdministrate.NationalTag.NoTags')}</div>
      )}

      <AlertErrors
        style={{ padding: 10 }}
        error={moveErrors}
        translationPath="Administrate.NationalTag.ValidationError"
      />

      {tree && tree.length > 0 && (
        <Tree
          items={tree}
          onSelect={handleSelect}
          selected={selectedTag?.id}
          highlightItems={
            !selectedTag?.id && selectedTag?.parentId
              ? [selectedTag?.parentId]
              : []
          }
          onDrop={handleDrop}
        />
      )}
    </>
  );
};

TagsTree.displayName = 'TagsTree';
