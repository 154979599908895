import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { Administrators } from '@dimatech/features-core/lib/features/administrate-users/Administrators';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { searchPortfolios } from 'api/portfolio/portfolioSearchSlice';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PortfolioOwners } from './components/PortfolioOwners';
import { ProjectEffectRealisationResponsible } from './components/ProjectEffectRealisationResponsible';
import { ProjectManagers } from './components/ProjectManagers';
import { ProjectOrderingParties } from './components/ProjectOrderingParties';
import { Reviewers } from './components/Reviewers';

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { data: entities, isLoading } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const rootEntityId = entities?.find((e) => !e.parentId);

  const isViewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isViewersEnabled];
  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
  const isProjectRolesEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectRolesEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isProjectRoleOrderingPartyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectRoleOrderingPartyEnabled];

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigateSearch = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation();

    dispatch(
      searchPortfolios({
        entityIds: [id],
        paginator: {
          page: 1,
          pageSize: 50,
          orderBy: 'portfolioName',
          orderDirection: SortDirection.Asc,
        },
      })
    );

    navigate('/search', {
      state: { id },
    });
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Users.Title')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === 0}
          handleSelect={() => handleSelectTab(0)}
        >
          {t('UserRoles.PortfolioOwner.Title')}
        </Tab>

        {!accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === 1}
            handleSelect={() => handleSelectTab(1)}
          >
            {t('UserRoles.CustomerAdmin.Title')}
          </Tab>
        )}

        {isViewersEnabledFlagOn && (
          <Tab
            isSelected={selectedTabIndex === 2}
            handleSelect={() => handleSelectTab(2)}
          >
            {t('UserRoles.Reader.Title')}
          </Tab>
        )}
        {isProjectIdeasReviewersEnabledFlagOn && !accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === 3}
            handleSelect={() => handleSelectTab(3)}
          >
            {t('UserRoles.PiosReviewer.Title')}
          </Tab>
        )}
        {isProjectRolesEnabledFlagOn && !accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === 4}
            handleSelect={() => handleSelectTab(4)}
          >
            {t('UserRoles.PiosProjectManager.Title')}
          </Tab>
        )}
        {isProjectRolesEnabledFlagOn &&
          isProjectEffectRealisationEnabledFlagOn &&
          !accessToken.isBaseLicense && (
            <Tab
              isSelected={selectedTabIndex === 5}
              handleSelect={() => handleSelectTab(5)}
            >
              {t('UserRoles.PiosEffectRealizationResponsible.Title')}
            </Tab>
          )}

        {isProjectRoleOrderingPartyEnabledFlagOn && !accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === 6}
            handleSelect={() => handleSelectTab(6)}
          >
            {t('UserRoles.PiosOrderingParty.Title')}
          </Tab>
        )}
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === 0 && <PortfolioOwners />}

          {selectedTabIndex === 1 && (
            <Administrators
              handleNavigateSearch={handleNavigateSearch}
              isLoading={isLoading}
              entities={entities}
            />
          )}

          {selectedTabIndex === 2 && (
            <>
              {!accessToken.isBaseLicense ? (
                <Viewers
                  handleNavigateSearch={handleNavigateSearch}
                  isLoading={isLoading}
                  entities={entities}
                />
              ) : (
                <Viewers
                  isEntityFilterShown={false}
                  rootEntityId={rootEntityId?.id}
                />
              )}
            </>
          )}

          {selectedTabIndex === 3 && (
            <Reviewers
              handleNavigateSearch={handleNavigateSearch}
              isLoading={isLoading}
              entities={entities}
            />
          )}
          {selectedTabIndex === 4 && <ProjectManagers />}
          {selectedTabIndex === 5 && <ProjectEffectRealisationResponsible />}
          {selectedTabIndex === 6 && <ProjectOrderingParties />}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
