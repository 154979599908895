var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTagsQuery } from '../api/tag/tagApi';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
export var SelectTags = function (_a) {
    var tagIds = _a.tagIds, setTagIds = _a.setTagIds, placeholder = _a.placeholder, filter = _a.filter, _b = _a.inverted, inverted = _b === void 0 ? false : _b, _c = _a.showSelectAll, showSelectAll = _c === void 0 ? true : _c, tags = _a.tags, style = _a.style;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _d = useState(), nodes = _d[0], setNodes = _d[1];
    var _e = useGetTagsQuery(!tags && accessToken.customerId ? accessToken.customerId : skipToken), data = _e.data, isLoading = _e.isLoading;
    var handleChange = function (selectedNodes) {
        setTagIds(selectedNodes.map(function (node) { return node.id; }));
    };
    useEffect(function () {
        var list = tags !== null && tags !== void 0 ? tags : data;
        if (list) {
            setNodes(list
                .filter(function (tag) { return !filter || filter(tag); })
                .map(function (tag) {
                var _a;
                return ({
                    id: tag.id,
                    name: tag.displayName,
                    description: tag.description,
                    parentId: (_a = tag.parentId) !== null && _a !== void 0 ? _a : null,
                    payload: tag,
                });
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, tags]);
    if (isLoading) {
        return (_jsx("div", __assign({ style: { margin: 10 } }, { children: _jsx(LoaderSmall, {}) })));
    }
    return (_jsx(_Fragment, { children: nodes && (_jsx(MultiSelect, { inverted: inverted, style: style, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : t('Search.AnyTag'), options: nodes, setSelected: function (selectedNodes) {
                handleChange(selectedNodes);
            }, showSelectAll: showSelectAll, selected: nodes.filter(function (node) { return tagIds === null || tagIds === void 0 ? void 0 : tagIds.includes(node.id); }) })) }));
};
SelectTags.displayName = 'SelectTags';
