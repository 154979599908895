import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ViewCentered } from 'views';

export const Unauthorized = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const from = (location?.state as Record<string, string>)?.from as string;

  return (
    <ViewCentered title={t('Unauthorized.Title')} style={{ width: 300 }}>
      <Heading2>{t('Unauthorized.Title')}</Heading2>
      <AlertWarning style={{ marginTop: 20 }}>
        {t('Unauthorized.Text')}
      </AlertWarning>

      {from && (
        <div style={{ marginTop: 20 }}>
          <Link to={from}>{t('Common.UI.Back')}</Link>
        </div>
      )}
    </ViewCentered>
  );
};

Unauthorized.displayName = 'Unauthorized';
