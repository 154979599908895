var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Buttons, ButtonSecondary, Input, Label, MultiSelect, TextArea, } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGenerateApiKeyMutation, useUpdateApiKeyMutation, } from '../../../api/customer/customerApiApi';
import { customerApiKeyActions, selectSelectedApiKey, } from '../../../api/customer/customerApiKeySlice';
import { ApiRoles } from '../../../models';
export var ApiKeyEditFields = function (_a) {
    var _b, _c;
    var isAdding = _a.isAdding, setIsAdding = _a.setIsAdding, setError = _a.setError, setApiKey = _a.setApiKey;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var selectedApiKey = useSelector(selectSelectedApiKey);
    var _d = useState(true), isValid = _d[0], setIsValid = _d[1];
    var _e = useGenerateApiKeyMutation(), generate = _e[0], _f = _e[1], generateErrors = _f.error, generatePosting = _f.isLoading;
    var _g = useUpdateApiKeyMutation(), updateApiKey = _g[0], _h = _g[1], updateErrors = _h.error, updatePosting = _h.isLoading;
    var errors = generateErrors || updateErrors;
    var optionsRoles = Object.keys(ApiRoles).map(function (value) { return ({
        id: value,
        name: t("Roles.".concat(value)),
        parentId: null,
        payload: value,
    }); });
    var handleCancel = function () {
        dispatch(customerApiKeyActions.selectedApiKey());
        if (isAdding === true) {
            setIsAdding(false);
        }
    };
    var handleSave = function () {
        setIsValid(true);
        if ((selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.roles.length) === 0 || !(selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.name)) {
            setIsValid(false);
            return;
        }
        if (selectedApiKey) {
            if (selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.id) {
                updateApiKey(__assign({}, selectedApiKey));
            }
            else {
                generate(__assign({}, selectedApiKey))
                    .unwrap()
                    .then(function (result) { return (setApiKey ? setApiKey(result) : undefined); });
            }
        }
        if (isAdding === true) {
            setIsAdding(false);
        }
        dispatch(customerApiKeyActions.selectedApiKey());
    };
    var handleChange = function (event) {
        var _a;
        var target = event.currentTarget;
        if (selectedApiKey) {
            dispatch(customerApiKeyActions.selectedApiKey(__assign(__assign({}, selectedApiKey), (_a = {}, _a[target.name] = target.value, _a))));
        }
    };
    useEffect(function () {
        if (isAdding) {
            dispatch(customerApiKeyActions.selectedApiKey({
                name: '',
                description: '',
                roles: [],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdding]);
    useEffect(function () {
        setError(errors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    if (generatePosting || updatePosting) {
        return _jsx(LoaderSmall, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "name" }, { children: t('Administrate.Account.Api.Name') })), _jsx(Input, { type: "text", id: "name", name: "name", value: (_b = selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.name) !== null && _b !== void 0 ? _b : '', onChange: function (e) { return handleChange(e); }, invalid: !isValid && !(selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.name) })] }), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "rolesOfLockInEffects" }, { children: t('Administrate.Account.Api.Roles') })), _jsx(MultiSelect, { style: {
                            maxWidth: isAdding ? '100%' : '300px',
                        }, isValid: selectedApiKey && (isValid || (selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.roles.length) > 0), showSelectAll: false, placeholder: "- ".concat(t('Common.UI.Select')), placeholderAll: optionsRoles.map(function (role) { return role.name; }).join(', '), options: optionsRoles !== null && optionsRoles !== void 0 ? optionsRoles : [], setSelected: function (selectedNodes) {
                            var roles = selectedNodes.map(function (node) { return node.payload; });
                            dispatch(customerApiKeyActions.selectedApiKey(__assign(__assign({}, selectedApiKey), { roles: roles })));
                        }, selected: optionsRoles.filter(function (node) { var _a; return (_a = selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.roles) === null || _a === void 0 ? void 0 : _a.includes(node.id); }) })] }), _jsxs("div", __assign({ style: { flexDirection: 'column' } }, { children: [_jsx("div", { children: _jsx(Label, __assign({ htmlFor: "description" }, { children: t('Administrate.Account.Api.Description') })) }), _jsx(TextArea, { id: "description", name: "description", value: (_c = selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.description) !== null && _c !== void 0 ? _c : '', onChange: function (e) { return handleChange(e); } })] })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: handleCancel }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave }, { children: isAdding
                            ? t('Administrate.Account.Api.Add.Title')
                            : t('Common.Form.Save') }))] })] }));
};
ApiKeyEditFields.displayName = 'ApiKeyEditFields';
