import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useTitle } from '@dimatech/shared/lib/hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ProjectAdd } from 'features/project/components/ProjectAdd';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OverviewTabIndex } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isReadOnly } from 'utils';
import { PortfolioBudgetAndBalanceOverTime } from './components/PortfolioBudgetAndBalanceOverTime';
import { PortfolioHeader } from './components/PortfolioHeader';
import { PortfolioKeyStatistics } from './components/PortfolioKeyStatistics';
import { PortfolioProjectsBudgetAndBalance } from './components/PortfolioProjectsBudgetAndBalance';
import { PortfolioProjectsList } from './components/PortfolioProjectsList';
import { PortfolioProjectsTagTreeTable } from './components/PortfolioProjectsTagTreeTable';
import { PortfolioProjectsTimeline } from './components/PortfolioProjectsTimeline';

export const Portfolio = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);
  const { id = '' } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { setTitle } = useTitle();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('balance-over-time')
      ? OverviewTabIndex.BalanceOverTime
      : location.pathname.endsWith('balance')
      ? OverviewTabIndex.Balance
      : location.pathname.endsWith('tag')
      ? OverviewTabIndex.Tag
      : location.pathname.endsWith('table')
      ? OverviewTabIndex.Table
      : location.pathname.endsWith('timeline')
      ? OverviewTabIndex.Timeline
      : OverviewTabIndex.Summary
  );

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];
  const isGanttEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isGanttEnabled];

  const { data: portfolio } = useGetPortfolioOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: { portfolioIds: [id] },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const { data: projects } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: {
            ...filter,
            portfolioIds: [id],
            entityIds: undefined,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/portfolio/${id}/${
        index === OverviewTabIndex.BalanceOverTime
          ? 'balance-over-time'
          : index === OverviewTabIndex.Balance
          ? 'balance'
          : index === OverviewTabIndex.Tag
          ? 'tag'
          : index === OverviewTabIndex.Table
          ? 'table'
          : index === OverviewTabIndex.Timeline
          ? 'timeline'
          : 'summary'
      }`,
      {
        state: { from: location.pathname },
      }
    );
  };

  useEffect(() => {
    if (portfolio) {
      setTitle(`${t('Portfolio.Title')} - ${portfolio.portfolioName}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio]);

  return (
    <>
      {portfolio && <PortfolioHeader portfolio={portfolio} />}

      {portfolio && (
        <Card>
          <CardBody>
            <SelectExtendedFilters
              isEntityFilterShown={false}
              isIncludeMineOnlyFilterShown={false}
              isPeriodFilterShown={true}
              isPhaseFilterShown={true}
              isStatusFilterShown={true}
              isResetFilterShown={true}
              isCustomDimensionFiltersShown={true}
              isProjectReadyForExportFilterShown={false}
              isProjectPublishedPubliclyFilterShown={true}
              isPeriodMonthFilterShown={true}
            />
          </CardBody>
        </Card>
      )}

      {portfolio && portfolio.totalProjects === 0 && (
        <Card>
          <CardBody>
            <div style={{ marginBottom: 20 }}>
              {t('Portfolio.NoProjectsInPortfolio')}
            </div>

            {!isReadOnly(portfolio) && (
              <ProjectAdd projects={[]} portfolio={portfolio} />
            )}
          </CardBody>
        </Card>
      )}

      {portfolio &&
        portfolio.totalProjects > 0 &&
        projects?.projects &&
        projects.projects.length === 0 && (
          <Card>
            <CardBody>
              {t('Portfolio.NoProjectsInPortfolioMatchingFilter')}
            </CardBody>
          </Card>
        )}

      {projects?.projects && projects.projects.length > 0 && (
        <Tabs>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Summary}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Summary)}
          >
            {t('Portfolio.KeyStatistics.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Balance}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Balance)}
          >
            {t('Portfolio.BudgetAndBalance.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.BalanceOverTime}
            handleSelect={() =>
              handleSelectTab(OverviewTabIndex.BalanceOverTime)
            }
          >
            {t('Portfolio.BudgetAndBalanceOverTime.Title')}
          </Tab>
          {isTagsEnabledFlagOn && (
            <Tab
              isSelected={selectedTabIndex === OverviewTabIndex.Tag}
              handleSelect={() => handleSelectTab(OverviewTabIndex.Tag)}
            >
              {t('Portfolio.TagTable.Title')}
            </Tab>
          )}
          {isGanttEnabledFlagOn && (
            <Tab
              isSelected={selectedTabIndex === OverviewTabIndex.Timeline}
              handleSelect={() => handleSelectTab(OverviewTabIndex.Timeline)}
            >
              {t('Projects.Timeline.Title')}
            </Tab>
          )}
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Table}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Table)}
          >
            {t('Portfolio.List.Title')}
          </Tab>
        </Tabs>
      )}

      {selectedTabIndex === OverviewTabIndex.Summary && (
        <PortfolioKeyStatistics />
      )}

      {selectedTabIndex === OverviewTabIndex.Balance && (
        <PortfolioProjectsBudgetAndBalance />
      )}

      {selectedTabIndex === OverviewTabIndex.BalanceOverTime && (
        <PortfolioBudgetAndBalanceOverTime />
      )}

      {selectedTabIndex === OverviewTabIndex.Tag && isTagsEnabledFlagOn && (
        <PortfolioProjectsTagTreeTable />
      )}

      {selectedTabIndex === OverviewTabIndex.Timeline &&
        isGanttEnabledFlagOn && <PortfolioProjectsTimeline />}

      {selectedTabIndex !== OverviewTabIndex.Timeline && (
        <PortfolioProjectsList />
      )}
    </>
  );
};

Portfolio.displayName = 'Portfolio';
