import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  HelpIcon,
  HelpIconCentered,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Button,
  ButtonFooter,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  piosActions,
  selectFilter,
  selectSelectedProjectIdea,
} from 'api/piosSlice';
import { useUpdateProjectIdeaMutation } from 'api/project/projectIdeaApi';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Calculation,
  CalculationType,
  CommonRoles,
  Dimension,
  DimensionDisplayType,
  DimensionType,
  Permission,
  PiosColors,
  ProjectIdea,
  ProjectIdeaAction,
} from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { hasPermission, isAccountAdmin, updateCurrentUrl } from 'utils';
import { IdeaClassification } from './IdeaClassification';
import { IdeaListTableGroupHeader } from './IdeaListTableGroupHeader';
import { IdeaStatus } from './IdeaStatus';

// TODO: Fix, split code
/* eslint-disable max-lines-per-function */

export const IdeaListClassify = withTheme(
  ({
    ideas,
    classifications,
    calculations,
    theme,
  }: {
    ideas: ProjectIdea[];
    classifications: Dimension[];
    calculations?: Calculation[];
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectAndNavigate = useSelectAndNavigate();
    const filter = useAppSelector(selectFilter);

    const selectedIdea = useAppSelector(selectSelectedProjectIdea);

    const { accessToken } = useContext(AuthenticationContext);

    const columnCountStake = classifications.filter(
      (c) =>
        c.type === DimensionType.ClassificationStake &&
        c.displayType === DimensionDisplayType.Rating
    ).length;

    const columnCountBenefit = classifications.filter(
      (c) =>
        c.type === DimensionType.ClassificationBenefit &&
        c.displayType === DimensionDisplayType.Rating
    ).length;

    const hasCalculations =
      ideas.some(
        (idea) =>
          idea.calculation && idea.calculation.type !== CalculationType.System
      ) &&
      calculations &&
      calculations.length > 0;

    const isAdmin =
      accessToken.customerId &&
      (isAccountAdmin(accessToken) ||
        accessToken.isInRole(CommonRoles.CustomerAdmin));

    const [hasChanges, setHasChanges] = useState(false);
    const [updateProjectIdea] = useUpdateProjectIdeaMutation();

    const {
      items: sortedIdeas,
      setPage,
      paginator,
      sorter,
    } = useUiSortablePaginator({
      orderBy: 'title',
      pageSize: 25,
      data: {
        totalRecords: ideas.length,
        records: ideas,
      },
    });

    const handleChange = (idea: ProjectIdea) => {
      dispatch(piosActions.setSelectedProjectIdea({ ...idea }));

      setHasChanges(true);
    };

    const handleSave = (idea: ProjectIdea) => {
      setHasChanges(false);

      updateProjectIdea(idea);
    };

    const handleCancel = () => {
      setHasChanges(false);

      dispatch(piosActions.setSelectedProjectIdea(undefined));

      updateCurrentUrl(`/project-idea/${ProjectIdeaAction.Classify}/`);
    };

    const handleSelect = (idea: ProjectIdea) => {
      if (selectedIdea?.id === idea.projectId) {
        return;
      }

      selectAndNavigate(ProjectIdeaAction.Classify, idea);
    };

    const groupBorderStyle = `1px solid ${theme.colors.border}`;

    useEffect(() => {
      setPage(1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
      dispatch(piosActions.setHasUnsavedChanges(hasChanges));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasChanges]);

    return (
      <>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          {t('FutureProjects.ProjectIdea.ClassifyText')}
        </div>

        {classifications.length === 0 && (
          <AlertInfo style={{ marginTop: 0, marginBottom: 30 }}>
            {t('FutureProjects.ProjectIdea.NoClassifications')}
            {isAdmin && (
              <div>
                <Link to="/administrate/custom-classifications">
                  {t('FutureProjects.ProjectIdea.AddClassifications')}
                </Link>
              </div>
            )}
          </AlertInfo>
        )}

        {ideas.length === 0 && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {t('FutureProjects.ProjectIdea.NoProjectIdeas')}
          </div>
        )}

        <Pagination
          currentPage={paginator.page}
          totalCount={ideas.length}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
        />

        <TableResponsiveContainer>
          <Table>
            {sortedIdeas.length > 0 && (
              <>
                <IdeaListTableGroupHeader
                  startColumnsCount={hasCalculations ? 3 : 2}
                  classifications={classifications}
                />

                <thead>
                  <tr>
                    <ThCentered
                      className="narrow"
                      sortKey="projectPhase"
                      sorter={sorter}
                      style={{ width: 50 }}
                    >
                      {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                    </ThCentered>
                    <Th
                      className="narrow"
                      sortKey="externalId"
                      sorter={sorter}
                      style={{ width: 60 }}
                    >
                      {t('Project.Id')}
                    </Th>
                    <Th>
                      <SortElements>
                        <SortElement sortKey="title" sorter={sorter}>
                          {t('FutureProjects.ProjectIdea.ProjectIdea')}
                        </SortElement>
                        <SortElement sortKey="portfolioName" sorter={sorter}>
                          {t('Portfolio.Portfolio')}
                        </SortElement>
                      </SortElements>
                    </Th>

                    <ThCentered
                      sortKey="efficiencyInnovation"
                      sorter={sorter}
                      className="narrow"
                    >
                      {t('FutureProjects.ProjectIdea.EfficiencyInnovation')}
                    </ThCentered>
                    <ThCentered
                      sortKey="internalExternal"
                      sorter={sorter}
                      className="narrow"
                    >
                      {t('FutureProjects.ProjectIdea.InternalExternal')}
                    </ThCentered>

                    {hasCalculations && (
                      <ThCentered
                        sortKey="calculationId"
                        sorter={sorter}
                        className="narrow"
                      >
                        <HelpIconCentered>
                          {t('FutureProjects.ProjectIdea.Calculation')}

                          <HelpIcon
                            showTiny={true}
                            style={{ marginLeft: 5 }}
                            title={t('FutureProjects.ProjectIdea.Calculation')}
                            text={t(
                              'FutureProjects.ProjectIdea.CalculationDescription'
                            )}
                          />
                        </HelpIconCentered>
                      </ThCentered>
                    )}

                    {classifications?.map((classification, index) => (
                      <ThCentered
                        key={classification.id}
                        className="narrow"
                        style={{
                          borderLeft:
                            index === 0 &&
                            columnCountBenefit + columnCountStake > 0
                              ? groupBorderStyle
                              : undefined,
                          borderRight:
                            index === columnCountBenefit - 1 ||
                            index === columnCountBenefit + columnCountStake - 1
                              ? groupBorderStyle
                              : undefined,
                        }}
                        sortKey={`classification-${classification.id}`}
                        sorter={sorter}
                        sortPredicate={(projectA, projectB) => {
                          const dimensionA = projectA.customDimensions?.find(
                            (cd) => cd.projectDimensionId === classification.id
                          );
                          const dimensionB = projectB.customDimensions?.find(
                            (cd) => cd.projectDimensionId === classification.id
                          );

                          // This is used to force empty/null string to be sorted last
                          const forceLast = 'ÖÖ';

                          return {
                            a: dimensionA?.value ?? forceLast,
                            b: dimensionB?.value ?? forceLast,
                          };
                        }}
                      >
                        <HelpIconCentered>
                          {classification.name}

                          {classification.description && (
                            <HelpIcon
                              showTiny={true}
                              style={{ marginLeft: 5 }}
                              title={classification.name}
                              text={classification.description}
                            />
                          )}
                        </HelpIconCentered>
                      </ThCentered>
                    ))}

                    <Th style={{ width: 20 }} />
                  </tr>
                </thead>
              </>
            )}

            <tbody>
              {sortedIdeas.map((idea) => (
                <Fragment key={idea.id}>
                  <Tr
                    onSelect={(e) => {
                      if (
                        ['INPUT', 'SELECT'].includes(
                          (e.target as HTMLElement).nodeName
                        )
                      ) {
                        return;
                      }

                      handleSelect(idea);
                    }}
                  >
                    <TdCentered className="narrow">
                      <IdeaStatus status={idea.projectPhase} />
                    </TdCentered>
                    <Td className="narrow">{idea.externalId}</Td>
                    <Td>
                      <div className="b">{idea.title}</div>
                      <div className="i">{idea.portfolioName}</div>
                    </Td>

                    {hasPermission(Permission.EditIdea, idea) &&
                      selectedIdea &&
                      selectedIdea?.id === idea.id && (
                        <>
                          <TdCentered className="narrow">
                            <Progress
                              width="80px"
                              value={idea.efficiencyInnovation}
                              color={PiosColors.efficiency}
                              backgroundColor={PiosColors.innovation}
                            />
                          </TdCentered>
                          <TdCentered className="narrow">
                            <Progress
                              width="80px"
                              value={idea.internalExternal}
                              color={PiosColors.internal}
                              backgroundColor={PiosColors.external}
                            />
                          </TdCentered>

                          {hasCalculations && (
                            <TdCentered className="narrow">
                              <HelpIconCentered>
                                {idea.calculation?.name}

                                {idea.calculation?.description && (
                                  <HelpIcon
                                    showTiny={true}
                                    style={{ marginLeft: 5 }}
                                    title={idea.calculation?.name}
                                    text={idea.calculation?.description}
                                  />
                                )}
                              </HelpIconCentered>
                            </TdCentered>
                          )}

                          {classifications?.map((classification, index) => (
                            <TdCentered
                              key={classification.id}
                              className="narrow"
                              style={{
                                borderLeft:
                                  index === 0 &&
                                  columnCountBenefit + columnCountStake > 0
                                    ? groupBorderStyle
                                    : undefined,
                                borderRight:
                                  index === columnCountBenefit - 1 ||
                                  index ===
                                    columnCountBenefit + columnCountStake - 1
                                    ? groupBorderStyle
                                    : undefined,
                              }}
                            >
                              <IdeaClassification
                                idea={selectedIdea}
                                setIdea={(newIdea) => handleChange(newIdea)}
                                classification={classification}
                                isEditing={true}
                              />
                            </TdCentered>
                          ))}

                          <Td />
                        </>
                      )}

                    {(!hasPermission(Permission.EditIdea, idea) ||
                      selectedIdea?.id !== idea.id) && (
                      <>
                        <TdCentered className="narrow">
                          <Progress
                            width="80px"
                            value={idea.efficiencyInnovation}
                            color={PiosColors.efficiency}
                            backgroundColor={PiosColors.innovation}
                          />
                        </TdCentered>
                        <TdCentered className="narrow">
                          <Progress
                            width="80px"
                            value={idea.internalExternal}
                            color={PiosColors.internal}
                            backgroundColor={PiosColors.external}
                          />
                        </TdCentered>

                        {hasCalculations && (
                          <TdCentered className="narrow">
                            <HelpIconCentered>
                              {idea.calculation?.name}

                              {idea.calculation?.description && (
                                <HelpIcon
                                  showTiny={true}
                                  style={{ marginLeft: 5 }}
                                  title={idea.calculation?.name}
                                  text={idea.calculation?.description}
                                />
                              )}
                            </HelpIconCentered>
                          </TdCentered>
                        )}

                        {classifications?.map((classification, index) => (
                          <TdCentered
                            key={classification.id}
                            className="narrow"
                            style={{
                              borderLeft:
                                index === 0 &&
                                columnCountBenefit + columnCountStake > 0
                                  ? groupBorderStyle
                                  : undefined,
                              borderRight:
                                index === columnCountBenefit - 1 ||
                                index ===
                                  columnCountBenefit + columnCountStake - 1
                                  ? groupBorderStyle
                                  : undefined,
                            }}
                          >
                            <IdeaClassification
                              idea={idea}
                              classification={classification}
                            />
                          </TdCentered>
                        ))}

                        <TdRight>
                          {hasPermission(Permission.EditIdea, idea) && (
                            <BadgeMini
                              data-tip
                              data-for="tt-project-edit"
                              onClick={() => {
                                setHasChanges(false);
                                handleSelect(idea);
                              }}
                            >
                              <BsPencil />
                            </BadgeMini>
                          )}
                        </TdRight>
                      </>
                    )}
                  </Tr>

                  {hasPermission(Permission.EditIdea, idea) &&
                    selectedIdea &&
                    selectedIdea?.id === idea.id && (
                      <Tr>
                        <TdRight
                          colSpan={200}
                          style={{
                            backgroundColor: theme.colors.surfaceVariant,
                          }}
                        >
                          <ButtonFooter style={{ margin: '-10px 0 30px 0' }}>
                            <Buttons>
                              <ButtonSecondary onClick={handleCancel}>
                                {hasChanges
                                  ? t('Common.Form.Cancel')
                                  : t('Common.UI.Close')}
                              </ButtonSecondary>
                              <Button
                                onClick={() => handleSave(selectedIdea)}
                                disabled={!hasChanges}
                              >
                                {t('Common.Form.Save')}
                              </Button>
                            </Buttons>
                          </ButtonFooter>
                        </TdRight>
                      </Tr>
                    )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>

        <Pagination
          currentPage={paginator.page}
          totalCount={ideas.length}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
        />
      </>
    );
  }
);

IdeaListClassify.displayName = 'IdeaListClassify';
