import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { selectSelectedProjectIdea } from 'api/piosSlice';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppSelector } from 'hooks';
import { Permission, ProjectIdeaAction } from 'models';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from 'utils';
import { IdeaListEditButtons } from './IdeaListEditButtons';

export const IdeaHeader = ({
  backAction = ProjectIdeaAction.Identify,
  showEditButtons = true,
}: {
  backAction?: ProjectIdeaAction;
  showEditButtons?: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const selectAndNavigate = useSelectAndNavigate();

  const selectedIdea = useAppSelector(selectSelectedProjectIdea);

  const handleBack = () => {
    selectAndNavigate(backAction);
  };

  return (
    <ViewHeader style={{ marginLeft: 0, marginTop: 0 }}>
      <div>
        <BackButton handleBack={handleBack} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Heading1>{selectedIdea?.title}</Heading1>

        {selectedIdea && (
          <ButtonLink
            onClick={() => navigate(`/portfolio/${selectedIdea.portfolioId}`)}
          >
            {selectedIdea.portfolioName}
          </ButtonLink>
        )}
      </div>

      {selectedIdea && showEditButtons && (
        <ViewHeaderActions style={{ marginBottom: 0, marginRight: 10 }}>
          {hasPermission(Permission.EditIdea, selectedIdea) && (
            <IdeaListEditButtons idea={selectedIdea} showLargeIcons={true} />
          )}
        </ViewHeaderActions>
      )}
    </ViewHeader>
  );
};

IdeaHeader.displayName = 'IdeaHeader';
