import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { Overview } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency, formatAsPercent } from 'utils';

export const OverviewScatterChartTooltipSingle = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    payload: Overview;
  }[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const formatterPercent = (value: number | undefined) => {
    return value === undefined || isNaN(value)
      ? '-'
      : `${formatAsPercent(100 - value, '0', 0)} - ${formatAsPercent(
          value,
          '0',
          0
        )}`;
  };

  if (active && payload && payload.length) {
    const item = payload[0].payload;

    return (
      <TooltipStyle>
        <div className="tooltip-title">
          {payload[0].payload.label}
          {payload[0].payload.entityName && (
            <div className="tooltip-info">{payload[0].payload.entityName}</div>
          )}
        </div>

        <div>
          {t('Portfolio.EfficiencyInnovation')} :{' '}
          {formatterPercent(item.efficiencyInnovation)}
        </div>
        <div>
          {t('Portfolio.InternalExternal')} :{' '}
          {formatterPercent(item.internalExternal)}
        </div>
        <div>
          {t('Project.Budget', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}{' '}
          : {formatAsCurrency(item.budget)}
        </div>
      </TooltipStyle>
    );
  }

  return null;
};

OverviewScatterChartTooltipSingle.displayName =
  'OverviewScatterChartTooltipSingle';
