import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { FileIcon } from '@dimatech/shared/lib/components/FileIcon';
import {
  Button,
  ButtonSecondary,
  Input,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { useAddDocumentMutation } from 'api/project/projectDocumentApi';
import { config } from 'config';
import { ProjectDocumentType } from 'models';
import React, { CSSProperties, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';
import styled from 'styled-components';
import { isPiosReadOnly, toBase64 } from 'utils';

export const ProjectDocumentFileProperties = ({
  projectId,
  setIsAddingFile,
  style,
}: {
  projectId: string;
  setIsAddingFile: (isAddingFile: boolean) => void;
  style?: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [addDocument, { isLoading: isPosting }] = useAddDocumentMutation();
  const [file, setFile] = useState<File | undefined>(undefined);

  const [validationError, setValidationError] = useState('');

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError('');

    const file = event.currentTarget.files?.[0];

    if (file) {
      const sizeInKb = file.size / 1024;

      if (sizeInKb > config.pios.fileUpload.maxSizeInKb) {
        setValidationError(
          t('Portfolio.Document.Add.FileToBig', {
            maxSizeInMb: Math.ceil(config.pios.fileUpload.maxSizeInKb / 1024),
          })
        );
        return;
      }
    }

    setFile(file);
  };

  const handleCancel = () => {
    setIsAddingFile(false);
  };

  const handleFileUpload = async () => {
    if (isViewReadOnly || !file) {
      return;
    }

    addDocument({
      document: {
        content: await toBase64(file),
        name: file.name,
        contentType: file.type,
        type: ProjectDocumentType.Document,
      },
      projectId,
    })
      .unwrap()
      .then(() => setIsAddingFile(false));

    setFile(undefined);
  };

  return (
    <Style style={{ ...style }}>
      <Heading3>{t('Portfolio.Document.Add.Title')}</Heading3>

      {validationError && (
        <AlertWarning
          style={{ marginTop: 10, width: 'fit-content', minWidth: 200 }}
        >
          {validationError}
        </AlertWarning>
      )}

      {!file && (
        <Upload htmlFor="file">
          <BsUpload />
          <span>{t('Portfolio.Document.Add.SelectFile')}</span>
        </Upload>
      )}

      <Input type="file" id="file" onChange={(e) => handleChange(e)} />

      {file && (
        <div style={{ padding: '20px 0 10px 0' }}>
          <FileIcon fileName={file.name as string} />

          <span style={{ paddingLeft: 5 }}>{file.name}</span>
        </div>
      )}

      <ButtonSecondary type="button" onClick={handleCancel}>
        {t('Common.Form.Cancel')}
      </ButtonSecondary>

      <Button
        onClick={handleFileUpload}
        type="button"
        disabled={isViewReadOnly || isPosting || !file}
      >
        {t('Portfolio.Document.Add.Upload')}
      </Button>
    </Style>
  );
};

ProjectDocumentFileProperties.displayName = 'ProjectDocumentFileProperties';

const Style = styled.div`
  margin: 10px 0 20px 0;
  padding: 0 20px 10px 0;

  input[type='file'] {
    display: none;
  }

  button {
    margin-top: 20px;
    min-width: 100px;

    &:last-of-type {
      margin-left: 20px;
    }
  }
`;

const Upload = styled(Label)`
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: baseline;
  vertical-align: middle;

  margin-top: 20px;
  margin-bottom: 10px;

  border-bottom: 1px solid
    ${({ theme }: { theme: Theme }) => theme.colors.secondary};

  span {
    padding-left: 10px;
  }

  svg {
    margin-bottom: 0;
  }
`;
