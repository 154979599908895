import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { RadarChartAxisTick } from '@dimatech/features-core/lib/components/RadarChartAxisTick';
import { Theme } from '@dimatech/shared/lib/themes';
import { Dimension, DimensionDisplayType, ProjectIdea } from 'models';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar as RadarChartRadar,
} from 'recharts';
import styled, { withTheme } from 'styled-components';

export const IdeaRadarChart = withTheme(
  ({
    theme,
    project,
    classifications,
  }: {
    theme: Theme;
    project: ProjectIdea;
    classifications: Dimension[];
  }): JSX.Element | null => {
    if (classifications.length <= 2) {
      // No point in showing spider chart with 2 or less data points
      return null;
    }

    const data = classifications
      .filter(
        (classification) =>
          classification.displayType === DimensionDisplayType.Rating
      )
      .map((classification) => {
        const value =
          project.customDimensions?.find(
            (cd) => cd.projectDimensionId === classification.id
          )?.value ?? '';

        return {
          key: classification.name,
          val: !isNaN(parseInt(value)) ? +value : undefined,
        };
      });

    return (
      <Style>
        <Chart>
          <RadarChart
            style={{ height: '90%' }}
            cx="48%"
            cy="50%"
            outerRadius="70%"
            data={data}
          >
            <PolarGrid
              stroke={theme.colors.chart.axisStroke}
              strokeWidth={0.5}
            />
            <PolarAngleAxis
              dataKey="key"
              strokeWidth={0.5}
              stroke={theme.colors.chart.axisStroke}
              tickLine={false}
              tick={({ x, y, payload }) => (
                <RadarChartAxisTick x={x} y={y} payload={payload} />
              )}
              tickFormatter={(key: string) => key}
            />

            <PolarRadiusAxis
              angle={90}
              domain={[0, 6]}
              tickCount={5}
              strokeWidth={0.5}
              tickLine={false}
              tickFormatter={(value) => value}
              stroke={theme.colors.chart.axisStroke}
            />

            <RadarChartRadar
              name={project.title}
              dataKey="val"
              stroke={theme.colors.primary}
              fill={theme.colors.primary}
              fillOpacity={0}
            />
          </RadarChart>
        </Chart>
      </Style>
    );
  }
);

IdeaRadarChart.displayName = 'IdeaRadarChart';

const Style = styled.div`
  padding: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;

  > div {
    height: 330px;
    width: 410px;
  }
`;
