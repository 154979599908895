import { CacheTags, piosApi } from 'api/piosApi';
import { PortfolioMutation } from 'models';

const portfolioApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get portfolios
     */
    getPortfolios: build.query<
      PortfolioMutation[],
      {
        entityId?: string;
        _customerId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ entityId, _customerId }) =>
        `portfolios${entityId ? `?entityId=${entityId}` : ''}`,
      providesTags: [CacheTags.Portfolio],
      transformResponse: (result: PortfolioMutation[], _meta, arg) => {
        return result.sort((a, b) =>
          (a.name as string).localeCompare(b.name as string)
        );
      },
    }),

    /**
     * Create new portfolio
     */
    addPortfolio: build.mutation({
      query: (portfolio: PortfolioMutation) => ({
        url: `portfolios?entityId=${portfolio.entityId}`,
        method: 'POST',
        body: portfolio,
      }),
      invalidatesTags: [
        CacheTags.Portfolio,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.PortfolioHistory,
        CacheTags.PortfolioOwner,
      ],
      transformResponse: (result: string, _meta, arg) => {
        return {
          ...arg,
          id: result,
        };
      },
    }),

    /**
     * Update portfolio
     */
    updatePortfolio: build.mutation({
      query: (portfolio: PortfolioMutation) => ({
        url: `portfolios/${portfolio.id}`,
        method: 'PUT',
        body: {
          name: portfolio.name,
          description: portfolio.description,
          ownerEmail: portfolio.ownerEmail,
          externalId: portfolio.externalId,
          calculationId: portfolio.calculationId,
        },
      }),
      invalidatesTags: [
        CacheTags.Portfolio,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.ProjectIdeaOverview,
        CacheTags.PortfolioHistory,
      ],
    }),

    /**
     * Delete portfolio
     */
    deletePortfolio: build.mutation({
      query: (portfolioId: string) => ({
        url: `portfolios/${portfolioId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.Portfolio,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.PortfolioHistory,
      ],
    }),

    /**
     * Move portfolio
     */
    movePortfolio: build.mutation({
      query: ({
        portfolioId,
        entityId,
      }: {
        portfolioId: string;
        entityId?: string;
      }) => ({
        url: `portfolios/${portfolioId}/move/${entityId}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.Portfolio,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.PortfolioHistory,
      ],
      transformResponse: (_result, _meta, arg) => {
        return {
          id: arg.portfolioId,
          entityId: arg.entityId,
        };
      },
    }),
  }),
});

export const {
  useGetPortfoliosQuery,
  useAddPortfolioMutation,
  useDeletePortfolioMutation,
  useMovePortfolioMutation,
  useUpdatePortfolioMutation,
  useLazyGetPortfoliosQuery,
} = portfolioApi;
