import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useGetDefaultView } from 'hooks/useGetDefaultView';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { NavigationAdministrate } from './NavigationAdministrate';
import { NavigationFuture } from './NavigationFuture';
import { NavigationGlobalAdministrate } from './NavigationGlobalAdministrate';
import { NavigationHelp } from './NavigationHelp';
import { NavigationImplemented } from './NavigationImplemented';
import { NavigationOngoing } from './NavigationOngoing';
import { NavigationProducts } from './NavigationProducts';

export const Navigation = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);
  const defaultView = useGetDefaultView();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isDemoUser = accessToken.isInRole(CommonRoles.DemoUser);
  const isPortfolioOwner = accessToken.isInRole(CommonRoles.PortfolioOwner);
  const isReviewer = accessToken.isInRole(CommonRoles.PiosReviewer);
  const isProjectManager = accessToken.isInRole(CommonRoles.PiosProjectManager);
  const isEffectRealisationResponsible = accessToken.isInRole(
    CommonRoles.PiosEffectRealizationResponsible
  );

  const isStructuredNavigationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStructuredNavigationEnabled];

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const isAdmin =
    accessToken.customerId &&
    (isCustomerAccountAdmin ||
      isGlobalAdmin ||
      accessToken.isInRole(CommonRoles.CustomerAdmin));

  const isReader =
    isAdmin ||
    isResearcher ||
    isDemoUser ||
    isPortfolioOwner ||
    isReviewer ||
    isProjectManager ||
    isEffectRealisationResponsible ||
    accessToken.isInRole(CommonRoles.Reader);

  return (
    <>
      {isStructuredNavigationEnabledFlagOn && (
        <>
          <NavigationFuture isReader={isReader} defaultView={defaultView} />
          <NavigationOngoing isReader={isReader} defaultView={defaultView} />
        </>
      )}

      {!isStructuredNavigationEnabledFlagOn && (
        <>
          <NavigationOngoing isReader={isReader} defaultView={defaultView} />
          <NavigationFuture isReader={isReader} defaultView={defaultView} />
        </>
      )}

      <NavigationImplemented isReader={isReader} defaultView={defaultView} />
      <NavigationAdministrate />

      <NavigationGlobalAdministrate />
      <NavigationHelp />
      <NavigationProducts />
    </>
  );
};

Navigation.displayName = 'Navigation';
