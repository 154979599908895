import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { PaginatedResult, SearchFilter, SearchResult } from 'models';
import { RootState } from 'store';

const initialState: {
  data: PaginatedResult<SearchResult>;
  isLoading: boolean;
  isSearchCompleted: boolean;
  error?: string | null;
} = {
  data: {
    totalRecords: 0,
    records: [],
  },
  isLoading: false,
  isSearchCompleted: false,
  error: null,
};

export const searchPortfolios = createAsyncThunk(
  'search/portfolio',
  async (search: SearchFilter) => {
    const url = `${config.apiUrl.product.pios}search?pageNumber=${search.paginator.page}&pageSize=${search.paginator.pageSize}&orderBy.propertyName=${search.paginator.orderBy}&orderBy.direction=${search.paginator.orderDirection}`;

    const filters = `&filter.name=${search.name || ''}${
      search.entityIds && search.entityIds.length > 0
        ? search.entityIds
            .map((entityId) => entityId && `&filter.entityIds=${entityId}`)
            .join('')
        : ''
    }${search.period ? `&filter.period=${search.period}` : ''}${
      search.owner ? `&filter.owner=${search.owner}` : ''
    }${
      search.tagIds && search.tagIds.length > 0
        ? search.tagIds.map((tagId) => `&filter.tags=${tagId}`).join('')
        : ''
    }${
      search.dimensions && search.dimensions.length > 0
        ? search.dimensions
            .filter((dimension) => !!dimension.value)
            .map(
              (dimension, index) =>
                `&filter.custom[${index}].ProjectDimensionId=${dimension.projectDimensionId}&filter.custom[${index}].Value=${dimension.value}`
            )
            .join('')
        : ''
    }${
      search.projectManager
        ? `&filter.projectManager=${search.projectManager}`
        : ''
    }${
      search.effectRealisationResponsible
        ? `&filter.effectRealizationResponsible=${search.effectRealisationResponsible}`
        : ''
    }${search.periodMonth ? `&filter.periodMonth=${search.periodMonth}` : ''}`;

    const { data } = await axios.get(`${url}${filters}`);

    return data;
  }
);

export const {
  reducer: portfolioSearchReducer,
  actions: portfolioSearchActions,
} = createSlice({
  name: 'portfolio/search',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.isSearchCompleted = false;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(searchPortfolios.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isSearchCompleted = true;
        state.isLoading = false;
      })
      .addCase(searchPortfolios.pending, (state) => {
        state.isSearchCompleted = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchPortfolios.rejected, (state, action) => {
        state.data = initialState.data;
        state.isSearchCompleted = true;
        state.isLoading = false;
        state.error = action.error.name;
      })
      .addCase(appActions.resetApplication, () => initialState);
  },
});

export const selectSearchPortfoliosResult = (
  state: RootState
): PaginatedResult<SearchResult> => state.portfolioSearch.data;

export const selectSearchPortfoliosError = (
  state: RootState
): string | null | undefined => state.portfolioSearch.error;

export const selectSearchPortfoliosIsLoading = (state: RootState): boolean =>
  state.portfolioSearch.isLoading;

export const selectSearchPortfoliosIsSearchCompleted = (
  state: RootState
): boolean => state.portfolioSearch.isSearchCompleted;
