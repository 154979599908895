import { PiosFilter } from 'models';
import { filterProjectIdeaStatus } from 'utils';

const filterToQuery = (filter: PiosFilter) => {
  return `${
    filter.entityIds && filter.entityIds.length > 0
      ? filter.entityIds
          .map((entityId) => entityId && `&filter.entityIds=${entityId}`)
          .join('')
      : ''
  }${filter.onlyMine ? `&filter.onlyMine=${filter.onlyMine}` : ''}${
    filter.portfolioIds && filter.portfolioIds.length > 0
      ? filter.portfolioIds.map((id) => `&filter.portfolioIds=${id}`).join('')
      : ''
  }${
    filter.phases && filter.phases.length > 0
      ? filter.phases.map((phase) => `&filter.projectPhases=${phase}`).join('')
      : ''
  }${filter.status ? `&filter.projectStatus=${filter.status}` : ''}${
    filter.tagIds && filter.tagIds.length > 0
      ? filter.tagIds.map((tagId) => `&filter.tags=${tagId}`).join('')
      : ''
  }${
    filter.dimensions && filter.dimensions.length > 0
      ? filter.dimensions
          .filter((dimension) => !!dimension.value)
          .map(
            (dimension, index) =>
              `&filter.custom[${index}].ProjectDimensionId=${dimension.projectDimensionId}&filter.custom[${index}].Value=${dimension.value}`
          )
          .join('')
      : ''
  }`;
};

export const filterToPortfolioQuery = (filter: PiosFilter) => {
  //In the portfolios we do not use the filter by tags
  return `${filterToQuery({
    ...filter,
    tagIds: undefined,
  })}${filter.period ? `&filter.period=${filter.period}` : ''}${
    filter.periodMonth ? `&filter.periodMonth=${filter.periodMonth}` : ''
  }`;
};

export const filterToPortfolioByPeriodQuery = (filter: PiosFilter) => {
  //In the portfolios we do not use the filter by tags
  return `${filterToQuery({
    ...filter,
    tagIds: undefined,
  })}`;
};

export const filterToProjectQuery = (filter: PiosFilter) => {
  return `${filterToQuery(filter)}${
    filter.period ? `&filter.period=${filter.period}` : ''
  }${filter.periodMonth ? `&filter.periodMonth=${filter.periodMonth}` : ''}${
    filter.hasEffectTakeHomePlan
      ? `&filter.hasEffectTakeHomePlan=${filter.hasEffectTakeHomePlan}`
      : ''
  }${
    filter.isReadyForExport
      ? `&filter.isReadyForExport=${filter.isReadyForExport}`
      : ''
  }${
    filter.isPublishedPublicly
      ? `&filter.isPublishedPublicly=${filter.isPublishedPublicly}`
      : ''
  }`;
};

export const filterToProjectByPeriodQuery = (filter: PiosFilter) => {
  return `${filterToQuery(filter)}${
    filter.hasEffectTakeHomePlan
      ? `&filter.hasEffectTakeHomePlan=${filter.hasEffectTakeHomePlan}`
      : ''
  }${
    filter.isReadyForExport
      ? `&filter.isReadyForExport=${filter.isReadyForExport}`
      : ''
  }${
    filter.isPublishedPublicly
      ? `&filter.isPublishedPublicly=${filter.isPublishedPublicly}`
      : ''
  }`;
};

export const filterToProjectIdeaQuery = (
  filter: PiosFilter,
  isProjectIdeasReviewersEnabled: boolean
) => {
  const phasesIdea = filterProjectIdeaStatus(
    filter?.phasesIdea ? filter.phasesIdea : [],
    isProjectIdeasReviewersEnabled
  );

  return `${filterToQuery(filter)}${
    filter.ideaId ? `&filter.ideaId=${filter.ideaId}` : ''
  }${
    filter.phasesIdea && filter.phasesIdea.length > 0
      ? phasesIdea.map((phase) => `&filter.projectPhases=${phase}`).join('')
      : ''
  }${filter.period ? `&filter.period=${filter.period}` : ''}${
    filter.periodMonth ? `&filter.periodMonth=${filter.periodMonth}` : ''
  }`;
};

export const filterToProjectEffectRealisationQuery = (filter: PiosFilter) => {
  // Need to remove phases and period here so we can treat them specially for effect realisation
  return `${filterToProjectQuery({
    ...filter,
    phases: undefined,
    period: undefined,
  })}${
    filter.periodRange?.from
      ? `&filter.startYear=${filter.periodRange.from}`
      : ''
  }${filter.periodRange?.to ? `&filter.endYear=${filter.periodRange.to}` : ''}${
    filter.phasesEffectRealisation && filter.phasesEffectRealisation.length > 0
      ? filter.phasesEffectRealisation
          .map((phase) => `&filter.projectPhases=${phase}`)
          .join('')
      : ''
  }`;
};
