var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, Buttons, ButtonSecondary, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { useRevokeApiKeyMutation } from '../../../api/customer/customerApiApi';
export var ApiKeysListEditButtons = function (_a) {
    var apiKey = _a.apiKey, setError = _a.setError;
    var t = useTranslation().t;
    var _b = useRevokeApiKeyMutation(), revokeApiKey = _b[0], errorRevoke = _b[1].error;
    var _c = useState(false), isDeleting = _c[0], setIsDeleting = _c[1];
    var handleConfirmDelete = function (e) {
        e.stopPropagation();
        if (apiKey.id) {
            revokeApiKey(apiKey.id);
        }
        setIsDeleting(false);
    };
    var handleDelete = function (e, show) {
        e.stopPropagation();
        setIsDeleting(show);
    };
    useEffect(function () {
        setError(errorRevoke);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorRevoke]);
    return (_jsxs(_Fragment, { children: [isDeleting && (_jsxs(Modal, __assign({ title: t('Administrate.Account.Api.Delete.ConfirmTitle'), handleKeyEnter: function (e) { return handleConfirmDelete(e); }, handleKeyEsc: function (e) { return handleDelete(e, false); } }, { children: [_jsx("div", { children: t('Administrate.Account.Api.Delete.ConfirmText') }), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function (e) { return handleDelete(e, false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: function (e) { return handleConfirmDelete(e); } }, { children: t('Common.UI.Yes') }))] })] }))), _jsx(TooltipContent, { id: "tt-apiKey-delete", title: t('Administrate.Account.Api.Delete.TooltipTitle'), text: t('Administrate.Account.Api.Delete.Tooltip') }), _jsx(BadgeMini, __assign({ "data-tip": true, "data-for": "tt-apiKey-delete", onClick: function (e) { return handleDelete(e, true); } }, { children: _jsx(BsTrash, {}) }))] }));
};
ApiKeysListEditButtons.displayName = 'ApiKeysListEditButtons';
