import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { RadarChartAxisTick } from '@dimatech/features-core/lib/components/RadarChartAxisTick';
import { Theme } from '@dimatech/shared/lib/themes';
import { Dimension, DimensionDisplayType, ProjectIdea } from 'models';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar as RadarChartRadar,
} from 'recharts';
import styled, { withTheme } from 'styled-components';

export const IdeaRadarChartCompare = withTheme(
  ({
    theme,
    ideas,
    classifications,
  }: {
    theme: Theme;
    ideas: ProjectIdea[];
    classifications: Dimension[];
  }): JSX.Element | null => {
    const data = classifications
      .filter(
        (classification) =>
          classification.displayType === DimensionDisplayType.Rating
      )
      .map((classification) => {
        const values = ideas.reduce((list, idea) => {
          const value =
            idea.customDimensions?.find(
              (projectDimension) =>
                projectDimension.projectDimensionId === classification.id
            )?.value ?? '';

          return {
            ...list,
            [idea.id as string]: !isNaN(parseInt(value)) ? +value : undefined,
          };
        }, {} as Record<string, number | undefined>);

        return {
          key: classification.name,
          ...values,
        };
      });

    if (classifications.length <= 2) {
      // No point in showing spider chart with 2 or less data points
      return null;
    }

    return (
      <Style>
        <Chart>
          <RadarChart
            style={{ height: '100%' }}
            cx="48%"
            cy="45%"
            outerRadius="70%"
            data={data}
          >
            <PolarGrid
              stroke={theme.colors.chart.gridStroke}
              strokeWidth={0.5}
            />
            <PolarAngleAxis
              dataKey="key"
              strokeWidth={0.5}
              stroke={theme.colors.chart.axisStroke}
              tickLine={false}
              tick={({ x, y, payload }) => (
                <RadarChartAxisTick x={x} y={y} payload={payload} />
              )}
              tickFormatter={(key: string) => key}
            />

            <PolarRadiusAxis
              angle={90}
              domain={[0, 6]}
              tickCount={5}
              strokeWidth={0.5}
              tickLine={false}
              tickFormatter={(value) => value}
              stroke={theme.colors.chart.axisStroke}
            />

            {ideas.map((idea, index) => (
              <RadarChartRadar
                key={idea.id}
                name={idea.title}
                dataKey={idea.id as string}
                strokeWidth={1.5}
                stroke={
                  theme.colors.chart.colors[
                    index % theme.colors.chart.colors.length
                  ]
                }
                fill={
                  theme.colors.chart.colors[
                    index % theme.colors.chart.colors.length
                  ]
                }
                fillOpacity={0}
              />
            ))}
          </RadarChart>
        </Chart>
      </Style>
    );
  }
);

IdeaRadarChartCompare.displayName = 'IdeaRadarChartCompare';

const Style = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  justify-content: flex-start;

  > div {
    height: 370px;
    width: 420px;

    > div {
      border-radius: 5px;
      background-color: ${({ theme }: { theme: Theme }) =>
        theme.colors.surface};
      color: ${({ theme }: { theme: Theme }) => theme.colors.onSurface};
    }
  }
`;
