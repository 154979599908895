import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { UserPortfolio } from '@dimatech/shared/lib/models';
import { useTranslation } from 'react-i18next';
import { BsArrowRightSquare, BsBriefcase, BsGear } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export const PortfoliosListForUser = ({
  list,
}: {
  list?: UserPortfolio[];
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          padding: 20,
          paddingTop: 0,
        }}
      >
        <div style={{ padding: 10 }}>{t(`Portfolio.Owner.PortfolioText`)}</div>
        <Table
          $striped={true}
          style={{ width: 'fit-content', minWidth: '50%', marginBottom: 30 }}
        >
          <tbody>
            {[...(list ?? [])]
              ?.sort((a, b) =>
                (a.name as string) > (b.name as string) ? 1 : -1
              )
              ?.map((item, index) => (
                <Tr key={index}>
                  <Td style={{ width: 25 }}>
                    <BsBriefcase />
                  </Td>
                  <Td>{item.name}</Td>
                  <TdRight>
                    <EditButtons
                      portfolioId={item.id}
                      portfolioEntityId={item.entityId}
                    />
                  </TdRight>
                </Tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

PortfoliosListForUser.displayName = 'PortfoliosListForUser';

const EditButtons = ({
  portfolioId,
  portfolioEntityId,
}: {
  portfolioId: string;
  portfolioEntityId: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateAdministratePortfolio = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate('/administrate/portfolios', {
      state: { portfolioId, portfolioEntityId },
    });
  };

  const handleNavigatePortfolio = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`/portfolio/${portfolioId}`, {
      state: { from: location.pathname },
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <LinkWithTooltip
        tooltip={t('Administrate.Users.Navigate.OpenAdministratePortfolio')}
        icon={<BsGear />}
        handleClick={(e) => handleNavigateAdministratePortfolio(e)}
      />

      <LinkWithTooltip
        tooltip={t('Administrate.Users.Navigate.OpenPortfolio')}
        icon={<BsArrowRightSquare />}
        style={{ marginLeft: 10 }}
        handleClick={(e) => handleNavigatePortfolio(e)}
      />
    </div>
  );
};
