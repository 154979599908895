import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetMyProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { useAppSelector } from 'hooks';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MyProjectsList } from './components/MyProjectsList';

export const MyProjects = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const { data } = useGetMyProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  return (
    <>
      <ViewHeader>
        <Heading1>{t('MyProjects.Title')}</Heading1>
      </ViewHeader>

      {data?.projects && data.projects.length === 0 && (
        <Card>
          <CardBody>{t('MyProjects.NoProjects')}</CardBody>
        </Card>
      )}

      <MyProjectsList />
    </>
  );
};

MyProjects.displayName = 'MyProjects';
