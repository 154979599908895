import { FeatureFlag } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureFlag';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import screenshot from './feature-flag-goal-ai-evaluation.png';

export const FeatureGoalAiEvaluation = (): JSX.Element | null => {
  const { t } = useTranslation();
  const values = {
    url: 'https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy?context=%2Fazure%2Fai-services%2Fopenai%2Fcontext%2Fcontext',
    urlText: 'Data, privacy, and security for Azure OpenAI Service',
  };

  return (
    <Card>
      <CardBody>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <Trans
            i18nKey="Administrate.FeatureFlag.Features.GoalAiEvaluation.Description"
            values={values}
          >
            <a href={values.url} target="_blank" rel="noreferrer">
              {values.urlText}
            </a>
          </Trans>

          <Image
            src={screenshot}
            alt={t('Administrate.FeatureFlag.Features.GoalAiEvaluation.Title')}
          />
        </div>

        <FeatureFlag
          featureFlagKey={flags.permanent.app.pios.isGoalAiEvaluationEnabled}
        />
      </CardBody>
    </Card>
  );
};

FeatureGoalAiEvaluation.displayName = 'FeatureGoalAiEvaluation';

const Image = styled.img`
  display: inline-block;
  max-width: 550px;
  max-height: 450px;
  margin: 10px;
  box-shadow: ${(props) => props.theme.boxShadows.default};
`;
