import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useDeleteDimensionMutation } from 'api/dimension/dimensionApi';
import { dimensionActions } from 'api/dimension/dimensionSlice';
import { useAppDispatch } from 'hooks';
import { Dimension } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DimensionPropertiesEditButtons = ({
  item,
  setSelectedItem,
  isReadOnly,
}: {
  item: Dimension;
  setSelectedItem: React.Dispatch<React.SetStateAction<Dimension | undefined>>;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteDimension] = useDeleteDimensionMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly || !item.id) {
      return;
    }

    deleteDimension(item.id);
    setSelectedItem(undefined);
    dispatch(dimensionActions.selectDimension());

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Administrate.CustomDimension.Delete.ConfirmTitle')}>
          <div>{t('Administrate.CustomDimension.Delete.ConfirmText')}</div>

          <AlertWarning style={{ marginTop: 10 }}>
            {t('Administrate.CustomDimension.Delete.Warning')}
          </AlertWarning>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <ButtonSecondary
        type="button"
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginLeft: '0' }}
      >
        {t('Common.Form.Delete')}
      </ButtonSecondary>
    </>
  );
};

DimensionPropertiesEditButtons.displayName = 'DimensionPropertiesEditButtons';
