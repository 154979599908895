import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { Overview } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency, formatAsPercent } from 'utils';
import { OverviewScatterChartTooltipSingle } from './OverviewScatterChartTooltipSingle';

export const OverviewScatterChartTooltip = ({
  active,
  data,
  title,
  payload,
}: {
  active?: boolean;
  data?: Overview[];
  title: string;
  payload?: {
    payload: Overview;
  }[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const formatterPercent = (value: number | undefined) => {
    return value === undefined || isNaN(value)
      ? '-'
      : `${formatAsPercent(100 - value, '0', 0)} - ${formatAsPercent(
          value,
          '0',
          0
        )}`;
  };

  const similar = data?.filter((point) => {
    if (active && payload && payload.length) {
      return (
        point.efficiencyInnovation ===
          payload[0].payload.efficiencyInnovation &&
        point.internalExternal === payload[0].payload.internalExternal
      );
    }

    return false;
  });

  if (!active || !payload || !similar) {
    return null;
  }

  if (similar.length === 1) {
    return (
      <OverviewScatterChartTooltipSingle active={active} payload={payload} />
    );
  }

  const item = payload[0].payload;

  return (
    <TooltipStyle>
      <div className="tooltip-title">
        {title}: {similar.length}
      </div>

      <div>
        {t('Portfolio.InternalExternal')} :{' '}
        {formatterPercent(item.internalExternal)}
      </div>
      <div>
        {t('Portfolio.EfficiencyInnovation')} :{' '}
        {formatterPercent(item.efficiencyInnovation)}
      </div>

      {/* Show 4 at the most */}
      {similar.slice(0, 4).map((point, index) => (
        <div key={index}>
          <div className="tooltip-subtitle">{point.label}</div>

          {point.entityName && (
            <div className="tooltip-info">{point.entityName}</div>
          )}

          <div>
            {t('Project.Budget', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}{' '}
            {formatAsCurrency(point.budget)}
          </div>
        </div>
      ))}

      {similar.length > 4 && (
        <div style={{ paddingTop: 15 }}>
          {t('Common.UI.AndXMore', { count: similar.length - 4 })}
        </div>
      )}
    </TooltipStyle>
  );
};

OverviewScatterChartTooltip.displayName = 'OverviewScatterChartTooltip';
