import { MyProjects as MyProjectsFeature } from 'features/project/MyProjects';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const MyProjects = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('MyProjects.Title')}>
      <MyProjectsFeature />
    </View>
  );
};

MyProjects.displayName = 'MyProjects';
