import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { DimensionDisplayType, ProjectDimensionMutation } from 'models';
import { formatAsNumber } from 'utils';
import { ProjectDataTable } from './ProjectDataTable';

export const ProjectDimensionsTable = ({
  dimensions,
}: {
  dimensions: ProjectDimensionMutation[];
}) => {
  return (
    <ProjectDataTable>
      <tbody>
        {dimensions.map((dimension, index) => (
          <Tr key={index}>
            <Td>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {dimension.name}

                {dimension.description && (
                  <HelpIcon
                    showTiny={true}
                    style={{ marginLeft: 5 }}
                    title={dimension.name}
                    text={dimension.description}
                  />
                )}
              </div>
            </Td>
            <Td key={dimension.projectDimensionId}>
              {dimension.displayType === DimensionDisplayType.Numeric
                ? formatAsNumber(dimension.value, '-')
                : dimension.value ?? '-'}
            </Td>
          </Tr>
        ))}
      </tbody>
    </ProjectDataTable>
  );
};

ProjectDimensionsTable.displayName = 'ProjectDimensionsTable';
