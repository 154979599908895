var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { Button, ButtonSecondary, Buttons, Label, Radio, Switch, } from '@dimatech/shared/lib/components/form';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { config } from '@dimatech/shared/lib/config';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetFeatureFlagQuery, useUpdateFeatureFlagMutation, } from '../../../api/feature/featureApi';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { UserAdd } from './UserAdd';
import { Users } from './Users';
export var FeatureFlag = function (_a) {
    var featureFlagKey = _a.featureFlagKey, handleFeatureChanged = _a.handleFeatureChanged;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useState(), feature = _b[0], setFeature = _b[1];
    var _c = useState(false), isSaving = _c[0], setIsSaving = _c[1];
    var _d = useState(false), hasChanges = _d[0], setHasChanges = _d[1];
    var _e = useState(true), isEnabledForEveryone = _e[0], setIsEnabledForEveryone = _e[1];
    var _f = useGetFeatureFlagQuery(accessToken.customerId
        ? {
            featureFlagKey: featureFlagKey,
            _customerId: accessToken.customerId,
        }
        : skipToken), data = _f.data, isFetching = _f.isFetching, isLoading = _f.isLoading;
    var _g = useUpdateFeatureFlagMutation(), updateFeatureFlag = _g[0], isPosting = _g[1].isLoading;
    var handleSave = function () {
        if (feature) {
            updateFeatureFlag({
                feature: __assign(__assign({}, feature), { users: isEnabledForEveryone ? [] : feature.users, key: featureFlagKey }),
            });
            setIsSaving(false);
            setHasChanges(false);
        }
    };
    var handleSetInitialFeature = function () {
        setHasChanges(false);
        setFeature(data ? __assign({}, data) : undefined);
        setIsEnabledForEveryone(!(data === null || data === void 0 ? void 0 : data.users) || data.users.length === 0);
    };
    var handleSetFeature = function (feature) {
        setHasChanges(true);
        setFeature(feature ? __assign({}, feature) : undefined);
    };
    useEffect(function () {
        handleSetInitialFeature();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    useEffect(function () {
        handleFeatureChanged && handleFeatureChanged(feature);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feature]);
    if (isLoading || isFetching || isPosting) {
        return _jsx(LoaderFill, {});
    }
    if (!feature) {
        return null;
    }
    return (_jsxs(Style, { children: [!feature.isEnabled && (_jsx(AlertWarning, __assign({ style: { marginBottom: 20 } }, { children: _jsx(Trans, __assign({ i18nKey: "Administrate.FeatureFlag.Disabled", values: {
                        supportEmail: config.support.email,
                    } }, { children: _jsx("a", __assign({ href: "mailto: ".concat(config.support.email) }, { children: config.support.email })) })) }))), _jsx(Switch, { isLabelRightAligned: true, label: feature.on
                    ? t('Administrate.FeatureFlag.OnOff.FeatureIsOn')
                    : t('Administrate.FeatureFlag.OnOff.FeatureIsOff'), tooltipText: _jsx(Trans, { i18nKey: "Administrate.FeatureFlag.OnOff.Tooltip" }), setIsChecked: function (checked) {
                    return handleSetFeature(__assign(__assign({}, feature), { on: checked }));
                }, isChecked: feature.on, style: { marginBottom: 10 }, isDisabled: isSaving || !feature.isEnabled }), _jsx("div", __assign({ style: { marginBottom: 20 } }, { children: t('Administrate.FeatureFlag.Description') })), isSaving && (_jsxs(Modal, __assign({ title: (feature === null || feature === void 0 ? void 0 : feature.on)
                    ? t('Administrate.FeatureFlag.Save.ConfirmTitleOn')
                    : t('Administrate.FeatureFlag.Save.ConfirmTitleOff'), handleKeyEnter: handleSave, handleKeyEsc: function () { return setIsSaving(false); } }, { children: [feature.on && isEnabledForEveryone && (_jsx("div", { children: t('Administrate.FeatureFlag.Save.ConfirmTextOnAll') })), feature.on && !isEnabledForEveryone && (_jsx("div", { children: t('Administrate.FeatureFlag.Save.ConfirmTextOnSelected') })), !feature.on && (_jsx("div", { children: t('Administrate.FeatureFlag.Save.ConfirmTextOff') })), _jsxs(Buttons, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsSaving(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ type: "button", onClick: handleSave }, { children: t('Common.Form.Save') }))] })] }))), feature.on && (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs(Label, __assign({ htmlFor: "isEnabledForEveryone_".concat(featureFlagKey) }, { children: [_jsx(Radio, { onChange: function (e) {
                                        setIsEnabledForEveryone(e.target.checked);
                                        setHasChanges(true);
                                    }, id: "isEnabledForEveryone_".concat(featureFlagKey), name: "isEnabledForEveryone_".concat(featureFlagKey), value: 1, checked: isEnabledForEveryone, style: { marginRight: 7 }, disabled: isSaving || !feature.isEnabled }), t('Administrate.FeatureFlag.AllUsers')] })) }), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: _jsxs(Label, __assign({ htmlFor: "isNotEnabledForEveryone_".concat(featureFlagKey) }, { children: [_jsx(Radio, { onChange: function (e) {
                                        setIsEnabledForEveryone(!e.target.checked);
                                        setHasChanges(true);
                                    }, id: "isNotEnabledForEveryone_".concat(featureFlagKey), name: "isEnabledForEveryone_".concat(featureFlagKey), value: 0, checked: !isEnabledForEveryone, style: { marginRight: 7 }, disabled: isSaving || !feature.isEnabled }), t('Administrate.FeatureFlag.SelectedUsers')] })) })), feature && !isEnabledForEveryone && (_jsxs(UsersStyle, { children: [_jsx(UserAdd, { feature: feature, setFeature: handleSetFeature }), _jsx(Users, { feature: feature, setFeature: handleSetFeature })] }))] })), _jsxs(Buttons, __assign({ style: { marginTop: 40 } }, { children: [_jsx(ButtonSecondary, __assign({ onClick: handleSetInitialFeature, disabled: !hasChanges, style: { marginRight: 10 } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ onClick: function () { return setIsSaving(true); }, disabled: !hasChanges }, { children: t('Common.Form.Save') }))] }))] }));
};
FeatureFlag.displayName = 'FeatureFlag';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 10px;\n  padding: 15px;\n\n  border-radius: 5px;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  margin-top: 10px;\n  padding: 15px;\n\n  border-radius: 5px;\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
});
var UsersStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 20px;\n  padding: 15px;\n  min-width: 50%;\n  width: fit-content;\n\n  border-radius: 5px;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  margin-top: 20px;\n  padding: 15px;\n  min-width: 50%;\n  width: fit-content;\n\n  border-radius: 5px;\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
});
var templateObject_1, templateObject_2;
