import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { InputNumber, Label } from '@dimatech/shared/lib/components/form';
import produce from 'immer';
import { Dimension } from 'models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ClassificationCalculations = ({
  classification,
  setClassification,
  setHasChanges,
  isValid,
  standardCalculationId,
}: {
  classification: Dimension;
  setClassification: (dimension: Dimension) => void;
  setHasChanges: (hasChanges: boolean) => void;
  isValid: boolean;
  standardCalculationId?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const handleChange = (calculationId: string, weight: string) => {
    if (!classification.calculationWeights) {
      return;
    }

    const index = classification.calculationWeights.findIndex(
      (item) => item.calculationId === calculationId
    );

    const calculationWeights = produce(
      classification.calculationWeights,
      (draftState) => {
        draftState[index].weight = !isNaN(parseInt(weight))
          ? +weight
          : undefined;
      }
    );

    setHasChanges(true);
    setClassification({
      ...classification,
      calculationWeights,
    });
  };

  return (
    <>
      <div style={{ marginTop: 20 }}>
        {t('Administrate.CustomDimension.CalculationWeights')}
      </div>
      <Style>
        {classification?.calculationWeights
          ?.filter((weight) => weight.calculationId !== standardCalculationId)
          .map((calculation) => (
            <div key={calculation.calculationId}>
              <Label htmlFor={`weight_${calculation.calculationId}`}>
                {calculation.calculationName}

                {calculation.calculationName && (
                  <HelpIcon
                    showTiny={true}
                    style={{ marginLeft: 5 }}
                    title={calculation.calculationName}
                    text={calculation.calculationDescription}
                  />
                )}
              </Label>

              <InputNumber
                id={`weight_${calculation.calculationId}`}
                name={`weight_${calculation.calculationId}`}
                value={calculation.weight ?? ''}
                invalid={!isValid && !calculation.weight}
                style={{ width: 100 }}
                onValueChange={({ value }) => {
                  handleChange(calculation.calculationId, value);
                }}
              />
            </div>
          ))}
      </Style>
    </>
  );
};

ClassificationCalculations.displayName = ' ClassificationCalculations';

const Style = styled.div`
  display: flex;
  gap: 30px;

  label {
    display: flex;
    align-items: center;
  }
`;
