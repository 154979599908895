import { SelectEntityWithoutSurvey } from '@dimatech/features-core/lib/components/SelectEntityWithoutSurvey';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  Buttons,
  ButtonSecondary,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  useDeletePortfolioMutation,
  useMovePortfolioMutation,
} from 'api/portfolio/portfolioApi';
import { portfolioActions } from 'api/portfolio/portfolioSlice';
import { useAppDispatch } from 'hooks';
import { PortfolioMutation } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PortfolioPropertiesEditButtons = ({
  portfolio,
  isReadOnly,
}: {
  portfolio: PortfolioMutation;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deletePortfolio] = useDeletePortfolioMutation();
  const [movePortfolio] = useMovePortfolioMutation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [entityId, setEntityId] = useState<string>('');

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly || !portfolio.id) {
      return;
    }

    deletePortfolio(portfolio.id);

    setIsDeleting(false);
    dispatch(portfolioActions.selectedPortfolio());
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  const handleMove = () => {
    if (isReadOnly) {
      return;
    }

    if (portfolio.id) {
      movePortfolio({
        portfolioId: portfolio.id,
        entityId,
      });

      setIsMoving(false);
      dispatch(portfolioActions.selectedPortfolio());
    }
  };

  return (
    <>
      {isMoving && (
        <Modal title={t('Administrate.Portfolio.Move.Title')}>
          <div>{t('Administrate.Portfolio.Move.Text')}</div>

          <Label>
            {t('Administrate.Portfolio.Move.To', { name: portfolio.name })}
          </Label>

          <SelectEntityWithoutSurvey
            entityId={entityId}
            setEntityId={setEntityId}
            placeholder={t('Administrate.Portfolio.Move.SelectEntity')}
          />

          <Buttons>
            <ButtonSecondary type="button" onClick={() => setIsMoving(false)}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button type="button" onClick={handleMove} disabled={isReadOnly}>
              {t('Administrate.Portfolio.Move.Move')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {isDeleting && (
        <Modal title={t('Administrate.Portfolio.Delete.ConfirmTitle')}>
          <div>{t('Administrate.Portfolio.Delete.ConfirmText')}</div>

          <AlertWarning style={{ marginTop: 10, marginBottom: 10 }}>
            {t('Administrate.Portfolio.Delete.Warning')}
          </AlertWarning>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <ButtonSecondary
        type="button"
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginLeft: '0' }}
      >
        {t('Common.Form.Delete')}
      </ButtonSecondary>
      <ButtonSecondary type="button" onClick={() => setIsMoving(true)}>
        {t('Administrate.Portfolio.Move.Move')}
      </ButtonSecondary>
    </>
  );
};

PortfolioPropertiesEditButtons.displayName = 'PortfolioPropertiesEditButtons';
