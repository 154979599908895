import { PrivateRoute } from '@dimatech/features-core/lib/components/PrivateRoute';
import {
  AuthenticationContext,
  AuthenticationProvider,
} from '@dimatech/features-core/lib/features/authentication';
import {
  useGoogleAnalytics,
  useLaunchDarkly,
} from '@dimatech/features-core/lib/hooks';
import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useRedirectToMaintenance } from '@dimatech/shared/lib/hooks';
import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { useGetDefaultView } from 'hooks/useGetDefaultView';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles, View } from 'models';
import { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AdministrateAccount } from 'views/AdministrateAccount';
import { AdministrateCustomDimensions } from 'views/AdministrateCustomDimensions';
import { AdministrateFeatureFlag } from 'views/AdministrateFeatureFlag';
import { AdministrateMessageTemplate } from 'views/AdministrateMessageTemplate';
import { AdministrateMessages } from 'views/AdministrateMessages';
import { AdministrateOrganisation } from 'views/AdministrateOrganisation';
import { AdministratePortfolios } from 'views/AdministratePortfolios';
import { AdministrateUsers } from 'views/AdministrateUsers';
import { CookieInformation } from 'views/CookieInformation';
import { Demo } from 'views/Demo';
import { ForgotPassword } from 'views/ForgotPassword';
import { GlobalAdministrateAllFeatureFlags } from 'views/GlobalAdministrateAllFeatureFlags';
import { GlobalAdministrateAppConfig } from 'views/GlobalAdministrateAppConfig';
import { GlobalAdministrateNationalTags } from 'views/GlobalAdministrateNationalTags';
import { Help } from 'views/Help';
import { Login } from 'views/Login';
import { Maintenance } from 'views/Maintenance';
import { Message } from 'views/Message';
import { MyPortfolio } from 'views/MyPortfolio';
import { MyPortfolios } from 'views/MyPortfolios';
import { MyProjects } from 'views/MyProjects';
import { OrderDikios } from 'views/OrderDikios';
import { OrderDimios } from 'views/OrderDimios';
import { OrderDios } from 'views/OrderDios';
import { Portfolio } from 'views/Portfolio';
import { PortfolioHistory } from 'views/PortfolioHistory';
import { Portfolios } from 'views/Portfolios';
import { Project } from 'views/Project';
import { ProjectEdit } from 'views/ProjectEdit';
import { ProjectEffectMyProjects } from 'views/ProjectEffectMyProjects';
import { ProjectEffectProjectsBenefits } from 'views/ProjectEffectProjectsBenefits';
import { ProjectHistory } from 'views/ProjectHistory';
import { ProjectIdea } from 'views/ProjectIdea';
import { ProjectIdeas } from 'views/ProjectIdeas';
import { ProjectIdeasArchive } from 'views/ProjectIdeasArchive';
import { ProjectIdeasReview } from 'views/ProjectIdeasReview';
import { Projects } from 'views/Projects';
import { Register } from 'views/Register';
import { ReleaseNotes } from 'views/ReleaseNotes';
import { ResetPassword } from 'views/ResetPassword';
import { Search } from 'views/Search';
import { StyleGuide } from 'views/StyleGuide';
import { Unauthorized } from 'views/Unauthorized';
import { UpgradeLicense } from 'views/UpgradeLicense';
import { UserProfile } from 'views/UserProfile';
import { VideoLibrary } from 'views/VideoLibrary';
import { Welcome } from 'views/Welcome';

/* eslint-disable max-lines-per-function */

const App = (): JSX.Element => {
  return (
    <AuthenticationProvider>
      <WithAuthentication>
        <TrackingProvider>
          <ThemeProvider themes={themes}>
            <ApplicationProvider>
              <GlobalStyles />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/register" element={<Register />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/cookies" element={<CookieInformation />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/reset-password/:resetToken"
                  element={<ResetPassword />}
                />
                <Route path="/welcome/:resetToken" element={<Welcome />} />
                <Route path="/style-guide" element={<StyleGuide />} />

                <Route
                  path="user-profile"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <UserProfile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/portfolios"
                  element={
                    <PrivateRoute>
                      <Portfolios />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects/summary"
                  element={
                    <PrivateRoute>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects/balance"
                  element={
                    <PrivateRoute>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects/balance-over-time"
                  element={
                    <PrivateRoute>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects/tag"
                  element={
                    <PrivateRoute>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects/table"
                  element={
                    <PrivateRoute>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/projects/timeline"
                  element={
                    <PrivateRoute>
                      <Projects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/history"
                  element={
                    <PrivateRoute
                      requireFlag={flags.permanent.app.pios.isHistoryEnabled}
                    >
                      <PortfolioHistory />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/summary"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/balance"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/balance-over-time"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/tag"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/table"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio/:id/timeline"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/:id/new"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <ProjectEdit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/:id/:projectId"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <Project />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/:id/:projectId/edit"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <ProjectEdit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/:id/:projectId/history"
                  element={
                    <PrivateRoute
                      isBaseLicenseAllowed={true}
                      requireFlag={flags.permanent.app.pios.isHistoryEnabled}
                    >
                      <ProjectHistory />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/project/:id/:projectId/idea"
                  element={
                    <PrivateRoute>
                      <ProjectIdea />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/myportfolio/:id"
                  element={
                    <PrivateRoute>
                      <MyPortfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/myportfolio/:id/:projectId"
                  element={
                    <PrivateRoute>
                      <MyPortfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/myportfolios"
                  element={
                    <PrivateRoute>
                      <MyPortfolios />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/myprojects"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.GlobalAdmin,
                        CommonRoles.PiosProjectManager,
                        CommonRoles.PiosEffectRealizationResponsible,
                        CommonRoles.PiosOrderingParty,
                      ]}
                      requireFlag={
                        flags.permanent.app.pios.isProjectRolesEnabled
                      }
                    >
                      <MyProjects />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <PrivateRoute>
                      <Search />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/help"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <Help />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/video-library"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <VideoLibrary />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/release-notes"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <ReleaseNotes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/release-notes/:version"
                  element={
                    <PrivateRoute isBaseLicenseAllowed={true}>
                      <ReleaseNotes />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/archive"
                  element={
                    <PrivateRoute>
                      <ProjectIdeasArchive />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/archive/:id/:projectId"
                  element={
                    <PrivateRoute>
                      <ProjectIdeasArchive />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/review"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.app.pios.isProjectIdeasReviewersEnabled
                      }
                    >
                      <ProjectIdeasReview />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/review/:id/:projectId"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.app.pios.isProjectIdeasReviewersEnabled
                      }
                    >
                      <ProjectIdeasReview />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/:action/:id/:projectId"
                  element={
                    <PrivateRoute>
                      <ProjectIdeas />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/:action/:id"
                  element={
                    <PrivateRoute>
                      <ProjectIdeas />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea/:action/"
                  element={
                    <PrivateRoute>
                      <ProjectIdeas />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-idea"
                  element={
                    <PrivateRoute>
                      <ProjectIdeas />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-effect"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.app.pios
                          .isProjectEffectRealisationEnabled
                      }
                    >
                      <ProjectEffectProjectsBenefits />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/project-effect/my-projects"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.app.pios
                          .isProjectEffectRealisationEnabled
                      }
                    >
                      <ProjectEffectMyProjects />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/organisation"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAdmin,
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                        CommonRoles.Researcher,
                        CommonRoles.DemoUser,
                      ]}
                    >
                      <AdministrateOrganisation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/administrate/portfolios"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAdmin,
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                        CommonRoles.Researcher,
                        CommonRoles.DemoUser,
                      ]}
                    >
                      <AdministratePortfolios />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/custom-dimensions"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                        CommonRoles.Researcher,
                        CommonRoles.DemoUser,
                      ]}
                    >
                      <AdministrateCustomDimensions />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/custom-tags"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                        CommonRoles.Researcher,
                        CommonRoles.DemoUser,
                      ]}
                    >
                      <AdministrateCustomDimensions />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/custom-classifications"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                        CommonRoles.Researcher,
                        CommonRoles.DemoUser,
                      ]}
                    >
                      <AdministrateCustomDimensions />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/custom-calculations"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                        CommonRoles.Researcher,
                        CommonRoles.DemoUser,
                      ]}
                    >
                      <AdministrateCustomDimensions />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/message-template/"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <AdministrateMessageTemplate />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/account"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                      isBaseLicenseAllowed={true}
                    >
                      <AdministrateAccount />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/features"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.shared.isFeatureFlagsEditEnabled
                      }
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <AdministrateFeatureFlag />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/messages"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.shared.isUserMessagesForCustomersEnabled
                      }
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <AdministrateMessages />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="administrate/message/:userMessageId"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.shared.isUserMessagesForCustomersEnabled
                      }
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <Message />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/order-dimios"
                  element={
                    <PrivateRoute
                      isBaseLicenseAllowed={true}
                      requireRole={[
                        CommonRoles.CustomerAdmin,
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <OrderDimios />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/order-dios"
                  element={
                    <PrivateRoute
                      isBaseLicenseAllowed={true}
                      requireRole={[
                        CommonRoles.CustomerAdmin,
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <OrderDios />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/order-dikios"
                  element={
                    <PrivateRoute
                      isBaseLicenseAllowed={true}
                      requireRole={[
                        CommonRoles.CustomerAdmin,
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                    >
                      <OrderDikios />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/upgrade-license"
                  element={
                    <PrivateRoute
                      requireFlag={
                        flags.permanent.app.pios.isBaseLicenseEnabled
                      }
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                      isBaseLicenseAllowed={true}
                    >
                      <UpgradeLicense />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/administrate/users"
                  element={
                    <PrivateRoute
                      requireRole={[
                        CommonRoles.CustomerAccountAdmin,
                        CommonRoles.GlobalAdmin,
                      ]}
                      isBaseLicenseAllowed={true}
                    >
                      <AdministrateUsers />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/global-administrate/common-tags"
                  element={
                    <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                      <GlobalAdministrateNationalTags />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/global-administrate/appconfig"
                  element={
                    <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                      <GlobalAdministrateAppConfig />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/all-feature-flags"
                  element={
                    <PrivateRoute>
                      <GlobalAdministrateAllFeatureFlags />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <RedirectByRole />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </ApplicationProvider>
          </ThemeProvider>
        </TrackingProvider>
      </WithAuthentication>
    </AuthenticationProvider>
  );
};

export default App;

// We need to separate this from App so we can access AuthenticationContext
const WithAuthentication = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { accessToken, logout } = useContext(AuthenticationContext);

  const navigate = useNavigate();

  useLaunchDarkly();
  useGoogleAnalytics();
  useRedirectToMaintenance(flags.permanent.app.pios.isMaintenanceOn, logout);

  const isDemoEnabled = useFlags()[flags.permanent.app.pios.isDemoEnabled];

  useEffect(() => {
    if (!isDemoEnabled && accessToken.isInRole(CommonRoles.DemoUser)) {
      logout();
      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoEnabled, accessToken.token]);

  return (
    <LocalizationProvider defaultLanguage={accessToken.locale.language}>
      {children}
    </LocalizationProvider>
  );
};

const RedirectByRole = (): JSX.Element => {
  const defaultView = useGetDefaultView();

  if (defaultView === View.Projects) {
    return <Projects />;
  }

  if (defaultView === View.MyPortfolios) {
    return <MyPortfolios />;
  }

  if (defaultView === View.MyProjects) {
    return <MyProjects />;
  }

  if (defaultView === View.ProjectEffectMyProjects) {
    return <ProjectEffectMyProjects />;
  }

  if (defaultView === View.ProjectIdeasReview) {
    return <ProjectIdeasReview />;
  }

  return <Portfolios />;
};
