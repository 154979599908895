import {
  Table,
  TableResponsiveContainer,
  Td,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import {
  dimensionActions,
  selectSelectedClassification,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dimension, DimensionDisplayType, DimensionType } from 'models';
import { useTranslation } from 'react-i18next';
import { BsCardList } from 'react-icons/bs';

export const ClassificationsList = ({
  classifications,
}: {
  classifications: Dimension[];
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedClassification = useAppSelector(selectSelectedClassification);

  const handleSelect = (classification: Dimension) => {
    dispatch(dimensionActions.selectClassification(classification));
  };

  return (
    <>
      {classifications && classifications?.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ minWidth: 400 }}>
            <thead>
              <tr>
                <Th style={{ width: 25 }} />
                <Th>{t(`Administrate.CustomDimension.Name`)}</Th>
                <Th>{t(`Administrate.CustomDimension.Type`)}</Th>
              </tr>
            </thead>
            <tbody>
              {classifications?.map((dimension, index) => (
                <Tr
                  key={index}
                  onSelect={() => handleSelect(dimension)}
                  isSelected={dimension.id === selectedClassification?.id}
                >
                  <Td>
                    <BsCardList />
                  </Td>
                  <Td>{dimension.name}</Td>
                  <Td>
                    {t(
                      `Administrate.CustomDimension.DimensionDisplayType.${dimension.displayType}`
                    )}
                    {dimension.displayType === DimensionDisplayType.Rating &&
                      dimension.type ===
                        DimensionType.ClassificationBenefit && (
                        <>
                          ,{' '}
                          {t(
                            `Administrate.CustomDimension.DimensionClassificationType.Benefit`
                          )}
                        </>
                      )}
                    {dimension.displayType === DimensionDisplayType.Rating &&
                      dimension.type === DimensionType.ClassificationStake && (
                        <>
                          ,{' '}
                          {t(
                            `Administrate.CustomDimension.DimensionClassificationType.Stake`
                          )}
                        </>
                      )}
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

ClassificationsList.displayName = 'ClassificationsList';
