import {
  HelpIcon,
  HelpIconCentered,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Th, ThCentered, Tr } from '@dimatech/shared/lib/components/table';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { Dimension, DimensionDisplayType, DimensionType } from 'models';
import { Trans, useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

export const IdeaListTableGroupHeader = withTheme(
  ({
    startColumnsCount,
    classifications,
    showComparisonHelp = false,
    theme,
  }: {
    startColumnsCount: number;
    classifications: Dimension[];
    showComparisonHelp?: boolean;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    const columnCount =
      startColumnsCount +
      (classifications.length > 0 ? 1 : 0) + // Quota column +
      2;

    const columnCountStake = classifications.filter(
      (c) =>
        c.type === DimensionType.ClassificationStake &&
        c.displayType === DimensionDisplayType.Rating
    ).length;

    const columnCountBenefit = classifications.filter(
      (c) =>
        c.type === DimensionType.ClassificationBenefit &&
        c.displayType === DimensionDisplayType.Rating
    ).length;

    if (columnCountStake === 0 && columnCountBenefit === 0) {
      return null;
    }

    return (
      <thead>
        <TrHeader>
          <ThCentered
            colSpan={columnCount}
            style={{
              backgroundColor: theme.colors.surface,
            }}
          />

          {columnCountBenefit > 0 && (
            <ThCentered
              colSpan={columnCountBenefit}
              style={{
                borderBottom: `2px solid ${Color.CaribbeanGreenLight}`,
              }}
            >
              <HelpIconCentered>
                {t(
                  'Administrate.CustomDimension.DimensionClassificationType.Benefit'
                )}

                <HelpIcon
                  showTiny={true}
                  isInverted={true}
                  style={{ marginLeft: 5 }}
                  title={t(
                    'Administrate.CustomDimension.DimensionClassificationType.Benefit'
                  )}
                  text={
                    <>
                      <Trans i18nKey="Administrate.CustomDimension.DimensionClassificationType.Help" />
                      {showComparisonHelp && (
                        <Trans i18nKey="Administrate.CustomDimension.DimensionClassificationType.HelpCompare" />
                      )}
                    </>
                  }
                />
              </HelpIconCentered>
            </ThCentered>
          )}

          {columnCountStake > 0 && (
            <ThCentered
              colSpan={columnCountStake}
              style={{
                borderBottom: `2px solid ${Color.KojiOrange}`,
              }}
            >
              <HelpIconCentered>
                {t(
                  'Administrate.CustomDimension.DimensionClassificationType.Stake'
                )}

                <HelpIcon
                  showTiny={true}
                  isInverted={true}
                  style={{ marginLeft: 5 }}
                  title={t(
                    'Administrate.CustomDimension.DimensionClassificationType.Stake'
                  )}
                  text={
                    <>
                      <Trans i18nKey="Administrate.CustomDimension.DimensionClassificationType.Help" />
                      {showComparisonHelp && (
                        <Trans i18nKey="Administrate.CustomDimension.DimensionClassificationType.HelpCompare" />
                      )}
                    </>
                  }
                />
              </HelpIconCentered>
            </ThCentered>
          )}

          <Th
            colSpan={100}
            style={{
              backgroundColor: theme.colors.surface,
            }}
          />
        </TrHeader>
      </thead>
    );
  }
);

IdeaListTableGroupHeader.displayName = 'IdeaListTableGroupHeader';

const TrHeader = styled(Tr)`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

  th {
    padding: 8px 0;
    border-bottom: none;
  }
`;
