export var getMetaData = function (history) {
    var _a, _b;
    var betweenBracketAndEqual = /\[(.*)=/g;
    var betweenEqualAndBracket = /=(.*)\]/g;
    return {
        valueName: ((_a = betweenBracketAndEqual.exec(history.path)) !== null && _a !== void 0 ? _a : [null, null])[1],
        newValue: ((_b = betweenEqualAndBracket.exec(history.path)) !== null && _b !== void 0 ? _b : [null, null])[1],
        name: getObjectName(history.path),
        additionalPropertyName: getAdditionalPropertyName(history.path),
    };
};
var getObjectName = function (path) {
    return path.replace(/\[.*$/, '').replace('.Value', '');
};
var getAdditionalPropertyName = function (path) {
    var _a;
    var afterDot = /\.(.*)/g;
    return ((_a = afterDot.exec(path)) !== null && _a !== void 0 ? _a : [null, null])[1];
};
