import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGoalsQuery } from 'api/project/projectGoalApi';
import { PiosColors } from 'models';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFlag } from 'react-icons/bs';
import styled from 'styled-components';
import {
  innovationEfficiencyToPercent,
  internalExternalToPercent,
} from 'utils';

export const GoalList = ({
  projectId,
}: {
  projectId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);

  const { data: goals, isFetching } = useGetGoalsQuery(
    accessToken.customerId && projectId
      ? {
          projectId,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  if (isFetching) {
    return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
  }

  return (
    <>
      <Heading4 style={{ marginBottom: 10 }}>
        {t('Project.Goals.Goals')}
      </Heading4>

      {goals && goals.length === 0 && <div>{t('Portfolio.Goal.NoGoals')}</div>}

      <TableResponsiveContainer>
        <TableStyle>
          {goals && goals.length > 0 && (
            <thead>
              <tr>
                <Th style={{ width: 25 }} />
                <Th style={{ width: '100%' }}>{t('Portfolio.Goal.Name')}</Th>
                <ThCentered style={{ minWidth: 170 }}>
                  {t('Project.EfficiencyInnovation')}
                </ThCentered>
                <ThCentered style={{ minWidth: 170 }}>
                  {t('Project.InternalExternal')}
                </ThCentered>
              </tr>
            </thead>
          )}
          <tbody>
            {goals?.map((goal) => (
              <Fragment key={goal.id}>
                <Tr>
                  <TdCentered className="narrow">
                    <BsFlag />
                  </TdCentered>

                  <Td>{goal.name}</Td>

                  <TdCentered>
                    <Progress
                      width={110}
                      value={innovationEfficiencyToPercent(
                        goal.innovationEfficiencyClassification
                      )}
                      color={PiosColors.efficiency}
                      backgroundColor={PiosColors.innovation}
                    />
                  </TdCentered>

                  <TdCentered>
                    <Progress
                      width={110}
                      value={internalExternalToPercent(
                        goal.internalExternalClassification
                      )}
                      color={PiosColors.internal}
                      backgroundColor={PiosColors.external}
                    />
                  </TdCentered>
                </Tr>
              </Fragment>
            ))}
          </tbody>
        </TableStyle>
      </TableResponsiveContainer>
    </>
  );
};

GoalList.displayName = 'GoalList';

const TableStyle = styled(Table)`
  tbody {
    tr {
      height: 50px;

      td {
        vertical-align: middle;
      }
    }
  }
`;
