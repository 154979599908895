import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { ProjectsTable } from 'components/ProjectsTable';
import { ProjectAdd } from 'features/project/components/ProjectAdd';
import { useAppSelector } from 'hooks';
import { useContext, useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isReadOnly } from 'utils';

export const PortfolioProjectsList = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);
  const { id = '' } = useParams();

  const saveToPngElementId = useId();

  const { data, isFetching } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: {
            ...filter,
            portfolioIds: [id],
            entityIds: undefined,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const { data: portfolio, isFetching: isFetchingPortfolio } =
    useGetPortfolioOverviewQuery(
      accessToken.customerId && accessToken.user?.id && id
        ? {
            filter: { portfolioIds: [id] },
            _customerId: accessToken.customerId,
            _userId: accessToken.user.id,
          }
        : skipToken
    );

  if (data && data.projects.length === 0) {
    return null;
  }

  return (
    <Card
      helpTextTitle={t('Portfolio.List.Title')}
      helpText={<Trans i18nKey={'Portfolio.List.Help'} />}
      canBeSavedAsPng={data?.projects && data.projects.length > 0}
      saveToPngElementId={saveToPngElementId}
      style={{ paddingTop: 10 }}
    >
      <CardBody isLoading={isFetching || isFetchingPortfolio}>
        {portfolio && !isReadOnly(portfolio) && (
          <ProjectAdd projects={[]} portfolio={portfolio} />
        )}

        {data && (
          <ProjectsTable
            projects={data.projects}
            portfolio={portfolio}
            saveToPngElementId={saveToPngElementId}
          />
        )}
      </CardBody>
    </Card>
  );
};

PortfolioProjectsList.displayName = 'PortfolioProjectsList';
