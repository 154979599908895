import { FeatureFlag } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureFlag';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Feature } from 'models';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

export const FeaturePublishFunctionality = (): JSX.Element | null => {
  const [feature, setFeature] = useState<Feature>();

  return (
    <>
      <Card>
        <CardBody>
          <Style>
            <Trans i18nKey="Administrate.FeatureFlag.Features.PublishFunctionality.Description" />
          </Style>

          <FeatureFlag
            featureFlagKey={flags.permanent.app.pios.isPublishPubliclyEnabled}
            handleFeatureChanged={(feature) => setFeature(feature)}
          />
        </CardBody>
      </Card>

      {feature && feature.on && (
        <Card>
          <CardBody>
            <Style>
              <Trans i18nKey="Administrate.FeatureFlag.Features.PublishFunctionality.Description2" />
            </Style>

            <FeatureFlag
              featureFlagKey={
                flags.permanent.app.pios.isPublishPubliclyByDefaultEnabled
              }
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

FeaturePublishFunctionality.displayName = 'FeaturePublishFunctionality';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
`;
