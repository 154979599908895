var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { Tree } from '@dimatech/shared/lib/components/tree';
import { getAllChildren } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTag, BsTags } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useGetTagsQuery, useMoveTagMutation } from '../../../api/tag/tagApi';
import { selectSelectedTag, tagActions } from '../../../api/tag/tagSlice';
import { isAdminReadOnly } from '../../../utils';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
export var TagsTree = function () {
    var _a;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var selectedTag = useSelector(selectSelectedTag);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useState(), tree = _b[0], setTree = _b[1];
    var _c = useMoveTagMutation(), moveTag = _c[0], moveErrors = _c[1].error;
    var isReadOnly = isAdminReadOnly(accessToken);
    var tags = useGetTagsQuery((_a = accessToken.customerId) !== null && _a !== void 0 ? _a : skipToken).data;
    var handleSelect = function (item) {
        var icon = item.icon, items = item.items, tag = __rest(item, ["icon", "items"]);
        dispatch(tagActions.selectedTag(tag));
    };
    var handleDrop = function (id, parentId) {
        var draggedTag = tags === null || tags === void 0 ? void 0 : tags.find(function (item) { return item.id === id; });
        var hasChildren = tags ? getAllChildren(tags, [id]) : [];
        if (isReadOnly ||
            id === parentId ||
            (draggedTag === null || draggedTag === void 0 ? void 0 : draggedTag.parentId) === parentId ||
            hasChildren.length >= 1) {
            return;
        }
        moveTag({ tagId: id, parentId: parentId });
    };
    var mapToItems = function (tags, parentId) {
        var items = tags
            .filter(function (tag) { return tag.parentId === parentId; })
            .map(function (tag) {
            var children = mapToItems(tags, tag.id || null);
            return {
                content: tag.displayName,
                id: tag.id,
                parentId: tag.parentId,
                displayName: tag.displayName,
                description: tag.description,
                items: children,
                icon: children.length > 0 ? _jsx(BsTags, {}) : _jsx(BsTag, {}),
                isOpen: true,
            };
        });
        return items;
    };
    useEffect(function () {
        var items = tags ? mapToItems(tags, null) : [];
        setTree(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags]);
    return (_jsxs(_Fragment, { children: [(!tree || tree.length === 0) && !selectedTag && (_jsx("div", { children: t('Administrate.Tag.NoTags') })), _jsx(AlertErrors, { style: { padding: 10 }, error: moveErrors, translationPath: "Administrate.Tag.ValidationError" }), tree && tree.length > 0 && (_jsx(Tree, { items: tree, onSelect: handleSelect, selected: selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.id, highlightItems: !(selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.id) && (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.parentId)
                    ? [selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.parentId]
                    : [], onDrop: handleDrop }))] }));
};
TagsTree.displayName = 'TagsTree';
