import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonFooter,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useArchiveProjectIdeaMutation } from 'api/project/projectIdeaApi';
import { ProjectIdea } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { isPiosReadOnly } from 'utils';

export const IdeaArchiveListEditButtons = ({
  idea,
  showAsFooterButton = false,
}: {
  idea: ProjectIdea;
  showAsFooterButton?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [archiveIdea] = useArchiveProjectIdeaMutation();

  const [isRestoring, setIsRestoring] = useState(false);

  const handleRestore = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isViewReadOnly) {
      return;
    }

    archiveIdea({ ...idea });

    setIsRestoring(false);
  };

  return (
    <>
      {isRestoring && (
        <Modal
          title={t('FutureProjects.ProjectIdeaArchive.Restore.ConfirmTitle')}
        >
          <div>
            {t('FutureProjects.ProjectIdeaArchive.Restore.ConfirmText')}
          </div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={() => setIsRestoring(false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleRestore(e)}
              disabled={isViewReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-project-restore"
        title={t('FutureProjects.ProjectIdeaArchive.Restore.TooltipTitle')}
        text={t('FutureProjects.ProjectIdeaArchive.Restore.Tooltip')}
      />

      {!showAsFooterButton && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <BadgeMini
            data-tip
            data-for="tt-project-restore"
            onClick={(e) => {
              e.stopPropagation();
              setIsRestoring(true);
            }}
          >
            <BsArrowCounterclockwise />
          </BadgeMini>
        </div>
      )}

      {showAsFooterButton && (
        <ButtonFooter>
          <Buttons style={{ marginLeft: 10 }}>
            <ButtonSecondary
              type="button"
              data-tip
              data-for="tt-project-restore"
              onClick={() => setIsRestoring(true)}
            >
              {t('FutureProjects.ProjectIdeaArchive.Restore.TooltipTitle')}
            </ButtonSecondary>
          </Buttons>
        </ButtonFooter>
      )}
    </>
  );
};

IdeaArchiveListEditButtons.displayName = 'IdeaArchiveListEditButtons';
