import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { TagMutation } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetNationalTagsQuery } from 'api/nationalTag/nationalTagApi';
import {
  nationalTagActions,
  selectSelectedNationalTag,
} from 'api/nationalTag/nationalTagSlice';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isAdminReadOnly } from 'utils';
import { TagProperties } from './components/TagProperties';
import { TagsTree } from './components/TagsTree';

export const GlobalAdministrateNationalTags = ({
  handleMoved,
  handleDeleted,
  isDescriptionEnabled,
}: {
  handleMoved?: () => void;
  handleDeleted?: () => void;
  isDescriptionEnabled?: boolean;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedTag = useSelector(selectSelectedNationalTag);
  const { data: tags } = useGetNationalTagsQuery(skipToken);

  const handleCreate = () => {
    if (isReadOnly) {
      return;
    }

    const tag: TagMutation = { parentId: null };

    if (selectedTag) {
      tag.parentId = selectedTag.id;
    }

    dispatch(nationalTagActions.selectedNationalTag(tag));
  };

  useEffect(() => {
    dispatch(nationalTagActions.selectedNationalTag());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('GlobalAdministrate.NationalTag.Title')}</Heading1>
        <div>
          <TooltipContent
            id="tt-national-tag-add"
            title={t('GlobalAdministrate.NationalTag.Add.TooltipTitle')}
            text={t('GlobalAdministrate.NationalTag.Add.Tooltip')}
          />
          <BadgeMini
            data-tip
            data-for="tt-national-tag-add"
            onClick={handleCreate}
            $inverted={true}
            disabled={isReadOnly}
          >
            <BsPlus />
          </BadgeMini>
        </div>
      </ViewHeader>

      <Card>
        <CardBody>
          <div style={{ marginBottom: 20 }}>
            {t('GlobalAdministrate.NationalTag.Text')}
          </div>

          <LinkWithTooltip
            isPrimary={true}
            isDisabled={isReadOnly}
            handleClick={handleCreate}
            title={t('GlobalAdministrate.NationalTag.Add.Title')}
            tooltipTitle={t('GlobalAdministrate.NationalTag.Add.TooltipTitle')}
            tooltip={t('GlobalAdministrate.NationalTag.Add.Tooltip')}
            icon={<BsPlus />}
            style={{ marginBottom: 20 }}
          />

          {tags?.length === 0 && !selectedTag ? (
            <>
              <div style={{ marginBottom: 20 }}>
                {t('GlobalAdministrate.NationalTag.NoTags')}
              </div>
              <ButtonLink type="button" onClick={handleCreate}>
                {t('GlobalAdministrate.NationalTag.NoTagsAddFirst')}
              </ButtonLink>
            </>
          ) : (
            <Style>
              <TagsTree />

              {selectedTag && (
                <div style={{ flexGrow: 1, maxWidth: 800 }}>
                  <TagProperties
                    isDescriptionEnabled={isDescriptionEnabled}
                    handleDeleted={handleDeleted}
                    handleMoved={handleMoved}
                  />
                </div>
              )}
            </Style>
          )}
        </CardBody>
      </Card>
    </>
  );
};

GlobalAdministrateNationalTags.displayName = 'GlobalAdministrateNationalTags';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding: 0 30px 30px 0;
  }
`;
