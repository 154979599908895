var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Trans, useTranslation } from 'react-i18next';
import { FeatureFlag } from './FeatureFlag';
export var FeatureTestFlag1 = function () {
    var t = useTranslation().t;
    return (_jsx(Card, { children: _jsxs(CardBody, { children: [_jsx("div", __assign({ style: { marginTop: 10, marginBottom: 20 } }, { children: t('Administrate.FeatureFlag.Features.TestFlagDescription') })), _jsx("div", __assign({ style: { marginTop: 10, marginBottom: 20 } }, { children: _jsx(Trans, __assign({ i18nKey: "Administrate.FeatureFlag.Features.TestFlagLink", values: {
                            testFlagLink: '/all-feature-flags',
                        } }, { children: _jsx("a", __assign({ href: "/all-feature-flags" }, { children: "/all-feature-flags" })) })) })), _jsx(AlertInfo, __assign({ style: { marginTop: 10, marginBottom: 20 } }, { children: t('Administrate.FeatureFlag.Features.TestFlag1.Description') })), _jsx(FeatureFlag, { featureFlagKey: flags.permanent.test.isTestFlag1Enabled })] }) }));
};
FeatureTestFlag1.displayName = 'FeatureTestFlag1';
