var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { parseISO } from 'date-fns';
import { HistoryLogChangeType } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { config } from '../../config';
import { colorFromEmail, formatDate, initialsFromEmail } from '../../utils';
import { Badge } from '../Badge';
import { SearchBox } from '../Search';
import { Select } from '../form';
import { Table, TableResponsiveContainer, Td, Tr } from '../table';
import { HistoryChangeLogChange } from './HistoryChangeLogChange';
export var HistoryChangeLog = withTheme(function (_a) {
    var history = _a.history, currentUserEmail = _a.currentUserEmail, _b = _a.isInverted, isInverted = _b === void 0 ? false : _b, theme = _a.theme;
    var t = useTranslation().t;
    var _c = useState(''), search = _c[0], setSearch = _c[1];
    var _d = useState([]), properties = _d[0], setProperties = _d[1];
    var _e = useState(), selectedProperty = _e[0], setSelectedProperty = _e[1];
    var _f = useState([]), filtered = _f[0], setFiltered = _f[1];
    var _g = useState(), selectedChangeType = _g[0], setSelectedChangeType = _g[1];
    var compareChangeType = function (changeType) {
        if ((changeType === HistoryLogChangeType.RemoveFromSet ||
            changeType === HistoryLogChangeType.Delete) &&
            (selectedChangeType === HistoryLogChangeType.RemoveFromSet ||
                selectedChangeType === HistoryLogChangeType.Delete)) {
            return true;
        }
        if ((changeType === HistoryLogChangeType.AddToSet ||
            changeType === HistoryLogChangeType.Create) &&
            (selectedChangeType === HistoryLogChangeType.AddToSet ||
                selectedChangeType === HistoryLogChangeType.Create)) {
            return true;
        }
        return changeType === selectedChangeType;
    };
    useEffect(function () {
        var _a;
        var normalisedSearch = search.toUpperCase();
        setFiltered((_a = history.filter(function (item) {
            return item.changes.find(function (change) {
                var _a, _b;
                return (((_a = change.metaData.oldValue) === null || _a === void 0 ? void 0 : _a.toUpperCase().includes(normalisedSearch)) ||
                    ((_b = change.metaData.newValue) === null || _b === void 0 ? void 0 : _b.toUpperCase().includes(normalisedSearch))) &&
                    (!selectedProperty ||
                        change.metaData.displayName === selectedProperty) &&
                    (!selectedChangeType || compareChangeType(change.type));
            });
        })) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, selectedProperty, history, selectedChangeType]);
    useEffect(function () {
        // Get unique properties from history to use as filter
        var changes = history.reduce(function (changes, item) {
            item.changes.forEach(function (item) {
                var _a;
                changes.add((_a = item.metaData.displayName) !== null && _a !== void 0 ? _a : '');
            });
            return changes;
        }, new Set());
        setProperties(Array.from(changes).sort(function (a, b) { return a.localeCompare(b); }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);
    return (_jsxs(_Fragment, { children: [history && history.length === 0 && _jsx(_Fragment, { children: t("History.NoHistory") }), history && history.length > 0 && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                            display: 'flex',
                            gap: 30,
                            alignItems: 'flex-start',
                            marginBottom: 30,
                        } }, { children: [_jsxs(Select, __assign({ value: selectedChangeType, onChange: function (e) {
                                    setSelectedChangeType(e.currentTarget.value);
                                }, style: { minWidth: 150, width: 'fit-content' } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('History.ChangeType') })), _jsx("option", __assign({ value: "Update" }, { children: t("History.HistoryUpdateChangeTypeMade.Update") })), _jsx("option", __assign({ value: "Create" }, { children: t("History.HistoryUpdateChangeTypeMade.Create") })), _jsx("option", __assign({ value: "Delete" }, { children: t("History.HistoryUpdateChangeTypeMade.Delete") }))] })), _jsxs(Select, __assign({ value: selectedProperty, onChange: function (e) {
                                    setSelectedProperty(e.currentTarget.value);
                                }, style: { minWidth: 150, width: 'fit-content' } }, { children: [_jsx("option", __assign({ value: "" }, { children: t('History.Property') })), properties.map(function (property, index) { return (_jsx("option", __assign({ value: property }, { children: property }), index)); })] })), _jsx(SearchBox, { name: search, setName: setSearch, placeholder: t('History.SearchValue') })] })), filtered &&
                        filtered.length === 0 &&
                        history &&
                        history.length > 0 && _jsx(_Fragment, { children: t("History.NoHistoryForFilter") }), _jsx(TableResponsiveContainer, { children: _jsx(Table, { children: _jsx("tbody", { children: filtered === null || filtered === void 0 ? void 0 : filtered.map(function (update, index) {
                                    var initials = !update.createdBy ||
                                        update.createdBy === config.support.email
                                        ? 'D'
                                        : initialsFromEmail(update.createdBy);
                                    var emailColor = colorFromEmail({
                                        email: update.createdBy,
                                        currentUserEmail: currentUserEmail,
                                    });
                                    return (_jsxs(Tr, { children: [_jsx(Td, __assign({ style: { padding: '15px 10px', width: 50 } }, { children: _jsx(Badge, __assign({ style: {
                                                        backgroundColor: emailColor || theme.colors.primary,
                                                    } }, { children: initials })) })), _jsxs(Td, __assign({ style: {
                                                    padding: '15px 10px',
                                                    minWidth: 220,
                                                    whiteSpace: 'nowrap',
                                                } }, { children: [_jsx("div", { children: formatDate(parseISO(update.created)) }), _jsx("div", { children: update.createdBy })] })), _jsx(Td, __assign({ style: { width: '100%' } }, { children: _jsx(TableStyle, { children: _jsx("tbody", { children: update.changes.map(function (change, index2) { return (_jsxs(Tr, __assign({ style: {} }, { children: [_jsx(Td, __assign({ style: { width: 100 } }, { children: _jsx("span", __assign({ style: selectedChangeType &&
                                                                            compareChangeType(change.metaData
                                                                                .type)
                                                                            ? {
                                                                                padding: 5,
                                                                                color: theme.colors.onWarning,
                                                                                backgroundColor: theme.colors.warning,
                                                                            }
                                                                            : undefined }, { children: t("History.HistoryUpdateChangeTypeMade.".concat(change.metaData.type)) })) })), _jsxs(Td, __assign({ style: { width: 300 } }, { children: [_jsx("span", __assign({ className: "b", style: selectedProperty &&
                                                                                selectedProperty ===
                                                                                    change.metaData.displayName
                                                                                ? {
                                                                                    padding: 5,
                                                                                    color: theme.colors.onWarning,
                                                                                    backgroundColor: theme.colors.warning,
                                                                                }
                                                                                : undefined }, { children: change.metaData.displayName })), _jsx("span", __assign({ className: "b" }, { children: change.metaData.additionalPropertyName &&
                                                                                ", ".concat(change.metaData.additionalPropertyName) }))] })), _jsx(Td, { children: _jsx(HistoryChangeLogChange, { change: change, isInverted: isInverted }) })] }), index2)); }) }) }) }))] }, index));
                                }) }) }) })] }))] }));
});
HistoryChangeLog.displayName = 'HistoryChangeLog';
var TableStyle = styled(Table)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  tbody {\n    tr {\n      td {\n        padding: 10px 0;\n        vertical-align: middle;\n      }\n    }\n\n    tr:last-of-type {\n      td {\n        border: none;\n      }\n    }\n  }\n"], ["\n  tbody {\n    tr {\n      td {\n        padding: 10px 0;\n        vertical-align: middle;\n      }\n    }\n\n    tr:last-of-type {\n      td {\n        border: none;\n      }\n    }\n  }\n"])));
var templateObject_1;
