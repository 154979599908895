import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Table, Td, Tr } from '@dimatech/shared/lib/components/table';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetNotesQuery } from 'api/project/projectNoteApi';
import { selectSelectedProjectNote } from 'api/project/projectNoteSlice';
import { Owners } from 'components/Owners';
import { parseISO } from 'date-fns';
import { useAppSelector } from 'hooks';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCardText } from 'react-icons/bs';
import styled from 'styled-components';
import { formatDate, htmlDecode } from 'utils';
import { NoteAdd } from './NoteAdd';
import { NoteEdit } from './NoteEdit';
import { NoteListEditButtons } from './NoteListEditButtons';

export const NoteList = ({
  projectId,
  canEdit,
}: {
  projectId: string;
  canEdit: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const selectedNote = useAppSelector(selectSelectedProjectNote);

  const { data: notes, isFetching } = useGetNotesQuery(
    accessToken.customerId && projectId
      ? {
          projectId,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  if (isFetching) {
    return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
  }

  return (
    <>
      <Heading4 style={{ marginBottom: 10 }}>
        {t('Portfolio.Note.Notes')}
      </Heading4>

      {notes && notes.length === 0 && !selectedNote && (
        <div>{t('Portfolio.Note.NoNotes')}</div>
      )}

      {notes && notes.length > 0 && (
        <TableStyle>
          <tbody>
            {notes?.map((note) => (
              <Fragment key={note.id}>
                <Tr>
                  <Td style={{ width: 25 }} className="narrow">
                    <BsCardText />
                  </Td>
                  <Td>
                    <NoteHeaderStyle>
                      <div>
                        {note.created
                          ? formatDate(parseISO(note.created))
                          : '-'}
                        <Owners owners={[note.createdBy as string]} />
                      </div>

                      {canEdit && <NoteListEditButtons note={note} />}
                    </NoteHeaderStyle>

                    <NoteStyle
                      dangerouslySetInnerHTML={{
                        __html:
                          htmlDecode(note.content ? note.content : '') ?? '',
                      }}
                    />
                  </Td>
                </Tr>

                {selectedNote && selectedNote.id === note.id && (
                  <Tr>
                    <Td colSpan={5}>
                      <NoteEdit canEdit={canEdit} />
                    </Td>
                  </Tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </TableStyle>
      )}

      {canEdit && !selectedNote && (
        <NoteAdd
          style={{ marginTop: 30 }}
          projectId={projectId}
          canEdit={canEdit}
        />
      )}

      {selectedNote && !selectedNote.id && <NoteEdit canEdit={canEdit} />}
    </>
  );
};

NoteList.displayName = 'NoteList';

const TableStyle = styled(Table)`
  tr {
    height: 30px;
  }

  td {
    border: none;
  }
`;

const NoteHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  button {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.base};
  }
`;

const NoteStyle = styled.div`
  margin-bottom: 10px;

  > p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
