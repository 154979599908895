import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink, Input } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Dimension, DimensionValue } from 'models';
import { nanoid } from 'nanoid';
import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus, BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import { isAdminReadOnly } from 'utils';

export const DimensionListValues = ({
  dimension,
  setDimension,
  setHasChanges,
}: {
  dimension: Dimension;
  setDimension: (dimension: Dimension) => void;
  setHasChanges: (hasChanges: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const handleAdd = (e: React.SyntheticEvent) => {
    const values = [...(dimension.values ?? [])];

    values.push({
      uid: nanoid(6),
    });

    setDimension({ ...dimension, values });
    setHasChanges(true);
  };

  const handleDelete = (value: DimensionValue) => {
    if (isReadOnly) {
      return;
    }

    if (dimension.values && value.uid) {
      const index = dimension.values.findIndex((d) => d.uid === value.uid);

      if (index > -1) {
        const newValues = [...dimension.values];

        newValues.splice(index, 1);

        setDimension({ ...dimension, values: newValues });
        setHasChanges(true);
      }
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: DimensionValue
  ) => {
    if (!dimension?.values) {
      return;
    }

    const values = [...dimension.values];
    const index = values.findIndex((item) => item.uid === value.uid);

    if (index === -1) {
      return;
    }

    values[index] = {
      ...value,
      name: e.currentTarget.value,
    };

    setDimension({ ...dimension, values });
    setHasChanges(true);
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <Heading4>{t('Administrate.CustomDimension.ListValues.Title')}</Heading4>

      <TooltipContent
        id="tt-value-add"
        title={t('Administrate.CustomDimension.ListValues.Add.TooltipTitle')}
        text={t('Administrate.CustomDimension.ListValues.Add.Tooltip')}
      />
      <TooltipContent
        id="tt-value-delete"
        title={t('Administrate.CustomDimension.ListValues.Delete.TooltipTitle')}
        text={t('Administrate.CustomDimension.ListValues.Delete.Tooltip')}
      />

      {dimension.values?.map((value) => (
        <ValueStyle key={value.uid}>
          <Input
            value={value.name ?? ''}
            onChange={(e) => handleChange(e, value)}
          />
          <BadgeMini
            data-tip
            data-for="tt-value-delete"
            style={{ marginLeft: 10 }}
            onClick={() => handleDelete(value)}
          >
            <BsTrash />
          </BadgeMini>
        </ValueStyle>
      ))}

      <ButtonLink
        style={{
          margin: '15px 30px 5px 0',
          alignSelf: 'flex-start',
          width: 'fit-content',
          whiteSpace: 'nowrap',
        }}
        data-tip
        data-for="tt-value-add"
        onClick={handleAdd}
      >
        <BsPlus /> {t('Administrate.CustomDimension.ListValues.Add.Title')}
      </ButtonLink>
    </div>
  );
};

DimensionListValues.displayName = ' DimensionListValues';

const ValueStyle = styled.div`
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  > label {
    min-width: 100px;
    padding-bottom: 3px;
  }
`;
