var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertError, AlertInfo, AlertWarning, } from '@dimatech/shared/lib/components/Alert';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import { Input } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useGetApiKeyQuery } from '../../../api/customer/customerApiApi';
import { customerApiKeyActions, selectSelectedApiKey, } from '../../../api/customer/customerApiKeySlice';
import { AuthenticationContext } from '../../authentication/AuthenticationProvider';
import { ApiKeyEditFields } from './ApiKeyEditFields';
import { ApiKeysList } from './ApiKeysList';
export var ApiKey = function () {
    var _a;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var selectedApiKey = useSelector(selectSelectedApiKey);
    var _b = useState(false), isAdding = _b[0], setIsAdding = _b[1];
    var _c = useState(), apiKey = _c[0], setApiKey = _c[1];
    var _d = useState(), error = _d[0], setError = _d[1];
    var _e = useGetApiKeyQuery((_a = accessToken.customerId) !== null && _a !== void 0 ? _a : skipToken), data = _e.data, isFetching = _e.isFetching;
    useEffect(function () {
        if ((selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.description) || (selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.roles)) {
            dispatch(customerApiKeyActions.selectedApiKey(__assign({}, selectedApiKey)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.description, selectedApiKey === null || selectedApiKey === void 0 ? void 0 : selectedApiKey.roles]);
    if (isFetching) {
        return _jsx(LoaderSmall, { children: t('Common.UI.Loading') });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Heading3, { children: t('Administrate.Account.Api.Title') }), _jsx("div", __assign({ style: { marginTop: 10 } }, { children: t('Administrate.Account.Api.Text') })), _jsx(LinkWithTooltip, { isPrimary: true, handleClick: function () { return setIsAdding(true); }, title: t('Administrate.Account.Api.Add.Title'), tooltipTitle: t('Administrate.Account.Api.Add.TooltipTitle'), tooltip: t('Administrate.Account.Api.Add.Tooltip'), icon: _jsx(BsPlus, {}), style: { marginTop: 20, marginBottom: 10 } }), error && (_jsx(AlertError, __assign({ style: { marginTop: 10 } }, { children: error.message }))), !apiKey && (_jsx(AlertInfo, __assign({ style: { marginTop: 10 } }, { children: t('Administrate.Account.Api.GenerateText') }))), isAdding && (_jsxs(Modal, __assign({ title: t('Administrate.Account.Api.Add.Title') }, { children: [_jsx("div", { children: t('Administrate.Account.Api.Add.Text') }), _jsx(ApiKeyEditFields, { setIsAdding: setIsAdding, isAdding: isAdding, setError: setError, setApiKey: setApiKey })] }))), apiKey && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginTop: 10 } }, { children: t('Administrate.Account.Api.Generated') })), _jsx(AlertWarning, __assign({ style: { marginTop: 10, marginBottom: 20 } }, { children: t('Administrate.Account.Api.CopyText') })), _jsxs("div", __assign({ style: { display: 'flex' } }, { children: [_jsx(Input, { defaultValue: apiKey, readOnly: true, style: { maxWidth: 500 } }), _jsx(CopyToClipboard, { style: { marginLeft: 10 }, value: apiKey !== null && apiKey !== void 0 ? apiKey : '', text: t('Administrate.Account.Api.CopyKey') })] }))] })), _jsx(ApiKeysList, { apiKeys: data, isAdding: isAdding, setIsAdding: setIsAdding, setError: setError })] }));
};
ApiKey.displayName = 'ApiKey';
