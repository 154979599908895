import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparatorSlim,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles, View } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClipboardData, BsMagic } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

export const NavigationImplemented = ({
  isReader,
  defaultView,
}: {
  isReader: boolean;
  defaultView: View;
}): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isEffectRealisationResponsible = accessToken.isInRole(
    CommonRoles.PiosEffectRealizationResponsible
  );

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  if (!isReader) {
    return null;
  }

  if (!isProjectEffectRealisationEnabledFlagOn) {
    return null;
  }

  return (
    <>
      <NavigationSeparatorSlim />

      <NavigationHeader>{t('Navigation.ImplementedProjects')}</NavigationHeader>

      {(isEffectRealisationResponsible || isGlobalAdmin) && (
        <NavigationItem
          $selected={
            location.pathname.startsWith('/project-effect/my-projects') ||
            (location.pathname === '/' &&
              defaultView === View.ProjectEffectMyProjects)
          }
          $disabled={accessToken.isBaseLicense}
        >
          <BsClipboardData />
          <Link to="/project-effect/my-projects">
            {t('Navigation.ProjectEffectMyProjects')}
          </Link>
        </NavigationItem>
      )}

      <NavigationItem
        $selected={
          location.pathname.startsWith('/project-effect') &&
          !location.pathname.startsWith('/project-effect/my-projects') &&
          !location.pathname.startsWith('/project-effect/actual') &&
          !location.pathname.startsWith('/project-effect/concluded')
        }
        $disabled={accessToken.isBaseLicense}
      >
        <BsMagic />
        <Link to="/project-effect">
          {t('Navigation.ProjectEffectProjectsBenefits')}
        </Link>
      </NavigationItem>
    </>
  );
};

NavigationImplemented.displayName = 'NavigationImplemented';
