import { MyPortfolio as MyPortfolioFeature } from 'features/portfolio/MyPortfolio';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const MyPortfolio = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Portfolio.Title')} scrollToTop={false}>
      <MyPortfolioFeature />
    </View>
  );
};

MyPortfolio.displayName = 'MyPortfolio';
