import { SelectTag } from '@dimatech/features-core/lib/components/SelectTag';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tags } from '@dimatech/shared/lib/components/Tag';
import {
  useAddProjectTagMutation,
  useDeleteProjectTagMutation,
} from 'api/project/projectTagApi';
import { Tag } from 'models';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isPiosReadOnly } from 'utils';

export const ProjectTagList = ({
  tags,
  portfolioId,
  projectId,
  canEdit,
  handleChangeTag,
}: {
  tags: Tag[] | undefined;
  portfolioId: string;
  projectId: string;
  canEdit: boolean;
  handleChangeTag?: (tags: Tag[]) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [deleteProjectTag] = useDeleteProjectTagMutation();
  const [addProjectTag] = useAddProjectTagMutation();

  const handleDeleteTag = (tag: Tag) => {
    if (isViewReadOnly) {
      return;
    }

    deleteProjectTag({
      portfolioId,
      projectId,
      tag,
    })
      .unwrap()
      .then(() => {
        handleChangeTag?.(tags?.filter((t) => t.id !== tag.id) ?? []);
      });
  };

  const handleAddTag = (
    e: React.ChangeEvent<HTMLSelectElement>,
    tag: Tag | undefined
  ) => {
    if (isViewReadOnly || !tag) {
      return;
    }

    addProjectTag({
      portfolioId,
      projectId,
      tag,
    })
      .unwrap()
      .then(() => {
        handleChangeTag?.([...(tags ?? []), tag]);
      });

    e.currentTarget.selectedIndex = 0;
  };

  return (
    <>
      <div className="b" style={{ marginBottom: 10 }}>
        {t('Project.Tag.Tags')}
      </div>

      {tags && tags.length === 0 && <div>{t('Project.Tag.NoTags')}</div>}

      {tags && tags.length > 0 && (
        <Tags onDelete={canEdit ? handleDeleteTag : undefined} tags={tags} />
      )}

      {canEdit && (
        <SelectTag
          onSelectTag={handleAddTag}
          style={{ minWidth: 150, width: 'fit-content', marginTop: 10 }}
          placeholder={t('Project.Tag.Add')}
        />
      )}
    </>
  );
};

ProjectTagList.displayName = 'ProjectTagList';
