import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { piosActions, selectFilterByReadyForReview } from 'api/piosSlice';
import { useGetProjectIdeasOverviewQuery } from 'api/project/projectIdeaOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { IdeaListPrioritise } from 'features/project-idea/components/IdeaListPrioritise';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  Dimension,
  DimensionDisplayType,
  DimensionType,
  Phase,
  ProjectIdea,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { calculateProjectIdeasQuota } from 'utils/dimension';

export const IdeaReview = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);

  const filterReadyForReview = useAppSelector(selectFilterByReadyForReview);

  const { projectId = '' } = useParams<{
    projectId: string;
  }>();

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
  const ldContext = useLDClient()?.getContext();

  const { data: calculations } = useGetCalculationsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const { data, isFetching, isLoading } = useGetProjectIdeasOverviewQuery(
    accessToken.customerId && accessToken.user?.id && !ldContext?.anonymous
      ? {
          filter: filterReadyForReview,
          isProjectIdeasReviewersEnabled: isProjectIdeasReviewersEnabledFlagOn,
          _userId: accessToken.user.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const { data: customDimensions } = useGetDimensionsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const [classifications, setClassifications] = useState<Dimension[]>([]);
  const [ideas, setIdeas] = useState<ProjectIdea[]>([]);

  useEffect(() => {
    if (customDimensions) {
      const dimensions = customDimensions
        .filter(
          (dimension) =>
            (dimension.type === DimensionType.ClassificationBenefit ||
              dimension.type === DimensionType.ClassificationStake) &&
            dimension.projectPhases?.includes(Phase.Draft)
        )
        .sort((_a, b) =>
          b.type === DimensionType.ClassificationBenefit ? 1 : -1
        )
        .sort((_a, b) =>
          b.displayType === DimensionDisplayType.Rating ? 1 : -1
        );

      setClassifications(dimensions);
    }
  }, [customDimensions]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (classifications.length === 0) {
      setIdeas(data);
    }

    const calculated = calculateProjectIdeasQuota({
      ideas: data,
      dimensions: classifications,
      calculations,
    });

    setIdeas(calculated);
  }, [data, classifications, calculations]);

  useEffect(() => {
    if (!projectId) {
      dispatch(piosActions.setSelectedProjectIdea(undefined));
    }

    if (projectId && ideas) {
      const selected = ideas.find((p) => p.projectId === projectId);

      dispatch(piosActions.setSelectedProjectIdea({ ...selected }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideas, projectId]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('FutureProjects.ProjectIdea.ReviewTitle')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isTagFilterShown={true}
            isPhaseFilterShown={false}
            isStatusFilterShown={false}
            isResetFilterShown={true}
            isPeriodFilterShown={true}
            isIncludeMineOnlyFilterShown={false}
            isProjectEffectTakeHomeFilterShown={false}
            isCustomDimensionFiltersShown={true}
            isPortfolioFilterShown={true}
            isProject={false}
          />
        </CardBody>
      </Card>

      {ideas && (
        <Card>
          <CardBody isLoading={isFetching || isLoading}>
            <IdeaListPrioritise
              ideas={ideas}
              classifications={classifications}
              calculations={calculations}
              isReviewing={true}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

IdeaReview.displayName = 'IdeaReview';
