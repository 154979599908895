import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparator,
} from '@dimatech/shared/lib/components/workspace';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTagsFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

export const NavigationGlobalAdministrate = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const location = useLocation();
  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);

  return (
    <>
      {isGlobalAdmin && (
        <>
          <NavigationSeparator />

          <NavigationHeader>
            {t('Navigation.GlobalAdministrate.GlobalAdministrate')}
          </NavigationHeader>

          <NavigationItem
            $selected={location.pathname === '/global-administrate/common-tags'}
          >
            <BsTagsFill />
            <Link to="/global-administrate/common-tags">
              {t('Navigation.GlobalAdministrate.NationalTag')}
            </Link>
          </NavigationItem>
        </>
      )}
    </>
  );
};

NavigationGlobalAdministrate.displayName = 'NavigationGlobalAdministrate';
