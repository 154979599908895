import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  Buttons,
  InputNumber,
  Label,
} from '@dimatech/shared/lib/components/form';
import { useUpdateDimensionMutation } from 'api/dimension/dimensionApi';
import { dimensionActions } from 'api/dimension/dimensionSlice';
import { useAppDispatch } from 'hooks';
import produce from 'immer';
import { CalculationMutation, Dimension, DimensionCalculation } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';

export const WeightProperties = ({
  classification,
  calculation,
}: {
  classification: Dimension;
  calculation: CalculationMutation;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [classificationNew, setClassificationNew] = useState<
    Dimension | undefined
  >();
  const [isValid, setIsValid] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);

  const [updateDimension, { isLoading: updatePosting }] =
    useUpdateDimensionMutation();

  const handleChange = (
    classification: Dimension,
    value: string,
    weight: DimensionCalculation | undefined
  ) => {
    if (!classification.calculationWeights) {
      return;
    }

    const index = classification.calculationWeights.findIndex(
      (item) => item.calculationId === weight?.calculationId
    );

    const calculationWeights = produce(
      classification.calculationWeights,
      (draftState) => {
        draftState[index].weight = !isNaN(parseInt(value)) ? +value : undefined;
      }
    );

    setClassificationNew({
      ...classification,
      calculationWeights,
    });
  };

  const handleSave = () => {
    if (isReadOnly) {
      return;
    }
    setIsValid(true);

    if (
      classificationNew?.calculationWeights?.some(
        (weight) => weight.weight === undefined
      )
    ) {
      setIsValid(false);
      return;
    }

    if (classificationNew?.id) {
      updateDimension({
        ...classificationNew,
      })
        .unwrap()
        .then(() => {
          setHasChanges(false);
        });
    }

    dispatch(dimensionActions.selectClassification(undefined));
  };

  const weight = classification.calculationWeights?.find(
    (w) => w.calculationId === calculation?.id
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <Label htmlFor={`weight_${classification.id}`}>
          {classification?.name}
        </Label>
        <InputNumber
          id={`weight_${classification.id}`}
          name={`weight_${classification.id}`}
          value={weight?.weight ?? ''}
          invalid={
            !isValid &&
            classificationNew?.calculationWeights?.some(
              (weight) => weight.weight === undefined
            )
          }
          style={{ width: 100, marginLeft: 10 }}
          onValueChange={({ value }) => {
            handleChange(classification, value, weight);
            setHasChanges(true);
          }}
        />
      </div>

      <Buttons style={{ marginBottom: 20 }}>
        <Button
          type="button"
          disabled={isReadOnly || updatePosting || !hasChanges}
          onClick={handleSave}
        >
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </>
  );
};

WeightProperties.displayName = 'WeightProperties';
