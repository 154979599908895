import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Checkbox } from '@dimatech/shared/lib/components/form';
import { useUiSortablePaginator } from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { ProjectPhase } from 'components/ProjectPhase';
import { ProjectStatus as ProjectStatusComponent } from 'components/ProjectStatus';
import { ProjectStatus } from 'models';
import { ProjectEffectRealisation } from 'models/project-effect';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckAll, BsFileCheck, BsFiles, BsX } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { formatAsCurrency, formatAsNumber } from 'utils';
import { ProjectEdit } from './ProjectEdit';

/* eslint-disable max-lines-per-function */

export const ProjectsList = withTheme(
  ({
    projects,
    shownProjects,
    setShownProjects,
    saveToPngElementId,
    theme,
  }: {
    projects: ProjectEffectRealisation[];
    shownProjects: ProjectEffectRealisation[];
    setShownProjects: (projects: ProjectEffectRealisation[]) => void;
    saveToPngElementId: string;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const [selectedProject, setSelectedProject] =
      useState<ProjectEffectRealisation>();
    const [markedProjects, setMarkedProjects] = useState<
      ProjectEffectRealisation[]
    >([]);

    const [hasMarkedChanged, setHasMarkedChanged] = useState(false);

    const noOfMarked = markedProjects ? markedProjects.length : 0;
    const noOfTotal = projects.length;
    const isAllMarked = noOfMarked === noOfTotal;

    const { items, sorter } = useUiSortablePaginator({
      orderBy: 'title',
      pageSize: 500,
      data: {
        totalRecords: (projects ?? []).length,
        records: projects ?? [],
      },
    });

    const handleMark = (project: ProjectEffectRealisation) => {
      const newProjects = [...markedProjects];
      const index = newProjects.findIndex(
        (s) => s.projectId === project.projectId
      );

      if (index === -1) {
        newProjects.push(project);
      } else {
        newProjects.splice(index, 1);
      }

      if (newProjects.length === 0) {
        setShownProjects(projects);
      }

      setHasMarkedChanged(true);
      setMarkedProjects(newProjects);
    };

    const handleShowMarked = () => {
      setShownProjects(markedProjects);
      setHasMarkedChanged(false);
    };

    const handleShowAll = () => {
      setShownProjects(projects);
      setMarkedProjects([]);
      setHasMarkedChanged(true);
    };

    const handleMarkAll = (checked: boolean) => {
      setMarkedProjects(checked ? projects : []);
      setHasMarkedChanged(true);
    };

    const handleSelect = (project: ProjectEffectRealisation) => {
      if (project.projectId === selectedProject?.projectId) {
        setSelectedProject(undefined);
      } else {
        setSelectedProject(project);
      }
    };

    return (
      <>
        {projects && projects.length === 0 && (
          <div style={{ marginBottom: 20 }}>
            {t('ImplementedProjects.NoProjects')}
          </div>
        )}

        {items.length > 0 && (
          <div
            style={{
              margin: '20px 0 10px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 15,
            }}
          >
            <LinkWithTooltip
              handleClick={() => handleMarkAll(!isAllMarked)}
              title={
                isAllMarked
                  ? t(`Common.UI.DeselectAll`)
                  : t(`Common.UI.SelectAll`)
              }
              icon={isAllMarked ? <BsX /> : <BsCheckAll />}
              disabledTimerInMs={1}
            />

            <LinkWithTooltip
              handleClick={handleShowMarked}
              title={t(
                `ImplementedProjects.ProjectsBenefits.Projects.ShowSelected.Title`
              )}
              tooltip={t(
                `ImplementedProjects.ProjectsBenefits.Projects.ShowSelected.Tooltip`
              )}
              icon={<BsFileCheck />}
              isDisabled={markedProjects.length === 0 || !hasMarkedChanged}
            />

            <LinkWithTooltip
              handleClick={handleShowAll}
              title={t(
                `ImplementedProjects.ProjectsBenefits.Projects.ShowAll.Title`
              )}
              tooltip={t(
                `ImplementedProjects.ProjectsBenefits.Projects.ShowAll.Tooltip`
              )}
              icon={<BsFiles />}
              isDisabled={
                shownProjects.length === 0 ||
                shownProjects.length === projects.length
              }
            />
          </div>
        )}

        {projects && projects.length > 0 && (
          <TableResponsiveContainer style={{ marginTop: 20 }}>
            <Table id={saveToPngElementId}>
              {items.length > 0 && (
                <thead>
                  <tr>
                    <Th className="narrow" style={{ width: 25 }} />
                    <ThCentered
                      style={{ width: 55 }}
                      sortKey="status"
                      sorter={sorter}
                    >
                      {t('Project.Status.Status')}
                    </ThCentered>
                    <Th
                      className="narrow"
                      sortKey="projectPhase"
                      sorter={sorter}
                      style={{ width: 100 }}
                    >
                      {t('Project.Phase.Phase')}
                    </Th>
                    <Th
                      style={{ width: 100 }}
                      className="narrow"
                      sortKey="externalId"
                      sorter={sorter}
                    >
                      {t('Project.Id')}
                    </Th>
                    <Th>
                      <SortElements>
                        <SortElement sortKey="title" sorter={sorter}>
                          {t('Project.Project')}
                        </SortElement>
                        <>
                          <SortElement sortKey="portfolioName" sorter={sorter}>
                            {t('Portfolio.Portfolio')}
                          </SortElement>
                        </>
                      </SortElements>
                    </Th>
                    <Th sortKey="entityPath" sorter={sorter}>
                      {t('Portfolio.Entity')}
                    </Th>
                    <ThRight
                      style={{ width: 150 }}
                      className="narrow"
                      sortKey="budget"
                      sorter={sorter}
                    >
                      {t('Project.Budget', {
                        currency: t(
                          `Common.Currency.${accessToken.locale.currency}.Name`
                        ),
                      })}
                    </ThRight>
                    <ThRight
                      style={{ width: 110 }}
                      sortKey="estimatedBenefit"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.ExpectedBenefit', {
                        currency: t(
                          `Common.Currency.${accessToken.locale.currency}.Name`
                        ),
                      })}
                    </ThRight>
                    <ThRight
                      style={{ width: 110 }}
                      sortKey="actualBenefit"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.ActualBenefit', {
                        currency: t(
                          `Common.Currency.${accessToken.locale.currency}.Name`
                        ),
                      })}
                    </ThRight>
                    <ThRight
                      style={{ width: 110 }}
                      sortKey="result"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.Deviation', {
                        currency: t(
                          `Common.Currency.${accessToken.locale.currency}.Name`
                        ),
                      })}
                    </ThRight>
                    <ThRight
                      style={{ width: 80 }}
                      sortKey="startYear"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.ProjectStartYear')}
                    </ThRight>
                    <ThRight
                      style={{ width: 80 }}
                      sortKey="endYear"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.ProjectEndYear')}
                    </ThRight>
                    <ThRight
                      style={{ width: 80 }}
                      sortKey="takeHomePeriodYear"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.TakeHomePeriodYear')}
                    </ThRight>
                    <ThRight
                      style={{ width: 80 }}
                      sortKey="takeHomePeriodEndYear"
                      sorter={sorter}
                    >
                      {t('ImplementedProjects.TakeHomePeriodEndYear')}
                    </ThRight>
                  </tr>
                </thead>
              )}

              <tbody>
                {items.map((project, index) => (
                  <Fragment key={index}>
                    <Tr
                      onSelect={(e) => {
                        if (
                          ['INPUT', 'SELECT'].includes(
                            (e.target as HTMLElement).nodeName
                          )
                        ) {
                          return;
                        }

                        handleSelect(project);
                      }}
                    >
                      <Td className="narrow">
                        <Checkbox
                          onChange={() => {
                            handleMark(project);
                          }}
                          id={project.projectId}
                          name={project.projectId}
                          value={project.projectId}
                          checked={markedProjects.some(
                            (p) => p.projectId === project.projectId
                          )}
                        />
                      </Td>
                      <TdCentered
                        data-value={t(
                          `Project.Status.${
                            project.projectStatus ?? ProjectStatus.Zero
                          }`
                        )}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <ProjectStatusComponent
                          status={project.projectStatus}
                        />
                      </TdCentered>
                      <Td className="narrow">
                        <ProjectPhase phase={project.projectPhase} />
                      </Td>
                      <Td className="narrow">{project.externalId}</Td>
                      <Td>
                        <strong>{project.title}</strong>
                        <div className="i">{project.portfolioName}</div>
                      </Td>
                      <Td data-value={project.entityPath}>
                        {project.entityName}
                        {project.entityPath !== project.entityName && (
                          <div className="size-xs i">{project.entityPath}</div>
                        )}
                      </Td>
                      <TdRight data-value={project.budget} className="narrow">
                        {formatAsNumber(project.budget, null)}
                      </TdRight>
                      <TdRight data-value={project.totalExpectedBenefit}>
                        {formatAsCurrency(project.totalExpectedBenefit)}
                      </TdRight>
                      <TdRight data-value={project.totalActualBenefit}>
                        {formatAsCurrency(project.totalActualBenefit)}
                      </TdRight>
                      <TdRight data-value={project.deviation}>
                        <div
                          style={{
                            display: 'flex',
                            gap: 5,
                            justifyContent: 'flex-end',
                          }}
                        >
                          {formatAsCurrency(project.deviation)}
                        </div>
                      </TdRight>
                      <TdRight data-value={project.startYear}>
                        {project.startYear ?? '-'}
                      </TdRight>
                      <TdRight data-value={project.endYear}>
                        {project.endYear ?? '-'}
                      </TdRight>
                      <TdRight>{project.maxTakeHomePeriod}</TdRight>
                      <TdRight>{project.takeHomePeriodEndYear ?? '-'}</TdRight>
                    </Tr>

                    {selectedProject?.projectId === project.projectId &&
                      project.projectId && (
                        <Tr>
                          <Td
                            colSpan={100}
                            style={{
                              paddingBottom: 60,
                              paddingTop: 20,
                              paddingRight: 20,
                              paddingLeft: 20,
                              backgroundColor: theme.colors.surfaceVariant,
                            }}
                          >
                            <ProjectEdit project={project} />
                          </Td>
                        </Tr>
                      )}
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </TableResponsiveContainer>
        )}
      </>
    );
  }
);

ProjectsList.displayName = 'ProjectsList';
