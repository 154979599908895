import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dimension } from 'models';
import { RootState } from 'store';

const initialState: {
  selectedDimension?: Dimension;
  selectedClassification?: Dimension;
} = {};

export const { reducer: dimensionReducer, actions: dimensionActions } =
  createSlice({
    name: 'dimension',
    initialState,
    reducers: {
      selectDimension: (
        state,
        action: PayloadAction<Dimension | undefined>
      ) => {
        state.selectedDimension = action.payload;
      },
      selectClassification: (
        state,
        action: PayloadAction<Dimension | undefined>
      ) => {
        state.selectedClassification = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, () => initialState);
    },
  });

export const selectSelectedDimension = (
  state: RootState
): Dimension | undefined => state.dimension.selectedDimension;

export const selectSelectedClassification = (
  state: RootState
): Dimension | undefined => state.dimension.selectedClassification;
