/* eslint-disable max-lines-per-function */
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import {
  getPortfolioExportBundle,
  selectPortfolioExportBundleIsPosting,
} from 'api/portfolio/portfolioExportBundleSlice';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OverviewTabIndex } from 'models';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFileExcel } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAdminAvailable, isPiosReadOnly } from 'utils';
import { PortfoliosBudgetAndBalance } from './components/PortfoliosBudgetAndBalance';
import { PortfoliosBudgetAndBalanceOverTime } from './components/PortfoliosBudgetAndBalanceOverTime';
import { PortfoliosKeyStatistics } from './components/PortfoliosKeyStatistics';
import { PortfoliosList } from './components/PortfoliosList';
import { PortfoliosProjectsTagTreeTable } from './components/PortfoliosProjectsTagTreeTable';
import { PortfoliosProjectsTimeline } from './components/PortfoliosProjectsTimeline';

export const Portfolios = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const location = useLocation();
  const navigate = useNavigate();

  const isViewReadOnly = isPiosReadOnly(accessToken);
  const isPosting = useAppSelector(selectPortfolioExportBundleIsPosting);

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('balance-over-time')
      ? OverviewTabIndex.BalanceOverTime
      : location.pathname.endsWith('balance')
      ? OverviewTabIndex.Balance
      : location.pathname.endsWith('tag')
      ? OverviewTabIndex.Tag
      : location.pathname.endsWith('table')
      ? OverviewTabIndex.Table
      : location.pathname.endsWith('timeline')
      ? OverviewTabIndex.Timeline
      : OverviewTabIndex.Summary
  );

  const isPortfolioExportEveryonesEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPortfolioExportEveryonesEnabled];
  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];
  const isGanttEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isGanttEnabled];

  // Disable if not admin and not owner, and the
  // isPortfolioExportEveryonesEnabled flag is off
  const canExportPortfolios =
    isAdminAvailable(accessToken) ||
    isPortfolioExportEveryonesEnabledFlagOn ||
    (accessToken.user?.email && filter.onlyMine === true);

  const { data } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const hasData = data?.portfolios && data.portfolios.length > 0;

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/portfolios/${
        index === OverviewTabIndex.BalanceOverTime
          ? 'balance-over-time'
          : index === OverviewTabIndex.Balance
          ? 'balance'
          : index === OverviewTabIndex.Tag
          ? 'tag'
          : index === OverviewTabIndex.Table
          ? 'table'
          : index === OverviewTabIndex.Timeline
          ? 'timeline'
          : 'summary'
      }`,
      {
        state: { from: location.pathname },
      }
    );
  };

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isViewReadOnly || !canExportPortfolios) {
      return;
    }

    dispatch(
      getPortfolioExportBundle({
        filter,
        bundleName: t('Portfolios.Title'),
      })
    );
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Portfolios.Title')}</Heading1>

        {hasData && canExportPortfolios && (
          <ViewHeaderActions>
            <LinkWithTooltip
              handleClick={(e) => !isPosting && handleExportDownload(e)}
              title={t('Portfolios.Export.Download.TooltipTitle')}
              tooltipTitle={t('Portfolios.Export.Download.TooltipTitle')}
              tooltip={t('Portfolios.Export.Download.Tooltip')}
              icon={isPosting ? <LoaderSmall /> : <FaRegFileExcel />}
              isInverted={true}
              isDisabled={isPosting}
            />
          </ViewHeaderActions>
        )}
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isIncludeMineOnlyFilterShown={true}
            isPeriodFilterShown={true}
            isPhaseFilterShown={true}
            isStatusFilterShown={true}
            isResetFilterShown={true}
            isCustomDimensionFiltersShown={true}
            isFilterForPortfolios={true}
            isPortfolioFilterShown={true}
            isPeriodMonthFilterShown={true}
          />
        </CardBody>
      </Card>

      {data?.portfolios && data.portfolios.length === 0 && (
        <Card>
          <CardBody>{t('Portfolios.NoPortfolios')}</CardBody>
        </Card>
      )}

      {data?.portfolios && data.portfolios.length > 0 && (
        <Tabs>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Summary}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Summary)}
          >
            {t('Portfolios.KeyStatistics.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Balance}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Balance)}
          >
            {t('Portfolios.BudgetAndBalance.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.BalanceOverTime}
            handleSelect={() =>
              handleSelectTab(OverviewTabIndex.BalanceOverTime)
            }
          >
            {t('Portfolios.BudgetAndBalanceOverTime.Title')}
          </Tab>
          {isTagsEnabledFlagOn && (
            <Tab
              isSelected={selectedTabIndex === OverviewTabIndex.Tag}
              handleSelect={() => handleSelectTab(OverviewTabIndex.Tag)}
            >
              {t('Portfolios.TagTable.Title')}
            </Tab>
          )}
          {isGanttEnabledFlagOn && (
            <Tab
              isSelected={selectedTabIndex === OverviewTabIndex.Timeline}
              handleSelect={() => handleSelectTab(OverviewTabIndex.Timeline)}
            >
              {t('Projects.Timeline.Title')}
            </Tab>
          )}
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Table}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Table)}
          >
            {t('Portfolios.List.Title')}
          </Tab>
        </Tabs>
      )}

      {selectedTabIndex === OverviewTabIndex.Summary && (
        <PortfoliosKeyStatistics />
      )}

      {selectedTabIndex === OverviewTabIndex.Balance && (
        <PortfoliosBudgetAndBalance />
      )}

      {selectedTabIndex === OverviewTabIndex.BalanceOverTime && (
        <PortfoliosBudgetAndBalanceOverTime />
      )}

      {selectedTabIndex === OverviewTabIndex.Tag && isTagsEnabledFlagOn && (
        <PortfoliosProjectsTagTreeTable />
      )}

      {selectedTabIndex === OverviewTabIndex.Timeline &&
        isGanttEnabledFlagOn && <PortfoliosProjectsTimeline />}

      {selectedTabIndex !== OverviewTabIndex.Timeline && <PortfoliosList />}
    </>
  );
};

Portfolios.displayName = 'Portfolios';
