import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationItem,
  NavigationSeparator,
} from '@dimatech/shared/lib/components/workspace';
import { Color } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsApp } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export const NavigationProducts = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);

  return (
    <>
      <NavigationSeparator />

      <NavigationItem>
        <BsApp style={{ color: Color.DimiosBlue }} />
        <ProductLink
          productId={config.products.dimios.id as string}
          productUrl={config.products.dimios.url}
          productName={t('Account.Dimios.Name')}
          orderRoute="/order-dimios"
        />
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.SandyBrown }} />
        <ProductLink
          productId={config.products.dios.id as string}
          productUrl={config.products.dios.url}
          productName={t('Account.Dios.Name')}
          orderRoute="/order-dios"
        />
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.Amethyst }} />
        <ProductLink
          productId={config.products.dikios.id as string}
          productUrl={config.products.dikios.url}
          productName={t('Account.Dikios.Name')}
          orderRoute="/order-dikios"
        />
      </NavigationItem>

      {isGlobalAdmin && (
        <NavigationItem>
          <BsApp style={{ color: Color.DimatechBlue }} />
          <a href={config.products.admin.url}>{t('Account.Admin.Name')}</a>
        </NavigationItem>
      )}
    </>
  );
};

NavigationProducts.displayName = 'NavigationProducts';

const ProductLink = ({
  productId,
  productUrl,
  productName,
  orderRoute,
}: {
  productId: string;
  productUrl: string;
  productName: string;
  orderRoute: string;
}) => {
  const { accessToken } = useContext(AuthenticationContext);

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isDemoUser = accessToken.isInRole(CommonRoles.DemoUser);

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));
  const isAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAdmin));

  const customerHasAccess =
    (isCustomerAccountAdmin || isAdmin || isDemoUser || isResearcher) &&
    accessToken.hasCustomerProductAccess(productId);

  if (isGlobalAdmin) {
    return <a href={productUrl}>{productName}</a>;
  }

  if (customerHasAccess && accessToken.hasUserProductAccess(productId)) {
    return <a href={productUrl}>{productName}</a>;
  }

  if (isAdmin || isCustomerAccountAdmin) {
    return <Link to={orderRoute}>{productName}</Link>;
  }

  return <a href={`${productUrl}/register`}>{productName}</a>;
};
