import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import {
  dimensionActions,
  selectSelectedDimension,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dimension, DimensionType } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { isAdminReadOnly, projectOngoingPhases } from 'utils';
import { TabContentStyle } from './TabContentStyle';
import { DimensionProperties } from './components/DimensionProperties';
import { DimensionsList } from './components/DimensionsList';

export const Dimensions = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [dimensions, setDimensions] = useState<Dimension[]>([]);
  const selectedDimension = useAppSelector(selectSelectedDimension);

  const { data, isFetching, isLoading } = useGetDimensionsQuery(
    accessToken.customerId ?? skipToken
  );

  const { data: calculations } = useGetCalculationsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const handleCreate = () => {
    dispatch(
      dimensionActions.selectDimension({
        type: DimensionType.Data,
        projectPhases: projectOngoingPhases,
      })
    );
  };

  useEffect(() => {
    setDimensions([
      ...(data?.filter((dimension) => dimension.type === DimensionType.Data) ??
        []),
    ]);
  }, [data, calculations]);

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        {t('Administrate.CustomDimension.Text')}
      </div>

      <LinkWithTooltip
        isPrimary={true}
        isDisabled={isReadOnly}
        handleClick={handleCreate}
        title={t('Administrate.CustomDimension.Add.TooltipTitle')}
        tooltipTitle={t('Administrate.CustomDimension.Add.TooltipTitle')}
        tooltip={t('Administrate.CustomDimension.Add.Tooltip')}
        icon={<BsPlus />}
        style={{ marginBottom: 20 }}
      />

      {(isFetching || isLoading) && <LoaderOverlay />}

      {(!dimensions || dimensions.length === 0) && !selectedDimension ? (
        <>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.CustomDimension.NoDimensions')}
          </div>
          <ButtonLink type="button" onClick={handleCreate}>
            {t('Administrate.CustomDimension.NoDimensionsAddFirst')}
          </ButtonLink>
        </>
      ) : (
        <TabContentStyle>
          <DimensionsList dimensions={dimensions} />

          {selectedDimension && (
            <div style={{ flexGrow: 1 }}>
              <DimensionProperties />
            </div>
          )}
        </TabContentStyle>
      )}
    </>
  );
};

Dimensions.displayName = 'Dimensions';
