import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  HelpIcon,
  HelpIconCentered,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Input, InputNumber } from '@dimatech/shared/lib/components/form';
import {
  Table,
  Td,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency, toTitleCase } from '@dimatech/shared/lib/utils';
import { ProjectGoal, ProjectGoalBenefit } from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { mergeBenefits } from 'utils/project-effect';

export const GoalBenefits = withTheme(
  ({
    goal,
    handleChangeGoal,
    theme,
    canEditExpected = true,
    canEditActual = true,
  }: {
    goal: ProjectGoal;
    handleChangeGoal?: (goal: ProjectGoal) => void;
    canEditExpected?: boolean;
    canEditActual?: boolean;
    theme: Theme;
  }) => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const [benefits, setBenefits] = useState<ProjectGoalBenefit[]>([]);

    const handleChange = (benefit: ProjectGoalBenefit) => {
      if (!canEditExpected && !canEditActual) {
        return;
      }

      const newBenefits = [...benefits];
      const index = newBenefits.findIndex(
        (item) => item.benefitType === benefit.benefitType
      );

      if (index === -1) {
        return;
      }

      newBenefits[index] = {
        ...benefit,
      };

      handleChangeGoal?.({
        ...goal,
        benefits: newBenefits,
      });
    };

    useEffect(() => {
      if (goal) {
        setBenefits(mergeBenefits(goal.benefits ?? []));
      }
    }, [goal]);

    return (
      <BenefitTableStyle>
        <thead>
          <TrHeader>
            <Th />

            <ThCentered
              colSpan={2}
              style={{
                backgroundColor: theme.colors.surfaceVariant,
                borderBottom: `2px solid ${theme.colors.lowPriority}`,
              }}
            >
              <HelpIconCentered>
                {t('ImplementedProjects.ExpectedBenefitHeader')}

                <HelpIcon
                  showTiny={true}
                  isInverted={true}
                  style={{ marginLeft: 5 }}
                  title={t('ImplementedProjects.ExpectedBenefitHeader')}
                  text={
                    <Trans i18nKey="ImplementedProjects.ExpectedBenefitHelp" />
                  }
                />
              </HelpIconCentered>
            </ThCentered>

            <ThCentered
              colSpan={2}
              style={{
                backgroundColor: theme.colors.surfaceVariant,
                borderBottom: `2px solid ${theme.colors.lowPriority}`,
              }}
            >
              <HelpIconCentered>
                {t('ImplementedProjects.ActualBenefitHeader')}

                <HelpIcon
                  showTiny={true}
                  isInverted={true}
                  style={{ marginLeft: 5 }}
                  title={t('ImplementedProjects.ActualBenefitHeader')}
                  text={
                    <Trans i18nKey="ImplementedProjects.ActualBenefitHelp" />
                  }
                />
              </HelpIconCentered>
            </ThCentered>
          </TrHeader>
        </thead>
        <thead>
          <TrHeader>
            <Th style={{ width: 120 }} />

            <ThRight
              style={{
                width: 130,
                borderLeft: `1px solid ${theme.colors.border}`,
              }}
            >
              {toTitleCase(
                t(`Common.Currency.${accessToken.locale.currency}.Name`)
              )}
            </ThRight>
            <Th style={{ width: '40%' }}>
              {t('ImplementedProjects.ExpectedBenefitDescription')}
            </Th>
            <ThRight
              style={{
                width: 130,
                borderLeft: `1px solid ${theme.colors.border}`,
              }}
            >
              {toTitleCase(
                t(`Common.Currency.${accessToken.locale.currency}.Name`)
              )}
            </ThRight>
            <Th
              style={{
                width: '40%',
                borderRight: `1px solid ${theme.colors.border}`,
              }}
            >
              {t('ImplementedProjects.ActualBenefitDescription')}
            </Th>
          </TrHeader>
        </thead>
        <tbody>
          {benefits.map((benefit) => (
            <Tr key={benefit.benefitType}>
              <Td>
                {t(`ImplementedProjects.Benefits.${benefit.benefitType}`)}
              </Td>
              <TdRight
                style={{
                  borderLeft: `1px solid ${theme.colors.border}`,
                }}
              >
                {!canEditExpected && formatAsCurrency(benefit.expected)}

                {canEditExpected && (
                  <InputNumber
                    id="expected"
                    name="expected"
                    style={{ minWidth: 70 }}
                    value={benefit.expected ?? ''}
                    onValueChange={({ floatValue }) => {
                      handleChange({
                        ...benefit,
                        expected: floatValue,
                      });
                    }}
                  />
                )}
              </TdRight>
              <Td>
                {!canEditExpected && benefit.expectedDescription}

                {canEditExpected && (
                  <Input
                    type="text"
                    id="expectedDescription"
                    name="expectedDescription"
                    value={benefit.expectedDescription ?? ''}
                    onChange={(e) => {
                      handleChange({
                        ...benefit,
                        expectedDescription: e.currentTarget.value,
                      });
                    }}
                  />
                )}
              </Td>

              <TdRight
                style={{
                  borderLeft: `1px solid ${theme.colors.border}`,
                }}
              >
                {!canEditActual && formatAsCurrency(benefit.actual)}

                {canEditActual && (
                  <InputNumber
                    id="actual"
                    name="actual"
                    style={{ minWidth: 70 }}
                    value={benefit.actual ?? ''}
                    onValueChange={({ floatValue }) => {
                      handleChange({
                        ...benefit,
                        actual: floatValue,
                      });
                    }}
                  />
                )}
              </TdRight>
              <Td
                style={{
                  borderRight: `1px solid ${theme.colors.border}`,
                }}
              >
                {!canEditActual && benefit.actualDescription}

                {canEditActual && (
                  <Input
                    type="text"
                    id="actualDescription"
                    name="actualDescription"
                    value={benefit.actualDescription ?? ''}
                    onChange={(e) => {
                      handleChange({
                        ...benefit,
                        actualDescription: e.currentTarget.value,
                      });
                    }}
                  />
                )}
              </Td>
            </Tr>
          ))}

          <Tr>
            <Td className="b">{t('Common.UI.Total')}</Td>
            <TdRight
              className="b"
              style={{
                borderLeft: `1px solid ${theme.colors.border}`,
              }}
            >
              {formatAsCurrency(goal.totalExpectedBenefit)}
            </TdRight>
            <Td />
            <TdRight
              className="b"
              style={{
                borderLeft: `1px solid ${theme.colors.border}`,
              }}
            >
              {formatAsCurrency(goal.totalActualBenefit)}
            </TdRight>
            <Td
              style={{
                borderRight: `1px solid ${theme.colors.border}`,
              }}
            />
          </Tr>
        </tbody>
      </BenefitTableStyle>
    );
  }
);

GoalBenefits.displayName = 'GoalBenefits';

const BenefitTableStyle = styled(Table)`
  width: 100%;

  > tbody {
    > tr {
      height: 30px;

      td {
        padding: 5px;
        vertical-align: middle;

        &:first-of-type {
          font-style: italic;
        }

        input[type='number'] {
          width: 120px;
          min-width: 120px;
        }

        input[type='text'] {
          width: 100%;
        }

        textarea {
          min-height: 70px;
        }
      }

      &:last-of-type {
        td {
          &:first-of-type {
            font-style: normal;
          }
        }
      }
    }
  }
`;

const TrHeader = styled(Tr)`
  height: 50px;
  th {
    border-bottom: none;
  }
`;
