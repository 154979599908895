import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filterToProjectIdeaQuery } from 'api/utils';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { PiosFilter } from 'models';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getProjectIdeasExportRaw = createAsyncThunk(
  'overview/ideas/export/raw',
  async ({
    filter,
    name,
    isProjectIdeasReviewersEnabled,
  }: {
    filter: PiosFilter;
    name: string;
    isProjectIdeasReviewersEnabled: boolean;
  }) => {
    const url = `overview/ideas/export/raw?1=1`;
    const filters = filterToProjectIdeaQuery(
      filter,
      isProjectIdeasReviewersEnabled
    );
    return axios({
      url: `${config.apiUrl.product.pios}${url}${filters}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = `${name}.xlsx`;

      const file = new File([response.data], fileName);

      saveAs(file, fileName);
    });
  }
);

export const {
  reducer: projectIdeasExportRawReducer,
  actions: projectIdeasExportRawActions,
} = createSlice({
  name: 'overview/ideas/export/raw',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getProjectIdeasExportRaw.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getProjectIdeasExportRaw.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getProjectIdeasExportRaw.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectProjectIdeasExportRawContentError = (
  state: RootState
): string | null | undefined => state.projectIdeasExportRaw.error;

export const selectProjectIdeasExportRawIsPosting = (
  state: RootState
): boolean => state.projectIdeasExportRaw.isPosting;
