import { Color } from '@dimatech/shared/lib/themes';

export * from '@dimatech/features-core/lib/models';
export * from '@dimatech/shared/lib/models';
export * from './authentication';
export * from './calculation';
export * from './dimension';
export * from './enums';
export * from './filter';
export * from './portfolio';
export * from './project';
export * from './project-effect';

export const PiosColors = {
  efficiency: Color.DimiosBlue,
  innovation: Color.BrightTurquoise,
  internal: Color.SandyBrown,
  external: Color.Eminence,
};
