import { Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const StatisticsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;

  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};
  border-bottom: 3px solid
    ${({ theme }: { theme: Theme }) => theme.colors.background};

  > div {
    margin: 10px 0;
    flex-grow: 1;
    flex-basis: 50%;
    justify-content: space-around;
    gap: 15px;

    > div {
      > div {
        justify-content: center;
      }
    }
  }
`;

StatisticsStyle.displayName = 'StatisticsStyle';
