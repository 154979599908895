import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { selectFilter } from 'api/piosSlice';
import { Owners } from 'components/Owners';
import { useAppSelector } from 'hooks';
import { PiosColors, PortfolioOverview, SortDirection } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBriefcase } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatAsNumber } from 'utils';

export const PortfoliosTable = ({
  portfolios,
  showPeriodData = false,
  saveToPngElementId,
}: {
  portfolios: PortfolioOverview[];
  showPeriodData?: boolean;
  saveToPngElementId?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const filter = useAppSelector(selectFilter);

  const { items, sorter } = useSortableData(portfolios, {
    key: 'portfolioName',
    direction: SortDirection.Asc,
  });

  const handleSelect = (portfolioId: string) => {
    navigate(`/portfolio/${portfolioId}`, {
      state: { from: location.pathname },
    });
  };

  if (items && items.length === 0) {
    return null;
  }

  return (
    <TableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th className="narrow" />

            <Th sortKey="portfolioExternalId" sorter={sorter}>
              {t('Portfolio.Id')}
            </Th>
            <Th sortKey="portfolioName" sorter={sorter}>
              {t('Portfolio.Portfolio')}
            </Th>
            <Th sortKey="entityName" sorter={sorter}>
              {t('Portfolio.Entity')}
            </Th>
            <Th style={{ width: '1%' }}>
              {t('UserRoles.PortfolioOwner.Title')}
            </Th>

            <ThRight sortKey="totalProjects" sorter={sorter}>
              {t('Portfolio.NoOfProjects')}
            </ThRight>
            <ThRight sortKey="totalBudget" sorter={sorter}>
              {t('Portfolio.TotalBudget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
            </ThRight>

            {showPeriodData && filter.period !== undefined && (
              <>
                <ThRight sortKey="totalYearlyBudgetForYear" sorter={sorter}>
                  {t('StatisticsYearly.TotalYearlyBudgetForYear', {
                    year: filter.period,
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                <ThCentered
                  sortKey="efficiencyInnovation"
                  sorter={sorter}
                  style={{ width: '1%' }}
                >
                  {t('Portfolio.YearlyEfficiencyInnovation', {
                    year: filter.period,
                  })}
                </ThCentered>
                <ThCentered
                  className="narrow"
                  sortKey="internalExternal"
                  sorter={sorter}
                  style={{ width: '1%' }}
                >
                  {t('Portfolio.YearlyInternalExternal', {
                    year: filter.period,
                  })}
                </ThCentered>
              </>
            )}

            <ThCentered
              sortKey="efficiencyInnovation"
              sorter={sorter}
              style={{ width: '1%' }}
            >
              {t('Portfolio.EfficiencyInnovation')}
            </ThCentered>
            <ThCentered
              className="narrow"
              sortKey="internalExternal"
              sorter={sorter}
              style={{ width: '1%' }}
            >
              {t('Portfolio.InternalExternal')}
            </ThCentered>
          </tr>
        </thead>

        <tbody>
          {items.map((portfolio, index) => (
            <Tr
              key={index}
              onSelect={() => handleSelect(portfolio.portfolioId as string)}
            >
              <TdCentered className="narrow" style={{ width: 25 }}>
                <BsBriefcase />
              </TdCentered>

              <Td>{portfolio.portfolioExternalId}</Td>
              <Td>
                <div className="b">{portfolio.portfolioName}</div>
                {portfolio.portfolioDescription && (
                  <div className="size-s">{portfolio.portfolioDescription}</div>
                )}
              </Td>
              <Td>{portfolio.entityName}</Td>
              <Td>
                <Owners owners={portfolio.owners as []} />
              </Td>

              <TdRight>{formatAsNumber(portfolio.totalProjects, '0')}</TdRight>
              <TdRight>{formatAsNumber(portfolio.totalBudget, null)}</TdRight>

              {showPeriodData && filter.period !== undefined && (
                <>
                  <TdRight>
                    {formatAsNumber(portfolio.totalBudgetPeriod, null)}
                  </TdRight>
                  <TdCentered>
                    <Progress
                      width={110}
                      value={portfolio.efficiencyInnovationPeriod}
                      color={PiosColors.efficiency}
                      backgroundColor={PiosColors.innovation}
                    />
                  </TdCentered>
                  <TdCentered className="narrow">
                    <Progress
                      width={110}
                      value={portfolio.internalExternalPeriod}
                      color={PiosColors.internal}
                      backgroundColor={PiosColors.external}
                    />
                  </TdCentered>
                </>
              )}

              <TdCentered>
                <Progress
                  width={110}
                  value={portfolio.efficiencyInnovation}
                  color={PiosColors.efficiency}
                  backgroundColor={PiosColors.innovation}
                />
              </TdCentered>
              <TdCentered className="narrow">
                <Progress
                  width={110}
                  value={portfolio.internalExternal}
                  color={PiosColors.internal}
                  backgroundColor={PiosColors.external}
                />
              </TdCentered>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

PortfoliosTable.displayName = 'PortfoliosTable';
