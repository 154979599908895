import { UserFilters } from '@dimatech/features-core/lib/components/UserFilters';
import { UserEditButtons } from '@dimatech/features-core/lib/features/administrate-users/components/UserEditButtons';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Loader, LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin, ApiError, SortDirection } from '@dimatech/shared/lib/models';
import {
  getOwnersExport,
  selectOwnerExportIsPosting,
} from 'api/portfolio/ownerExportSlice';
import {
  useAddPortfolioOwnerMutation,
  useLazyGetPortfolioOwnersQuery,
  useRemovePortfolioOwnershipMutation,
} from 'api/portfolio/portfolioOwnerApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { Owners } from 'components/Owners';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CommonRoles, Portfolio, SearchAdministrateUsers } from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel, BsPersonBadge, BsPersonPlus } from 'react-icons/bs';
import { formatDate } from 'utils';
import { OwnerAdd } from './OwnerAdd';
import { PortfoliosListForUser } from './PortfoliosListForUser';

export const PortfolioOwners = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const dispatch = useAppDispatch();
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const isExportPosting = useAppSelector(selectOwnerExportIsPosting);

  const [filter, setFilter] = useState<SearchAdministrateUsers>();
  const [selectedUser, setSelectedUser] = useState<Admin | undefined>();
  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (accessToken.isInRole(CommonRoles.GlobalAdmin) ||
      accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const [getPortfolioOwners, { data, isLoading }] =
    useLazyGetPortfolioOwnersQuery();
  const [addPortfolioOwner, { error }] = useAddPortfolioOwnerMutation();
  const [removeOwnership] = useRemovePortfolioOwnershipMutation();

  const handleGetPortfolioOwners = (entityIds?: string[], email?: string) => {
    accessToken.customerId &&
      getPortfolioOwners(
        {
          filter: {
            entityIds: entityIds || [],
            email: email || '',
          },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const { items, sorter } = useSortableData(data ?? [], {
    key: 'email',
    direction: SortDirection.Asc,
  });

  const handleAdd = (user: Admin, portfolio: Portfolio) => {
    setValidationErrors(undefined);

    if (user?.email?.includes(' ')) {
      setValidationErrors?.(['Owners']);
      return;
    }

    if (user.email && portfolio.owners?.includes(user.email)) {
      setValidationErrors(['ErrorSameOwner']);
      return;
    }

    addPortfolioOwner({
      portfolioId: portfolio.id as string,
      owner: user.email as string,
    })
      .unwrap()
      .then((result) => {
        if (selectedPortfolio?.owners) {
          dispatch(
            portfolioActions.selectedPortfolio({
              ...selectedPortfolio,
              owners: [...selectedPortfolio.owners, `${result.owner}`],
            })
          );
        }
      });
  };

  const handleDelete = (user: Admin) => {
    if (user.email) {
      removeOwnership({
        owner: user.email,
        includeChildEntities: true,
      })
        .unwrap()
        .then(() => {
          if (selectedPortfolio?.owners) {
            const owners = selectedPortfolio.owners.filter(
              (owner) => owner !== user.email
            );
            dispatch(
              portfolioActions.selectedPortfolio({
                ...selectedPortfolio,
                owners,
              })
            );
          }
        });
    }
  };

  const handleSelect = (user: Admin) => {
    setSelectedUser(selectedUser?.email === user.email ? undefined : user);
  };

  const handleSearch = () => {
    handleGetPortfolioOwners(filter?.entityIds, filter?.email?.toLowerCase());
  };

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (filter) {
      dispatch(
        getOwnersExport({
          filter,
          name: t('UserRoles.PortfolioOwner.Title'),
        })
      );
    }
  };

  useEffect(() => {
    handleGetPortfolioOwners();
    setFilter({
      email: undefined,
      entityIds: undefined,
      hasChanges: false,
    });
    setValidationErrors(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  useEffect(() => {
    if (filter?.hasChanges === false) {
      handleGetPortfolioOwners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (error) {
      setValidationErrors((error as ApiError)?.validationErrors);
    } else {
      setValidationErrors(undefined);
    }
  }, [error]);

  if (isLoading) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PortfolioOwner.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PortfolioOwner.Title')}
          text={<TransArray i18nKey="UserRoles.PortfolioOwner.Help" />}
        />
      </HelpIconLeft>

      <LinkWithTooltip
        isPrimary={true}
        handleClick={() => setIsAdding(true)}
        title={t('Portfolio.Owner.Add.Title')}
        tooltipTitle={t('Portfolio.Owner.Add.TooltipTitle')}
        tooltip={t('Portfolio.Owner.Add.Tooltip')}
        icon={<BsPersonPlus />}
        style={{ marginTop: 20, marginBottom: 10 }}
      />

      {data && data.length > 0 && (
        <>
          <LinkWithTooltip
            title={t('Administrate.Entity.Export.Download.Download')}
            tooltipTitle={t('Administrate.Entity.Export.Download.TooltipTitle')}
            tooltip={t('Administrate.Entity.Export.Download.Tooltip')}
            icon={isExportPosting ? <LoaderSmall /> : <BsFileExcel />}
            handleClick={(e) => !isExportPosting && handleExportDownload(e)}
            isDisabled={isExportPosting}
            style={{ paddingTop: 10 }}
          />
        </>
      )}

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(`Portfolio.Owner.ValidationError.${validationError}`)}
            </div>
          ))}
        </AlertWarning>
      )}

      {isAdding && (
        <OwnerAdd setIsAdding={setIsAdding} handleAddOwner={handleAdd} />
      )}

      <UserFilters
        handleSearch={handleSearch}
        filter={filter}
        setFilter={setFilter}
        isEntityFilterShown={!accessToken.isBaseLicense}
      />

      {data?.length === 0 && (
        <div style={{ marginTop: 20 }}>{t('Portfolio.Owner.NoOwners')}</div>
      )}

      {data && data.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 15, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th sortKey="email" sorter={sorter}>
                  {t('Portfolio.Owner.Email')}
                </Th>
                <ThRight sortKey="lastLoginDate" sorter={sorter}>
                  {t('Portfolio.Owner.LastLogin')}
                </ThRight>
                {isCustomerAccountAdmin && <Th />}
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => (
                <Fragment key={index}>
                  <Tr
                    key={index}
                    isSelected={
                      selectedUser?.email === item.email &&
                      !accessToken.isBaseLicense
                    }
                    isHoverable={!accessToken.isBaseLicense}
                    onSelect={() => handleSelect(item)}
                  >
                    <Td style={{ width: 25 }}>
                      <BsPersonBadge />
                    </Td>
                    <Td>
                      <Owners owners={[item.email as string]} />
                    </Td>
                    <TdRight
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </TdRight>
                    {isCustomerAccountAdmin && (
                      <TdRight>
                        {item.email !== accessToken.user?.email && (
                          <div
                            style={{
                              verticalAlign: 'middle',
                              display: 'flex',
                              justifyContent: 'end',
                            }}
                          >
                            <UserEditButtons
                              user={item}
                              setValidationErrors={setValidationErrors}
                              role={CommonRoles.PortfolioOwner}
                              deleteUser={handleDelete}
                            />
                          </div>
                        )}
                      </TdRight>
                    )}
                  </Tr>

                  {selectedUser &&
                    selectedUser.email === item.email &&
                    !accessToken.isBaseLicense && (
                      <Tr>
                        <Td colSpan={4} style={{ padding: 0 }}>
                          <PortfoliosListForUser list={item.portfolios} />
                        </Td>
                      </Tr>
                    )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

PortfolioOwners.displayName = 'PortfolioOwners';
