import { FeatureFlag } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureFlag';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Trans } from 'react-i18next';

export const FeatureProjectIdeasReviewers = (): JSX.Element | null => {
  return (
    <Card>
      <CardBody>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <Trans i18nKey="Administrate.FeatureFlag.Features.ProjectIdeasReviewers.Description" />
          <TransArray i18nKey="UserRoles.PiosReviewer.Help" />
        </div>

        <FeatureFlag
          featureFlagKey={
            flags.permanent.app.pios.isProjectIdeasReviewersEnabled
          }
        />
      </CardBody>
    </Card>
  );
};

FeatureProjectIdeasReviewers.displayName = 'FeatureProjectIdeasReviewers';
