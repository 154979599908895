import { Search as SearchFeature } from 'features/search';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Search = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Search')}>
      <SearchFeature />
    </View>
  );
};

Search.displayName = 'Search';
