import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useDeleteDocumentMutation } from 'api/project/projectDocumentApi';
import { ProjectDocument, ProjectDocumentType } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsDownload, BsTrash } from 'react-icons/bs';

export const ProjectDocumentListEditButtons = ({
  document,
  projectId,
  canEdit,
  handleDownload,
}: {
  document: ProjectDocument;
  projectId: string;
  canEdit: boolean;
  handleDownload: (e: React.SyntheticEvent, document: ProjectDocument) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!document.id) {
      return;
    }

    deleteDocument({
      projectId,
      documentId: document.id,
    });
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Portfolio.Document.Delete.ConfirmTitle')}>
          <div>{t('Portfolio.Document.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-document-download"
        title={t('Portfolio.Document.Download.TooltipTitle')}
        text={t('Portfolio.Document.Download.Tooltip')}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {document.type === ProjectDocumentType.Document && (
          <BadgeMini
            data-tip
            data-for="tt-document-download"
            $inverted={true}
            onClick={(e) => handleDownload(e, document)}
          >
            <BsDownload />
          </BadgeMini>
        )}

        {canEdit && (
          <>
            <TooltipContent
              id="tt-document-delete"
              title={t('Portfolio.Document.Delete.TooltipTitle')}
              text={t('Portfolio.Document.Delete.Tooltip')}
            />
            <BadgeMini
              data-tip
              data-for="tt-document-delete"
              $inverted={true}
              onClick={(e) => handleConfirmDelete(e, true)}
            >
              <BsTrash />
            </BadgeMini>
          </>
        )}
      </div>
    </>
  );
};

ProjectDocumentListEditButtons.displayName = 'ProjectDocumentListEditButtons';
