import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { ProjectEffectRealisationPerYear } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency } from 'utils';

export const ProjectsBenefitsTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    payload: ProjectEffectRealisationPerYear;
  }[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  if (active && payload && payload.length > 0) {
    const item = payload[0].payload;

    return (
      <TooltipStyle>
        <div className="tooltip-title">
          {t('Common.Calendar.Year', {
            year: item.year,
          })}
        </div>

        <div>
          {t('Project.Budget', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}{' '}
          : {formatAsCurrency(item.totalBudget)}
        </div>

        <div style={{ marginTop: 10 }}>
          {t('ImplementedProjects.ExpectedBenefit', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          : {formatAsCurrency(item.totalExpectedBenefit)}
        </div>
        <div>
          {t('ImplementedProjects.ActualBenefit', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          : {formatAsCurrency(item.totalActualBenefit)}
        </div>
        <div>
          {t('ImplementedProjects.Deviation', {
            currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
          })}
          : {formatAsCurrency(item.deviation)}
        </div>

        <div className="tooltip-title" style={{ marginTop: 10 }}>
          {t('ImplementedProjects.Benefits.Benefits')}
        </div>

        <div>
          {t('ImplementedProjects.Benefits.Finance')}:{' '}
          {formatAsCurrency(item.finance)}
        </div>
        <div>
          {t('ImplementedProjects.Benefits.Redistribution')}:{' '}
          {formatAsCurrency(item.redistribution)}
        </div>
        <div>
          {t('ImplementedProjects.Benefits.Quality')}:{' '}
          {formatAsCurrency(item.quality)}
        </div>
        <div>
          {t('ImplementedProjects.Benefits.Environment')}:{' '}
          {formatAsCurrency(item.environment)}
        </div>
        <div>
          {t('ImplementedProjects.Benefits.Other')}:{' '}
          {formatAsCurrency(item.other)}
        </div>
      </TooltipStyle>
    );
  }

  return null;
};

ProjectsBenefitsTooltip.displayName = 'ProjectsBenefitsTooltip';
