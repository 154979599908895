import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { Heading5 } from '@dimatech/shared/lib/components/typography';
import { CardRow, CardTile } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color } from '@dimatech/shared/lib/themes';
import { ProjectPhase } from 'components/ProjectPhase';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { formatAsCurrency, isReadOnly } from 'utils';
import { ProjectDataTable } from './ProjectDataTable';
import { ProjectNationalTagList } from './ProjectNationalTagList';
import { ProjectPublishedPubliclyIcon } from './ProjectPublishedPubliclyIcon';

export const ProjectViewBaseLicense = ({
  project,
}: {
  project?: Project;
}): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);

  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const { t } = useTranslation();

  if (!project) {
    return null;
  }

  return (
    <Style>
      <CardRow style={{ gap: 0 }}>
        <CardTile
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td style={{ display: 'flex' }}>
                  {t('Project.Phase.Phase')}
                  <ProjectPublishedPubliclyIcon project={project} />
                </Td>
                <Td>
                  {project.projectPhase ? (
                    <ProjectPhase phase={project.projectPhase} />
                  ) : (
                    '-'
                  )}
                </Td>
              </Tr>
              {isPublishPubliclyEnabledFlagOn &&
                project.projectPhase !== Phase.Draft &&
                project.projectPhase !== Phase.Ready && (
                  <Tr>
                    <Td>{t('Project.IsPublishedPublicly.Label')}</Td>
                    <Td>
                      {!project.isPublishedPublicly && (
                        <div>
                          <BsCircle />
                          {t('Common.UI.No')}
                        </div>
                      )}

                      {project.isPublishedPublicly && (
                        <div>
                          <BsCheckCircleFill
                            style={{ color: Color.CaribbeanGreenLight }}
                          />
                          {t('Common.UI.Yes')}
                        </div>
                      )}
                    </Td>
                  </Tr>
                )}
            </tbody>
          </ProjectDataTable>
        </CardTile>

        <CardTile>
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td style={{ display: 'flex' }}>
                  {t('Project.Budget', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                  <ProjectPublishedPubliclyIcon project={project} />
                </Td>
                <Td>{formatAsCurrency(project.budget)}</Td>
              </Tr>
            </tbody>
          </ProjectDataTable>
        </CardTile>
      </CardRow>

      <CardRow>
        <CardTile>
          <Heading5 style={{ display: 'flex' }}>
            {t('Project.Description')}
            <ProjectPublishedPubliclyIcon project={project} />
          </Heading5>
          <div>{project.description ?? '-'}</div>
        </CardTile>
        <CardTile>
          <Heading5 style={{ display: 'flex' }}>
            {t('Project.BenefitsAndEffects')}
            <ProjectPublishedPubliclyIcon project={project} />
          </Heading5>
          <div>{project.benefitsAndEffects ?? '-'}</div>
        </CardTile>
      </CardRow>

      {project.id &&
        isPublishPubliclyEnabledFlagOn &&
        isAreasOfOperationEnabledFlagOn && (
          <CardRow>
            <CardTile>
              <ProjectNationalTagList
                canEdit={!isReadOnly(project)}
                project={project}
              />
            </CardTile>
          </CardRow>
        )}
    </Style>
  );
};

ProjectViewBaseLicense.displayName = 'ProjectViewBaseLicense';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  column-gap: 15px;
  row-gap: 15px;
`;
