var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BsX } from 'react-icons/bs';
import styled from 'styled-components';
import { HelpIcon } from './HelpIcon';
export var Tag = function (_a) {
    var tag = _a.tag;
    return (_jsxs("div", __assign({ style: { display: 'flex' } }, { children: [_jsx("div", { children: tag.displayName }), _jsx(TagDescription, { tag: tag })] })));
};
Tag.displayName = 'Tag';
export var Tags = function (_a) {
    var tags = _a.tags, onDelete = _a.onDelete, onClick = _a.onClick, style = _a.style, descriptionStyle = _a.descriptionStyle;
    var handleClick = function (e, tag) {
        e.stopPropagation();
        onClick && onClick(tag);
    };
    var handleDelete = function (e, tag) {
        e.stopPropagation();
        onDelete && onDelete(tag);
    };
    return (_jsx(Style, __assign({ style: __assign({}, style) }, { children: tags.map(function (tag) {
            var _a;
            return (_jsxs("div", __assign({ onClick: tag.id ? function (e) { return handleClick(e, tag); } : undefined, style: { cursor: onClick && tag.id ? 'pointer' : 'unset' } }, { children: [tag.displayName, ' ', _jsx(TagDescription, { tag: tag, style: __assign({}, descriptionStyle) }), onDelete && (_jsx(DeleteButton, __assign({ onClick: function (e) { return handleDelete(e, tag); } }, { children: _jsx(BsX, {}) })))] }), (_a = tag.id) !== null && _a !== void 0 ? _a : tag.displayName));
        }) })));
};
Tags.displayName = 'Tags';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  min-height: 40px;\n  gap: 10px;\n\n  > div {\n    display: flex;\n    align-items: center;\n\n    padding: 5px 10px;\n\n    background-color: ", ";\n    color: ", ";\n\n    box-shadow: ", ";\n\n    border-radius: 3px;\n  }\n\n  > div:first-of-type {\n    margin-left: 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  min-height: 40px;\n  gap: 10px;\n\n  > div {\n    display: flex;\n    align-items: center;\n\n    padding: 5px 10px;\n\n    background-color: ", ";\n    color: ", ";\n\n    box-shadow: ", ";\n\n    border-radius: 3px;\n  }\n\n  > div:first-of-type {\n    margin-left: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onPrimary;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
});
var DeleteButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  margin-left: 5px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  cursor: pointer;\n  border-radius: 50%;\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  width: 20px;\n  height: 20px;\n  margin-left: 5px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  cursor: pointer;\n  border-radius: 50%;\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
});
var TagDescription = function (_a) {
    var tag = _a.tag, style = _a.style;
    if (!tag.description) {
        return null;
    }
    return (_jsx(HelpIcon, { style: __assign({ marginLeft: 5 }, style), showTiny: true, title: tag.displayName, text: tag.description }));
};
var templateObject_1, templateObject_2;
