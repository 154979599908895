import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
  Input,
  InputNumber,
  Label,
  Select,
  TextArea,
} from '@dimatech/shared/lib/components/form';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  useAddDimensionMutation,
  useUpdateDimensionMutation,
} from 'api/dimension/dimensionApi';
import {
  dimensionActions,
  selectSelectedClassification,
} from 'api/dimension/dimensionSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import produce from 'immer';
import {
  Calculation,
  CalculationType,
  Dimension,
  DimensionDisplayType,
  DimensionType,
} from 'models';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hasDuplicates, isAdminReadOnly } from 'utils';
import { ClassificationCalculations } from './ClassificationCalculations';
import { ClassificationPropertiesEditButtons } from './ClassificationPropertiesEditButtons';
import { DimensionListValues } from './DimensionListValues';

/* eslint-disable max-lines-per-function */

export const ClassificationProperties = ({
  calculations,
}: {
  calculations?: Calculation[];
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addDimension, { isLoading: addPosting, error: addErrors }] =
    useAddDimensionMutation();
  const [updateDimension, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateDimensionMutation();

  const selectedClassification = useAppSelector(selectSelectedClassification);
  const [classification, setClassification] = useState<Dimension | undefined>();
  const isAdding = selectedClassification && !selectedClassification.id;
  const isPosting = updatePosting || addPosting;

  const [isValid, setIsValid] = useState(true);
  const [isWeightValid, setIsWeightValid] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>();

  const standardCalculation = calculations?.find(
    (calculation) => calculation.type === CalculationType.System
  );

  const handleSave = async () => {
    if (isReadOnly) {
      return;
    }

    setIsValid(true);
    setIsWeightValid(true);
    setValidationErrors(undefined);

    if (
      classification?.displayType === DimensionDisplayType.Rating &&
      !classification?.type
    ) {
      setIsValid(false);
      return;
    }

    if (
      !classification?.name ||
      !classification?.displayType ||
      (classification.displayType === DimensionDisplayType.Rating &&
        classification.calculationWeights?.some(
          (weight) => weight.weight === undefined
        ))
    ) {
      setIsValid(false);
      setIsWeightValid(false);
      return;
    }

    if (classification.displayType === DimensionDisplayType.List) {
      if (!classification.values || classification.values.length === 0) {
        setIsValid(false);
        setValidationErrors(['MissingValues']);
        return;
      }

      const gotDuplicates = hasDuplicates(
        classification.values,
        (a, b) => a.name === b.name
      );

      if (gotDuplicates) {
        setValidationErrors(['DuplicateValues']);
        setIsValid(false);
        return;
      }

      const hasEmptyValue = classification.values.some(
        (value) => !value.name?.trim()
      );

      if (hasEmptyValue) {
        setValidationErrors(['EmptyValue']);
        setIsValid(false);
        return;
      }
    }

    if (classification?.id) {
      updateDimension({
        ...classification,
      })
        .unwrap()
        .then(() => {
          dispatch(
            dimensionActions.selectClassification({
              ...classification,
            })
          );
          setIsChangesSaved(true);
          setHasChanges(false);
        });

      return;
    }

    addDimension({
      ...classification,
      values:
        classification.displayType === DimensionDisplayType.Rating
          ? [
              { name: '1' },
              { name: '2' },
              { name: '3' },
              { name: '4' },
              { name: '5' },
            ]
          : classification.values,
    })
      .unwrap()
      .then((result) => {
        dispatch(
          dimensionActions.selectClassification({
            ...classification,
            ...result,
          })
        );
        setIsChangesSaved(true);
        setHasChanges(false);
      })
      .catch(() => {
        // Do nothing
      });
  };

  const handleCancel = () => {
    dispatch(dimensionActions.selectClassification());
  };

  const handleSelectType = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    setHasChanges(true);
    setClassification({
      ...classification,
      displayType: e.currentTarget.value as DimensionDisplayType,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === 'Enter') {
      handleSave();
    }

    if (e?.key === 'Escape') {
      handleCancel();
    }
  };

  useEffect(() => {
    setClassification(
      selectedClassification
        ? {
            ...selectedClassification,
            values: selectedClassification.values?.map((value) => ({
              ...value,
              uid: value.id ?? nanoid(6),
            })),
          }
        : undefined
    );
  }, [selectedClassification]);

  const handleChangeStandardWeight = (weight: string) => {
    if (!selectedClassification?.calculationWeights) {
      return;
    }

    if (!classification?.calculationWeights) {
      return;
    }

    const index = selectedClassification?.calculationWeights.findIndex(
      (item) => item.calculationId === standardCalculation?.id
    );

    const calculationWeights = produce(
      classification.calculationWeights,
      (draftState) => {
        draftState[index].weight = !isNaN(parseInt(weight))
          ? +weight
          : undefined;
      }
    );

    setClassification({
      ...classification,
      calculationWeights,
    });
  };

  return (
    <>
      <AlertErrors
        error={addErrors ?? updateErrors}
        customValidationErrors={validationErrors}
        translationPath="Administrate.CustomDimension.ValidationError"
      />

      <Label htmlFor="name"> {t('Administrate.CustomDimension.Name')}</Label>
      <Input
        maxLength={100}
        autoFocus
        type="text"
        id="name"
        name="name"
        placeholder={t(
          'Administrate.CustomDimension.NamePlaceholderClassification'
        )}
        value={classification?.name ?? ''}
        invalid={!isValid && !classification?.name}
        style={{ width: '100%', maxWidth: 400 }}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setHasChanges(true);
          setClassification({
            ...classification,
            name: e.currentTarget.value,
          });
        }}
      />

      <Style>
        <div style={{ marginBottom: 20, maxWidth: 500 }}>
          {t('Administrate.CustomDimension.HelpClassification')}
        </div>

        <Label htmlFor="displayType">
          {t('Administrate.CustomDimension.Type')}
        </Label>
        <Select
          id="displayType"
          name="displayType"
          value={classification?.displayType ?? ''}
          onChange={handleSelectType}
          disabled={!!classification?.id}
          invalid={!isValid && !classification?.displayType}
          style={{ width: '100%', maxWidth: 300 }}
        >
          <option value="">
            {t('Administrate.CustomDimension.SelectType')}
          </option>

          {Object.values(DimensionDisplayType).map((value) => (
            <option key={value} value={value}>
              {t(`Administrate.CustomDimension.DimensionDisplayType.${value}`)}
            </option>
          ))}
        </Select>

        {classification?.id && (
          <div className="size-s i">
            {t(
              'Administrate.CustomDimension.TypeCannotBeChangedClassification'
            )}
          </div>
        )}

        {classification?.displayType === DimensionDisplayType.Rating && (
          <>
            <Label htmlFor="displayType" style={{ marginTop: 20 }}>
              {t(
                'Administrate.CustomDimension.DimensionClassificationType.Title'
              )}
            </Label>
            <Select
              id="classificationType"
              name="classificationType"
              value={classification?.type ?? ''}
              onChange={(e) => {
                setHasChanges(true);
                setClassification({
                  ...classification,
                  type: e.currentTarget.value as DimensionType,
                });
              }}
              invalid={!isValid && !classification?.type}
              style={{ width: '100%', maxWidth: 300 }}
            >
              <option value="">- {t('Common.UI.Select')}</option>

              <option value="ClassificationBenefit">
                {t(
                  `Administrate.CustomDimension.DimensionClassificationType.Benefit`
                )}
              </option>
              <option value="ClassificationStake">
                {t(
                  `Administrate.CustomDimension.DimensionClassificationType.Stake`
                )}
              </option>
            </Select>
          </>
        )}
      </Style>

      {classification?.displayType === DimensionDisplayType.Rating && (
        <Style>
          {!isWeightValid && (
            <AlertError style={{ marginBottom: 20 }}>
              {t(
                'Administrate.CustomDimension.ValidationError.WeightIsMissing'
              )}
            </AlertError>
          )}

          <Label htmlFor="standardWeight">
            {classification?.calculationWeights &&
            classification?.calculationWeights.some(
              (cw) => cw.calculationId !== standardCalculation?.id
            )
              ? t('Administrate.CustomDimension.DefaultWeight')
              : t('Administrate.CustomDimension.Weight')}
          </Label>

          {standardCalculation?.name && (
            <Label htmlFor="standardWeight">
              {standardCalculation?.name}

              <HelpIcon
                showTiny={true}
                style={{ marginLeft: 5 }}
                title={standardCalculation.name}
                text={standardCalculation.description}
              />
            </Label>
          )}

          <InputNumber
            id="standardWeight"
            name="standardWeight"
            onKeyDown={handleKeyDown}
            value={
              classification?.calculationWeights?.find(
                (weight) => weight.calculationId === standardCalculation?.id
              )?.weight ?? ''
            }
            invalid={
              !isValid &&
              !classification?.calculationWeights?.find(
                (weight) => weight.calculationId === standardCalculation?.id
              )?.weight
            }
            style={{ width: 80 }}
            onValueChange={({ value }) => {
              setHasChanges(true);
              handleChangeStandardWeight(value);
            }}
          />

          {classification?.calculationWeights &&
            classification?.calculationWeights.some(
              (weight) => weight.calculationId !== standardCalculation?.id
            ) && (
              <ClassificationCalculations
                classification={classification}
                setClassification={setClassification}
                setHasChanges={setHasChanges}
                isValid={isValid}
                standardCalculationId={standardCalculation?.id}
              />
            )}
        </Style>
      )}

      <Label style={{ marginTop: 30 }} htmlFor="description">
        {t('Administrate.CustomDimension.Description')}
      </Label>
      <div className="size-s i">
        {t('Administrate.CustomDimension.DescriptionInfo')}
      </div>
      <TextArea
        id="description"
        name="description"
        value={classification?.description ?? ''}
        onChange={(e) => {
          setHasChanges(true);
          setClassification({
            ...classification,
            description: e.currentTarget.value,
          });
        }}
      />

      {classification?.displayType === DimensionDisplayType.List && (
        <DimensionListValues
          dimension={classification}
          setDimension={setClassification}
          setHasChanges={setHasChanges}
        />
      )}

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && classification && (
          <Buttons style={{ justifyContent: 'flex-start' }}>
            <ClassificationPropertiesEditButtons
              item={classification}
              setSelectedItem={setClassification}
              isReadOnly={isReadOnly}
            />
          </Buttons>
        )}

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={isReadOnly || isPosting || !hasChanges}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

ClassificationProperties.displayName = ' ClassificationProperties';

const Style = styled.div`
  margin-top: 20px;
  padding: 10px !important;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};
`;
