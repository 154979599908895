import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tag } from '@dimatech/shared/lib/components/Tag';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { ProjectOverviewByTag, SortDirection } from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTag, BsTags } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { formatAsNumber } from 'utils';

export const TagTreeTable = ({
  overviewsByTag,
}: {
  overviewsByTag: ProjectOverviewByTag[];
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [slices, setSlices] = useState<ProjectOverviewByTag[] | undefined>();

  const toSlices = (
    items: ProjectOverviewByTag[],
    parentId: string | null,
    level: number | null
  ): ProjectOverviewByTag[] => {
    return items
      .filter((item) => item.parentId === parentId)
      .map((item, index) => ({
        ...item,
        displayName:
          item.id === null ? t('Project.TagTable.Other') : item.displayName,
        index: !level ? index : level,
        children: item.id !== null ? toSlices(items, item.id, level) : [],
      }));
  };

  const { items, sorter } = useSortableData(slices ?? [], {
    key: 'displayName',
    direction: SortDirection.Asc,
  });

  useEffect(() => {
    if (overviewsByTag) {
      setSlices(toSlices(overviewsByTag, null, null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewsByTag]);

  return (
    <TableResponsiveContainer style={{ minWidth: 350, width: 'fit-content' }}>
      <Table>
        {items.length > 0 && (
          <thead>
            <tr>
              <Th sortKey="displayName" sorter={sorter}>
                {t('Project.Tag.Tag')}
              </Th>

              <ThRight sortKey="budget" sorter={sorter}>
                {t('Project.Budget', {
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                })}
              </ThRight>
            </tr>
          </thead>
        )}

        <tbody>
          <TreeTableRow items={items} />
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

TagTreeTable.displayName = 'TagTreeTable';

const TreeTableRow = withTheme(
  ({
    items,
    level = 0,
    color,
    theme,
  }: {
    items: ProjectOverviewByTag[];
    level?: number;
    color?: string;
    theme: Theme;
  }) => {
    return (
      <>
        {items.map((item) => {
          const iconStyle = {
            marginRight: 10,
            marginLeft: 10,
            color:
              color ??
              theme.colors.chart.colors[
                item.index % theme.colors.chart.colors.length
              ],
          };

          return (
            <Fragment key={item.id}>
              <Tr>
                <Td style={{ paddingLeft: 25 * level }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderTop: 'none',
                    }}
                  >
                    {item.children.length === 0 && <BsTag style={iconStyle} />}

                    {item.children.length > 0 && <BsTags style={iconStyle} />}

                    <Tag tag={item} />
                  </div>
                </Td>

                <TdRight>{formatAsNumber(item.budget, null)}</TdRight>
              </Tr>

              <TreeTableRow
                items={item.children}
                level={level + 1}
                color={iconStyle.color}
              />
            </Fragment>
          );
        })}
      </>
    );
  }
);
