import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, Buttons } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Overview, SortDirection } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRightSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { formatAsCurrency, formatAsPercent } from 'utils';

export const OverviewDetails = ({
  title,
  data,
  showDetailsFor,
  setShowDetailsFor,
}: {
  title: string;
  data: Overview[];
  showDetailsFor: Overview;
  setShowDetailsFor: (item?: Overview) => void;
}) => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const formatterPercent = (value: number | undefined) => {
    return value === undefined || isNaN(value)
      ? '-'
      : `${formatAsPercent(100 - value, '0', 0)} - ${formatAsPercent(
          value,
          '0',
          0
        )}`;
  };

  const similar = data.filter(
    (point) =>
      point.internalExternal === showDetailsFor.internalExternal &&
      point.efficiencyInnovation === showDetailsFor.efficiencyInnovation
  );

  const { items, sorter } = useSortableData(similar, {
    key: 'budget',
    direction: SortDirection.Desc,
  });

  return (
    <Modal
      title={title}
      handleKeyEnter={() => setShowDetailsFor(undefined)}
      handleKeyEsc={() => setShowDetailsFor(undefined)}
      style={{ minWidth: 300, width: '90%' }}
    >
      <div>
        {t('Portfolio.InternalExternal')} :{' '}
        {formatterPercent(showDetailsFor.internalExternal)}
      </div>
      <div style={{ marginBottom: 15 }}>
        {t('Portfolio.EfficiencyInnovation')} :{' '}
        {formatterPercent(showDetailsFor.efficiencyInnovation)}
      </div>

      <TableResponsiveContainer
        style={{
          overflowY: 'auto',
          maxHeight: 400,
        }}
      >
        <Table>
          <thead>
            <Tr>
              <Th sortKey="externalId" sorter={sorter} style={{ width: 60 }}>
                {t('Project.Id')}
              </Th>
              <Th sortKey="label" sorter={sorter}>
                {t('Project.Title')}
              </Th>
              <Th sortKey="entityName" sorter={sorter}>
                {t('Portfolio.Entity')}
              </Th>
              <ThRight sortKey="budget" sorter={sorter}>
                {t('Project.Budget', {
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                })}
              </ThRight>
              <Th style={{ width: 40 }} />
            </Tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <Tr key={index}>
                <Td>{item.externalId}</Td>
                <Td>{item.label}</Td>
                <Td>{item.entityName}</Td>
                <TdRight>{formatAsCurrency(item.budget)}</TdRight>
                <Td>
                  <TooltipContent
                    id="tt-portfolio-open"
                    text={t('Administrate.OpenPortfolio')}
                  />

                  <BadgeMini
                    data-tip
                    data-for="tt-portfolio-open"
                    onClick={() => {
                      item.portfolioId &&
                        navigate(`/portfolio/${item.portfolioId}`);
                    }}
                  >
                    <BsArrowRightSquare />
                  </BadgeMini>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableResponsiveContainer>

      <Buttons>
        <Button type="button" onClick={() => setShowDetailsFor(undefined)}>
          {t('Common.UI.Ok')}
        </Button>
      </Buttons>
    </Modal>
  );
};

OverviewDetails.displayName = 'OverviewDetails';
