import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
  Input,
  Label,
  Select,
  TextArea,
} from '@dimatech/shared/lib/components/form';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import {
  useAddPortfolioMutation,
  useUpdatePortfolioMutation,
} from 'api/portfolio/portfolioApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CalculationType, PortfolioMutation } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';
import { PortfolioOwnersList } from './PortfolioOwnersList';
import { PortfolioPropertiesEditButtons } from './PortfolioPropertiesEditButtons';

export const PortfolioProperties = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const [addPortfolio, { isLoading: addPosting, error: addErrors }] =
    useAddPortfolioMutation();
  const [updatePortfolio, { isLoading: updatePosting, error: updateErrors }] =
    useUpdatePortfolioMutation();

  const { data: calculations } = useGetCalculationsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const selectedEntity = useAppSelector(selectSelectedEntity);
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const isAdding = selectedPortfolio && !selectedPortfolio.id;
  const isPosting = updatePosting || addPosting;

  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [portfolio, setPortfolio] = useState<PortfolioMutation | undefined>();
  const [isValid, setIsValid] = useState(true);

  const standardCalculationId = calculations?.find(
    (calculation) => calculation?.type === CalculationType.System
  )?.id;

  useEffect(() => {
    setPortfolio(selectedPortfolio ? { ...selectedPortfolio } : undefined);
    if (isAdding) {
      setPortfolio({
        calculationId: standardCalculationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio, isAdding]);

  const handleSave = (e: React.SyntheticEvent) => {
    if (isReadOnly) {
      return;
    }

    setIsValid(true);

    if (!portfolio?.name?.trim()) {
      setIsValid(false);
      return;
    }

    if (portfolio?.id) {
      updatePortfolio({
        ...portfolio,
        name: portfolio.name.trim(),
        description: portfolio.description?.trim(),
        calculationId: portfolio.calculationId ?? standardCalculationId,
        entityId: selectedEntity?.id,
      })
        .unwrap()
        .then(() => {
          setIsChangesSaved(true);
        });

      return;
    }

    if (selectedEntity?.id) {
      addPortfolio({
        ...portfolio,
        name: portfolio.name.trim(),
        description: portfolio.description?.trim(),
        calculationId: portfolio.calculationId ?? standardCalculationId,
        entityId: selectedEntity?.id,
      })
        .unwrap()
        .then(() => {
          setIsChangesSaved(true);
          dispatch(portfolioActions.selectedPortfolio());
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    dispatch(portfolioActions.selectedPortfolio());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === 'Enter') {
      handleSave(e);
    }

    if (e?.key === 'Escape') {
      handleCancel(e);
    }
  };

  return (
    <div style={{ marginTop: 30, width: '100%' }}>
      {isValid && (
        <AlertErrors
          error={addErrors ?? updateErrors}
          translationPath="Portfolio.ValidationError"
        />
      )}

      {(portfolio || isAdding) && (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flexBasis: 120, marginRight: 20 }}>
              <Label htmlFor="externalId">{t('Portfolio.ExternalId')}</Label>
              <Input
                type="text"
                id="externalId"
                name="externalId"
                value={portfolio?.externalId ?? ''}
                onKeyDown={handleKeyDown}
                onChange={(e) =>
                  setPortfolio({
                    ...portfolio,
                    externalId: e.currentTarget.value,
                  })
                }
              />
            </div>

            <div style={{ flexGrow: 8 }}>
              <Label htmlFor="name"> {t('Portfolio.Name')}</Label>
              <Input
                autoFocus
                type="text"
                id="name"
                name="name"
                value={portfolio?.name ?? ''}
                invalid={!isValid && !portfolio?.name?.trim()}
                onKeyDown={handleKeyDown}
                onChange={(e) =>
                  setPortfolio({
                    ...portfolio,
                    name: e.currentTarget.value,
                  })
                }
              />
            </div>
          </div>

          <div>
            <Label htmlFor="description">
              {t('Administrate.Portfolio.Description')}
            </Label>
            <TextArea
              id="description"
              name="description"
              value={portfolio?.description ?? ''}
              onChange={(e) =>
                setPortfolio({
                  ...portfolio,
                  description: e.currentTarget.value,
                })
              }
            />
          </div>

          {calculations &&
            calculations.some((c) => c.type !== CalculationType.System) && (
              <div style={{ marginTop: 20 }}>
                <Label htmlFor="calculation">
                  {t('Portfolio.Calculation')}
                </Label>
                <Select
                  name="calculation"
                  value={portfolio?.calculationId ?? ''}
                  style={{ width: 'fit-content', minWidth: 200 }}
                  onChange={(e) =>
                    setPortfolio({
                      ...portfolio,
                      calculationId: e.currentTarget.value,
                    })
                  }
                >
                  {calculations?.map((calculation) => (
                    <option key={calculation.id} value={calculation.id}>
                      {calculation.name}
                    </option>
                  ))}
                </Select>
              </div>
            )}
        </>
      )}

      {portfolio && (
        <PortfolioOwnersList
          portfolio={portfolio}
          setPortfolio={setPortfolio}
        />
      )}

      <ButtonFooterWithToast
        style={{ marginTop: 20 }}
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        {!isAdding && portfolio && (
          <Buttons>
            <PortfolioPropertiesEditButtons
              portfolio={portfolio}
              isReadOnly={isReadOnly}
            />
          </Buttons>
        )}

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={isReadOnly || isPosting}
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </div>
  );
};

PortfolioProperties.displayName = 'PortfolioProperties';
