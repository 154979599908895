import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLazyGetPortfoliosQuery } from 'api/portfolio/portfolioApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { EntitiesTree } from 'features/administrate-organisation/components/EntitiesTree';
import { PortfolioList } from 'features/administrate-portfolios/components/PortfolioList';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PortfolioMutation } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isAdminReadOnly } from 'utils';
import { PortfolioProperties } from './components/PortfolioProperties';

export const AdministratePortfolios = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);

  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const navigate = useNavigate();

  const location = useLocation();
  const searchedEntityId = (location?.state as Record<string, string>)
    ?.portfolioEntityId as string;
  const searchedPortfolioId = (location?.state as Record<string, string>)
    ?.portfolioId as string;
  const [selectedEntityId, setSelectedEntityId] = useState<string>(
    searchedEntityId || ''
  );
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<string>(
    searchedPortfolioId || ''
  );

  const { data: entities } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const [getPortfolios, { data: portfolios }] = useLazyGetPortfoliosQuery();

  const handleCreate = () => {
    if (isReadOnly) {
      return;
    }

    dispatch(portfolioActions.selectedPortfolio({}));
  };

  useEffect(() => {
    if (selectedPortfolioId && accessToken.customerId) {
      getPortfolios({
        entityId: searchedEntityId,
        _customerId: accessToken.customerId,
      });
      const portfolio = portfolios?.find(
        (portfolio) => portfolio.id === selectedPortfolioId
      );
      if (portfolio) {
        dispatch(
          portfolioActions.selectedPortfolio({
            ...portfolio,
          } as PortfolioMutation)
        );
        setSelectedPortfolioId?.('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolioId, portfolios]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Portfolio.Portfolios')}</Heading1>

        {selectedEntity && (
          <div>
            <TooltipContent
              id="tt-portfolio-add"
              title={t('Portfolio.Add.TooltipTitle')}
              text={t('Portfolio.Add.Tooltip')}
            />
            <BadgeMini
              data-tip
              data-for="tt-portfolio-add"
              onClick={handleCreate}
              $inverted={true}
              disabled={isReadOnly}
            >
              <BsPlus />
            </BadgeMini>
          </div>
        )}
      </ViewHeader>

      <Card>
        <CardBody>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.Portfolio.Text')}
          </div>

          <LinkWithTooltip
            isPrimary={true}
            isDisabled={isReadOnly || !selectedEntity}
            handleClick={handleCreate}
            title={t('Portfolio.Add.TooltipTitle')}
            tooltipTitle={t('Portfolio.Add.TooltipTitle')}
            tooltip={t('Portfolio.Add.Tooltip')}
            icon={<BsPlus />}
            style={{ marginBottom: 20 }}
          />

          {entities?.length === 0 && !selectedEntity ? (
            <>
              <div style={{ marginBottom: 20 }}>
                {t('Administrate.Entity.NoEntities')}
              </div>
              <ButtonLink
                type="button"
                onClick={() => {
                  navigate('/administrate/organisation');
                }}
              >
                {t('Administrate.Portfolio.NoEntitiesAdministrate')}
              </ButtonLink>
            </>
          ) : (
            <Style>
              <EntitiesTree
                selectedEntityId={selectedEntityId}
                setSelectedEntityId={setSelectedEntityId}
                isDraggable={false}
              />

              <div style={{ flexGrow: 1, maxWidth: 800 }}>
                {(!selectedPortfolio || selectedPortfolio?.id) && (
                  <PortfolioList handleCreate={handleCreate} />
                )}

                {selectedPortfolio && <PortfolioProperties />}
              </div>
            </Style>
          )}
        </CardBody>
      </Card>
    </>
  );
};

AdministratePortfolios.displayName = 'AdministratePortfolios';

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    padding: 0 30px 30px 0;
  }
`;
