import { CacheTags, piosApi } from 'api/piosApi';
import { Project } from 'models';

const projectApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Create new project
     */
    addProject: build.mutation({
      query: (project: Project) => {
        return {
          url: `portfolio/${project.portfolioId}/projects`,
          method: 'POST',
          body: { ...project },
        };
      },
      invalidatesTags: [
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.PortfolioHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectEffectRealisationOverview,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectTimeline,
      ],
      transformResponse: (result: Project, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Update project
     */
    updateProject: build.mutation({
      query: (project: Project) => ({
        url: `portfolio/${project.portfolioId}/projects/${project.id}`,
        method: 'PUT',
        body: { ...project },
      }),
      invalidatesTags: [
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.ProjectHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectEffectRealisationOverview,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Delete project
     */
    deleteProject: build.mutation({
      query: (project: Project) => ({
        url: `portfolio/${project.portfolioId}/projects/${project.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.PortfolioHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectEffectRealisationOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Move project
     */
    moveProject: build.mutation({
      query: ({
        portfolioId,
        projectId,
        newPortfolioId,
        copy,
      }: {
        portfolioId: string;
        projectId?: string;
        newPortfolioId?: string;
        copy?: boolean;
      }) => ({
        url: `portfolio/${portfolioId}/projects/${projectId}/${newPortfolioId}?copy=${copy}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.ProjectOverviewByPeriod,
        CacheTags.PortfolioOverview,
        CacheTags.PortfolioOverviewByPeriod,
        CacheTags.PortfolioHistory,
        CacheTags.ProjectOverviewByTag,
        CacheTags.MyProjectOverview,
        CacheTags.MyProjectOverviewByPeriod,
        CacheTags.ProjectManager,
        CacheTags.EffectRealisationResponsible,
        CacheTags.ProjectTimeline,
      ],
      transformResponse: ({ projectId }, _meta, arg) => {
        return {
          projectId: projectId ?? arg.projectId,
        };
      },
    }),
  }),
});

export const {
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useMoveProjectMutation,
} = projectApi;
