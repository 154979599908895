import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import {
  Table,
  Td,
  TdRight,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosQuery } from 'api/portfolio/portfolioApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PortfolioMutation } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBriefcase } from 'react-icons/bs';
import { PortfolioListEditButtons } from './PortfolioListEditButtons';

export const PortfolioList = ({
  handleCreate,
}: {
  handleCreate: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);

  const handleSelect = (portfolio: PortfolioMutation) => {
    dispatch(portfolioActions.selectedPortfolio(portfolio));
  };

  const { data: portfolios } = useGetPortfoliosQuery(
    selectedEntity?.id && accessToken.customerId
      ? { entityId: selectedEntity?.id, _customerId: accessToken.customerId }
      : skipToken
  );

  return (
    <>
      {selectedEntity && (
        <Heading2 style={{ marginBottom: 10 }}>{selectedEntity.name}</Heading2>
      )}

      {selectedEntity && portfolios?.length === 0 && (
        <>
          <div style={{ marginBottom: 20 }}>
            {t('Administrate.Portfolio.NoPortfoliosInEntity', {
              name: selectedEntity?.name,
            })}
          </div>
          <ButtonLink type="button" onClick={handleCreate}>
            {t('Administrate.Portfolio.NoPortfoliosAddFirst')}
          </ButtonLink>
        </>
      )}

      {selectedEntity?.id && portfolios && portfolios.length > 0 && (
        <Table style={{ width: '100%' }}>
          <thead>
            <tr>
              <Th />
              <Th>{t('Portfolio.Name')}</Th>
              <Th>{t('Portfolio.ExternalId')}</Th>
              <Th />
            </tr>
          </thead>
          <tbody>
            {portfolios?.map((portfolio, index) => (
              <Tr
                key={index}
                isSelected={selectedPortfolio?.id === portfolio.id}
                onSelect={() => handleSelect(portfolio)}
              >
                <Td style={{ width: 25 }}>
                  <BsBriefcase />
                </Td>
                <Td>{portfolio.name}</Td>
                <Td>{portfolio.externalId}</Td>
                <TdRight
                  style={{
                    verticalAlign: 'middle',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <PortfolioListEditButtons item={portfolio} />
                </TdRight>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

PortfolioList.displayName = 'PortfolioList';
