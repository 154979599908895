import { Loader } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Tooltip } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Paginator, Tag } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  selectSearchPortfoliosIsLoading,
  selectSearchPortfoliosIsSearchCompleted,
  selectSearchPortfoliosResult,
} from 'api/portfolio/portfolioSearchSlice';
import { Owners } from 'components/Owners';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase } from 'models';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { BsBriefcase, BsClipboardData, BsLightbulb } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isProjectOngoing } from 'utils';

export const SearchPortfolioResult = ({
  initialPaginator,
  handlePaginatorChange,
}: {
  initialPaginator: Paginator;
  handlePaginatorChange: (paginator: Paginator) => void;
}): JSX.Element | null => {
  const data = useAppSelector(selectSearchPortfoliosResult);

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];

  const isLoading = useAppSelector(selectSearchPortfoliosIsLoading);
  const isSearchCompleted = useAppSelector(
    selectSearchPortfoliosIsSearchCompleted
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange,
  });

  const handleSelect = (
    portfolioId: string,
    projectId?: string,
    phase?: Phase
  ) => {
    if (projectId && isProjectOngoing(phase)) {
      navigate(`/project/${portfolioId}/${projectId}`);
      return;
    }

    if (projectId && !isProjectOngoing(phase)) {
      navigate(`/project-idea/view/`, { state: { ideaId: projectId } });
      return;
    }

    navigate(`/portfolio/${portfolioId}`);
  };

  if (isLoading) {
    return <Loader>{t('Common.UI.Loading')}</Loader>;
  }

  if (!isSearchCompleted) {
    return null;
  }

  return (
    <Style>
      <Tooltip />

      {data.records.length === 0 && <div>{t('Search.NoMatches')}</div>}

      {data.records.length > 0 && (
        <>
          <Pagination
            currentPage={paginator.page}
            totalCount={data.totalRecords}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />

          <TableResponsiveContainer style={{ marginTop: 20 }}>
            <Table>
              <thead>
                <tr>
                  <Th />
                  <Th sortKey="portfolioExternalId" sorter={sorter}>
                    {t('Portfolio.ExternalId')}
                  </Th>
                  <Th sortKey="projectName" sorter={sorter}>
                    {t('Portfolio.Name')}
                  </Th>
                  <Th sortKey="entityName" sorter={sorter}>
                    {t('Portfolio.Entity')}
                  </Th>
                  {isTagsEnabledFlagOn && <Th>{t('Project.Tag.Tags')}</Th>}
                  <Th>{t('UserRoles.PortfolioOwner.Title')}</Th>
                </tr>
              </thead>
              <tbody>
                {data.records.map((item, index) => (
                  <Tr
                    key={index}
                    onSelect={() =>
                      handleSelect(
                        item.portfolioId as string,
                        item.projectId as string,
                        item.projectPhase
                      )
                    }
                  >
                    <TdCentered style={{ width: 25 }}>
                      {item.projectId ? (
                        isProjectOngoing(item.projectPhase) ? (
                          <BsClipboardData />
                        ) : (
                          <BsLightbulb />
                        )
                      ) : (
                        <BsBriefcase />
                      )}
                    </TdCentered>
                    <Td
                      style={{ whiteSpace: 'nowrap', width: '1%' }}
                      data-html={true}
                      data-tip={ReactDOMServer.renderToString(
                        <TdTooltip
                          phase={item.projectPhase}
                          projectName={item.projectName}
                          portfolioName={item.portfolioName}
                        />
                      )}
                    >
                      {item.projectExternalId || item.portfolioExternalId}
                    </Td>
                    <Td
                      style={{ width: '40%' }}
                      data-html={true}
                      data-tip={ReactDOMServer.renderToString(
                        <TdTooltip
                          phase={item.projectPhase}
                          projectName={item.projectName}
                          portfolioName={item.portfolioName}
                        />
                      )}
                    >
                      {item.projectName && (
                        <>
                          <span className="b">{item.projectName}</span>
                          <div>
                            {t('Search.Portfolio')}: {item.portfolioName}
                          </div>
                        </>
                      )}
                      {!item.projectName && (
                        <span className="b">{item.portfolioName}</span>
                      )}
                    </Td>
                    <Td>{item.entityName}</Td>
                    {isTagsEnabledFlagOn && (
                      <Td>
                        {item.tags
                          .map((tag: Tag) => tag.displayName)
                          .join(', ')}
                      </Td>
                    )}
                    <Td>
                      <Owners owners={item.owners as []} />
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableResponsiveContainer>

          <Pagination
            currentPage={paginator.page}
            totalCount={data.totalRecords}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />
        </>
      )}
    </Style>
  );
};

SearchPortfolioResult.displayName = 'PortfoliosListByEntity';

const Style = styled.div`
  margin-top: 20px;

  td > div {
    color: ${({ theme }: { theme: Theme }) => theme.colors.onBackground};
  }
`;

const TdTooltip = ({
  projectName,
  phase,
  portfolioName,
}: {
  projectName?: string;
  phase?: Phase;
  portfolioName?: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="tooltip-title">
        {projectName && isProjectOngoing(phase) && (
          <>
            {t('Search.Project')}: {projectName}
          </>
        )}

        {projectName && !isProjectOngoing(phase) && (
          <>
            {t('Search.ProjectIdea')}: {projectName}
          </>
        )}

        {!projectName && (
          <>
            {t('Search.Portfolio')}: {portfolioName}
          </>
        )}
      </div>

      <div>
        {t('Search.OpenPortfolio', {
          portfolioName,
        })}
      </div>
    </>
  );
};
