var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
export var GanttTooltip = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, texts = _a.texts;
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "tooltip-title", style: { marginBottom: 0 } }, { children: title })), subtitle && _jsx("div", __assign({ className: "tooltip-subtitle" }, { children: subtitle })), texts && (_jsx("div", { children: texts.map(function (text, index) { return (_jsx("div", { children: text }, index)); }) }))] }));
};
GanttTooltip.displayName = 'GanttTooltip';
