import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { UserProject } from '@dimatech/shared/lib/models';
import { CommonRoles } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArchive,
  BsArrowRightSquare,
  BsClipboardData,
  BsLightbulb,
} from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export const ProjectsListForUser = ({
  list,
  role,
}: {
  list?: UserProject[];
  role: CommonRoles;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const [path, setPath] = useState('');

  useEffect(() => {
    if (role === CommonRoles.PiosEffectRealizationResponsible) {
      setPath(`Project.EffectRealisationResponsible`);
    }
    if (role === CommonRoles.PiosProjectManager) {
      setPath(`Project.Manager`);
    }
    if (role === CommonRoles.PiosOrderingParty) {
      setPath(`Project.OrderingParty`);
    }
  }, [role]);

  return (
    <>
      <div
        style={{
          padding: 20,
          paddingTop: 0,
        }}
      >
        <div style={{ padding: 10 }}>{t(`${path}.ProjectText`)}</div>
        <Table
          $striped={true}
          style={{ width: 'fit-content', minWidth: '50%', marginBottom: 30 }}
        >
          <tbody>
            {[...(list ?? [])]
              ?.sort((a, b) => (a.title > b.title ? 1 : -1))
              ?.map((item, index) => (
                <Tr key={index}>
                  <Td style={{ width: 25 }}>
                    <BsClipboardData />
                  </Td>
                  <Td>{item.title}</Td>
                  <TdRight>
                    <EditButtons item={item} />
                  </TdRight>
                </Tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

ProjectsListForUser.displayName = 'ProjectsListForUser';

const EditButtons = ({ item }: { item: UserProject }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateProject = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`/project/${item.portfolioId}/${item.id}`, {
      state: { from: location.pathname },
    });
  };

  const handleNavigateIdea = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate('/project-idea/view/', {
      state: { ideaId: item.id },
    });
  };

  const handleNavigateIdeaArchive = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate('/project-idea/archive/', {
      state: { ideaId: item.id },
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      {item.isIdea ? (
        <>
          {item.isArchived ? (
            <LinkWithTooltip
              tooltip={t('Administrate.Users.Navigate.OpenIdeaArchive')}
              icon={<BsArchive />}
              handleClick={(e) => handleNavigateIdeaArchive(e)}
            />
          ) : (
            <LinkWithTooltip
              tooltip={t('Administrate.Users.Navigate.OpenIdea')}
              icon={<BsLightbulb />}
              handleClick={(e) => handleNavigateIdea(e)}
            />
          )}
        </>
      ) : (
        <LinkWithTooltip
          tooltip={t('Administrate.Users.Navigate.OpenProject')}
          icon={<BsArrowRightSquare />}
          handleClick={(e) => handleNavigateProject(e)}
        />
      )}
    </div>
  );
};
