import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertLowPrio } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Admin } from '@dimatech/shared/lib/models';
import { useLazyGetPortfoliosQuery } from 'api/portfolio/portfolioApi';
import { SelectPortfolio } from 'components/SelectPortfolio';
import { Portfolio } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OwnerAdd = ({
  handleAddOwner,
  setIsAdding,
}: {
  handleAddOwner?: (user: Admin, portfolio: Portfolio) => void;
  setIsAdding: (isAdding: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [user, setUser] = useState<Admin | null>(null);
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [isValid, setIsValid] = useState(true);

  const [getPortfolios, { data: portfolios }] = useLazyGetPortfoliosQuery();

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsValid(true);

    if (!user?.email || !portfolio) {
      setIsValid(false);
      return;
    }
    handleAddOwner?.({ ...user }, portfolio);

    setIsAdding(false);
  };

  const handleCancel = () => {
    setIsAdding(false);
  };

  useEffect(() => {
    if (accessToken.customerId && accessToken.isBaseLicense) {
      getPortfolios({
        _customerId: accessToken.customerId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId, accessToken.isBaseLicense]);

  useEffect(() => {
    if (accessToken.isBaseLicense && portfolios && portfolios.length > 0) {
      setPortfolio(portfolios[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.isBaseLicense, portfolios]);

  return (
    <Modal
      title={t(`Portfolio.Owner.Add.Title`)}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsAdding(false)}
    >
      <div>{t(`Portfolio.Owner.Add.Text`)}</div>

      <AlertLowPrio style={{ margin: '10px 0' }}>
        {t(`Administrate.Users.EmailNotification`)}
      </AlertLowPrio>

      <SearchUser user={user} setUser={setUser} />

      {!accessToken.isBaseLicense && (
        <SelectPortfolio
          setPortfolio={setPortfolio}
          invalid={!isValid && !portfolio}
          style={{ marginTop: 20 }}
        />
      )}

      <Buttons>
        <ButtonSecondary type="button" onClick={handleCancel}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={handleSave}>
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </Modal>
  );
};

OwnerAdd.displayName = 'OwnerAdd';
