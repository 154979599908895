import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const PublishedProjectsCriteria = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const phases = Object.keys(Phase).map((value) => value as Phase);
  const { data: projectsOverview } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: {
            phases,
            isPublishedPublicly: true,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const publishPubliclyMinProjectsRequirement =
    useFlags()[flags.permanent.app.pios.publishPubliclyMinProjectsRequirement];

  return (
    <>
      <Heading3>{t('PublishedProjectsCriteria.Title')}</Heading3>

      {projectsOverview &&
      projectsOverview?.projects.length >=
        publishPubliclyMinProjectsRequirement ? (
        <p>
          {t('PublishedProjectsCriteria.Access.HasAccess', {
            publishPubliclyMinProjectsRequirement,
          })}
        </p>
      ) : (
        <p>
          {t('PublishedProjectsCriteria.Access.NotAccess', {
            publishPubliclyMinProjectsRequirement,
          })}
        </p>
      )}

      <p className="b">
        {t('PublishedProjectsCriteria.CurrentlyPublished', {
          number: projectsOverview?.projects.length,
        })}
      </p>
      <p>{t('PublishedProjectsCriteria.Text')}</p>
    </>
  );
};

PublishedProjectsCriteria.displayName = 'PublishedProjectsCriteria';
