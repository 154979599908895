import { CacheTags, piosApi } from 'api/piosApi';
import { ProjectGoal } from 'models';
import { calculateGoalEffectRealisation } from 'utils/project-effect';

const projectGoalApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get goals for project
     */
    getGoals: build.query<
      ProjectGoal[],
      {
        projectId: string;
        _customerId: string | undefined;
      }
    >({
      // _customerId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switching customer
      query: ({ projectId, _customerId }) => `projects/${projectId}/goals`,
      providesTags: [CacheTags.ProjectGoal],
      transformResponse: (result: ProjectGoal[]) =>
        calculateGoalEffectRealisation(result),
    }),

    /**
     * Create new goal
     */
    addGoal: build.mutation({
      query: (goal: ProjectGoal) => ({
        url: `projects/${goal.projectId}/goals`,
        method: 'POST',
        body: goal,
      }),
      invalidatesTags: [
        CacheTags.PortfolioOverview,
        CacheTags.ProjectGoal,
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
      transformResponse: (result: string, _meta, arg) => {
        return {
          ...arg,
          id: result,
        };
      },
    }),

    /**
     * Update goal
     */
    updateGoal: build.mutation({
      query: (goal: ProjectGoal) => ({
        url: `projects/${goal.projectId}/goals/${goal.id}`,
        method: 'PUT',
        body: {
          ...goal,
        },
      }),
      invalidatesTags: [
        CacheTags.PortfolioOverview,
        CacheTags.ProjectGoal,
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectEffectRealisationOverview,
        CacheTags.ProjectTimeline,
      ],
    }),

    /**
     * Evaluate goal
     */
    evaluateGoal: build.mutation({
      query: (goal: ProjectGoal) => ({
        url: `projects/${goal.projectId}/goals/evaluate/`,
        method: 'POST',
        body: {
          text: goal.name,
        },
      }),
      transformResponse: (result: { evaluationResult: string }) => {
        return result.evaluationResult;
      },
    }),

    /**
     * Delete goal
     */
    deleteGoal: build.mutation({
      query: (goal: ProjectGoal) => ({
        url: `projects/${goal.projectId}/goals/${goal.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        CacheTags.ProjectGoal,
        CacheTags.Project,
        CacheTags.ProjectOverview,
        CacheTags.MyProjectOverview,
        CacheTags.ProjectHistory,
        CacheTags.ProjectIdeaOverview,
        CacheTags.ProjectTimeline,
      ],
    }),
  }),
});

export const {
  useGetGoalsQuery,
  useAddGoalMutation,
  useUpdateGoalMutation,
  useDeleteGoalMutation,
  useEvaluateGoalMutation,
} = projectGoalApi;
