import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import {
  projectNoteActions,
  selectSelectedProjectNote,
} from 'api/project/projectNoteSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ProjectNote } from 'models';
import { nanoid } from 'nanoid';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';

export const NoteAdd = ({
  projectId,
  canEdit,
  style,
}: {
  projectId?: string;
  canEdit: boolean;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedNote = useAppSelector(selectSelectedProjectNote);

  const [note, setNote] = useState<ProjectNote | undefined>();

  useEffect(() => {
    setNote(selectedNote ? { ...selectedNote } : undefined);
  }, [selectedNote]);

  const handleAdd = () => {
    dispatch(
      projectNoteActions.selectProjectNote({
        ...selectedNote,
        projectId,
        uid: nanoid(6),
      })
    );
  };

  return canEdit && !note ? (
    <div style={{ display: 'flex', ...style }}>
      <LinkWithTooltip
        isPrimary={true}
        handleClick={handleAdd}
        title={t('Portfolio.Note.Add.Title')}
        tooltipTitle={t('Portfolio.Note.Add.TooltipTitle')}
        tooltip={t('Portfolio.Note.Add.Tooltip')}
        icon={<BsPlus />}
      />
    </div>
  ) : null;
};

NoteAdd.displayName = 'NoteAdd';
