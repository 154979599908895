import { useDeleteEntityMutation } from '@dimatech/features-core/lib/api/entity/entityApi';
import { entityActions } from '@dimatech/features-core/lib/api/entity/entitySlice';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { searchPortfolios } from 'api/portfolio/portfolioSearchSlice';
import { useAppDispatch } from 'hooks';
import { Entity, SortDirection } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EntityPropertiesEditButtons = ({
  item,
  setSelectedItem,
  isReadOnly,
}: {
  item: Entity;
  setSelectedItem: React.Dispatch<React.SetStateAction<Entity | undefined>>;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteEntity] = useDeleteEntityMutation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [hasPortfolios, setHasPortfolios] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly || !item.id) {
      return;
    }

    deleteEntity(item.id);
    setSelectedItem(undefined);

    dispatch(entityActions.selectedEntity());

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setHasChildren(false);
    setHasPortfolios(false);

    if (!show) {
      setIsDeleting(false);
      return;
    }

    if (item.childCount && item.childCount > 0) {
      setHasChildren(true);
      setIsDeleting(true);
      return;
    }

    dispatch(
      searchPortfolios({
        entityIds: item.id ? [item.id] : undefined,
        paginator: {
          page: 1,
          pageSize: 5,
          orderBy: 'portfolioName',
          orderDirection: SortDirection.Asc,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setHasPortfolios(response.totalRecords > 0);
        setIsDeleting(true);
      });
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Administrate.Entity.Delete.ConfirmTitle')}>
          {(hasChildren || hasPortfolios) && (
            <>
              <div>
                {hasChildren
                  ? t('Administrate.Entity.Delete.HasChildren')
                  : t('Administrate.Entity.Delete.HasPortfolios')}
              </div>
              <Buttons>
                <Button
                  type="button"
                  onClick={(e) => handleConfirmDelete(e, false)}
                >
                  {t('Common.UI.Ok')}
                </Button>
              </Buttons>
            </>
          )}

          {!hasChildren && !hasPortfolios && (
            <>
              <div>{t('Administrate.Entity.Delete.ConfirmText')}</div>
              <Buttons>
                <ButtonSecondary
                  type="button"
                  onClick={(e) => handleConfirmDelete(e, false)}
                >
                  {t('Common.Form.Cancel')}
                </ButtonSecondary>

                <Button
                  type="button"
                  onClick={(e) => handleDelete(e)}
                  disabled={isReadOnly}
                >
                  {t('Common.UI.Yes')}
                </Button>
              </Buttons>
            </>
          )}
        </Modal>
      )}

      <ButtonSecondary
        type="button"
        disabled={!item.parentId}
        onClick={(e) => handleConfirmDelete(e, true)}
        style={{ marginLeft: '0' }}
      >
        {t('Common.Form.Delete')}
      </ButtonSecondary>
    </>
  );
};

EntityPropertiesEditButtons.displayName = 'EntityPropertiesEditButtons';
