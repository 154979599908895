import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import {
  Button,
  ButtonSecondary,
  Input,
  Label,
  Select,
} from '@dimatech/shared/lib/components/form';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useAddDocumentMutation } from 'api/project/projectDocumentApi';
import {
  ProjectDocument,
  ProjectDocumentLinkType,
  ProjectDocumentType,
} from 'models';
import { CSSProperties, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isPiosReadOnly } from 'utils';

export const ProjectDocumentLinkProperties = ({
  projectId,
  setIsAddingLink,
  style,
}: {
  projectId: string;
  setIsAddingLink: (isUploading: boolean) => void;
  style?: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);

  const [addDocument, { isLoading, error }] = useAddDocumentMutation();
  const [isValid, setIsValid] = useState(true);
  const [document, setDocument] = useState<ProjectDocument>({
    linkContent: 'https://',
    linkType: ProjectDocumentLinkType.Document,
  });

  const handleCancel = () => {
    setIsAddingLink(false);
  };

  const handleSave = async () => {
    if (isViewReadOnly || !document) {
      return;
    }

    setIsValid(true);

    if (!document.linkContent || !document.name) {
      setIsValid(false);
      return;
    }

    addDocument({
      document: {
        ...document,
        type: ProjectDocumentType.Link,
      },
      projectId,
    })
      .unwrap()
      .then(() => setIsAddingLink(false));

    setDocument({});
  };

  return (
    <Style style={{ ...style }}>
      <Heading3>{t('Portfolio.Document.AddLink.Title')}</Heading3>

      <AlertErrors error={error} translationPath="Portfolio.Document.AddLink" />

      <Label htmlFor="linkType">
        {t('Portfolio.Document.AddLink.Type.Type')}
      </Label>
      <Select
        name="linkType"
        id="linkType"
        value={document.type}
        onChange={(e) =>
          setDocument({
            ...document,
            linkType: e.currentTarget.value as ProjectDocumentLinkType,
          })
        }
      >
        {Object.keys(ProjectDocumentLinkType).map((value) => (
          <option key={value} value={value}>
            {t(`Portfolio.Document.AddLink.Type.${value}`)}
          </option>
        ))}
      </Select>

      <Label htmlFor="name">{t('Portfolio.Document.AddLink.Name')}</Label>
      <Input
        type="text"
        id="name"
        name="name"
        value={document.name ?? ''}
        invalid={!isValid && !document.name}
        onChange={(e) =>
          setDocument({
            ...document,
            name: e.currentTarget.value,
          })
        }
      />

      <Label htmlFor="name">{t('Portfolio.Document.AddLink.Url')}</Label>
      <Input
        type="url"
        id="link"
        name="link"
        placeholder="https://..."
        value={document.linkContent ?? ''}
        invalid={!isValid && !document.linkContent}
        onChange={(e) =>
          setDocument({
            ...document,
            linkContent: e.currentTarget.value,
          })
        }
      />

      <ButtonSecondary type="button" onClick={handleCancel}>
        {t('Common.Form.Cancel')}
      </ButtonSecondary>

      <Button
        onClick={handleSave}
        type="button"
        disabled={isViewReadOnly || isLoading || !document}
      >
        {t('Common.Form.Save')}
      </Button>
    </Style>
  );
};

ProjectDocumentLinkProperties.displayName = 'ProjectDocumentLinkProperties';

const Style = styled.div`
  margin: 20px 0 20px 0;
  padding: 0 20px 10px 0;

  select {
    max-width: 200px;
  }

  button {
    margin-top: 20px;
    min-width: 100px;

    &:last-of-type {
      margin-left: 20px;
    }
  }
`;
