import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Button,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Admin } from '@dimatech/shared/lib/models';
import { isAdminReadOnly } from 'utils';

import { AlertLowPrio } from '@dimatech/shared/lib/components/Alert';
import { SelectPortfolio } from 'components/SelectPortfolio';
import { useAppSelector } from 'hooks';
import { CommonRoles, Portfolio } from 'models';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export const EntityUserAdd = ({
  handleAdd,
  handleAddOwner,
  setIsAdding,
  role,
  includeChildEntities,
  setIncludeChildEntities,
}: {
  handleAdd?: (user: Admin) => void;
  handleAddOwner?: (user: Admin, portfolio: Portfolio) => void;
  setIsAdding: (isAdding: boolean) => void;
  role: CommonRoles;
  includeChildEntities?: boolean;
  setIncludeChildEntities?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const [user, setUser] = useState<Admin | null>(null);
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [isValid, setIsValid] = useState(true);
  const [path, setPath] = useState('');

  const selectedEntity = useAppSelector(selectSelectedEntity);
  const isReadOnly = isAdminReadOnly(accessToken);

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsValid(true);

    if (isReadOnly) {
      return;
    }

    if (role === CommonRoles.PortfolioOwner) {
      if (!user?.email || !portfolio) {
        setIsValid(false);
        return;
      }

      handleAddOwner?.({ ...user }, portfolio);
    }

    if (role !== CommonRoles.PortfolioOwner) {
      if (!user?.email) {
        return;
      }
      handleAdd?.(user);
    }

    setIsAdding(false);
  };

  useEffect(() => {
    if (role === CommonRoles.PortfolioOwner) {
      setPath(`Portfolio.Owner`);
    }
    if (role === CommonRoles.CustomerAdmin) {
      setPath(`Administrate.Entity.Admin`);
    }
    if (role === CommonRoles.Reader) {
      setPath(`Administrate.Entity.Viewer`);
    }
    if (role === CommonRoles.PiosReviewer) {
      setPath(`Administrate.Entity.Reviewer`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  if (!selectedEntity) {
    return null;
  }

  return (
    <Modal
      title={t(`${path}.Add.Title`)}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsAdding(false)}
    >
      <div>{t(`${path}.Add.Text`)}</div>

      <AlertLowPrio style={{ margin: '10px 0' }}>
        {t(`Administrate.Users.EmailNotification`)}
      </AlertLowPrio>

      {role !== CommonRoles.PortfolioOwner && (
        <Label>{t(`${path}.Add.Email`)}</Label>
      )}

      <SearchUser user={user} setUser={setUser} />

      {role !== CommonRoles.PortfolioOwner &&
        selectedEntity.childCount !== undefined &&
        selectedEntity.childCount > 0 && (
          <div>
            <Label htmlFor="includeChildEntities">
              <Checkbox
                id="includeChildEntities"
                name="includeChildEntities"
                style={{ marginRight: 10 }}
                checked={includeChildEntities ?? true}
                onChange={(e) =>
                  setIncludeChildEntities?.(e.currentTarget.checked)
                }
              />
              {t(`${path}.Add.IncludeChildEntities`)}
            </Label>
          </div>
        )}

      {role === CommonRoles.PortfolioOwner && (
        <SelectPortfolio
          setPortfolio={setPortfolio}
          entityId={selectedEntity.id}
          invalid={!isValid && !portfolio}
          style={{ marginTop: 20 }}
        />
      )}

      <Buttons>
        <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={handleSave} disabled={isReadOnly}>
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </Modal>
  );
};

EntityUserAdd.displayName = 'EntityUserAdd';
