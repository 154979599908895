import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { piosActions, selectFilter } from 'api/piosSlice';
import { IdeaAdd } from 'features/project-idea/components/IdeaAdd';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permission, ProjectIdea, ProjectIdeaAction } from 'models';
import { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermission, isPortfolioEditAvailable } from 'utils';
import { IdeaListEditButtons } from './IdeaListEditButtons';
import { IdeaStatus } from './IdeaStatus';

export const IdeaList = ({ ideas }: { ideas: ProjectIdea[] }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);

  const selectAndNavigate = useSelectAndNavigate();
  const filter = useAppSelector(selectFilter);

  const {
    items: sortedIdeas,
    setPage,
    paginator,
    sorter,
  } = useUiSortablePaginator({
    orderBy: 'title',
    pageSize: 25,
    data: {
      totalRecords: ideas.length,
      records: ideas,
    },
  });

  const handleSelect = (idea: ProjectIdea) => {
    selectAndNavigate(ProjectIdeaAction.View, idea);
  };

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    dispatch(piosActions.setHasUnsavedChanges(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        {t('FutureProjects.ProjectIdea.IdentifyText')}
      </div>

      {isPortfolioEditAvailable(accessToken) && <IdeaAdd />}

      {sortedIdeas.length === 0 && (
        <div style={{ marginTop: 20 }}>
          {t('FutureProjects.ProjectIdea.NoProjectIdeas')}
        </div>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={ideas.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />

      {sortedIdeas.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 20 }}>
            <thead>
              <tr>
                <ThCentered
                  className="narrow"
                  sortKey="projectPhase"
                  sorter={sorter}
                  style={{ width: 50 }}
                >
                  {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                </ThCentered>
                <Th
                  className="narrow"
                  sortKey="externalId"
                  sorter={sorter}
                  style={{ width: 60 }}
                >
                  {t('Project.Id')}
                </Th>
                <Th>
                  <SortElements>
                    <SortElement sortKey="title" sorter={sorter}>
                      {t('FutureProjects.ProjectIdea.ProjectIdea')}
                    </SortElement>
                    <SortElement sortKey="portfolioName" sorter={sorter}>
                      {t('Portfolio.Portfolio')}
                    </SortElement>
                  </SortElements>
                </Th>
                <Th sortKey="entityName" sorter={sorter}>
                  {t('Portfolio.Entity')}
                </Th>

                <ThRight sortKey="startYear" sorter={sorter}>
                  {t('Project.StartYear')}
                </ThRight>
                <ThRight sortKey="endYear" sorter={sorter}>
                  {t('Project.EndYear')}
                </ThRight>

                <Th style={{ width: 60 }} />
              </tr>
            </thead>

            <tbody>
              {sortedIdeas.map((idea) => (
                <Fragment key={idea.id}>
                  <Tr onSelect={() => handleSelect(idea)}>
                    <TdCentered className="narrow">
                      <IdeaStatus status={idea.projectPhase} />
                    </TdCentered>
                    <Td className="narrow" style={{ width: 80 }}>
                      {idea.externalId}
                    </Td>
                    <Td>
                      <div className="b">{idea.title}</div>
                      <div className="i">{idea.portfolioName}</div>
                    </Td>
                    <Td>{idea.entityName}</Td>

                    <TdRight>{idea.startYear}</TdRight>
                    <TdRight>{idea.endYear}</TdRight>

                    <TdRight style={{ verticalAlign: 'middle' }}>
                      {hasPermission(Permission.EditIdea, idea) && (
                        <IdeaListEditButtons idea={idea} />
                      )}
                    </TdRight>
                  </Tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={ideas.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />
    </>
  );
};

IdeaList.displayName = 'IdeaList';
