import { UserFiltersStyle } from '@dimatech/features-core/lib/components/UserFiltersStyle';
import { UserEditButtons } from '@dimatech/features-core/lib/features/administrate-users/components/UserEditButtons';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Button, Input } from '@dimatech/shared/lib/components/form';
import { Loader, LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin, ApiError, SortDirection } from '@dimatech/shared/lib/models';
import { formatDate } from '@dimatech/shared/lib/utils';
import {
  useAddEffectRealisationResponsibleMutation,
  useLazyGetEffectRealisationResponsibleQuery,
  useRemoveEffectRealisationResponsibleMutation,
} from 'api/project/effectRealisationResponsibleApi';

import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  getProjectEffectRealisationResponsibleExport,
  selectProjectEffectRealisationResponsibleExportIsPosting,
} from 'api/project/projectEffectRealisationResponsibleExportSlice';
import { SelectPortfolios } from 'components/SelectPortfolios';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CommonRoles, Project } from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArrowCounterclockwise,
  BsFileExcel,
  BsPersonBadge,
  BsPersonPlus,
  BsSearch,
} from 'react-icons/bs';
import { ProjectUserAdd } from './ProjectUserAdd';
import { ProjectsListForUser } from './ProjectsListForUser';

// eslint-disable-next-line max-lines-per-function
export const ProjectEffectRealisationResponsible = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const dispatch = useAppDispatch();
  const isExportPosting = useAppSelector(
    selectProjectEffectRealisationResponsibleExportIsPosting
  );

  const [selectedUser, setSelectedUser] = useState<Admin | undefined>();
  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();
  const [portfolioIds, setPortfolioIds] = useState<string[]>([]);
  const [email, setEmail] = useState<string>('');

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (accessToken.isInRole(CommonRoles.GlobalAdmin) ||
      accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const [getEffectRealisationResponsible, { data, isLoading }] =
    useLazyGetEffectRealisationResponsibleQuery();
  const [createEffectRealisationResponsible, { error }] =
    useAddEffectRealisationResponsibleMutation();
  const [removeEffectRealisationResponsible] =
    useRemoveEffectRealisationResponsibleMutation();

  const { items, sorter } = useSortableData(data ?? [], {
    key: 'email',
    direction: SortDirection.Asc,
  });

  const handleGetEffectRealisationResponsible = (
    portfolioIds?: string[],
    email?: string
  ) => {
    accessToken.customerId &&
      getEffectRealisationResponsible(
        {
          filter: {
            email: email || '',
            portfolioIds: portfolioIds || [],
          },
          _customerId: accessToken.customerId,
        },
        true
      );
  };

  const handleAdd = (user: Admin, portfolioId: string, project: Project) => {
    setValidationErrors(undefined);

    if (user?.email?.includes(' ')) {
      setValidationErrors?.(['Email']);
      return;
    }

    if (
      user.email &&
      project.effectRealizationResponsible?.includes(user.email)
    ) {
      setValidationErrors(['ErrorSameEmail']);
      return;
    }
    if (project.id && user.email) {
      createEffectRealisationResponsible({
        portfolioId,
        projectId: project.id,
        projectUsers: [user.email],
      });
    }
  };

  const handleDelete = (user: Admin) => {
    if (user.email) {
      removeEffectRealisationResponsible({
        projectUser: user.email,
      });
    }
  };

  const handleSelect = (user: Admin) => {
    setSelectedUser(selectedUser?.email === user.email ? undefined : user);
  };

  const handleSearch = () => {
    handleGetEffectRealisationResponsible(portfolioIds, email?.toLowerCase());
  };

  const handleResetFilter = () => {
    setEmail('');
    setPortfolioIds([]);
    handleGetEffectRealisationResponsible();
  };

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      getProjectEffectRealisationResponsibleExport({
        filter: {
          email: email || '',
          portfolioIds: portfolioIds || [],
        },
        name: t('UserRoles.PiosEffectRealizationResponsible.Title'),
      })
    );
  };

  useEffect(() => {
    handleGetEffectRealisationResponsible();
    setEmail('');
    setPortfolioIds([]);
    setValidationErrors(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.customerId]);

  useEffect(() => {
    if (error) {
      setValidationErrors((error as ApiError)?.validationErrors);
    } else {
      setValidationErrors(undefined);
    }
  }, [error]);

  if (isLoading) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PiosEffectRealizationResponsible.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PiosEffectRealizationResponsible.Title')}
          text={
            <TransArray i18nKey="UserRoles.PiosEffectRealizationResponsible.Help" />
          }
        />
      </HelpIconLeft>

      {isCustomerAccountAdmin && (
        <LinkWithTooltip
          isPrimary={true}
          handleClick={() => setIsAdding(true)}
          title={t('Project.EffectRealisationResponsible.Add.Title')}
          tooltipTitle={t(
            'Project.EffectRealisationResponsible.Add.TooltipTitle'
          )}
          tooltip={t('Project.EffectRealisationResponsible.Add.Tooltip')}
          icon={<BsPersonPlus />}
          style={{ marginTop: 20, marginBottom: 10 }}
        />
      )}

      {data && data.length > 0 && (
        <>
          <LinkWithTooltip
            title={t('Administrate.Entity.Export.Download.Download')}
            tooltipTitle={t('Administrate.Entity.Export.Download.TooltipTitle')}
            tooltip={t('Administrate.Entity.Export.Download.Tooltip')}
            icon={isExportPosting ? <LoaderSmall /> : <BsFileExcel />}
            handleClick={(e) => !isExportPosting && handleExportDownload(e)}
            isDisabled={isExportPosting}
            style={{ paddingTop: 10 }}
          />
        </>
      )}

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(
                `Project.EffectRealisationResponsible.ValidationError.${validationError}`
              )}
            </div>
          ))}
        </AlertWarning>
      )}

      {isAdding && (
        <ProjectUserAdd
          setIsAdding={setIsAdding}
          handleAddProjectUser={handleAdd}
          role={CommonRoles.PiosEffectRealizationResponsible}
        />
      )}

      <UserFiltersStyle>
        <SelectPortfolios
          portfolioIds={portfolioIds}
          setPortfolioIds={setPortfolioIds}
        />

        <div>
          <Input
            value={email}
            placeholder={t('Search.EmailPlaceholder')}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <BsSearch />
        </div>

        <div style={{ display: 'flex' }}>
          <Button type="button" onClick={() => handleSearch()}>
            {t('Search.Search')}
          </Button>

          <div style={{ marginTop: 5, marginLeft: 7 }}>
            <LinkWithTooltip
              isDisabled={!email && portfolioIds.length === 0}
              isInverted={true}
              handleClick={handleResetFilter}
              tooltip={t('Search.ResetFilters')}
              icon={<BsArrowCounterclockwise />}
            />
          </div>
        </div>
      </UserFiltersStyle>

      {data?.length === 0 && (
        <div style={{ marginTop: 20 }}>
          {t(
            `Project.EffectRealisationResponsible.NoEffectRealisationResponsible`
          )}
        </div>
      )}

      {data && data.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 15, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th sortKey="email" sorter={sorter}>
                  {t(`Project.Email`)}
                </Th>
                <ThRight sortKey="lastLoginDate" sorter={sorter}>
                  {t(`Project.LastLogin`)}
                </ThRight>
                {isCustomerAccountAdmin && <Th />}
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => (
                <Fragment key={index}>
                  <Tr
                    key={index}
                    isSelected={selectedUser?.email === item.email}
                    isHoverable={true}
                    onSelect={() => handleSelect(item)}
                  >
                    <Td style={{ width: 25 }}>
                      <BsPersonBadge />
                    </Td>
                    <Td>{item.email as string}</Td>
                    <TdRight
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </TdRight>
                    {isCustomerAccountAdmin && (
                      <TdRight>
                        <div
                          style={{
                            verticalAlign: 'middle',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <UserEditButtons
                            user={item}
                            setValidationErrors={setValidationErrors}
                            role={CommonRoles.PiosEffectRealizationResponsible}
                            deleteUser={handleDelete}
                          />
                        </div>
                      </TdRight>
                    )}
                  </Tr>

                  {selectedUser && selectedUser.email === item.email && (
                    <Tr>
                      <Td colSpan={4} style={{ padding: 0 }}>
                        <ProjectsListForUser
                          list={item.projects}
                          role={CommonRoles.PiosEffectRealizationResponsible}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

ProjectEffectRealisationResponsible.displayName =
  'ProjectEffectRealisationResponsible';
