/* eslint-disable max-lines-per-function */
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { ButtonSecondary, Buttons } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Modal,
  UnsavedChangesWarning,
} from '@dimatech/shared/lib/components/modal';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useTitle } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import {
  piosActions,
  selectFilterByIdea,
  selectHasUnsavedChanges,
  selectSelectedProjectIdea,
  selectSelectedProjectIdeaAction,
} from 'api/piosSlice';
import {
  getProjectIdeasExportRaw,
  selectProjectIdeasExportRawIsPosting,
} from 'api/project/projectIdeaExportRawSlice';
import { useGetProjectIdeasOverviewQuery } from 'api/project/projectIdeaOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { IdeaList } from 'features/project-idea/components/IdeaList';
import { IdeaListClassify } from 'features/project-idea/components/IdeaListClassify';
import { IdeaListPrioritise } from 'features/project-idea/components/IdeaListPrioritise';
import { IdeaListQualify } from 'features/project-idea/components/IdeaListQualify';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  Dimension,
  DimensionDisplayType,
  DimensionType,
  Phase,
  ProjectIdea,
  ProjectIdeaAction,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel } from 'react-icons/bs';
import { FaRegFileExcel } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { calculateProjectIdeasQuota } from 'utils/dimension';
import { IdeaEdit } from './components/IdeaEdit';
import { IdeaHistory } from './components/IdeaHistory';
import { IdeaListQualifyEffectRealisation } from './components/IdeaListQualifyEffectRealisation';
import { IdeaView } from './components/IdeaView';
import { useSelectAndNavigate } from './useSelectAndNavigate';

export const ProjectIdeas = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const location = useLocation();
    const { setTitle } = useTitle();

    const selectAndNavigate = useSelectAndNavigate();
    const searchedIdeaId = (location?.state as Record<string, string>)
      ?.ideaId as string;
    const [selectedIdeaId, setSelectedIdeaId] = useState<string>(
      searchedIdeaId || ''
    );

    const { accessToken } = useContext(AuthenticationContext);

    const filter = useAppSelector(selectFilterByIdea);
    const selectedIdea = useAppSelector(selectSelectedProjectIdea);
    const selectedAction = useAppSelector(selectSelectedProjectIdeaAction);
    const hasUnsavedChanges = useAppSelector(selectHasUnsavedChanges);

    const isExportRawPosting = useAppSelector(
      selectProjectIdeasExportRawIsPosting
    );

    const { action } = useParams<{
      action: ProjectIdeaAction;
    }>();

    const isProjectEffectRealisationEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
    const isProjectIdeasQualifyStepEnabledFlagOn =
      useFlags()[flags.active.app.pios.isProjectIdeasQualifyStepEnabled];
    const isProjectIdeasReviewersEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
    const ldContext = useLDClient()?.getContext();

    const { data: calculations } = useGetCalculationsQuery(
      accessToken.customerId ? accessToken.customerId : skipToken
    );

    const { data, isFetching, isLoading } = useGetProjectIdeasOverviewQuery(
      accessToken.customerId && accessToken.user?.id && !ldContext?.anonymous
        ? {
            filter,
            isProjectIdeasReviewersEnabled:
              isProjectIdeasReviewersEnabledFlagOn,
            _userId: accessToken.user.id,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

    const { data: customDimensions } = useGetDimensionsQuery(
      accessToken.customerId ? accessToken.customerId : skipToken
    );

    const [showSelectExportType, setShowSelectExportType] = useState(false);
    const [classifications, setClassifications] = useState<Dimension[]>([]);
    const [ideas, setIdeas] = useState<ProjectIdea[]>();
    const [newAction, setNewAction] = useState<ProjectIdeaAction>();
    const [isUnsavedChangesWarningShown, setIsUnsavedChangesWarningShown] =
      useState(false);

    const hasIdeas = ideas && ideas.length > 0;

    const handleSelectTab = (action: ProjectIdeaAction) => {
      if (action !== selectedAction && hasUnsavedChanges) {
        setIsUnsavedChangesWarningShown(true);
        setNewAction(action);
      } else if (
        action !== selectedAction &&
        selectedIdea &&
        !selectedIdea?.id
      ) {
        setIsUnsavedChangesWarningShown(true);
        setNewAction(action);
      } else {
        selectAndNavigate(action, selectedIdea);
      }
    };

    const handleCancel = () => {
      dispatch(piosActions.setHasUnsavedChanges(false));
      setIsUnsavedChangesWarningShown(false);
      dispatch(piosActions.setSelectedProjectIdea(undefined));
      if (newAction) {
        selectAndNavigate(newAction);
      }
    };

    useEffect(() => {
      if (customDimensions) {
        const dimensions = customDimensions
          .filter(
            (dimension) =>
              (dimension.type === DimensionType.ClassificationBenefit ||
                dimension.type === DimensionType.ClassificationStake) &&
              dimension.projectPhases?.includes(Phase.Draft)
          )
          .sort((_a, b) =>
            b.type === DimensionType.ClassificationBenefit ? 1 : -1
          )
          .sort((_a, b) =>
            b.displayType === DimensionDisplayType.Rating ? 1 : -1
          );

        setClassifications(dimensions);
      }
    }, [customDimensions]);

    useEffect(() => {
      if (!data) {
        return;
      }

      if (classifications.length === 0) {
        setIdeas(data);
      }

      const calculated = calculateProjectIdeasQuota({
        ideas: data,
        dimensions: classifications,
        calculations,
      });

      setIdeas(calculated);
    }, [data, classifications, calculations]);

    useEffect(() => {
      if (
        selectedIdea?.projectId &&
        ideas &&
        selectedAction !== ProjectIdeaAction.Edit &&
        selectedAction !== ProjectIdeaAction.Create &&
        selectedAction !== ProjectIdeaAction.View
      ) {
        const selected = ideas.find(
          (p) => p.projectId === selectedIdea?.projectId
        );

        if (!selected) {
          selectAndNavigate(selectedAction);
        }

        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ideas, selectedIdea, selectedAction]);

    useEffect(() => {
      dispatch(piosActions.setSelectedProjectIdeaAction(action));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    useEffect(() => {
      if (
        !selectedIdea &&
        (action === ProjectIdeaAction.Create ||
          action === ProjectIdeaAction.Edit ||
          action === ProjectIdeaAction.View)
      ) {
        selectAndNavigate(ProjectIdeaAction.Identify);
      }

      if (!selectedIdea?.id && action !== ProjectIdeaAction.Create) {
        dispatch(piosActions.setSelectedProjectIdea(undefined));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (selectedIdeaId && accessToken.customerId) {
        const idea = ideas?.find((idea) => idea.id === searchedIdeaId);
        if (idea) {
          dispatch(
            piosActions.setSelectedProjectIdea({
              ...idea,
            })
          );
          setSelectedIdeaId('');
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdeaId, ideas]);

    useEffect(() => {
      if (selectedIdea?.title) {
        setTitle(
          `${t('FutureProjects.ProjectIdea.ProjectIdea')} - ${
            selectedIdea?.title
          }`
        );

        return;
      }

      setTitle(t('FutureProjects.ProjectIdea.Title'));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdea]);

    const handleExportRawDownload = (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(
        getProjectIdeasExportRaw({
          filter,
          isProjectIdeasReviewersEnabled: isProjectIdeasReviewersEnabledFlagOn,
          name: t('FutureProjects.ProjectIdea.Title'),
        })
      );
    };

    return (
      <>
        <ViewHeader>
          <Heading1>{t('FutureProjects.ProjectIdea.Title')}</Heading1>

          <ViewHeaderActions>
            <LinkWithTooltip
              handleClick={() =>
                !isExportRawPosting && setShowSelectExportType(true)
              }
              title={t(
                'FutureProjects.ProjectIdea.Export.Download.TooltipTitle'
              )}
              tooltipTitle={t(
                'FutureProjects.ProjectIdea.Export.Download.TooltipTitle'
              )}
              tooltip={t('FutureProjects.ProjectIdea.Export.Download.Tooltip')}
              icon={isExportRawPosting ? <LoaderSmall /> : <FaRegFileExcel />}
              isInverted={true}
              isDisabled={isExportRawPosting}
            />
          </ViewHeaderActions>
        </ViewHeader>

        <Card>
          <CardBody>
            <SelectExtendedFilters
              isTagFilterShown={true}
              isPhaseFilterShown={false}
              isStatusFilterShown={false}
              isResetFilterShown={true}
              isPeriodFilterShown={true}
              isIncludeMineOnlyFilterShown={true}
              isProjectEffectTakeHomeFilterShown={false}
              isCustomDimensionFiltersShown={true}
              isProjectIdeaStatusesFilterShown={true}
              isPortfolioFilterShown={true}
              isPeriodMonthFilterShown={true}
              isProject={false}
            />
          </CardBody>
        </Card>

        {isUnsavedChangesWarningShown && (
          <UnsavedChangesWarning
            handleContinue={() => handleCancel()}
            handleCancel={() => setIsUnsavedChangesWarningShown(false)}
          />
        )}

        <Style disabled={!hasIdeas}>
          <Tabs>
            <Tab
              isSelected={
                selectedAction === ProjectIdeaAction.Identify ||
                selectedAction === ProjectIdeaAction.Edit ||
                selectedAction === ProjectIdeaAction.Create ||
                selectedAction === ProjectIdeaAction.View
              }
              handleSelect={() => handleSelectTab(ProjectIdeaAction.Identify)}
            >
              <BadgeMini>1</BadgeMini>{' '}
              {t('FutureProjects.ProjectIdea.ProjectIdeaAction.Identify')}
            </Tab>
            <Tab
              isSelected={selectedAction === ProjectIdeaAction.Classify}
              handleSelect={() =>
                hasIdeas && handleSelectTab(ProjectIdeaAction.Classify)
              }
            >
              <BadgeMini disabled={!hasIdeas}>2</BadgeMini>{' '}
              {t('FutureProjects.ProjectIdea.ProjectIdeaAction.Classify')}
            </Tab>

            {isProjectIdeasQualifyStepEnabledFlagOn && (
              <Tab
                isSelected={selectedAction === ProjectIdeaAction.Qualify}
                handleSelect={() =>
                  hasIdeas && handleSelectTab(ProjectIdeaAction.Qualify)
                }
                style={
                  isProjectEffectRealisationEnabledFlagOn
                    ? {
                        backgroundColor: theme.colors.background,
                        color: theme.colors.onSurface,
                        border: `1px solid ${theme.colors.surface}`,
                      }
                    : undefined
                }
              >
                <BadgeMini disabled={!hasIdeas}>3</BadgeMini>{' '}
                {t('FutureProjects.ProjectIdea.ProjectIdeaAction.Qualify')}
              </Tab>
            )}

            <Tab
              isSelected={selectedAction === ProjectIdeaAction.Prioritise}
              handleSelect={() =>
                hasIdeas && handleSelectTab(ProjectIdeaAction.Prioritise)
              }
            >
              <BadgeMini disabled={!hasIdeas}>
                {isProjectIdeasQualifyStepEnabledFlagOn ? 4 : 3}
              </BadgeMini>{' '}
              {t('FutureProjects.ProjectIdea.ProjectIdeaAction.Prioritise')}
            </Tab>
          </Tabs>
        </Style>

        {showSelectExportType && (
          <Modal
            title={t('FutureProjects.ProjectIdea.Export.Download.TooltipTitle')}
            handleKeyEsc={() => setShowSelectExportType(false)}
          >
            <div style={{ marginBottom: 15 }}>
              {t('FutureProjects.ProjectIdea.Export.Download.Text')}
            </div>

            <LinkWithTooltip
              title={t(
                'FutureProjects.ProjectIdea.Export.Download.DownloadRaw'
              )}
              tooltipTitle={t(
                'FutureProjects.ProjectIdea.Export.Download.TooltipTitle'
              )}
              tooltip={t(
                'FutureProjects.ProjectIdea.Export.Download.DownloadRaw'
              )}
              handleClick={(e) =>
                !isExportRawPosting && handleExportRawDownload(e)
              }
              isDisabled={isExportRawPosting}
              icon={isExportRawPosting ? <LoaderSmall /> : <BsFileExcel />}
            />

            <Buttons>
              <ButtonSecondary
                type="button"
                onClick={() => setShowSelectExportType(false)}
              >
                {t('Common.Form.Cancel')}
              </ButtonSecondary>
            </Buttons>
          </Modal>
        )}

        {ideas && (
          <Card>
            <CardBody isLoading={isFetching || isLoading}>
              {selectedAction === ProjectIdeaAction.Identify &&
                !selectedIdea && <IdeaList ideas={ideas} />}

              {(selectedAction === ProjectIdeaAction.View ||
                (selectedAction === ProjectIdeaAction.Identify &&
                  selectedIdea)) && <IdeaView ideas={data} />}

              {selectedAction === ProjectIdeaAction.Edit && <IdeaEdit />}

              {selectedAction === ProjectIdeaAction.History && <IdeaHistory />}

              {selectedAction === ProjectIdeaAction.Create && <IdeaEdit />}

              {selectedAction === ProjectIdeaAction.Classify && (
                <IdeaListClassify
                  ideas={ideas}
                  classifications={classifications}
                  calculations={calculations}
                />
              )}

              {selectedAction === ProjectIdeaAction.Qualify &&
                isProjectIdeasQualifyStepEnabledFlagOn && (
                  <>
                    {isProjectEffectRealisationEnabledFlagOn ? (
                      <IdeaListQualifyEffectRealisation ideas={ideas} />
                    ) : (
                      <IdeaListQualify ideas={ideas} />
                    )}
                  </>
                )}

              {selectedAction === ProjectIdeaAction.Prioritise && (
                <IdeaListPrioritise
                  ideas={ideas}
                  classifications={classifications}
                  calculations={calculations}
                />
              )}
            </CardBody>
          </Card>
        )}
      </>
    );
  }
);

ProjectIdeas.displayName = 'ProjectIdeas';

const Style = styled.div<{
  readonly disabled?: boolean;
}>`
  > div > div > div > div {
    box-shadow: none;
    margin-right: 8px;
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.surface};

    color: ${(props) =>
      props.disabled ? props.theme.colors.dim : props.theme.colors.onSurface};
  }
`;
